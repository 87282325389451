import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../Global';

// Enums
import { ThemeMode } from '../common/utilities/Enums';

// Styles
import './MobileHeader.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import MenuIcon from '../common/svg/MenuIcon';

// Components
import BackButton from './BackButton';
import MobileLogo from './MobileLogo';
import PromptModal from '../common/prompt/PromptModal';
import ThemeToggle from '../desktop/components/themetoggle/ThemeToggle';
import UserButton from '../common/components/userbutton/UserButton';
import QuickNoteButton from '../common/quicknote/QuickNoteButton';

const MobileHeader = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    backVisible,
    menuVisible,
    currentApp,
    setMenuVisible
  } = useContext(Global);

  const [promptOpen, setPromptOpen] = useState(false);
  const [logoSrc, setLogoSrc] = useState(null);

  useEffect(() => {
    if (!currentApp) return;

    setLogoSrc(currentThemeMode === ThemeMode.DARK ? currentApp.logoDark : currentApp.logoLight);

  }, [currentApp.key]);

  /**
   * TOGGLE MENU
   */
  const toggleMenu = async () => {
    setMenuVisible(!menuVisible);
  };

  return (
    <>
      <div className="mobile-header-container">
        <div className="mobile-header-left">
          {backVisible ? (
            <BackButton />
          ) : (
            <div
              onClick={toggleMenu}>
              {logoSrc ? (
                <img
                  src={logoSrc}
                  alt="App Logo"
                  className="mobile-logo-symbol"
                />
              ) : (
                <MenuIcon
                  color={theme.foregroundColor}
                  width="25"
                  height="25"
                />
              )}
            </div>
          )}

          <MobileLogo />

        </div>
        <div className="mobile-header-right">

          {/* THEME */}
        <div className="mobile-header-theme-toggle">
          {currentApp &&
            <ThemeToggle />
          }
          </div>

          <UserButton />
        </div>
      </div>

      {/* PROMPT MODAL */}
      <PromptModal
        isOpen={promptOpen}
        setOpen={setPromptOpen}
      />

    </>
  );
};

export default MobileHeader;
