import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { FormMode, MenuType, Section, ThemeMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Desktop.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import gradient from '../site/gradient.jpg';
import gradientlight from '../site/gradientlight.png';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AppBar from '../common/components/appbar/AppBar';
import AppSettings from '../common/appsettings/AppSettings';
import Members from '../common/members/Members';
import Bookmarks from '../common/bookmarks/Bookmarks';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channels/chat/Chat';
import Favorites from '../common/favorites/Favorites';
import FieldSelector from '../common/form/fieldselector/FieldSelector';
import Form from '../common/form/Form';
import Forum from '../common/channels/forum/Forum';
import Menu from '../common/menu/Menu';
import MenuBar from '../common/menu/menubar/MenuBar';
import Messages from '../common/messages/Messages';
import Newsfeed from '../common/newsfeed/Newsfeed';
import Objects from '../common/objects/Objects';
import TitleBar from '../common/titlebar/TitleBar';
import VideoChat from '../common/channels/videochat/VideoChat';

// Dynamic Styles
import { DesktopStyles } from './DesktopStyles';

/**
 * Desktop Component
 * 
 * This component renders a desktop version of the app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Desktop = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    accountAboutVisible,
    currentSection,
    fieldSelectorVisible,
    formMode,
    menuType,
    currentApp,
    setFormMode,
  } = useContext(Global);

  /** 
   * In desktop mode, show the add form on the right side of the screen
   */
  useEffect(() => {
    if (!isMobile) {
      setFormMode(FormMode.ADD);
    }
  }, [setFormMode]);

  const renderContent = () => {

    if (currentSection === Section.BOOKMARKS) return <Bookmarks />;
    if (currentSection === Section.CALENDARS) return <Calendar />;
    if (currentSection === Section.CHAT) return <Chat />;
    if (currentSection === Section.FAVORITES) return <Favorites />;
    if (currentSection === Section.FORUM) return <Forum />;
    if (currentSection === Section.MEMBERS) return <Members />;
    if (currentSection === Section.MESSAGES) return <Messages />;
    if (currentSection === Section.NEWSFEEDS) return <Newsfeed />;
    if (currentSection === Section.SETTINGS) return <AppSettings />;
    if (currentSection === Section.VIDEOCHAT) return <VideoChat />;

    if (currentSection === Section.ACCOUNT) return (
      <>
        <AccountMenu />
        {accountAboutVisible ? <AccountAbout /> : <AccountBilling />}
      </>
    );

    return (
      <>
        {/* OBJECTS */}
        <Objects />

        {/* FIELD SELECTOR */}
        {fieldSelectorVisible ? (
          <div className="desktop-field-selector">
            <div className="desktop-field-selector-header"
              style={{ color: theme.foregroundColorFaded }}>
              Add Field
            </div>
            <FieldSelector
              size="LARGE"
            />
          </div>
        ) : (
          <>
            {/* FORM */}
            {formMode &&
              <Form />
            }
          </>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!currentApp) return;
    loadFont(currentApp.fontFamily || defaultFont);
  }, [currentApp]);

  return (
    <>
      <DesktopStyles
        fontFamily={currentApp && currentApp.fontFamily || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="desktop-container"
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: currentThemeMode === ThemeMode.DARK ? `url(${gradient})` : `url(${gradientlight})`,
          backgroundSize: 'cover', // Ensures the image covers the entire screen
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
          height: '100vh', // Ensures it takes up the full viewport height
          width: '100vw', // Ensures it takes up the full viewport width
        }}>

        {/* TITLE BAR */}
        {currentApp && (
          <div className="desktop-title-bar">
            <TitleBar />
          </div>
        )}

        <div className="desktop-main">

          {/* APP BAR */}
          <div className="desktop-left">
            <AppBar />
          </div>

          <div className="desktop-right">

            {/* MENU BAR */}
            {menuType === MenuType.TOP && (
              <div className={`desktop-menu-bar-wrapper ${menuType === MenuType.TOP ? 'menu-bar-visible' : 'menu-bar-hidden'}`}>
                <MenuBar />
              </div>
            )}

            <div className="desktop-middle"
              style={{
                height: menuType === MenuType.LEFT ? "calc(100vh - 50px)" : "calc(100vh - 100px)"
              }}>

              {/* MENU */}
              <div
                className={`desktop-menu-wrapper ${menuType === MenuType.LEFT ? 'menu-visible' : 'menu-hidden'}`}>
                <Menu />
              </div>

              {/* CONTENT */}
              {renderContent()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
