import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './SettingItem.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Images
import SettingsIcon from '../../../svg/SettingsIcon';

// Inline Styles
const InlineStyles = useStyle`
    .setting-item-container:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

/**
 * SettingItem Component
 * 
 * This component renders a setting item in the menu.
 * 
 * @param {object} setting - The setting to represent in the menu.
 * @param {boolean} isActive - Indicates if the item has been selected.
 * @param {function} onClick - The function to call on a click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const SettingItem = ({ setting, isActive, onClick }) => {
    const { theme } = useTheme();
    const {
        currentApp,
    } = useContext(Global);

    const IconComponent = setting.icon;

    return (
        <>
            {/* STYLES */}
            <InlineStyles hoverColor={theme.backgroundColorFaded} />

            {/* CONTAINER */}
            <div
                className="setting-item-container"
                style={{
                    backgroundColor: isActive ? theme.highlightBackgroundColor : 'transparent',
                }}>
                <div className="setting-item-left" onClick={onClick}>

                    {/* ICON */}
                    <IconComponent
                        color={isActive ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="18"
                        height="14"
                    />

                    {/* TEXT */}
                    <div
                        className="setting-item-text"
                        style={{
                            color: isActive ? theme.highlightForegroundColor : theme.foregroundColor,
                            fontFamily: currentApp.fontFamily,
                        }}>
                        {setting && setting.title}
                    </div>

                </div>

            </div>
        </>
    );
};

export default SettingItem;
