import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission } from '../../../common/utilities/Enums';
import { hasCollectionPermission } from '../../../common/utilities/Permissions';

// Styles
import './EventObjectSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../../common/svg/PlusIcon';

// Components
import EventDivider from './EventDivider';
import Search from '../../../common/search/Search';

const EventObjectSelector = ({
    onObjectSelect
}) => {
    const { theme } = useTheme();
    const {
        currentUser,
        appCollections,
        currentApp,
    } = useContext(Global);

    const [searchVisible, setSearchVisible] = useState(false);
    const [selectedCollectionKey, setSelectedCollectionKey] = useState('');

    const handleAdd = (modelKey) => {
        setSelectedCollectionKey(modelKey);
        setSearchVisible(true);
    };

    const handleSearch = (entry) => {
        setSearchVisible(false);
        onObjectSelect(entry);
    };

    return (
        <>
            {appCollections.map((model) => {
                // Only return if the user has the appropriate permission.
                if (!hasCollectionPermission(currentApp, currentUser, model, Permission.READ)) {
                    return null; // Skip this model if no permission
                }

                return (
                    // Assign a key to this top-level fragment returned by map
                    <React.Fragment key={model.key}>
                        <div
                            className="event-object-selector-wrapper"
                            onClick={() => handleAdd(model.key)}
                        >
                            {/* Button for adding the object */}
                            <div className="event-object-selector-button">
                                <PlusIcon
                                    color={theme.foregroundColorFaded}
                                    width="12"
                                    height="12"
                                />
                            </div>

                            {/* Label for what the button does */}
                            <div
                                className="event-object-button-add-label"
                                style={{
                                    color: theme.foregroundColorFaded,
                                    fontFamily: currentApp.fontFamily,
                                }}
                            >
                                Add {model.title}
                            </div>
                        </div>

                        {/* A divider between different event objects */}
                        <EventDivider />
                    </React.Fragment>
                );
            })}

            {/* SEARCH MODAL */}
            <Search
                onSearch={handleSearch}
                isVisible={searchVisible}
                setVisible={setSearchVisible}
                modelKey={selectedCollectionKey}
            />
        </>
    );

};

export default EventObjectSelector;
