import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserFields.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserFieldsGroup from './UserFieldsGroup';
import UserFieldsLabel from './UserFieldsLabel';
import UserFieldsRequired from './UserFieldsRequired';
import UserFieldsRow from './UserFieldsRow';
import UserFieldsVisibility from './UserFieldsVisibility';

const UserFields = ({ requiredFields, setRequiredFields, displayKey, setDisplayKey }) => {
    // Theme
    const { theme } = useTheme();
    const { currentApp } = useContext(Global);

    // Load initial state from currentApp.requiredFields and currentApp.displayKey
    useEffect(() => {
        if (currentApp.requiredFields) {
            setRequiredFields(currentApp.requiredFields);
        }
        if (currentApp.displayKey) {
            setDisplayKey(currentApp.displayKey);
        }
    }, [currentApp?.key, setRequiredFields, setDisplayKey]);

    // Handle changes to required fields
    const handleRequiredChange = (field, value) => {
        const updatedFields = {
            ...requiredFields,
            [field]: value,
        };
        
        // If the field is the current displayKey and is being unchecked, reset displayKey to 'username'
        if (!value && displayKey === field) {
            setDisplayKey('username');
        }
        
        setRequiredFields(updatedFields);
    };

    // Handle changes to displayKey
    const handleDisplayKeyChange = (e) => {
        const newDisplayKey = e.target.value;
        setDisplayKey(newDisplayKey);
    };

    // Filter the available select options based on required fields
    const availableDisplayKeyOptions = [
        { value: 'username', label: 'Username (Default)' },
        requiredFields.fullname && { value: 'fullname', label: 'Full Name' },
        requiredFields.displayname && { value: 'displayname', label: 'Display Name' },
        requiredFields.handle && { value: 'handle', label: '@ Handle' },
    ].filter(Boolean); // Remove false/undefined options

    return (
        <div className="user-fields-container">

            <UserFieldsGroup title="Required for All Users">
                <UserFieldsRow>
                    <UserFieldsLabel label="Username" />
                    <UserFieldsVisibility visible={true} />
                </UserFieldsRow>
                <UserFieldsRow>
                    <UserFieldsLabel label="Email Address" />
                    <UserFieldsVisibility visible={false} />
                </UserFieldsRow>
            </UserFieldsGroup>

            <UserFieldsGroup title="Can Be Required">
                <UserFieldsRow>
                    <UserFieldsLabel label="Full Name" />
                    <UserFieldsRequired
                        checked={requiredFields.fullname}
                        onChange={(newChecked) => handleRequiredChange('fullname', newChecked)}
                    />
                    <UserFieldsVisibility visible={true} />
                </UserFieldsRow>

                <UserFieldsRow>
                    <UserFieldsLabel label="Display Name" />
                    <UserFieldsRequired
                        checked={requiredFields.displayname}
                        onChange={(newChecked) => handleRequiredChange('displayname', newChecked)}
                    />
                    <UserFieldsVisibility visible={true} />
                </UserFieldsRow>

                <UserFieldsRow>
                    <UserFieldsLabel label="@ Handle" />
                    <UserFieldsRequired
                        checked={requiredFields.handle}
                        onChange={(newChecked) => handleRequiredChange('handle', newChecked)}
                    />
                    <UserFieldsVisibility visible={true} />
                </UserFieldsRow>

                <UserFieldsRow>
                    <UserFieldsLabel label="Birthdate" />
                    <UserFieldsRequired
                        checked={requiredFields.birthdate}
                        onChange={(newChecked) => handleRequiredChange('birthdate', newChecked)}
                    />
                    <UserFieldsVisibility visible={false} />
                </UserFieldsRow>
            </UserFieldsGroup>

            <UserFieldsGroup title="User-to-User Identifier">
                <select
                    className="user-fields-select"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                        color: theme.foregroundColor,
                        fontFamily: currentApp.fontFamily,
                    }}
                    value={displayKey}
                    onChange={handleDisplayKeyChange}
                >
                    {availableDisplayKeyOptions.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </UserFieldsGroup>

        </div>
    );
};

export default UserFields;
