import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Styles
import './NewsfeedAdd.css';

// Components
import NewsfeedTags from '../newsfeedtags/NewsfeedTags';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * NewsfeedAdd Component
 * 
 * This component allows the user to add a newsfeed.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NewsfeedAdd = () => {

    // Global
    const {
        newsfeedAddOpen,
        currentUser,
        hideProgress,
        appNewsfeeds,
        currentApp,
        setNewsfeedAddOpen,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);

    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a newsfeed title.');
            return;
        }

        showProgress("Adding newsfeed...");

        setNewsfeedAddOpen(false);

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const appKey = currentApp.key;

        const newNewsfeedKey = generateKey();  // Generate key for the new newsfeed

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each newsfeed to find the highest sort number
        appNewsfeeds.forEach(newsfeed => {
            const sortNumber = newsfeed.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        // Create the newsfeed data
        const newsfeedData = {
            key: newNewsfeedKey,
            appKey: appKey,
            userKey: userKey,
            username: username,
            title: title,
            description: description,
            sort: highestSortNumber + 1,
            tags: tags,
            dateCreated: now,
            dateModified: now,
        };

        // Add the newsfeed to the database first
        await dataManager.add(
            collections.newsfeeds,
            appKey, 
            newNewsfeedKey, 
            newsfeedData
        );

        // Reset the title field
        setTitle('');

        // Reset the description field
        setDescription('');

        // Hide the progress panel
        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Newsfeed"
                isOpen={newsfeedAddOpen}
                onClose={() => setNewsfeedAddOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Newsfeed Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <NewsfeedTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* AI FIELD GENERATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Generate Fields"
                        value={autogen}
                        onChange={() => setAutogen(!autogen)}
                    />

                </ModalSection>
                */}

                {/* AI DATA POPULATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Populate Data"
                        value={autopop}
                        onChange={() => setAutopop(!autopop)}
                    />

                </ModalSection>
                */}

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Continue..."
                    />

                </ModalButtons>

            </Modal >
        </>
    );
};

export default NewsfeedAdd;
