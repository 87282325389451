// Utilities
import { generateKey } from '../../common/utilities/Keys';

// Managers
import PromptManager from './PromptManager';

const promptManager = new PromptManager();

class RoleManager {

    /**
     * Generates a roles for a new app with the provided app title and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable nested object that can be used to determine a role hierarchy.
     */
    async generateRoles(appTitle, appDescription, temperature) {

        const rolesPrompt = await this.prepareRolesPrompt(appTitle, appDescription);

        const response = await promptManager.sendJsonPrompt(rolesPrompt, temperature);

        return response.roles;
    };

    /**
     * Prepares a prompt for generating a role hierarchy for an app based on an app's title 
     * and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * 
     * @returns Prompt that can be used to generate a list of roles.
     */
    async prepareRolesPrompt(appTitle, appDescription) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        The goal of your response is to define an initial role hierarchy for an app based upon the title
        and description of the app. 

        The top-level role must always be "Administrator". All other roles should be nested under this role in
        a hierarchical structure that makes sense for the purpose of the app.

        Please provide a title and description of each role.

        If nothing beyond "Administrator" is applicable, you can simply return just the "Administrator" role.

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Do not add comments or characters that might lead to the inability to parse the JSON.

        [APP TITLE AND DESCRIPTION]
        TITLE: ${appTitle}
        DESCRIPTION: ${appDescription}

        Do not structure the response differently from the example.

        [EXAMPLE RESPONSE]
        {
            roles: [
                {
                title: "Administrator",
                description: "The top-level role with full access to all features.",
                children: [
                    {
                    title: "Manager",
                    description: "Manages the team and has access to all features except for user management.",
                    children: [
                        {
                        title: "Team Lead",
                        description: "Leads a team and has access to all features except for user management.",
                        children: [
                            { 
                                title: "Developer", 
                                description: "Develops new features and maintains existing codebase.",
                                children: [] 
                            },
                            { 
                                title: "QA Tester", 
                                description: "Tests new features and reports bugs.",
                                children: [] 
                            }
                        ]
                        },
                        { 
                            title: "HR Specialist", 
                            description: "Manages HR-related tasks and has access to HR features.",
                            children: [] 
                        }
                    ]
                    },
                    {
                        title: "Support Staff",
                        description: "Provides customer support and has access to support features.",
                        children: [
                            { 
                                title: "Customer Support Representative", 
                                description: "Assists customers with issues and provides solutions.",
                                children: [] 
                            },
                            { 
                                title: "Technical Support Specialist", 
                                description: "Assists customers with technical issues and provides solutions.",
                                children: [] 
                            }
                        ]
                    }
                ]
                }
            ]
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }

    /**
     * Flattens a nested role object structure into an array of roles.
     * 
     * @param {string} appKey - The key of the app.
     * @param {string} userKey - The user key.
     * @param {object[]} roles - The nested role object structure.
     * @param {string|null} parentKey - The parent key for the current role.
     * @param {number} level - The current priority level in the hierarchy (default is 1).
     * @param {boolean} isTopLevel - Indicates if processing the top-level role.
     * 
     * @returns {Promise<object[]>} A Promise resolving to an array of roles with a unique key for each role.
     */
    async flattenRoles(appKey, userKey, roles, parentKey = null, level = 1, isTopLevel = true) {
        const flatRoles = [];

        for (const role of roles) {
            const roleKey = generateKey();

            // Add the current role to the flat list
            flatRoles.push({
                key: roleKey,
                appKey: appKey,
                userKey: userKey,
                title: role.title,
                description: role.description,
                parentKey: parentKey,
                priorityLevel: level, // Use the current level for priority
                isCreator: isTopLevel && level === 1 // Set isCreator to true for the top-level Administrator role
            });

            // Recursively process children, incrementing the level
            if (role.children && role.children.length > 0) {
                const childRoles = await this.flattenRoles(appKey, userKey, role.children, roleKey, level + 1, false);
                flatRoles.push(...childRoles);
            }
        }

        return flatRoles;
    }

}

export default RoleManager;
