import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode } from '../utilities/Enums';

// Styles
import './ObjectsGridItem.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import CheckOffIcon from '../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../common/svg/CheckOnIcon';

const ObjectsGridItem = ({ object }) => {
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        appFields,
        formMode,
        appCollections,
        selectedObject,
        setSelectedObject,
        setFormMode,
        setChangesMade,
    } = useContext(Global);

    // Local State
    const [titleFieldKey, setTitleFieldKey] = useState(null);
    const [model, setModel] = useState(null);

    // Defines which field types are considered simple "text-like" fields
    const simpleTypes = [
        'currency',
        'number',
        'phone',
        'text',
        'time',
        'year'
    ];

    // On component mount or when dependencies change, find the model and set the titleFieldKey
    useEffect(() => {
        if (!appFields || !appCollections) return;
        const m = appCollections.find((model) => model.key === object.modelKey);
        if (m) {
            setModel(m);
            setTitleFieldKey(m.titleFieldKey);
        }
    }, [appFields, appCollections, object.modelKey]);

    /**
     * Retrieves the field metadata for a given key.
     * @param {string} key - The key of the field to retrieve.
     * @returns {object|null} - The field metadata or null if not found.
     */
    function getField(key) {
        return appFields.find((field) => field.key === key) || null;
    }

    /**
     * Handles click on the object.
     * When clicked, sets the selected object and updates the form mode if needed.
     */
    const handleClick = async (e) => {
        e.stopPropagation();

        // Set the selected object
        setSelectedObject(object);

        // If the mode is ADD, switch it to EDIT (or VIEW based on your logic)
        if (formMode === FormMode.ADD) {
            setFormMode(FormMode.EDIT);
        }

        // Initialize changes made to false whenever a new object is selected
        setChangesMade(false);
    };

    /**
     * Sort fields according to the model's fieldSort array.
     * Fields not found in the sorting array appear at the end.
     * @param {Array} fields - The fields to sort.
     * @param {Array} fieldSort - The order to sort fields by.
     * @returns {Array} Sorted fields.
     */
    const sortFieldsByModelFieldSort = (fields, fieldSort) => {
        if (!fieldSort || !Array.isArray(fieldSort)) {
            // If fieldSort doesn't exist, just return fields as-is.
            console.log('Field sort not found');
            return fields;
        }
        return fields.sort((a, b) => {
            const indexA = fieldSort.indexOf(a.field.key);
            const indexB = fieldSort.indexOf(b.field.key);

            // Fields not in fieldSort will appear at the end.
            return (indexA === -1 ? Infinity : indexA) - (indexB === -1 ? Infinity : indexB);
        });
    };

    // Prepare and sort fields
    let sortedFields = [];
    if (model) {
        // Convert the object's entries into an array of { key, value, field }.
        // Filter out entries that don't have a corresponding field definition.
        const fieldsArray = Object.entries(object)
            .map(([key, value]) => {
                const field = getField(key);
                return field ? { key, value, field } : null;
            })
            .filter(Boolean);

        sortedFields = sortFieldsByModelFieldSort(fieldsArray, model.fieldSort);
    }

    // Find the first gallery field that has at least one image
    const galleryField = sortedFields.find(({ field, value }) =>
        field.type === 'gallery' && Array.isArray(value) && value.length > 0 && value[0].url
    );

    // If a gallery field with images is found, we will display the first image at the top.
    // We also will filter that gallery field out of the main rendering since we've already displayed it.
    let fieldsToRender = sortedFields;
    if (galleryField) {
        fieldsToRender = sortedFields.filter((f) => f !== galleryField);
    }

    return (
        <div
            key={object.key}
            className="objects-grid-item-container"
            onClick={(e) => handleClick(e)}
            style={{
                // Highlight this row if it's the selected object
                backgroundColor: (selectedObject && selectedObject.key === object.key)
                    ? theme.highlightBackgroundColor
                    : theme.backgroundColorFaded,
            }}>
                
            {/* If we have a gallery field with an image, show the first image before anything else */}
            {galleryField && galleryField.value[0] && (
                <div className="objects-grid-item-image-wrapper">
                    {/* Display the first image from the gallery */}
                    <img
                        src={galleryField.value[0].url}
                        alt="Gallery Preview"
                        className="objects-grid-item-image"
                    />
                </div>
            )}

            <div className="objects-grid-item-text-fields-container">

                {/* Now render all other fields except the gallery field we handled above */}
                {fieldsToRender.map(({ key, value, field }) => {
                    // Determine text color based on selection and whether it's the title field
                    const textColor = (selectedObject && selectedObject.key === object.key)
                        ? theme.highlightForegroundColor
                        : (key === titleFieldKey
                            ? theme.foregroundColor
                            : theme.foregroundColorFaded);

                    // Simple text-like fields
                    if (simpleTypes.includes(field.type)) {
                        return (
                            <div
                                key={key}
                                className={key === titleFieldKey ? "objects-grid-item-title" : "objects-grid-item-field"}
                                style={{ color: textColor }}
                            >
                                {value && value.toString().split('\n').map((line, index) => (
                                    <React.Fragment key={index}>
                                        {line}
                                        <br />
                                    </React.Fragment>
                                ))}
                            </div>
                        );
                    }

                    // Lookup fields
                    if (field.type === 'lookup') {
                        const optionTitle = field.options.find((option) => option.key === value)?.title;
                        return (
                            <div
                                key={key}
                                className={key === titleFieldKey ? "objects-grid-item-title" : "objects-grid-item-field"}
                                style={{ color: textColor }}
                            >
                                {optionTitle}
                            </div>
                        );
                    }

                    // Password fields
                    if (field.type === 'password') {
                        return (
                            <div
                                key={key}
                                className={key === titleFieldKey ? "objects-grid-item-title" : "objects-grid-item-field"}
                                style={{ color: textColor }}
                            >
                                *********
                            </div>
                        );
                    }

                    // Checklist fields
                    if (field.type === 'checklist') {
                        return (
                            <div
                                key={key}
                                className="objects-grid-item-checklist"
                            >
                                {value.map((item, index) => (
                                    <div
                                        key={index}
                                        className="objects-grid-item-checklist-item"
                                        style={{ color: textColor }}
                                    >
                                        {item.checked ? (
                                            <CheckOnIcon
                                                color={(selectedObject && selectedObject.key === object.key)
                                                    ? theme.highlightForegroundColor
                                                    : theme.backgroundColorFaded}
                                                width="9"
                                                height="9"
                                            />
                                        ) : (
                                            <CheckOffIcon
                                                color={(selectedObject && selectedObject.key === object.key)
                                                    ? theme.highlightForegroundColor
                                                    : theme.backgroundColorFaded}
                                                width="9"
                                                height="9"
                                            />
                                        )}
                                        {item.title}
                                    </div>
                                ))}
                            </div>
                        );
                    }

                    // For any other field types not handled above, return null
                    return null;
                })}

            </div>

        </div>
    );
};

export default ObjectsGridItem;
