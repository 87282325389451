import axios from 'axios';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';
import { FormMode } from '../utilities/Enums';

// Managers
import DataManager from './DataManager';
import ObjectManager from './ObjectManager';

const dataManager = new DataManager();
const objectManager = new ObjectManager();

class PromptManager {

    /**
     * Cleans a JSON string by removing any characters before the first '{' and after the last '}'.
     *
     * @param {string} Json string to clean.
     * 
     * @returns {string} Cleaned JSON string.
     */
    cleanJSON = (jsonString) => {
        try {
            // Remove any characters before the first '{'
            const startIndex = jsonString.indexOf('{');
            // Remove any characters after the last '}'
            const endIndex = jsonString.lastIndexOf('}') + 1;

            // Extract the valid JSON string
            const cleanString = jsonString.substring(startIndex, endIndex);

            // Parse the cleaned JSON string
            return cleanString;
        } catch (e) {
            throw new Error('Invalid JSON string');
        }
    };

    /**
     * Requests a response in Json format.
     * 
     * @param {string} promptContent - The textual content of the prompt.
     * 
     * @returns JSON object containing the response.
     */
    async sendJsonPrompt(promptContent, temperature) {
        const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
        const endpoint = 'https://api.openai.com/v1/chat/completions';

        const preparedPrompt = {
            model: "gpt-4o",
            messages: [
                {
                    role: "system",
                    content: "You are a JSON-only responder. Only return valid JSON responses."
                },
                {
                    role: "user",
                    content: promptContent
                }
            ],
            temperature: temperature
        };

        try {
            const response = await axios.post(endpoint, preparedPrompt, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            });

            const content = response.data.choices[0].message.content;

            return JSON.parse(this.cleanJSON(content));
        } catch (error) {
            console.error('Error sending prompt:', error);
        }
    }













    // Function to prepare the prompt and function definition
    async processPrompt(prompt) {

        // Define the function structure based on your initial design
        const functionDefinition = {
            name: "process_prompt",
            description: "Processes user input to determine the appropriate structured data for models and events.",
            parameters: {
                type: "object",
                properties: {
                    models: {
                        type: "array",
                        description: "A list of models to be created or updated, each with its fields and values.",
                        items: {
                            type: "object",
                            properties: {
                                title: { type: "string", description: "Human-readable title for the model." },
                                description: { type: "string", description: "Human-readable description for the model." },
                                titleFieldValue: { type: "string", description: "Human-readable title for the record." },
                                fields: {
                                    type: "array",
                                    description: "List of fields associated with the model.",
                                    items: {
                                        type: "object",
                                        properties: {
                                            title: { type: "string", description: "Human-readable title for the field." },
                                            description: { type: "string", description: "Human-readable description for the field." },
                                            type: { type: "string", description: "Type of data this field accepts." },
                                            value: { /* same as original schema */ },
                                        }
                                    }
                                }
                            }
                        }
                    },
                    appEvents: {
                        type: "array",
                        description: "A list of calendar events to be created.",
                        items: {
                            type: "object",
                            properties: {
                                title: { type: "string", description: "Title of the event." },
                                calendar: { type: "string", description: "Title of the calendar in which to place the event." },
                                startDateTime: { type: "string", description: "Start date and time in YYYY-MM-DDTHH:mm:ss format." },
                                endDateTime: { type: "string", description: "End date and time in YYYY-MM-DDTHH:mm:ss format, if applicable." },
                                notes: { type: "string", description: "Additional notes or details about the event." }
                            }
                        }
                    }
                }
            }
        };

        // Prepare the payload
        const preparedPrompt = {
            model: "gpt-4",
            messages: [
                {
                    role: "user",
                    content: prompt // Pass the raw user prompt
                }
            ],
            functions: [functionDefinition], // Include the function definition
            function_call: { name: "process_prompt" } // Force the function call
        };

        return preparedPrompt; // Return the prepared payload
    }

    // Function to send the request to the API
    async send(preparedPrompt) {
        const apiKey = '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';
        const endpoint = 'https://api.openai.com/v1/chat/completions';

        try {
            const response = await axios.post(endpoint, preparedPrompt, {
                headers: {
                    'Authorization': `Bearer ${apiKey}`,
                    'Content-Type': 'application/json'
                }
            });

            // Check if the response contains a function call result
            if (response.data && response.data.choices && response.data.choices[0].message.function_call) {
                return response.data.choices[0].message.function_call.arguments; // Return structured data
            }

            return 'No structured response found.'; // Fallback message
        } catch (error) {
            console.error('Error calling the ChatGPT API:', error);
            if (error.response && error.response.status === 429) {
                const retryAfter = error.response.headers['retry-after'];
                console.log(`Rate limit exceeded. Retry after ${retryAfter} seconds.`);
            }
            return 'Failed to get response from the API.'; // Return error message as a string
        }
    }


    async prepareAppPrompt(template, title, description) {

        let instructions = `
[APP CREATION - START PRELIMINARY INSTRUCTIONS]

[GOAL]
The goal of your response is to define the scaffolding of an app that the user is looking for. 
Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
Please do not add comments or characters that might lead to the inability to parse the JSON.

The template information will be the basis for the app. The user will provide a title and description for the app,
which you can use to fine-tune the app's purpose, structure and feature set.

[TEMPLATE INFORMATION]
KEY: ${template.key}
NAME: ${template.name}
DESCRIPTION: ${template.description}

[TITLE AND APP DESCRIPTION]
TITLE: ${title}
DESCRIPTION: ${description}

[CHANNELS]
Most, but not all, userApps will need channels for communication purposes. There are three types of channels: TEXT, FORUM, and VIDEO. These channels are similar to Discord channels. 
Please provide a list of pre-built channels that might be useful for users the app.

[MODELS]
The data portion of the app is based upon models. The models will have fields eventually, but for now I simply want to identify as many models
as possible that could make as comprehensive and useful an app as possible. Model titles should be plural. Titles will be used
as menu items in the app, so please be user friendly with the titles. Model descriptions will
provide some useful information about the purpose of each model in the app.

It is not necessary to create the following types of models, as they are already build into the system:
- User
- Calendar/Scheduling
- Messaging/Chat
- Payments and Commerce

[THEME]
The app will also need a theme. We need two color schemes for the app, one for dark mode and one for light mode.
Please choose a color scheme for the app that might appeal to potential users and that reflects the purpose of the app. 
Please provide unique hex values for the following:
- backgroundColorDark
- backgroundColorDarkFaded
- foregroundColorDark
- foregroundColorDarkFaded
- highlightBackgroundColorDark (button backgrounds)
- highlightForegroundColorDark (button foregrounds)
- backgroundColorLight
- backgroundColorLightFaded
- foregroundColorLight
- foregroundColorLightFaded
- highlightBackgroundColorLight
- highlightForegroundColorLight

Please return the response in JSON format exactly as I have outlined. Do not add any nesting, do not introduce any new fields, and do not structure the response differently from the example provided below. Here's the exact format:

[EXAMPLE RESPONSE]
{
    "appTitle": "${title}",
    "appDescription": "${description}",
    "templateKey: "${template.key}",
    "backgroundColorDark": "#1F2937",
    "backgroundColorDarkFaded": "#374151",
    "foregroundColorDark": "#FFFFFF",
    "foregroundColorDarkFaded": "#999999",
    "highlightColorDark": "#C9700F",
    "backgroundColorLight": "#111111",
    "backgroundColorLightFaded": "#444444",
    "foregroundColorLight": "#222222",
    "foregroundColorLightFaded": "#444444",
    "highlightColorLight": "#C9700F",
    "models": [
        {
            "title": "Realtors",
            "description": "The realtors in our group."
        },
        {
            "title": "Listings",
            "description": "All listings in our firm."
        },
        {
            "title": "Leads",
            "description": "A list of potential buyers."
        },
        {
            "title": "Contacts",
            "description": "Helpful list of contacts for our realtors."
        },
        {
            "title": "Appointments",
            "description": "Scheduling for all realtors."
        },
    ],
    "channels": [
        {
            "type": "CHAT",
            "title": "General Chat",
            "description": "General chat for realtors in our group."
        },
        {
            "type": "FORUM",
            "title": "Client Guidelines",
            "description": "Key guidelines for our clients."
        }
    ]
}

[END INSTRUCTIONS]

        `;

        return instructions;
    }





    async processModelResponse(
        r,
        app,
        model,
        showProgress,
        setSelectedCollection,
        setFormMode,
        resetSelections,
        currentUser
    ) {
        try {
            showProgress("Generating " + model.title + " user interface...");

            setSelectedCollection(model);

            console.log(r);

            const jsonString = this.cleanJSON(r);
            const jsonData = JSON.parse(jsonString);

            if (jsonData.fields && Array.isArray(jsonData.fields)) {
                let fieldSort = 0;
                let fieldIndex = 0;
                let titleFieldKey = ""; // Temporary variable to store the title field key
                let titleField = null; // Store title field data

                const fieldIDtoKeyMap = {};

                jsonData.fields.forEach((field) => {
                    const modelKey = model.key;
                    const fieldKey = generateKey(); // Generate system-specific key for this field

                    // Map the fieldID or title to the generated key
                    if (field.fieldID) {
                        fieldIDtoKeyMap[field.fieldID] = fieldKey;
                    } else {
                        fieldIDtoKeyMap[field.title] = fieldKey;
                    }

                    const options = [];
                    if (field.options && Array.isArray(field.options)) {
                        field.options.forEach((optionTitle) => {
                            options.push({ key: generateKey(), title: optionTitle });
                        });
                    }

                    const type = field.type;

                    const data = {
                        appKey: app.key,
                        key: fieldKey,
                        title: field.title,
                        description: field.description,
                        type: type,
                        sort: fieldSort,
                        modelKey: model.key,
                        modelTitle: model.title,
                        modelDescription: model.description,
                        options: options
                    };

                    // Identify the first "text" type field as the title field
                    const appropriateTypes = ["countries", "phone", "states", "text"];
                    if (appropriateTypes.includes(type) && fieldIndex === 0) {
                        titleField = data;
                        titleFieldKey = fieldKey; // Set this fieldKey as title field key
                        fieldIndex++;
                    }

                    // Store field data in the field manager
                    dataManager.add(
                        collections.fields,
                        app.key,
                        fieldKey,
                        data
                    );

                    fieldSort++;
                });

                titleField = {
                    appKey: app.key,
                    key: titleFieldKey,
                    title: "Title",
                    description: "Title of the item.",
                    type: "text",
                    sort: 0,
                    modelKey: model.key,
                    modelTitle: model.title,
                    modelDescription: "",
                    options: []
                };

                const titleFieldContentKey = generateKey();
                const titleFieldBlock = {
                    id: generateKey(),
                    content: [{
                        id: titleFieldContentKey,
                        key: titleFieldContentKey,
                        title: "Title",
                        field: titleField,
                        type: "field"
                    }],
                    align: 'left'
                };

                await dataManager.add(
                    collections.fields,
                    app.key,
                    titleFieldKey,
                    titleField
                );

                const modelData = {
                    titleFieldKey: titleFieldKey,
                    rows: [{ id: generateKey(), blocks: [titleFieldBlock] }]
                };

                await dataManager.update(collections.models, app.key, model.key, modelData);

                const updatedModel = { ...model, ...modelData };
                setSelectedCollection(updatedModel);

                // Process auto-populated data if available
                if (jsonData.data && Array.isArray(jsonData.data)) {
                    jsonData.data.forEach(async (dataEntry) => {
                        const currentObject = {};

                        Object.entries(dataEntry).forEach(([fieldID, value]) => {
                            if (value && value !== undefined && !isNaN(value)) {
                                const mappedFieldKey = fieldIDtoKeyMap[fieldID];
                                if (mappedFieldKey) {
                                    currentObject[mappedFieldKey] = value;
                                    console.log("Mapped field key:", mappedFieldKey, "Value:", value);
                                }
                            }
                        });

                        // Current timestamp
                        const now = Timestamp.now();

                        const data = {
                            key: generateKey(),
                            appKey: app.key,
                            modelKey: model.key,
                            userKey: currentUser.userKey,
                            username: currentUser.username,
                            tags: [],
                            dateCreated: now,
                            dateModified: now,
                            ...currentObject
                        };

                        await objectManager.add(app.key, model.key, modelData, data.key, data);

                        setSelectedCollection(updatedModel);
                    });
                }

                if (resetSelections) {
                    console.log("L");
                    resetSelections();
                }

                setFormMode(FormMode.ADD);

            } else {
                console.log("No fields found or invalid format.");
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }

    async prepareSummaryPrompt(app, model, fields) {

        let prompt = `

In my app I have models, and each model has fields. Users can generate models and their fields, so it is a flexible system in that regard.

In my UI I have a list of items, and I would like to display summaries of these items in a scrolling list. I need some UI layout help for the summary.

The items in the layout will be positioned absolutely. The width will be 420px, and the height will be 400px. Please confine all items within these constraints.
Provide me with the top, left, width, and height for each item in the summary layout.

Within width and height layout constraints of the entire card, I need to lay out attributes for the listing that might be important for a summary. For example, if the model is a real-estate listing, the user might want to see (within constraints of 420px x 400px.
- Photo (gallery)
- Title (text)
- Address (text)
- # Bedrooms
- # Bathrooms 
- Square feet

It's important that the layout is visually appealing and that the most important information is displayed prominently.

It's not necessary to include all fields in the response. Things need to fit comfortably within the 420px x 400px constraints once they are populated with data.

You will want to include only fields of the following types:

- currency
- date
- day 
- gallery
- lookup
- month 
- number
- phone
- states
- text
- time
- year

[EXAMPLE RESPONSE]
{
    "fields": [
        {
            "key": "4402983ut-02389ut",
            "title": "Photo Gallery",
            "type": "gallery",
            "top": "0px"
            "left": "0px"
            "width": "420px"
            "height": "300px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Address",
            "type": "text",
            "top": "200px"
            "left": "0px"
            "width": "420px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Number of Bedrooms",
            "type": "text",
            "top": "240px"
            "left": "0px"
            "width": "140px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Number of Bathrooms",
            "type": "text",
            "top": "240px"
            "left": "140px"
            "width": "140px"
            "height": "50px"
        },
        {
            "key": "4402983ut-02389ut",
            "title": "Square Feet",
            "type": "text",
            "top": "240px"
            "left": "280px"
            "width": "140px"
            "height": "50px"
        },
    ]
}

For context, I will give you information about the app, the model, and each possible field for the model.

        App Title: "` + app.title + `"
        App Description: "` + app.description + `"
        Model Name: "` + model.title + `"
        Model Description: "` + model.description + `"
        Model Fields:`;

        for (var i = 0; i < fields.length; i++) {
            prompt += ` - "` + fields[i].title + `" (type: ` + fields[i].type + `) (key: ` + fields[i].key + `)\n`;
        }

        return prompt;
    }























    async prepareFieldsPrompt(app, appCollections, model, fields) {

        let prompt = `

        App Title: "${app.title}"
        App Description: "${app.description}"
        
        Model Title: "${model.title}"

        Existing Fields:
        `;

        for (var i = 0; i < fields.length; i++) {
            prompt += ` - "` + fields[i].title + `"
            `;
        }

        prompt += `

        Existing Models:
        `;

        for (var x = 0; x < appCollections.length; x++) {
            prompt += ` - "${appCollections[x].title}" (key: ${appCollections[x].key})
        `;
        }

        return prompt;
    };
}

export default PromptManager;

