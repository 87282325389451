import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Screen } from '../../utilities/Enums';

// Styles
import './AppDelete.css';

// Components
import Modal from '../../components/modal/Modal';
import ModalButton from '../../components/modal/ModalButton';
import ModalButtons from '../../components/modal/ModalButtons';
import ModalMessage from '../../components/modal/ModalMessage';

// Managers
import AppManager from '../../managers/AppManager';

const appManager = new AppManager();

/**
 * AppDelete Component
 * 
 * This component confirms a delete prior to deleting an app.
 * 
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppDelete = ({ isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        currentApp,
        setCurrentApp,
        setScreen,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the app.
     */
    const handleDelete = async () => {

        showProgress('Deleting app...');

        try {
            
            const appKey = currentApp.key;
            
            await appManager.deleteApp(appKey);

            setCurrentApp(null);

            setScreen(Screen.HOME);

        } catch (error) {
            console.error('Error deleting app and related data: ', error);
        }

        hideProgress();

    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete App"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width={400}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text={`${currentApp.title} and all of its data will be permanently deleted. Are you sure you would like to continue?"`} />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Yes, I'm sure"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default AppDelete;
