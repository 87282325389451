import React, { useEffect, useRef, useState } from 'react';

// Utilities      
import { ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './ContextMenuPopup.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ContextMenuPopup Component
 * 
 * This component renders a popup for displaying context menu items.
 * 
 * @param {boolean} visible - Indicates whether the popup is visible.
 * @param {function} setVisible - The function to call to set the visibility of the popup.
 * @param {string} backgroundColor - The background color of the popup.
 * @param {object} position - The position of the popup.
 * @param {integer} width - The width of the popup.
 * @param {JSX.Element} children - The children to render.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuPopup = ({ visible, setVisible, backgroundColor, position, width, children }) => {

  // Theme
  const { currentThemeMode } = useTheme();

  const menuRef = useRef(null);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (position) {
      setMenuPosition({ x: position.x, y: position.y });
    }
  }, [position]);

  useEffect(() => {
    if (visible && menuRef.current) {
      const menuHeight = menuRef.current.offsetHeight;
      const bottomPosition = menuPosition.y + menuHeight;

      // Adjust Y position if the menu exceeds the viewport height
      if (bottomPosition > window.innerHeight) {
        setMenuPosition((prevPosition) => ({
          ...prevPosition,
          y: Math.max(0, window.innerHeight - menuHeight - 10), // Add a small margin
        }));
      }
    }
  }, [visible, menuPosition.y]);

  return (
    <>
      {visible && (
        <>
          {/* MASK */}
          <div
            className="context-menu-mask"
            style={{
              backgroundColor: currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
            }}
            onClick={() => setVisible(false)}></div>

          {/* POPUP */}
          <div
            ref={menuRef}
            className="context-menu-popup-container"
            style={{
              top: menuPosition.y,
              left: menuPosition.x - width - 10,
              width: width,
              backgroundColor: backgroundColor,
              position: 'fixed',
            }}
          >
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default ContextMenuPopup;
