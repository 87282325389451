import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

/**
 * Custom hook to load app permissions when the selected app changes.
 */
const useLoadAppPermissions = () => {

  // Global
  const { 
    currentApp,
    setAppPermissions,
  } = useContext(Global);

  useEffect(() => {
    if (!currentApp?.key) return;
    setAppPermissions(currentApp.permissions || {});
  }, [currentApp?.key]);
  
};

export default useLoadAppPermissions;
