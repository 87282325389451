import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { CalendarType } from '../../utilities/Enums';

// Formatter
import { formatDayAndDate, formatWeekRange, formatMonthAndYear } from '../../../common/utilities/Formatters';

// Styles
import './CalendarTitle.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const CalendarTitle = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        activeDate,
        currentApp,
        selectedCalendarType,
    } = useContext(Global);

    const [title, setTitle] = useState("");

    useEffect(() => {
        const newDate = new Date(activeDate);

        switch (selectedCalendarType) {
            case CalendarType.YEAR:
                setTitle(`${newDate.getFullYear()}`);
                break;

            case CalendarType.MONTH:
                setTitle(formatMonthAndYear(newDate)); // Format as "January 2024"
                break;

            case CalendarType.WEEK:
                setTitle(formatWeekRange(newDate)); // Format as "Mon Jan 13 2024 - Sun Jan 20 2024"
                break;

            case CalendarType.THREE:
                const threeDayRangeEnd = new Date(newDate);
                threeDayRangeEnd.setDate(newDate.getDate() + 2);
                setTitle(`${formatDayAndDate(newDate)} - ${formatDayAndDate(threeDayRangeEnd)}`);
                break;

            case CalendarType.DAY:
                setTitle(formatDayAndDate(newDate)); // Format as "Sunday, January 13, 2024"
                break;

            default:
                setTitle(""); // Default case
                break;
        }

    }, [activeDate, selectedCalendarType]);

    return (
        <div 
            className="calendar-title-container" 
            style={{ 
                color: theme.foregroundColor,
                fontFamily: currentApp.fontFamily,
            }}>
            {title}
        </div>
    );
};

export default CalendarTitle;
