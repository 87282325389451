// Managers
import PromptManager from './PromptManager';
const promptManager = new PromptManager();

class FontManager {

    /**
     * Generates a google font using the provided title and description.
     * 
     * @param {string} title - The title of the app.
     * @param {string} description - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns Object representing dark and light fonts.
     */
    async generateFont(title, description, temperature) {

        const fontPrompt = await this.prepareFontPrompt(title, description);

        const response = await promptManager.sendJsonPrompt(fontPrompt, temperature);

        console.log('Font response:', response);

        return response.font;
    };

    /**
     * Prepares a prompt for generating a font for an app based on an app title and description.
     * 
     * @param {string} title - The title of the app.
     * @param {string} description - The description of the app.
     * 
     * @returns String representing a prompt.
     */
    async prepareFontPrompt(title, description) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        Please provide the name of a readable and visually appealing font from the Google Font library for an app 
        with the provided TITLE and DESCRIPTION. A best match will work.

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Please do not add comments or characters that might lead to the inability to parse the JSON.

        [TITLE AND APP DESCRIPTION]
        TITLE: ${title}
        DESCRIPTION: ${description}

        Do not add any nesting, and do not structure the response differently from the example.

        [EXAMPLE RESPONSE]
        {
            font: "Roboto"
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }

}

export default FontManager;