import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './SignupLink.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * SignupLink Component
 * 
 * This component renders a link to log in a new user.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const SignupLink = ({ onClick }) => {
  const { theme } = useTheme();
  const {
    currentApp,
    coreTheme
  } = useContext(Global);

  return (

    <div
      className="signup-link-container"
      onClick={onClick}
      style={{
        color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor
      }}>
      Log In
    </div>

  );
};

export default SignupLink;
