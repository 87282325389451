import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserFieldsRequired.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CheckOffIcon from '../../../common/svg/CheckOffIcon';
import CheckOnIcon from '../../../common/svg/CheckOnIcon';

const UserFieldsRequired = ({ checked, onChange }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      currentApp,
  } = useContext(Global);

  // Local State
  const [isChecked, setIsChecked] = useState(checked);

  // Sync the internal state with the external `checked` prop
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleClick = () => {
    const newChecked = !isChecked;
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  return (
    <div
      className="user-fields-required-container"
      style={{
        color: theme.foregroundColorFaded,
        fontFamily: currentApp.fontFamily,
      }}
      onClick={handleClick}>
      {isChecked ? (
        <CheckOnIcon
          color={theme.highlightBackgroundColor}
          checkColor={theme.highlightForegroundColor}
          width="16"
          height="16"
        />
      ) : (
        <CheckOffIcon 
          color={theme.foregroundColor} 
          width="16" 
          height="16" 
        />
      )}
      Required
    </div>
  );
};

export default UserFieldsRequired;
