import React from 'react';

// Styles
import './CalendarAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PlusIcon from '../../../svg/PlusIcon';

/**
 * CalendarAddButton Component
 * 
 * This component renders a button for adding a calendar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarAddButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="calendar-add-button-container"
            onClick={onClick}>
            <PlusIcon
                color={theme.foregroundColorFaded}
                width="14"
                height="14"
            />
        </div>
    );
};

export default CalendarAddButton;
