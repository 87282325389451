import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode } from '../utilities/Enums';

// Styles
import './Object.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import ObjectRow from './ObjectRow';

/**
 * Object Component
 * 
 * This component renders an object in list view.
 * 
 * @param {object} object - The object containing field values.
 * @param {function} onClick - The function to call onClick.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Object = ({ object, onClick }) => {
  const { theme } = useTheme();
  const {
    appCollections,
    selectedObject,
    setChangesMade,
    setSelectedObject,
  } = useContext(Global);

  // Local State
  const [rows, setRows] = useState([]);

  /**
   * Initializes the model.
   */
  useEffect(() => {
    const appCollection = appCollections.find(appCollection => appCollection.key === object.modelKey);

    // Only update state if values differ
    if (appCollection) {
      if (appCollection.rows !== rows) setRows(appCollection.rows);
    }
  }, [object, appCollections, rows]);

  /**
   * Handles click on the object.
   */
  const handleClick = async () => {
    // Set the selected object
    setSelectedObject(object);

    // Initialize changes made to false
    setChangesMade(false);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className={isMobile ? "object-container-mobile" : "object-container"}
        key={object.key}
        onClick={onClick ? onClick : handleClick}
        style={{
          borderRightWidth: selectedObject && selectedObject.key === object.key ? "5px" : "0px",
          borderRightColor: selectedObject && selectedObject.key === object.key ? theme.highlightBackgroundColor : "transparent",
        }}>

        {/* ROWS */}
        {rows && rows.map((row, index) => (
          <ObjectRow
            key={row.id}
            object={object}
            row={row}
          />
        ))}

      </div>
    </>
  );
};

export default Object;
