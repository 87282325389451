import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './ObjectsGrid.css';

// Components
import ObjectsGridItem from './ObjectsGridItem';

const ObjectsGrid = () => {

    // Global
    const {
        objects,
        setFormMode,
        setSelectedObject
    } = useContext(Global);

    // New: Dynamic columns logic
    const [columns, setColumns] = useState([]); // columns will be an array of arrays
    const containerRef = useRef(null);

    // Function to calculate how many columns we can have based on width
    const calculateColumns = (width) => {
        const minCols = 2;
        const colWidth = 280; // maximum desired width per column
        const numCols = Math.max(minCols, Math.floor(width / colWidth));
        return numCols;
    };

    // Function to distribute objects into the given number of columns
    const distributeObjectsIntoColumns = (objectsArray, numCols) => {
        const newColumns = Array.from({ length: numCols }, () => []);
        objectsArray.forEach((obj, index) => {
            const colIndex = index % numCols;
            newColumns[colIndex].push(obj);
        });
        return newColumns;
    };

    const handleContainerClick = () => {
        setFormMode(null);
        setSelectedObject(null);
    };

    // Use ResizeObserver to dynamically recalculate columns on container width changes
    useEffect(() => {
        if (!containerRef.current) return;
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                const numCols = calculateColumns(width);
                if (objects && objects.length > 0) {
                    const distributed = distributeObjectsIntoColumns(objects, numCols);
                    setColumns(distributed);
                }
            }
        });
        observer.observe(containerRef.current);

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [objects]);

    // Initial distribution once objects are available
    useEffect(() => {
        if (!containerRef.current || !objects || objects.length === 0) return;
        const width = containerRef.current.getBoundingClientRect().width;
        const numCols = calculateColumns(width);
        const distributed = distributeObjectsIntoColumns(objects, numCols);
        setColumns(distributed);
    }, [objects]);

    return (
        <div className={isMobile ? "objects-grid-container-mobile" : "objects-grid-container"}
            onClick={handleContainerClick}
            ref={containerRef}
        >
            {/* Dynamically create columns based on `columns` state */}
            {columns.map((colObjects, colIndex) => (
                <div className="objects-grid-column" key={`column-${colIndex}`}>
                    {colObjects.map((object) => (
                        <ObjectsGridItem
                            key={object.key}
                            object={object}
                        />
                    ))}
                </div>
            ))}

        </div>
    );
};

export default ObjectsGrid;
