import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './Bookmarks.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import BookmarksToolBar from './BookmarksToolBar';
import Divider from '../../common/components/divider/Divider';
import Object from '../objects/Object';

const Bookmarks = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    memberBookmarks,
    appCollections,
    resetSelections,
    setSelectedCollection,
    setSelectedObject,
  } = useContext(Global);

  const handleObjectClick = async (object) => {
    resetSelections();
    const appCollection = appCollections.find(appCollection => appCollection.key === object.modelKey);
    setSelectedCollection(appCollection);
    setSelectedObject(object);
  };

  // Split into columns only for non-mobile devices
  const leftColumn = [];
  const rightColumn = [];

  if (!isMobile) {
    memberBookmarks.forEach((bookmark, index) => {
      if (index % 2 === 0) {
        leftColumn.push(bookmark);
      } else {
        rightColumn.push(bookmark);
      }
    });
  }

  return (

    <div className="bookmarks-container">

      {/* TOOLBAR */}
      <BookmarksToolBar />
      
      <div className={isMobile ? "bookmarks-wrapper-mobile" : "bookmarks-wrapper"}>
        {isMobile ? (

          // Single column for mobile
          <div className="bookmarks-column-mobile">
            {memberBookmarks.map((bookmark) => (
              <div key={bookmark.id} className="bookmarks-object">

                {/* OBJECT */}
                <Object
                  object={bookmark.object}
                  onClick={() => handleObjectClick(bookmark.object)} />

                {/* DIVIDER */}
                <Divider />

              </div>
            ))}
          </div>

        ) : (

          // Two columns for desktop
          <>
            {/* LEFT */}
            <div className="bookmarks-column">
              {leftColumn.map((bookmark) => (
                <div key={bookmark.id}
                  className="bookmarks-object">

                  {/* OBJECT */}
                  <Object
                    object={bookmark.object}
                    onClick={() => handleObjectClick(bookmark.object)} />

                  {/* DIVIDER */}
                  <Divider />
                </div>
              ))}
            </div>

            {/* VERTICAL DIVIDER */}
            <Divider direction="vertical" />

            {/* RIGHT */}
            <div className="bookmarks-column">
              {rightColumn.map((bookmark) => (
                <div key={bookmark.id}
                  className="bookmarks-object">

                  {/* OBJECT */}
                  <Object
                    object={bookmark.object}
                    onClick={() => handleObjectClick(bookmark.object)} />

                  {/* DIVIDER */}
                  <Divider />
                </div>
              ))}
            </div>
          </>

        )}
      </div>
    </div>

  );
};

export default Bookmarks;
