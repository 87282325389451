// Firebase
import { collection, writeBatch, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Managers
import { activity } from './ActivityManager';

// Utilities
import { generateKey } from '../utilities/Keys';

// Managers
import DataManager from './DataManager';
import PromptManager from './PromptManager';

const dataManager = new DataManager();
const promptManager = new PromptManager();

class ModelManager {

    /**
     * Generates a list of models for a new app with the provided app title and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable list of models that can individually be populated with fields
     *          in subsequent prompts.
     */
    async generateModelList(appTitle, appDescription, temperature) {

        const modelListPrompt = await this.prepareModelListPrompt(appTitle, appDescription);

        const response = await promptManager.sendJsonPrompt(modelListPrompt, temperature);

        return response.models;
    };

    /**
     * Prepares a prompt for generating a list of models for an app based on an app's title 
     * and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * 
     * @returns Prompt that can be used to generate a list of models.
     */
    async prepareModelListPrompt(appTitle, appDescription) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        The goal of your response is to define the data model structure for the app that the user is trying to create.
        Using the provided TITLE and DESCRIPTION, please define a list of models that will be used to structure the app's data.
        Fields for the model will be added later.

        It is acceptable to use user-friendly names for the models. For example, you can call a model "Inventory Items" instead 
        of "InventoryItems" or "inventoryitems".

        There is no need to create the following types of items, as those will be handled separately:
        - models related to appointments/schedules/events
        - models related to communication channels, messaging or chat
        - models related to news or current events
        - models related to users of the system
        - models related to payments and commerce

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Please do not add comments or characters that might lead to the inability to parse the JSON.

        [APP TITLE AND DESCRIPTION]
        TITLE: ${appTitle}
        DESCRIPTION: ${appDescription}

        Please include both a plural and singular form of the model title, with the "title" attribute being the plural
        version and the "singular" attribute being the singular version. For example, "Realtors" and "Realtor".

        Do not add any nesting, and do not structure the response differently from the example.

        [EXAMPLE RESPONSE]
        {
            "models": [
                {
                    "title": "Realtors",
                    "singular": "Realtor",
                    "description": "The realtors in our group."
                },
                {
                    "title": "Listings",
                    "singular": "Listing",
                    "description": "All listings in our firm."
                },
                {
                    "title": "Leads",
                    "singular": "Lead",
                    "description": "A list of potential buyers."
                },
                {
                    "title": "Contacts",
                    "singular": "Contact",
                    "description": "Helpful list of contacts for our realtors."
                },
                {
                    "title": "Appointments",
                    "singular": "Appointment",
                    "description": "Scheduling for all realtors."
                },
            ]
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }

    /**
     * Generates fields and tags for a model.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {string} modelTitle - The title of the model.
     * @param {string} modelDescription - The description of the model.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable model object.
     */
    async generateModel(appTitle, appDescription, modelTitle, modelDescription, temperature) {

        const modelPrompt = await this.prepareModelPrompt(appTitle, appDescription, modelTitle, modelDescription);

        const model = await promptManager.sendJsonPrompt(modelPrompt, temperature);

        return model;
    };

    async prepareModelPrompt(appTitle, appDescription, modelTitle, modelDescription) {

        let instructions = `
        [GOAL - CREATE A MODEL FIELD LIST]
        The purpose of our system is to utilize AI to assist users in creating useful userApps based upon models. Each model has fields, and each field represents an attribute of the model.
        
        The purpose of this query is to identify as many fields as possible for the given model and to provide a **comprehensive list of data entries**. Each data entry should contain values matching the fields generated, allowing us to pre-populate the app with meaningful data.
        
        Please provide your response in JSON format only, with no surrounding explanation or text. All formatting must be valid JSON to ensure it can be parsed. Avoid any comments or unnecessary characters.
        
        For context, here is some preliminary information that describes the app and model.
        
        [APP INFORMATION]
        App Title: "${appTitle}"
        App Description: "${appDescription}"

        [MODEL INFORMATION]
        Model Title: "${modelTitle}"
        Model Description: "${modelDescription}"
        `;

        instructions += `
        
        Each model should have:
        - A title field (type "text") as the primary field for identifying items.
        - Any other appropriate fields identified based on the model description.
        
        [FIELD REQUIREMENTS]
        Each field should include:
           - **title**: the name of the field.
           - **description**: a short explanation of the field.
           - **type**: the field's data type, selected from the available types below.

        It is very important that we have one field as the priority 1 field for the model. This field must be of 
        type "text". This field will be used as the identifying title field for any objects of this model type. This field 
        must be sorted first in your response, before any other text types. For example, for a person 
        it might be "Full Name". For a movie it might be "Title". When returning the field list, place the priority 1
        field before any other text fields. You can have other fields before it, just don't put any other text fields
        before it. When processing your response, I will search for the first "text" field, and process it as the priority one field.

        Field types available:
        - checkbox
        - checklist
        - comments
        - countries
        - currency
        - date
        - day
        - documents
        - gallery
        - image
        - lookup
        - month
        - number
        - password
        - phone
        - states
        - text
        - time
        - year
        
        If unsure of a data type, default to "text."
        
        Additionally:
        1. The first "text" type field should be the main identifier (e.g., "Title").
        2. Any relevant field values with defined options should use "lookup" with specific options.
        3. The model needs an array of tags to categorize items in the model.
        
        ---
        
        Here's the example JSON structure:
        
        {
            "fields": [
                {
                    "title": "Exercise Name",
                    "description": "Name of the CrossFit exercise.",
                    "type": "text",
                },
                {
                    "title": "Exercise Description",
                    "description": "Description of the CrossFit exercise.",
                    "type": "text",
                },
                {
                    "title": "Exercise Type",
                    "description": "Type of CrossFit exercise.",
                    "type": "lookup",
                    "options": ["Girls", "Heroes"],
                },
            ],
            "tags": [
                "Difficult", 
                "Moderate",
                "Easy",
            ]
        }
        `;

        return instructions;
    }

    async processModelResponse(response, app, model) {
        try {

            console.log(response);

            const tags = response.tags;

            console.log(tags);

            if (response.fields && Array.isArray(response.fields)) {
                let fieldIndex = 0;
                let titleFieldKey = ""; // Temporary variable to store the title field key
                let titleField = null; // Store title field data

                const fieldIDtoKeyMap = {};

                const fieldSort = [];

                response.fields.forEach((field) => {
                    const modelKey = model.key;
                    const fieldKey = generateKey(); // Generate system-specific key for this field

                    // Add the field key to the sort array
                    fieldSort.push(fieldKey);

                    // Map the fieldID or title to the generated key
                    if (field.fieldID) {
                        fieldIDtoKeyMap[field.fieldID] = fieldKey;
                    } else {
                        fieldIDtoKeyMap[field.title] = fieldKey;
                    }

                    const options = [];
                    if (field.options && Array.isArray(field.options)) {
                        field.options.forEach((optionTitle) => {
                            options.push({ key: generateKey(), title: optionTitle });
                        });
                    }

                    const type = field.type;

                    const data = {
                        appKey: app.key,
                        key: fieldKey,
                        title: field.title,
                        description: field.description,
                        type: type,
                        modelKey: model.key,
                        modelTitle: model.title,
                        modelDescription: model.description,
                        options: options
                    };

                    // Identify the first "text" type field as the title field
                    const appropriateTypes = ["countries", "phone", "states", "text"];
                    if (appropriateTypes.includes(type) && fieldIndex === 0) {
                        titleField = data;
                        titleFieldKey = fieldKey; // Set this fieldKey as title field key
                        fieldIndex++;
                    }

                    // Store field data in the field manager
                    dataManager.add(
                        collections.fields,
                        app.key,
                        fieldKey,
                        data
                    );
                });

                titleField = {
                    appKey: app.key,
                    key: titleFieldKey,
                    title: "Title",
                    description: "Title of the item.",
                    type: "text",
                    sort: 0,
                    modelKey: model.key,
                    modelTitle: model.title,
                    modelDescription: "",
                    options: []
                };

                const titleFieldContentKey = generateKey();
                const titleFieldBlock = {
                    id: generateKey(),
                    content: [{
                        id: titleFieldContentKey,
                        key: titleFieldContentKey,
                        title: "Title",
                        field: titleField,
                        type: "field"
                    }],
                    align: 'left'
                };

                await dataManager.add(
                    collections.fields,
                    app.key,
                    titleFieldKey,
                    titleField
                );

                const modelData = {
                    titleFieldKey: titleFieldKey,
                    rows: [{ id: generateKey(), blocks: [titleFieldBlock] }],
                    tags: tags || [],
                    fieldSort: fieldSort
                };

                await dataManager.update(collections.models, app.key, model.key, modelData);

            } else {
                console.log("No fields found or invalid format.");
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    }

    /**
     * Deletes a model from the Firestore database.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key of the model to delete.
     */
    async delete(appKey, modelKey) {
        try {
            // Start a batch
            const batch = writeBatch(db);

            // Fetch the model to get the modelKey
            const modelRef = doc(db, collections.models, modelKey);
            const modelSnap = await getDoc(modelRef);

            activity.log(appKey, 'reads', 1);

            if (!modelSnap.exists()) {
                console.error("Model does not exist");
                return;
            }

            // Delete the selected model
            batch.delete(modelRef);

            activity.log(appKey, 'deletes', 1);

            // Function to delete documents from a given collection
            const deleteFromCollection = async (collectionPath) => {
                const querySnapshot = await getDocs(query(collection(db, collectionPath), where("modelKey", "==", modelKey)));
                querySnapshot.forEach((doc) => {
                    batch.delete(doc.ref);
                    activity.log(appKey, 'deletes', 1);
                });
            };

            // Delete fields associated with the model
            await deleteFromCollection(collections.fields);

            // Delete objects from their specific collection
            const objectsQuerySnapshot = await getDocs(collection(db, modelKey));

            activity.log(appKey, 'reads', objectsQuerySnapshot.docs.length);

            objectsQuerySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
                activity.log(appKey, 'deletes', 1);
            });

            // Delete related index entries
            const indexQuery = query(collection(db, collections.index), where("modelKey", "==", modelKey));
            try {
                const querySnapshot = await getDocs(indexQuery);

                activity.log(appKey, 'reads', querySnapshot.docs.length);

                if (querySnapshot.empty) {
                    console.log(`No indexes found for model key ${modelKey}.`);
                } else {
                    querySnapshot.forEach((doc) => {
                        batch.delete(doc.ref);  // Avoid async in batch delete
                    });

                    activity.log(appKey, 'deletes', querySnapshot.docs.length);
                }
            } catch (error) {
                console.error(`Error deleting index entries for model key ${modelKey}:`, error);
                throw error;  // Propagate the error if deletion fails
            }

            // Commit the batch
            await batch.commit();

        } catch (error) {
            console.error('Error deleting model:', error);
            // Return an error message
            return { success: false, message: "Error deleting model." };
        }
    }

}

export default ModelManager;
