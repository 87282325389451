import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ChecklistAddButton.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import NewIcon from '../../../../common/svg/NewIcon';

/**
 * Component for rendering a checklist add button.
 *
 * @param {function} onClick - Function to call when the button is clicked.
 * 
 * @return {JSX.Element} - The checklist add button component.
 */
const ChecklistAddButton = ({
    onClick
}) => {
    
    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentApp,
    } = useContext(Global);

    return (

        <div onClick={onClick}
            className="checklist-add-button-container">

            {/* ICON */}
            <div className="checklist-add-button-image">
                <NewIcon
                    color={theme.foregroundColorFaded}
                    width="18"
                    height="18"
                />
            </div>

            {/* TEXT */}
            <div className="checklist-add-button-text"
                style={{ 
                    color: theme.foregroundColorFaded,
                    fontFamily: currentApp.fontFamily,
                }}>
                Add List Item
            </div>

        </div>

    );
};

export default ChecklistAddButton;
