import React from 'react';

// Styles
import './NewsfeedGridItem.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const NewsfeedGridItem = ({ item, onClick }) => {

    // Theme
    const { theme } = useTheme();

    return (
        <div className="newsfeed-grid-item-container"
            style={{
                backgroundColor: theme.backgroundColorFaded,
            }}
            onClick={() => onClick(item.link)}>

            {item.image &&
                <div className="newsfeed-grid-item-image-wrapper">
                    <img
                        src={item.image}
                        alt={item.title}
                        className="newsfeed-grid-item-image"
                    />
                </div>
            }

            <div className="newsfeed-grid-item-text-fields-container">

                <div className="newsfeed-grid-item-date"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: theme.fontFamily,
                    }}>
                    {new Date(item.pubDate).toLocaleString()}
                </div>

                <div className="newsfeed-grid-item-title"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: theme.fontFamily,
                    }}>
                    {item.title}
                </div>

                <div className="newsfeed-grid-item-source"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: theme.fontFamily,
                    }}>
                    {item.source}
                </div>
            </div>
        </div>
    );
};

export default NewsfeedGridItem;
