import React, { useEffect, useState } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { dayNamesLetter, dayNamesShort } from '../../../utilities/DateTime';

// Styles
import './CalendarMonthHeader.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * CalendarMonthHeader Component
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CalendarMonthHeader = ({ daysToShow }) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [dayNames, setDayNames] = useState([]);

    // Sets day names according to device type
    useEffect(() => {
        if (isMobile) {
            setDayNames(dayNamesLetter);
        } else {
            setDayNames(dayNamesShort);
        }
    }, []);

    return (
        <>
            {daysToShow.map((day, index) => {
                return (
                    <div key={index}
                        className="calendar-month-header-day">
                        <div
                            className="calendar-month-header-day-text"
                            style={{
                                color: theme.foregroundColor,
                            }}>
                            <span>{dayNames[index]}</span>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default CalendarMonthHeader;
