// Managers
import PromptManager from './PromptManager';
const promptManager = new PromptManager();

class ThemeManager {

    /**
     * Generates dark and light themes using the provided title and description.
     * 
     * @param {string} title - The title of the app.
     * @param {string} description - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns Object representing dark and light themes.
     */
    async generateThemes(title, description, temperature) {

        const themePrompt = await this.prepareThemePrompt(title, description);

        const themes = await promptManager.sendJsonPrompt(themePrompt, temperature);

        return themes;
    };

    /**
     * Prepares a prompt for generating a theme for an app based on an app title and description.
     * 
     * @param {string} title - The title of the app.
     * @param {string} description - The description of the app.
     * 
     * @returns String representing a prompt.
     */
    async prepareThemePrompt(title, description) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        Please provide a visually appealing theme for an app with the provided TITLE and DESCRIPTION.
        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Please do not add comments or characters that might lead to the inability to parse the JSON.

        [TITLE AND APP DESCRIPTION]
        TITLE: ${title}
        DESCRIPTION: ${description}

        [THEME]
        We need two color schemes for the app, one for dark mode and one for light mode. 
        For dark mode, please provide background colors that are easy on the eyes in low-light environments, 
        and fall in line with other userApps that have dark themes. The highlight background color should be
        a color that most reflects the purpose of the app.

        Please provide unique hex values in JSON format for the settings described in the example below.

        Do not add any nesting, and do not structure the response differently from the example. 

        [EXAMPLE RESPONSE]
        {
            dark: {
                backgroundColor: "#0F0F0F",
                backgroundColorFaded: "#222222",
                foregroundColor: "#FFFFFF",
                foregroundColorFaded: "#AAAAAA",
                highlightBackgroundColor: "#A2221D",
                highlightForegroundColor: "#FFFFFF",
            },
            light: {
                backgroundColor: "#FFFFFF",
                backgroundColorFaded: "#F0F0F0",
                foregroundColor: "#000000",
                foregroundColorFaded: "#555555",
                highlightBackgroundColor: "#A2221D",
                highlightForegroundColor: "#FFFFFF",
            }
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }

}

export default ThemeManager;