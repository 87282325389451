
// Firebase
import { collection, deleteDoc, getDoc, getDocs, query, orderBy, limit, where, doc, setDoc, Timestamp, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Utilities
import { activity } from './ActivityManager';

class IndexManager {

    /**
     * Method to add an index record, to assist with object searching
     * within userApps or appCollections, or across all appCollections in an app.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key of the object model type.
     * @param {string} objectKey - The key of the object. Will become the index document ID.
     * @param {string} objectTitle - Title of the object.
     * @param {array} objectTags - Tags of the object.
     * @param {object} object - Copy of the object record.
     * 
     * @returns {object} - New object.
    */
    async add(appKey, modelKey, objectKey, objectTitle, objectTags, object) {

        const now = Timestamp.now();

        const data = {
            appKey: appKey,
            dateCreated: now,
            modelKey: modelKey,
            objectKey: objectKey,
            objectTitle: objectTitle,
            objectTags: objectTags,
            object: object
        };

        await setDoc(doc(db, collections.index, objectKey), data);

        activity.log(appKey, 'writes', 1);

        //this.updateExistingDocumentsWithTimestamps()

        return data;
    }

    async updateExistingDocumentsWithTimestamps() {
        const collectionRef = collection(db, collections.index);

        try {
            // Fetch all documents in the index collection
            const querySnapshot = await getDocs(collectionRef);

            let currentTimestamp = Timestamp.now(); // Start with the current timestamp

            // Iterate through the documents and update each one
            const updatePromises = querySnapshot.docs.map(async (docSnapshot, index) => {
                const indexData = docSnapshot.data();

                // Decrement timestamp by one day for each iteration
                currentTimestamp = Timestamp.fromMillis(currentTimestamp.toMillis() - 24 * 60 * 60 * 1000);

                // Ensure modelKey and objectKey exist
                if (indexData.modelKey && indexData.objectKey) {
                    const objectCollectionRef = collection(db, indexData.modelKey);
                    const objectDocRef = doc(objectCollectionRef, indexData.objectKey);

                    // Fetch the corresponding object record
                    const objectDocSnapshot = await getDoc(objectDocRef);

                    if (objectDocSnapshot.exists()) {
                        const objectData = objectDocSnapshot.data();

                        // Update the index record with dateCreated and the full object
                        await updateDoc(docSnapshot.ref, {
                            dateCreated: currentTimestamp,
                            object: objectData, // Attach the object record
                        });

                        console.log(
                            `Updated document: ${docSnapshot.id}, dateCreated: ${currentTimestamp.toDate()}, object attached.`
                        );
                    } else {
                        console.warn(`Object not found for modelKey: ${indexData.modelKey}, objectKey: ${indexData.objectKey}`);
                    }
                } else {
                    console.warn(`Invalid index document: ${docSnapshot.id}`);
                }
            });

            // Wait for all updates to complete
            await Promise.all(updatePromises);
            console.log('All index documents updated successfully.');
        } catch (error) {
            console.error('Error updating index documents:', error);
        }
    }

    /**
     * Deletes an object from the index.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} objectKey - Key of the object being deleted.
    */
    async delete(appKey, objectKey) {
        await deleteDoc(doc(db, collections.index, objectKey));

        activity.log(appKey, 'deletes', 1);
    }

    /**
     * Deletes all object index records for an app.
     * 
     * @param {string} appKey - Key of the app.
    */
    async deleteByApp(appKey) {
        const q = query(collection(db, collections.index), where('appKey', '==', appKey));
        const querySnapshot = await getDocs(q);

        // Iterate over each document in the query result and delete it
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        await batch.commit();

        activity.log(appKey, 'deletes', querySnapshot.size);
    }

    /**
     * Deletes all object index records for a model.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} modelKey - Key of the model.
    */
    async deleteByModel(appKey, modelKey) {
        const q = query(collection(db, collections.index), where('modelKey', '==', modelKey));
        const querySnapshot = await getDocs(q);

        // Iterate over each document in the query result and delete it
        const batch = db.batch();
        querySnapshot.forEach((doc) => {
            batch.delete(doc.ref);
        });

        await batch.commit();

        activity.log(appKey, 'deletes', querySnapshot.size);
    }

    /**
     * Fetches index records for all objects in an app.
     * 
     * @param {string} appKey - App key.
    */
    async listByApp(appKey) {
        const q = query(collection(db, collections.index), where("appKey", "==", appKey));

        const snapshot = await getDocs(q);

        activity.log(appKey, 'reads', snapshot.docs.length);

        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    }

    /**
     * Fetches the most recent objects in the index.
     * 
     * @param {string} appKey - App key.
     * @param {number} count - Number of items to fetch.
     * @returns {Array} - List of the most recent items.
     */
    async listNewest(appKey, count) {
        // Query the index for the given appKey, ordered by dateCreated, limited to 'count'
        const q = query(
            collection(db, collections.index),
            where("appKey", "==", appKey),
            orderBy("dateCreated", "desc"),
            limit(count)
        );

        try {
            // Execute the query
            const snapshot = await getDocs(q);

            // Log the read activity
            activity.log(appKey, 'reads', snapshot.docs.length);

            // Map the results to a clean array of objects
            return snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
        } catch (error) {
            console.error("Error fetching the most recent items:", error);
            throw error;
        }
    }

    /**
     * Fetches index records for all objects in a model.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key to get fields for.
    */
    async listByModel(appKey, modelKey) {
        const q = query(collection(db, collections.index), where("modelKey", "==", modelKey));

        const snapshot = await getDocs(q);

        activity.log(appKey, 'reads', snapshot.docs.length);

        return snapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    }

    /**
     * Searches for all items in the array for a passed-in query string by comparing
     * against the string's presence anywhere in the "objectTitle" value.
     * 
     * @param {string} appKey - Key of the app.
     * @param {Array} entries - Array of object index entries.
     * @param {string} query - Query string to search for.
     * @param {string} modelKey - (Optional) Key of the model.
     * 
     * @returns {Array} - Filtered array of objects matching the query.
     */
    searchByApp(appKey, entries, query, modelKey) {
        const lowerCaseQuery = query.toLowerCase();

        if (modelKey) {
            return entries.filter(obj =>
                obj.appKey === appKey &&
                obj.modelKey === modelKey &&
                (
                    obj.objectTitle.toLowerCase().includes(lowerCaseQuery) ||
                    (obj.objectTags && obj.objectTags.some(tag => tag.toLowerCase().includes(lowerCaseQuery)))
                )
            );
        } else {
            return entries.filter(obj =>
                obj.appKey === appKey &&
                (
                    obj.objectTitle.toLowerCase().includes(lowerCaseQuery) ||
                    (obj.objectTags && obj.objectTags.some(tag => tag.toLowerCase().includes(lowerCaseQuery)))
                )
            );
        }
    }

    /**
     * Searches for all items in the array for a passed-in query string by comparing
     * against the string's presence anywhere in the "objectTitle" value.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} modelKey - Key of the model.
     * @param {Array} entries - Array of object index entries.
     * @param {string} query - Query string to search for.
     * 
     * @returns {Array} - Filtered array of objects matching the query.
     */
    searchByModel(appKey, modelKey, entries, query) {
        const lowerCaseQuery = query.toLowerCase();

        return entries.filter(obj =>
            obj.appKey === appKey &&
            obj.modelKey === modelKey &&
            (
                obj.objectTitle.toLowerCase().includes(lowerCaseQuery) ||
                (obj.objectTags && obj.objectTags.some(tag => tag.toLowerCase().includes(lowerCaseQuery)))
            )
        );
    }

    /**
     * Updates an object's title and tags in the index.
     * 
     * @param {string} appKey - Key of the app.
     * @param {string} objectKey - Key of the object being updated.
     * @param {string} objectTitle - New title of the object.
     * @param {array} objectTags - New tags of the object.
     * @param {object} object - Updated object.
    */
    async update(appKey, objectKey, objectTitle, objectTags, object) {
        const ref = doc(db, collections.index, objectKey);

        await setDoc(ref, {
            objectTitle: objectTitle,
            objectTags: objectTags,
            object: object
        }, { merge: true });

        activity.log(appKey, 'writes', 1);
    }

    /**
     * Updates an index record with new object data
     *
     * @param {string} appKey - Key of the app.
     * @param {string} objectKey - Key of the object being updated.
     * @param {object} updateData - Data to update in the index (e.g., title, tags, object).
     */
    async updateObject(appKey, objectKey, updateData) {
        const ref = doc(db, collections.index, objectKey);

        try {
            // Update the index document with the provided data
            await setDoc(ref, updateData, { merge: true });

            // Log the write operation
            activity.log(appKey, 'writes', 1);

            console.log(`Index record for object ${objectKey} updated successfully.`);
        } catch (error) {
            console.error(`Error updating index record for object ${objectKey}:`, error);
            throw error;
        }
    }


}

export default IndexManager;
