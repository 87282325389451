import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getUserDisplayValue } from '../../utilities/Users';

// Styles
import './MemberGridItem.css';

// Theme
import { useTheme } from '../../../ThemeContext';
import { use } from 'react';

const MemberGridItem = ({ member, onClick }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentApp,
        selectedMember,
    } = useContext(Global);

    if (!member) return null;

    // Function to format date
    const formatDate = (date) => {
        // Check if the date is a Firebase Timestamp object
        if (date && date.toDate) {
            date = date.toDate(); // Convert Firebase Timestamp to JavaScript Date object
        }

        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    return (
        <div className="member-grid-item-container"
            style={{
                backgroundColor: selectedMember && (selectedMember.key === member.key) ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
            }}
            onClick={onClick}>

            {member.photo &&
                <div className="member-grid-item-image-wrapper">
                    <img
                        src={member.photo}
                        alt={member.title}
                        className="member-grid-item-image"
                    />
                </div>
            }

            <div className="member-grid-item-text-fields-container">

                <div className="member-grid-item-title"
                    style={{
                        color: selectedMember && (selectedMember.key === member.key) ? theme.highlightForegroundColor : theme.foregroundColor,
                        fontFamily: theme.fontFamily,
                    }}>
                    {getUserDisplayValue(currentApp.displayKey, member)}
                </div>

                <div className="member-grid-item-joined"
                    style={{
                        color: selectedMember && (selectedMember.key === member.key) ? theme.highlightForegroundColor : theme.foregroundColorFaded,
                        fontFamily: theme.fontFamily,
                    }}>
                    Joined {formatDate(member.dateJoined)}
                </div>

            </div>
        </div>
    );
};

export default MemberGridItem;
