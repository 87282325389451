import React from 'react';

const UserInfoIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    version="1.1"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24">
    <path fill={color} d="M4,7A4,4,0,1,0,8,3,4,4,0,0,0,4,7Zm6,0A2,2,0,1,1,8,5,2,2,0,0,1,10,7ZM2,21H14a1,1,0,0,0,1-1V17a4,4,0,0,0-4-4H5a4,4,0,0,0-4,4v3A1,1,0,0,0,2,21Zm1-4a2,2,0,0,1,2-2h6a2,2,0,0,1,2,2v2H3ZM23,4a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h6A1,1,0,0,1,23,4Zm0,4a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h6A1,1,0,0,1,23,8Zm0,4a1,1,0,0,1-1,1H16a1,1,0,0,1,0-2h6A1,1,0,0,1,23,12Z" />
  </svg>
);

export default UserInfoIcon;
