import React from 'react';

const HideIcon = ({ color = "white", width = "24", height = "24" }) => (
  <svg 
  version="1.1"
  width={width}
  height={height}
  viewBox="0 0 24 24"
  fill="none">
<path d="M9.7606 14.3668C9.1856 13.7928 8.8356 13.0128 8.8356 12.1378C8.8356 10.3848 10.2476 8.9718 11.9996 8.9718C12.8666 8.9718 13.6646 9.3228 14.2296 9.8968" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.1048 12.6989C14.8728 13.9889 13.8568 15.0069 12.5678 15.2409" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M6.6546 17.4723C5.0676 16.2263 3.7236 14.4063 2.7496 12.1373C3.7336 9.85829 5.0866 8.02829 6.6836 6.77229C8.2706 5.51629 10.1016 4.83429 11.9996 4.83429C13.9086 4.83429 15.7386 5.52629 17.3356 6.79129" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.4476 8.99081C20.1356 9.90481 20.7406 10.9598 21.2496 12.1368C19.2826 16.6938 15.8066 19.4388 11.9996 19.4388C11.1366 19.4388 10.2856 19.2988 9.46759 19.0258" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M19.887 4.2496L4.11301 20.0236" stroke={color} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
);

export default HideIcon;
