import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Members.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import MemberGridItem from './griditem/MemberGridItem';
import MembersToolBar from './toolbar/MembersToolBar';
import MemberInviteButton from './invitebutton/MemberInviteButton';
import MemberProfile from './profile/MemberProfile';

const Members = () => {
    const { theme } = useTheme();
    const {
        members,
        selectedMember,
        setSelectedMember,
    } = useContext(Global);

    const [columns, setColumns] = useState([]); // columns will be an array of arrays
    const containerRef = useRef(null);

    const handleUserClick = (e, member) => {
        e.stopPropagation();
        console.log("setting selected member: ", member);
        setSelectedMember(member);
    };

    // We will calculate the number of columns based on container width
    const calculateColumns = (width) => {
        // Minimum 2 columns, max width per column ~280px
        const minCols = 2;
        const colWidth = 280;
        const maxPossibleCols = Math.max(minCols, Math.floor(width / colWidth));
        return maxPossibleCols;
    };

    // Function to distribute app users into the given number of columns
    const distributeItemsIntoColumns = (membersArray, numCols) => {
        const newColumns = Array.from({ length: numCols }, () => []);
        membersArray.forEach((item, index) => {
            const colIndex = index % numCols;
            newColumns[colIndex].push(item);
        });
        return newColumns;
    };

    // Use ResizeObserver to detect changes in container width
    useEffect(() => {
        if (!containerRef.current) return;
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                const numCols = calculateColumns(width);
                // Once we have the number of columns, distribute app users into columns
                if (members && members.length > 0) {
                    const distributed = distributeItemsIntoColumns(members, numCols);
                    setColumns(distributed);
                }
            }
        });
        observer.observe(containerRef.current);

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [members]);

    // Initially, when app users first load, we want to set columns as well
    useEffect(() => {
        if (!containerRef.current || !members || members.length === 0) return;
        const width = containerRef.current.getBoundingClientRect().width;
        const numCols = calculateColumns(width);
        const distributed = distributeItemsIntoColumns(members, numCols);
        setColumns(distributed);
    }, [members]);

    const handleContainerClick = () => {
        setSelectedMember(null);
    }

    return (
        <div className="members-container">

            <div className="members-left"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                    width: selectedMember ? '50%' : '100%',
                }}>
                <MembersToolBar />

                <div
                    className={isMobile ? "members-grid-container-mobile" : "members-grid-container"}
                    onClick={handleContainerClick}
                    ref={containerRef}>
                    {/* Dynamically create columns based on `columns` state */}
                    {columns.map((colItems, colIndex) => (
                        <div className="members-grid-column" key={`column-${colIndex}`}>
                            {colItems.map((item) => (
                                <MemberGridItem
                                    key={item.guid}
                                    member={item}
                                    onClick={(e) => handleUserClick(e, item)}
                                />
                            ))}
                        </div>
                    ))}
                </div>

                <div className={isMobile ? "members-add-button-mobile" : "members-add-button"}>
                    <MemberInviteButton />
                </div>
            </div>

            {selectedMember &&
                <div className="members-right">
                    <MemberProfile />
                </div>
            }

        </div>
    );
};

export default Members;
