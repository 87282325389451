import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { getMember, getUserDisplayValue } from '../../../../common/utilities/Users';

// Styles
import './ChatPost.css';

// Components
import Document from '../../components/document/Document';
import Event from '../../components/event/Event';
import Gallery from '../../components/gallery/Gallery';
import Object from '../../../objects/Object';
import VideoGallery from '../../components/videogallery/VideoGallery';
import UserThumb from '../../../components/userthumb/UserThumb';

// Theme
import { useTheme } from '../../../../ThemeContext';

const ChatPost = ({ post, handleUserClick, timestamp }) => {
    const { theme } = useTheme();
    const {
        members,
        appCollections,
        resetSelections,
        currentApp,
        setSelectedCollection,
        setSelectedObject
    } = useContext(Global);

    const handleObjectClick = async (object) => {
        resetSelections();
        const appCollection = appCollections.find(appCollection => appCollection.key === object.modelKey);
        setSelectedCollection(appCollection);
        setSelectedObject(object);
    };

    const getUrlsFromContent = (content) => {
        if (!content) {
            return [];
        }

        return Array.from(content).map((item) => ({
            url: getDisplayUrl(item),  // use the updated utility function here
        }));
    };

    /**
     * Gets a URL suitable for display, regardless if it's a local file (blob URL) or a remote URL.
     * If the URL already starts with 'blob:', it's returned as is.
     * 
     * @param {string} url - The URL string (either a local blob URL or remote URL)
     * 
     * @returns {string} - The displayable URL
     */
    const getDisplayUrl = (url) => {
        // If it's a blob URL, return it as is
        if (url.startsWith('blob:')) {
            return url;
        }
        // For remote URLs, just return the original URL
        return url;
    };

    return (
        <>
            <div
                key={post.key}
                className="chat-post-wrapper">
                <div className="chat-post-left"
                    onClick={() => handleUserClick(post.userKey)}>
                    <UserThumb
                        user={post} // username is in the post object
                        size="36"
                    />
                </div>
                <div className="chat-post-right">
                    <div className="chat-post-header">
                        <div className="chat-post-user"
                            onClick={() => handleUserClick(post.userKey)}
                            style={{
                                color: theme.foregroundColor,
                                fontFamily: currentApp.fontFamily
                            }}>
                            {getUserDisplayValue(currentApp.displayKey, getMember(members, post.userKey))}
                        </div>
                        <div className="chat-post-timestamp"
                            style={{
                                color: theme.foregroundColorFaded,
                                fontFamily: currentApp.fontFamily
                            }}>
                            • {timestamp}
                        </div>
                    </div>
                    <div className="chat-post-content"
                        style={{
                            color: theme.foregroundColor,
                            fontFamily: currentApp.fontFamily
                        }}>
                        {post.text}
                    </div>
                </div>
            </div>

            {/* PHOTOS */}
            {post.photoUrls && post.photoUrls.length > 0 &&
                <div className="chat-post-gallery-wrapper">
                    <Gallery
                        key={`${post.key}-gallery`}
                        content={getUrlsFromContent(post.photoUrls)} />
                </div>
            }

            {/* VIDEOS */}
            {post.videoUrls && post.videoUrls.length > 0 &&
                <div className="chat-post-gallery-wrapper">
                    <VideoGallery
                        key={`${post.key}-video-gallery`}
                        content={getUrlsFromContent(post.videoUrls)}
                    />
                </div>
            }

            {/* DOCUMENTS */}
            {post.documents && post.documents.length > 0 &&
                <div className="chat-post-document-wrapper">
                    {post.documents.map((document, index) => (
                        <Document
                            key={`document-${index}`}
                            content={document}
                        />
                    ))}
                </div>
            }

            {/* OBJECTS */}
            {post.objects && post.objects.length > 0 &&
                <div className="chat-post-object-wrapper">
                    {post.objects.map((object, index) => (
                        <div key={`object-${index}`} className="chat-post-object"
                            style={{
                                backgroundColor: theme.backgroundColorFaded
                            }}
                            onClick={() => handleObjectClick(object)}>
                            <Object object={object} />
                        </div>
                    ))}
                </div>
            }

            {/* EVENTS */}
            {post.events && post.events.length > 0 &&
                <div className="chat-post-events-wrapper">
                    {post.events.map((evt, index) => (
                        <Event
                            key={`event-${index}`}
                            event={evt}
                        />
                    ))}
                </div>
            }
        </>
    );
};

export default ChatPost;
