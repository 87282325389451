import React, { useState } from 'react';

// Styles
import './ObjectButton.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import CubeIcon from '../../../../svg/CubeIcon';

// Components
import Search from '../../../../search/Search';

const ObjectButton = ({ onSearch }) => {
  const { theme } = useTheme();   

  // Local State
  const [searchVisible, setSearchVisible] = useState(false);

  return (
    <>
      {/* CONTAINER */}
      <div className="object-button-container"
        style={{
          backgroundColor: theme.backgroundColorFaded,
        }}
        onClick={() => setSearchVisible(true)}>

        {/* ICON */}
        <CubeIcon
          color={theme.foregroundColorFaded}
          width="18"
          height="18"
        />

        {/* SEARCH MODAL */}
        <Search
            onSearch={onSearch}
            isVisible={searchVisible}
            setVisible={setSearchVisible}
        />

      </div>
    </>
  );
};

export default ObjectButton;
