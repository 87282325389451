import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Managers
import { activity } from '../../../managers/ActivityManager';

// Styles
import './CollectionDelete.css';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalMessage from '../../../components/modal/ModalMessage';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * CollectionDelete Component
 * 
 * This component confirms a delete prior to deleting the collection.
 * 
 * @param {object} collection - The collection object to edit.
 * @param {boolean} isOpen - Determines if the modal state is open.
 * @param {function} setOpen - Sets the isOpen state.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionDelete = ({ isOpen, setOpen }) => {

    // Global
    const {
        hideProgress,
        appCollections,
        currentApp,
        selectedCollection,
        setFormFields,
        setSelectedCollection,
        showProgress
    } = useContext(Global);

    /** 
     * Deletes the collection.
     */
    const handleDelete = async () => {

        showProgress("Deleting collection...");

        try {
            const collectionKey = selectedCollection.key;

            await dataManager.delete(
                collections.models, 
                currentApp.key, 
                collectionKey
            );

            // Update local models state to delete the deleted model
            const updatedFields = appCollections.filter(item => item.key !== collectionKey);

            setFormFields(updatedFields);

            setSelectedCollection(null);

            // Log the activity
            activity.log(currentApp.key, 'deletes', 1);

        } catch (error) {
            console.error("Error deleting model: ", error);
        }

        setOpen(false);

        hideProgress();
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="Delete Collection" width={400} isOpen={isOpen} onClose={() => setOpen(false)}>

                {/* CONFIRM MESSAGE */}
                <ModalMessage text="This will permanently delete all data in this collection. Continue?" />

                {/* BUTTONS */}
                <ModalButtons>

                    {/* DELETE BUTTON */}
                    <ModalButton
                        onClick={handleDelete}
                        label="Delete"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default CollectionDelete;
