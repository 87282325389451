import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../../../firebaseConfig';

// Activity
import { activity } from '../../../../common/managers/ActivityManager';

// Utilities
import { generateKey } from '../../../../common/utilities/Keys';

// Styles
import './LookupEditor.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import PlusIcon from '../../../../common/svg/PlusIcon';
import RemoveIcon from '../../../../common/svg/RemoveIcon';

// Components
import Modal from '../../../../common/components/modal/Modal';
import ModalButton from '../../../../common/components/modal/ModalButton';
import ModalButtons from '../../../../common/components/modal/ModalButtons';
import ModalLabel from '../../../../common/components/modal/ModalLabel';
import ModalSection from '../../../../common/components/modal/ModalSection';

const LookupEditor = ({ field, isOpen, setOpen }) => {
  const { theme } = useTheme();
  const {
    formFields,
    currentApp,
    setFormFields,
  } = useContext(Global);

  const [options, setOptions] = useState(field.options || []);
  const [newOptionTitle, setNewOptionTitle] = useState('');

  const handleRemoveOption = (keyToRemove) => {
    const updatedOptions = options.filter(option => option.key !== keyToRemove);
    setOptions(updatedOptions);
  };

  const handleAddOption = () => {
    if (!newOptionTitle.trim()) return;

    const newOption = { key: generateKey(), title: newOptionTitle };
    const updatedOptions = [...options, newOption];
    setOptions(updatedOptions);
    setNewOptionTitle('');
  };

  const handleOptionsSave = async (newOptions) => {
    const data = {
      options: newOptions
    };

    try {
      await updateDoc(doc(db, collections.fields, field.key), data);

      // Update the title in the formFields state
      const updatedFormFields = formFields.map(item =>
        item.key === field.key ? { ...item, options: newOptions } : item
      );
      setFormFields(updatedFormFields);

      activity.log(currentApp.key, 'writes', 1);

      setOpen(false);
    } catch (error) {
      console.error('Error renaming field:', error);
    }
  };

  return (

    <>
      {/* MODAL */}
      <Modal title={field.title}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        width="330px">

        <ModalSection>

          {/* LABEL */}
          <ModalLabel text="List Options" />

          {/* OPTIONS */}
          <div className="lookup-editor-options-wrapper">

            {options.map(option => (
              <div key={option.key} className="lookup-editor-option-item">

                <div className="lookup-editor-option-text"
                  style={{ color: theme.foregroundColor }}>
                  {option.title}
                </div>

                <div className="lookup-editor-remove-container"
                  onClick={() => handleRemoveOption(option.key)}>
                  <RemoveIcon
                    color={theme.foregroundColorFaded}
                    width="14"
                    height="14"
                  />
                </div>
              </div>
            ))}
            <div className="lookup-editor-add-container">

              <div className="lookup-editor-add-text-container">
                <input
                  type="text"
                  placeholder="Add option"
                  value={newOptionTitle}
                  onChange={(e) => setNewOptionTitle(e.target.value)}
                  className="lookup-editor-add-input"
                  style={{
                    color: theme.foregroundColor,
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded
                  }}
                />
              </div>

              <div
                className="lookup-editor-add-button-container"
                onClick={handleAddOption}>
                <PlusIcon
                  color={theme.foregroundColorFaded}
                  width="14"
                  height="14"
                />
              </div>
            </div>

          </div>

        </ModalSection>

        {/* SAVE BUTTON */}
        <ModalButtons>

          {/* ADD BUTTON */}
          <ModalButton
            onClick={() => handleOptionsSave(options)}
            label="Save Changes"
          />

        </ModalButtons>

      </Modal>
    </>
  );
};

export default LookupEditor;
