import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { UserPreference } from '../../common/utilities/Enums';

// Managers
import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

/**
 * Custom hook to load a user's last objects view for the selected app.
 */
const useLoadObjectsViewPreference = () => {

  // Global
  const { 
    currentUser,
    currentApp, 
    setObjectsView,
  } = useContext(Global);

  useEffect(() => {

    if (!currentApp) return;

    const lastObjectsView = userManager.getPreference(
      currentUser,
      currentApp.key,
      UserPreference.LAST_OBJECTS_VIEW
    );

    if (lastObjectsView) {
      setObjectsView(lastObjectsView);
    }

  }, [currentApp]);
  
};

export default useLoadObjectsViewPreference;
