import React from 'react';

// Styles
import './AppAddFeaturesItem.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Images
import CheckOffIcon from '../../svg/CheckOffIcon.jsx';
import CheckOnIcon from '../../svg/CheckOnIcon.jsx';

const AppAddFeaturesItem = ({ title, description, isChecked, onCheck }) => {

    // Theme
    const { theme } = useTheme();

    const handleItemCheck = () => {
        onCheck(!isChecked);
    };

    return (

        <div className="app-add-features-item-container"
            onClick={handleItemCheck}>

            {/* CHECKBOX */}
            <div className="app-add-features-item-checkbox">

                {isChecked ? (

                    <CheckOnIcon
                        color={theme.highlightBackgroundColor}
                        checkColor={theme.highlightForegroundColor}
                        width="18"
                        height="18"
                    />

                ) : (

                    <CheckOffIcon
                        color={theme.highlightBackgroundColor}
                        width="18"
                        height="18"
                    />

                )}

            </div>

            <div className="app-add-features-item-about">

                {/* TITLE */}
                <div className="app-add-features-item-title"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {title}
                </div>

                {/* DESCRIPTION */}
                <div className="app-add-features-item-description"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    {description}
                </div>
            </div>
        </div>

    );
};

export default AppAddFeaturesItem;