import React, { useContext } from 'react';

// Firebase
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebaseConfig.js"; // Adjust the import path

// Global
import { Global } from '../Global.js';

// Styles
import './AddAppButton.css';

// Images
import PlusIcon from '../common/svg/PlusIcon.jsx';

const AddAppButton = ({ app }) => {

    // Global
    const {
        setAppAddVisible,
        coreTheme
    } = useContext(Global);

    const handleAdd = async () => {
        setAppAddVisible(true);
    };

    return (

        <div
            className="add-app-button-container"
            style={{
                backgroundColor: coreTheme.highlightBackgroundColor,
                borderColor: coreTheme.backgroundColorFaded
            }}
            onClick={handleAdd}>

            <div className="add-app-button-title"
                style={{
                    color: coreTheme.highlightForegroundColor,
                }}>
                Build an App
            </div>

            <div className="add-app-button-description"
                style={{
                    color: coreTheme.highlightForegroundColor,
                }}>
                in seconds
            </div>

        </div>

    );
};

export default AddAppButton;
