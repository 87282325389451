import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Circle Loader
import { CircleLoader } from 'react-spinners';

// Styles
import './Progress.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import gradient from '../../../site/gradient.jpg';

/**
 * Progress Component
 * 
 * This component renders a masked progress indicator with conditional styles.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Progress = () => {
  // Theme
  const { theme } = useTheme();

  // Global
  const {
    progressMessage,
    progressVisible,
  } = useContext(Global);

  if (!progressVisible) return null;

  const overlayStyle = {
    backgroundAttachment: 'fixed',
    backgroundImage: `url(${gradient})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100vh',
    left: 0,
    opacity: 0.8,
    position: 'fixed',
    top: 0,
    width: '100vw',
    zIndex: 100, // Behind the content
  };

  return (
    <>
      {/* MASK */}
      <div
        className="progress-mask"
        style={{
          ...overlayStyle, // Gradient image background
        }}
      ></div>

      {/* CONTENT */}
      <div
        className="progress-content"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 200, // Above the mask
        }}>

        {/* CIRCLE LOADER */}
        <div className="progress-circle">
          <CircleLoader
            size={40}
            color={theme.highlightBackgroundColor}
            loading={true}
          />
        </div>

        {/* MESSAGE */}
        <div
          className="progress-status-message"
          style={{
            color: theme.foregroundColor,
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        >
          {progressMessage}
        </div>

      </div>
    </>
  );
};

export default Progress;
