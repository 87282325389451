import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './FormSaveButton.css';

// Images
import SaveIcon from '../../common/svg/SaveIcon';

// Theme
import { useTheme } from '../../ThemeContext';

const FormSaveButton = ({ onClick }) => {
    const { theme } = useTheme();
    const {
        changesMade
    } = useContext(Global);

    return (

        <div className="form-save-button-container"
            style={{
                backgroundColor: changesMade ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                color: changesMade ? theme.highlightForegroundColor : theme.foregroundColorFaded
            }}
            onClick={onClick}>
            <SaveIcon
                color={theme.highlightForegroundColor}
                width="25"
                height="25"
            />
        </div>
    );
};

export default FormSaveButton;
