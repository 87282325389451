import React, { createContext, useState } from 'react';

// Utilities
import { CalendarType, FormMode, LoginMode, MenuType, ObjectsView } from './common/utilities/Enums';

// Settings Images
import FontIcon from './common/svg/FontIcon';
import IconIcon from './common/svg/IconIcon';
import LockIcon from './common/svg/LockIcon';
import LogoIcon from './common/svg/LogoIcon';
import RolesIcon from './common/svg/RolesIcon';
import ThemeIcon from './common/svg/ThemeIcon';
import UserInfoIcon from './common/svg/UserInfoIcon';

export const Global = createContext();

export const GlobalProvider = ({ children }) => {

  /**
   * Core theme for appdeck.
   */
  const coreTheme = {
    backgroundColor: "#0f0f0f",
    backgroundColorFaded: "#1F1F1F",
    highlightBackgroundColor: '#d2360d', //#5E3023',
    highlightForegroundColor: "#FFFFFF", //"#8d4834", 
    foregroundColor: "#FFFFFF",
    foregroundColorFaded: "#999999"
  };

  /**
   * Login/Signup mode:
   * - LOGIN
   * - SIGNUP
   */
  const [loginMode, setLoginMode] = useState(LoginMode.LOGIN);

  /** 
   * The currently logged in user.
   */
  const [currentUser, setCurrentUser] = useState(null);

  /** 
   * Apps the user has created or is a member of.
   */
  const [userApps, setUserApps] = useState([]);

  /** 
   * Indicates if the app add dialog is visible.
   */
  const [appAddVisible, setAppAddVisible] = useState(false);

  /**
   * The currently selected app.
   */
  const [currentApp, setCurrentApp] = useState(null);

  /**
   * The currently active invite.
   */
  const [invite, setInvite] = useState(null);

  /**
   * Processed invite keys, to ensure invites are only processed once.
   */
  const [processedInviteKeys, setProcessedInviteKeys] = useState([]);

  /**
   * The following app-specific state variables are preloaded when the first 
   * app is loaded or the current app changes.
   */
  const [appCalendars, setAppCalendars] = useState([]);
  const [appChannels, setAppChannels] = useState([]);
  const [appCollections, setAppCollections] = useState([]);
  const [appEvents, setAppEvents] = useState([]);
  const [appFields, setAppFields] = useState([]);
  const [appNewsfeeds, setAppNewsfeeds] = useState([]);
  const [appRoles, setAppRoles] = useState([]);
  const [members, setMembers] = useState([]);

  /**
   * The following app user-specific state variables are preloaded when the first 
   * app is loaded or the current app changes.
   */
  const [memberBookmarks, setMemberBookmarks] = useState([]);
  const [memberFavorites, setFavorites] = useState([]);

  /**
   * Menu type in desktop mode:
   * - TOP
   * - LEFT
   */
  const [menuType, setMenuType] = useState(MenuType.TOP);

  /**
   * Indicates whether the menu is visible on mobile.
   */
  const [menuVisible, setMenuVisible] = useState(false);

  /**
   * Back button navigation.
   * 
   * Handles click events on the back button.
   * - Sets visibility of the back button.
   * - Sets the function to execute on back button click.
   */
  const [backVisible, setBackVisible] = useState(false);
  const [backButtonAction, setBackButtonAction] = useState(null);

  /**
   * High-level screens:
   * 
   * - SPLASH - initial splash screen
   * - HOME - logged in home screen
   * - WELCOME - welcome (logged out) screen
   * - RUN - screen in which individual apps are run
   */
  const [screen, setScreen] = useState(Screen.SPLASH);

  /**
   * Section of the app currently being viewed.
   * 
   * - ACCOUNT
   * - BOOKMARKS
   * - CALENDAR
   * - CHAT
   * - FAVORITES
   * - FORUM
   * - MEMBERS
   * - MESSAGES
   * - SETTINGS
   * - VIDEOCHAT
   */
  const [currentSection, setCurrentSection] = useState(null);

  /**
   * Resets all lists and selections in preparation for a new selection.
   */
  const resetSelections = () => {
    setCurrentSection(null);
    setFormMode(null);
    setMenuVisible(false);

    setObjects(null);
    
    setSelectedCalendar(null);
    setSelectedChannel(null);
    setSelectedCollection(null);
    setSelectedConversation(null);
    setSelectedForumPost(null);
    setSelectedMember(null);
    setSelectedNewsfeed(null);
    setSelectedObject(null);
    setSelectedSetting(null);
  };
  
  /**
   * Settings for each app.
   */
  const settings = [
    { type: 'SETTING', key: 'GENERAL', icon: LogoIcon, title: 'App Information', description: 'General app information.' },
    { type: 'SETTING', key: 'ROLES', icon: RolesIcon, title: 'Security Roles', description: 'User role hierarchy.' },
    { type: 'SETTING', key: 'PERMISSIONS', icon: LockIcon, title: 'Role Permissions', description: 'Role permissions.' },
    { type: 'SETTING', key: 'THEME', icon: ThemeIcon, title: 'Theme', description: 'Colors for dark/light modes.' },
    { type: 'SETTING', key: 'ICON', icon: IconIcon, title: 'App Icon', description: 'App icons for dark/light modes.' },
    { type: 'SETTING', key: 'FONT', icon: FontIcon, title: 'App Font', description: 'Font family to use throughout the app.' },
    { type: 'SETTING', key: 'USERFIELDS', icon: UserInfoIcon, title: 'User Fields', description: 'User reference fields.' },
  ];
  const [selectedSetting, setSelectedSetting] = useState(null);

  /**
   * Permissions for all roles within an app.
   */
  const [appPermissions, setAppPermissions] = useState({});

  /**
   * The app member profile currently being viewed.
   */
  const [selectedMember, setSelectedMember] = useState(null);

  /**
   * Collection-related state variables.
   */
  const [changesMade, setChangesMade] = useState(false);
  const [collectionAddOpen, setCollectionAddOpen] = useState(false);
  const [currentObject, setCurrentObject] = useState({});
  const [elements, setElements] = useState([]);
  const [fieldFilter, setFieldFilter] = useState('');
  const [fieldSelectorVisible, setFieldSelectorVisible] = useState(false);
  const [fieldVisible, setFieldVisible] = useState(false);
  const [formFields, setFormFields] = useState([]);
  const [formMode, setFormMode] = useState(FormMode.VIEW);
  const [lastCamera, setLastCamera] = useState(""); // Barcode Scanning
  const [objects, setObjects] = useState([]);
  const [objectsView, setObjectsView] = useState(ObjectsView.GRID);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);
  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedField, setSelectedField] = useState(null);
  const [selectedObject, setSelectedObject] = useState(null);
  const [selectedObjectTags, setSelectedObjectTags] = useState([]);
  const [sortField, setSortField] = useState('');
  const [sortDirection, setSortDirection] = useState('ASC');
  const [userElements, setUserElements] = useState([]);

  /**
   * Channel-related state variables.
   */
  const [channelAddOpen, setChannelAddOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [selectedForumPost, setSelectedForumPost] = useState(null);
  const [selectedForumTags, setSelectedForumTags] = useState([]);

  /**
   * Calendar-related state variables.
   */
  const [activeDate, setActiveDate] = useState(new Date());
  const [calendarAddOpen, setCalendarAddOpen] = useState(false);
  const [calendarTitle, setCalendarTitle] = useState('');
  const [currentDay, setCurrentDay] = useState(null);
  const [currentYear, setCurrentYear] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(null);
  const [currentWeek, setCurrentWeek] = useState(null);
  const [defaultEventTag, setDefaultEventTag] = useState(false);
  const [eventVisible, setEventVisible] = useState(null);
  const [monthPickerVisible, setMonthPickerVisible] = useState(false);
  const [selectedCalendar, setSelectedCalendar] = useState(null);
  const [selectedCalendarTags, setSelectedCalendarTags] = useState([]);
  const [selectedCalendarType, setSelectedCalendarType] = useState(CalendarType.DAY);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);

  /** 
   * Newsfeed-related state variables.
   */
  const [newsfeedAddOpen, setNewsfeedAddOpen] = useState(false);
  const [selectedNewsfeed, setSelectedNewsfeed] = useState(null);

  /**
   * Progress-related state variables and functions.
   */
  const [progressMessage, setProgressMessage] = useState("Ready.");
  const [progressVisible, setProgressVisible] = useState(false);

  // Show
  const showProgress = (message) => {
    setProgressMessage(message);
    setProgressVisible(true);
  };

  // Hide
  const hideProgress = () => {
    setProgressMessage("Ready.");
    setProgressVisible(false);
  };

  /**
   * Conversation-related state variables.
   */
  const [conversations, setConversations] = useState([]);
  const [selectedConversation, setSelectedConversation] = useState(null);

  /**
   * Account-related state variables.
   */
  const [accountAboutVisible, setAccountAboutVisible] = useState(false);
  const [accountBillingVisible, setAccountBillingVisible] = useState(false);
  const [accountOpen, setAccountOpen] = useState(false);

  /**
   * User search form visibility.
   */
  const [userSearchVisible, setUserSearchVisible] = useState(false);

  // Financials
  const [targetApp, setTargetApp] = useState("ALL");
  const [timespan, setTimespan] = useState("LAST24"); // CURRENT, LAST60, LAST24, LAST7, LAST30

  // Headlines
  const [editingHeadlines, setEditingHeadlines] = useState(false);
  const [headlines, setHeadlines] = useState([]);

  return (
    <Global.Provider value={{

      // Core Theme
      coreTheme,

      // Login Mode
      loginMode, setLoginMode,

      // Current User
      currentUser, setCurrentUser,

      // User Apps
      userApps, setUserApps,

      // Add App Dialog
      appAddVisible, setAppAddVisible,

      // Current App
      currentApp, setCurrentApp,

      // App Preloads
      appCalendars, setAppCalendars,
      appChannels, setAppChannels,
      appCollections, setAppCollections,
      appEvents, setAppEvents,
      appFields, setAppFields,
      appNewsfeeds, setAppNewsfeeds,
      appRoles, setAppRoles,
      members, setMembers,

      // App Settings
      settings,

      // Form Mode
      formMode, setFormMode,

      // Back Button Navigation
      backVisible, setBackVisible,
      backButtonAction, setBackButtonAction,

      currentSection, setCurrentSection,


      // Functions
      resetSelections,

      // App
      appPermissions, setAppPermissions,

      // Menu
      menuType, setMenuType,

      // Invites
      invite, setInvite,
      processedInviteKeys, setProcessedInviteKeys,

      // Selected Tags
      selectedForumTags, setSelectedForumTags,
      selectedObjectTags, setSelectedObjectTags,

      eventVisible, setEventVisible,

      menuVisible, setMenuVisible,
      screen, setScreen,
      progressVisible, showProgress, hideProgress,
      progressMessage, setProgressMessage,
      accountOpen, setAccountOpen,
      userSearchVisible, setUserSearchVisible,
      fieldVisible, setFieldVisible,

      // Bookmarks
      memberBookmarks, setMemberBookmarks,

      // Favorites
      memberFavorites, setFavorites,

      // Calendars
      calendarAddOpen, setCalendarAddOpen,
      calendarTitle, setCalendarTitle,
      selectedCalendar, setSelectedCalendar,
      selectedCalendarTags, setSelectedCalendarTags,
      selectedCalendarType, setSelectedCalendarType,
      defaultEventTag, setDefaultEventTag,

      // Collections
      collectionAddOpen, setCollectionAddOpen,
      selectedCollection, setSelectedCollection,

      // Newsfeeds
      newsfeedAddOpen, setNewsfeedAddOpen,
      selectedNewsfeed, setSelectedNewsfeed,

      // Fields
      fieldSelectorVisible, setFieldSelectorVisible,
      selectedField, setSelectedField,
      fieldFilter, setFieldFilter,

      // Run
      changesMade, setChangesMade,
      currentObject, setCurrentObject,
      formFields, setFormFields,
      lastCamera, setLastCamera,
      objects, setObjects,
      selectedObject, setSelectedObject,
      sortField, setSortField,
      sortDirection, setSortDirection,
      objectsView, setObjectsView,

      // Calendar
      activeDate, setActiveDate,
      monthPickerVisible, setMonthPickerVisible,
      selectedEvent, setSelectedEvent,
      currentYear, setCurrentYear,
      currentMonth, setCurrentMonth,
      currentWeek, setCurrentWeek,
      currentDay, setCurrentDay,
      selectedTime, setSelectedTime,

      // Conversations
      conversations, setConversations,
      selectedConversation, setSelectedConversation,

      // Financials
      targetApp, setTargetApp,
      timespan, setTimespan,

      // Account
      accountAboutVisible, setAccountAboutVisible,
      accountBillingVisible, setAccountBillingVisible,

      // App Users
      selectedMember, setSelectedMember,

      // Headlines
      headlines, setHeadlines,
      editingHeadlines, setEditingHeadlines,

      // Designer
      elements, setElements,
      selectedElement, setSelectedElement,

      // User Elements
      userElements, setUserElements,

      // Channels
      selectedChannel, setSelectedChannel,
      channelAddOpen, setChannelAddOpen,
      selectedForumPost, setSelectedForumPost,
      selectedBlock, setSelectedBlock,

      // Settings
      selectedSetting, setSelectedSetting,

    }}>
      {children}
    </Global.Provider>
  );
};
