import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { Screen } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppItem.css';

const AppItem = ({ app }) => {
    const {
        resetSelections,
        setMenuVisible,
        setScreen,
        setCurrentApp,
        setObjects
    } = useContext(Global);

    const handleAppClick = async (app) => {

        resetSelections();

        setCurrentApp(app);
        setScreen(Screen.RUN);

        if (isMobile) {
            setMenuVisible(true);
        }
    };
  
    useEffect(() => {
      if (!app) return;
      loadFont(app.fontFamily || defaultFont);
    }, [app]);

    return (

        <div
            key={app.key}
            className="app-item-container"
            style={{
                backgroundColor: app.themes.dark.backgroundColor,
                borderColor: app.themes.dark.backgroundColorFaded
            }}
            onClick={() => handleAppClick(app)}
        >
            <div className="app-item-wrapper">
                <div className="app-item-top">
                    <div className="app-item-left">
                        {app.logoDark &&
                            <img src={app.logoDark} alt="App Logo" className="app-item-logo" />
                        }
                        <div className="app-item-title"
                            style={{ 
                                color: app.themes.dark.foregroundColor,
                                fontFamily: app.fontFamily,
                                fontSize: isMobile ? (app.logoDark ? '8pt' : '9pt') : '13px'
                            }}>
                            {app.title}
                        </div>
                    </div>
                </div>
                <div className="app-item-description"
                    style={{ 
                        color: app.themes.dark.foregroundColorFaded,
                        fontFamily: app.fontFamily
                    }}>
                    {app.description}
                </div>
                <div style={{flexGrow: 1}}></div>
            </div>
        </div>

    );
};

export default AppItem;
