import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom'; // Import ReactDOM for createPortal

// Global
import { Global } from '../../../Global';

// Utilities
import { hexToRgba } from '../../../common/utilities/Colors';
import { ThemeMode } from '../../../common/utilities/Enums';

// Styles
import './Modal.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CloseIcon from '../../svg/CloseIcon';
import gradient from '../../../site/gradient.jpg';

/**
 * Modal Component
 * 
 * This component renders a modal dialog.
 * 
 * @param {string} title - The title of the modal.
 * @param {boolean} isOpen - Whether the modal is open.
 * @param {function} onClose - The function to call when the modal is closed.
 * @param {JSX.Element} children - The children to render.
 * @param {string} width - The width of the modal.
 * @param {string} height - The height of the modal.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Modal = ({ title, isOpen, onClose, children, width, height }) => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    currentApp,
    coreTheme
  } = useContext(Global);

  // Local State
  const [isAnimating, setIsAnimating] = useState(false);

  // Fade
  useEffect(() => {
    if (isOpen) {
      // Trigger the animation
      setIsAnimating(true);
    } else {
      setIsAnimating(false);
    }
  }, [isOpen]);

  /**
   * Create a style object that includes the width and height if provided.
   */
  const modalStyle = {
    ...currentApp ?
      ({ backgroundColor: currentApp ? theme.backgroundColor : coreTheme.backgroundColor }) :
      ({ backgroundColor: coreTheme && coreTheme.backgroundColor }),
    ...width && { width: width },
    ...height && { height: height },
    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
  };

  /**
   * Modal overlay styles to apply background color or image with opacity.
   */
  const overlayStyleApp = hexToRgba(
    currentThemeMode === ThemeMode.DARK ? "black" : "white",
    0.8 // 80% opacity
  );

  const overlayStyleCore = {
    backgroundImage: `url(${gradient})`, // Semi-transparent image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    opacity: 0.8, // Semi-transparency for the image
  };

  // If modal is not open and not animating, return null to render nothing
  if (!isOpen && !isAnimating) return null;

  // Define the modal content with overlay as a portal
  const modalContent = (
    <>
      {/* OVERLAY */}
      <div
        className="modal-overlay"
        style={{
          backgroundColor: currentApp ? overlayStyleApp : coreTheme.backgroundColor,
          ...overlayStyleCore,
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          zIndex: 10,
        }}
        onMouseDown={onClose}
      />

      {/* CONTENT */}
      <div
        className="modal-content"
        style={{
          ...modalStyle,
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 20,
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onClick={(e) => e.stopPropagation()}
      >

        {/* HEADER */}
        <div className="modal-header">

          {/* TITLE */}
          <div className="modal-title"
            style={{
              color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor
            }}>
            {title}
          </div>

          {/* CLOSE BUTTON */}
          <div className="modal-close-button" onClick={onClose}>
            <CloseIcon
              color={currentApp ?
                theme.foregroundColor :
                coreTheme.foregroundColor
              }
              width="16"
              height="16"
            />
          </div>

        </div>

        {/* BODY */}
        <div className="modal-body">
          {children}
        </div>
      </div>

    </>
  );

  // Use createPortal to render modalContent outside of the root hierarchy
  return ReactDOM.createPortal(modalContent, document.getElementById('portal-root'));
};

export default Modal;
