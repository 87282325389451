import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserFieldsLabel.css';

// Theme
import { useTheme } from '../../../ThemeContext';

const UserFieldsLabel = ({ label }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
      currentApp,
  } = useContext(Global);

  return (
    <div className="user-fields-label-container"
      style={{
        color: theme.foregroundColor,
        fontFamily: currentApp.fontFamily,
      }}>
      {label}:
    </div>
  );
};

export default UserFieldsLabel;
