import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Styles
import './MenuItems.css';

// Theme
import { useStyle, useTheme } from '../../ThemeContext';

// Components
import Calendars from './calendars/Calendars';
import Channels from './channels/Channels';
import Collections from './collections/Collections';
import MenuHeader from './MenuHeader';
import MenuSettings from './MenuSettings';
import Newsfeeds from './newsfeeds/Newsfeeds';
import Settings from './settings/Settings';

const InlineStyles = useStyle`
    .menu-item:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const MenuItems = () => {
    const { theme } = useTheme();
    const {
        selectedCollection,
    } = useContext(Global);

    const [isMenuSettingsOpen, setMenuSettingsOpen] = useState(false);

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className={isMobile ? "menuitems-container-mobile" : "menuitems-container"}
                style={{
                    backgroundColor: isMobile ? theme.backgroundColor : "transparent",
                }}>

                {isMobile &&
                    <MenuHeader />
                }

                <div className="menuitems-list">

                    {/* NEWSFEEDS */}
                    <Newsfeeds />

                    {/* COLLECTIONS */}
                    <Collections />

                    {/* CALENDARS */}
                    <Calendars />

                    {/* CHANNELS */}
                    <Channels />

                    {/* SETTINGS */}
                    <Settings />

                    {selectedCollection &&
                        <MenuSettings
                            modelKey={selectedCollection.key}
                            isMenuSettingsOpen={isMenuSettingsOpen}
                            setMenuSettingsOpen={setMenuSettingsOpen}
                        />
                    }

                </div>

                {/* BUTTONS */}
                {isMobile &&
                    <div className="menu-items-buttons">

                        {/* NAV BAR 
                        {currentApp && currentUser &&
                            <NavBar />
                        }
                        */}

                    </div>
                }
            </div>
        </>
    );
};

export default MenuItems;
