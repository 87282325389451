import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Enums
import { ThemeMode } from '../../common/utilities/Enums';

// Utilities
import { defaultFont } from '../utilities/Defaults.js';

// Firebase
import { doc, Timestamp, updateDoc } from 'firebase/firestore';
import { collections, db, storage } from '../../firebaseConfig';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';

// Styles
import './AppSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppDelete from './appdelete/AppDelete.js';
import AppSettingsFont from './AppSettingsFont';
import AppSettingsGeneral from './general/AppSettingsGeneral';
import AppSettingsIcon from './AppSettingsIcon';
import AppSettingsPermissions from './permissions/AppSettingsPermissions';
import AppSettingsSaveButton from './AppSettingsSaveButton.js';
import AppSettingsTheme from './AppSettingsTheme';
import UserFields from './userfields/UserFields';
import Roles from './roles/Roles.js';
import StatusMessage from '../../common/components/statusmessage/StatusMessage';

// Managers
import { activity } from '../../common/managers/ActivityManager';
import AppManager from '../../common/managers/AppManager';
import DataManager from '../../common/managers/DataManager';

const appManager = new AppManager();
const dataManager = new DataManager();

/**
 * AppSettings Component
 * 
 * This component allows the user to edit the settings of an app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppSettings = () => {

    // Theme
    const { defaultThemes, theme } = useTheme();

    // Global
    const {
        currentApp,
        selectedSetting,
        setCurrentApp,
    } = useContext(Global);

    // Local State

    // Title
    const [title, setTitle] = useState('');

    // Description
    const [description, setDescription] = useState('');

    // Domain
    const [domain, setDomain] = useState('');

    // Delete
    const [deleteVisible, setDeleteVisible] = useState('');

    // Icon
    const [selectedDarkIcon, setSelectedDarkIcon] = useState(null);
    const [selectedLightIcon, setSelectedLightIcon] = useState(null);
    const [iconSize, setIconSize] = useState(1.0);

    // Themes
    const [themes, setThemes] = useState(defaultThemes);
    const [fontFamily, setFontFamily] = useState('');

    // Define state for required fields and displayKey
    const [requiredFields, setRequiredFields] = useState({
        fullname: false,
        displayname: false,
        handle: false,
        birthdate: false,
    });
    const [displayKey, setDisplayKey] = useState('username'); // Default to 'username'

    // Status
    const [message, setMessage] = useState('');
    const [messageVisible, setMessageVisible] = useState(false);

    /** 
     * Updates the state when the selected app changes.
     */
    useEffect(() => {
        if (currentApp) {
            setTitle(currentApp.title || '');
            setDescription(currentApp.description || '');
            setDomain(currentApp.domain || '');
            setThemes(currentApp.themes || defaultThemes);
            setIconSize(currentApp.iconSize || 1.0);
            setFontFamily(currentApp.fontFamily || defaultFont);
            setRequiredFields(currentApp.requiredFields || {
                fullname: false,
                displayname: false,
                handle: false,
                birthdate: false,
            });
            setDisplayKey(currentApp.displayKey || 'username');
        }
    }, [currentApp]);

    /**
     * Method to handle an icon size change.
     * 
     * @param {decimal} size - Slider value, on a scale of 0 to 1.
     */
    const handleIconSizeChange = (size) => {
        setIconSize(size);
    };

    /**
     * Method to handle a theme update.
     * 
     * @param {object} updatedThemes - JSON object containing the updated themes.
     */
    const handleUpdateThemes = (updatedThemes) => {
        setThemes(updatedThemes);
    };

    /**
     * Method to handle a font family change.
     * 
     * @param {string} updatedFontFamily - Updated font family.
     */
    const handleFontChange = (updatedFontFamily) => {
        setFontFamily(updatedFontFamily);
    };

    /**
     * Method to handle saving of the app settings.
     */
    const handleSave = async () => {

        // Validate the title
        if (!title.trim()) {
            alert('Please enter an app title.');
            return;
        }

        // Validate the domain existence
        if (!domain.trim()) {
            alert('Please enter an app domain.');
            return;
        }

        // Ensure the domain is alphanumeric and contains no spaces
        const alphanumericRegex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/; // Allows alphanumeric with optional hyphens
        if (!alphanumericRegex.test(domain.trim().toLowerCase())) {
            alert('The domain must be alphanumeric and cannot contain spaces or special characters (except hyphens).');
            return;
        }

        // Validate the domain is not taken by a different app
        const domainExists = await appManager.domainExists(domain.trim().toLowerCase());
        if (domainExists) {
            if (currentApp.domain !== domain.trim().toLowerCase()) {
                alert('This domain is in use. Please choose another.');
                return;
            }
        }

        // Upload the dark icon if it was selected
        if (selectedDarkIcon) {
            await uploadIcon(ThemeMode.DARK);
        }

        // Upload the light icon if it was selected
        if (selectedLightIcon) {
            await uploadIcon(ThemeMode.LIGHT);
        }

        // Current timestamp
        const now = Timestamp.now();

        const data = {
            title: title.trim(),
            description: description.trim(),
            domain: domain.trim().toLowerCase(),
            themes: themes,
            iconSize: iconSize,
            fontFamily: fontFamily,
            requiredFields: requiredFields,
            displayKey: displayKey,
            dateModified: now,
        };

        // Call updateApp to update the database
        const result = await dataManager.update(
            collections.apps,
            currentApp.key,
            currentApp.key,
            data
        );

        if (result.success) {
            // Reset the model and close the modal
            setTitle('');
            setDescription('');

            // Update state
            setCurrentApp((prevApp) => ({
                ...prevApp,
                title: title.trim(),
                description: description.trim(),
                domain: domain.trim().toLowerCase(),
                themes: themes,
                fontFamily: fontFamily,
                requiredFields: requiredFields,
                displayKey: displayKey,
            }));
        }

        setMessage("App settings saved.");
        setMessageVisible(true);
    };

    /**
     * Method to upload an icon to Firebase Storage.
     * 
     * @param {string} mode - "DARK" or "LIGHT".
     */
    const uploadIcon = async (mode) => {
        const selectedFile = mode === ThemeMode.DARK ? selectedDarkIcon : selectedLightIcon;
        const fileExtension = selectedFile.name.split('.').pop();

        const createResizedImageBlob = (file) => {
            return new Promise((resolve, reject) => {
                const img = document.createElement('img');
                img.onload = () => {
                    const maxDimension = 1000;
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');
                    const ratio = Math.min(maxDimension / img.width, maxDimension / img.height);
                    const width = img.width * ratio;
                    const height = img.height * ratio;

                    canvas.width = width;
                    canvas.height = height;
                    ctx.drawImage(img, 0, 0, width, height);

                    canvas.toBlob((blob) => {
                        if (blob) {
                            resolve(blob); // Resolve the promise with the blob
                        } else {
                            reject(new Error("Failed to create blob"));
                        }
                    }, `image/${fileExtension}`);
                };
                img.onerror = reject; // Handle any errors that occur when loading the image
                img.src = URL.createObjectURL(file); // Set the image source to the selected file
            });
        };

        try {
            const resizedBlob = await createResizedImageBlob(selectedFile); // Resize the image

            const logoFolderRef = ref(storage, `logos/${mode.toLowerCase()}/`);

            // List all files in the folder and delete existing ones
            const listResults = await listAll(logoFolderRef);
            const existingFiles = listResults.items.filter((item) =>
                item.name.startsWith(currentApp.key)
            );
            for (const fileRef of existingFiles) {
                await deleteObject(fileRef);
            }

            // Upload the new file
            const storageRef = ref(storage, `logos/${mode.toLowerCase()}/${currentApp.key}.${fileExtension}`);
            await uploadBytes(storageRef, resizedBlob); // Upload the resized blob
            const fileUrl = await getDownloadURL(storageRef); // Get the URL

            // Update the selected app object with the new logo
            const updatedApp = {
                ...currentApp,
                [mode === ThemeMode.DARK ? 'logoDark' : 'logoLight']: fileUrl,
            };

            setCurrentApp(updatedApp); // Update app state

            // Update Firestore document
            await updateDoc(doc(db, collections.apps, currentApp.key), updatedApp);

            activity.log(currentApp.key, 'writes', 1);
        } catch (error) {
            console.error("Error handling app logo upload:", error);
        }
    };

    /**
     * Method to handle deleting an app.
     */
    const handleDelete = async () => {
        setDeleteVisible(true);
    };

    /**
     * Method to handle a dark icon selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleDarkIconSelect = (file) => {
        setSelectedDarkIcon(file);
    };

    /**
     * Method to handle a light icon selection.
     * 
     * @param {file} file - The selected file.
     */
    const handleLightIconSelect = (file) => {
        setSelectedLightIcon(file);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="app-settings-container">

                {/* TITLEBAR */}
                <div className="app-settings-title"
                    style={{
                        color: theme.foregroundColorFaded,
                    }}>
                    {selectedSetting && selectedSetting.title}

                    {/* STATUS */}
                    <div className="app-settings-status-wrapper">
                        <StatusMessage
                            message={message}
                            visible={messageVisible}
                            setVisible={setMessageVisible}
                        />
                    </div>
                    
                </div>

                {/* CONTENT */}
                <div className="app-settings-content">

                    {/* GENERAL */}
                    {selectedSetting.key === 'GENERAL' && (
                        <AppSettingsGeneral
                            title={title}
                            setTitle={setTitle}
                            description={description}
                            setDescription={setDescription}
                            domain={domain}
                            setDomain={setDomain}
                        />
                    )}

                    {/* USER FIELDS */}
                    {selectedSetting.key === 'USERFIELDS' && (
                        <UserFields
                            requiredFields={requiredFields}
                            setRequiredFields={setRequiredFields}
                            displayKey={displayKey}
                            setDisplayKey={setDisplayKey}
                        />
                    )}

                    {/* ROLES */}
                    {selectedSetting.key === 'ROLES' && (
                        <Roles />
                    )}

                    {/* ROLES */}
                    {selectedSetting.key === 'PERMISSIONS' && (
                        <AppSettingsPermissions />
                    )}

                    {/* ICON */}
                    {selectedSetting.key === 'ICON' && (
                        <AppSettingsIcon
                            themes={themes}
                            defaultSize={iconSize}
                            onDarkSelect={handleDarkIconSelect}
                            onLightSelect={handleLightIconSelect}
                            onSizeChange={handleIconSizeChange}
                        />
                    )}

                    {/* THEME */}
                    {selectedSetting.key === 'THEME' && (
                        <AppSettingsTheme themes={themes} onUpdate={handleUpdateThemes} />
                    )}

                    {/* FONT */}
                    {selectedSetting.key === 'FONT' && (
                        <AppSettingsFont fontFamily={fontFamily} onChange={handleFontChange} />
                    )}
                </div>

                {/* DELETE BUTTON 
                <div className="app-settings-delete-button-wrapper">
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="app-settings-delete-button"
                        style={{
                            color: theme.highlightBackgroundColor,
                            fontFamily: currentApp.fontFamily || 'Open Sans'
                        }}>
                        Delete App
                    </button>
                </div>
                */}

                {/* SAVE BUTTON */}
                <div className="app-settings-save-button-wrapper"
                    style={{
                        bottom: isMobile ? '70px' : '20px',
                    }}>
                    <AppSettingsSaveButton
                        onClick={handleSave}
                    />
                </div>

            </div>

            {/* DELETE */}
            <AppDelete
                isOpen={deleteVisible}
                setOpen={setDeleteVisible}
            />

        </>
    );
};

export default AppSettings;
