import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './CollectionEdit.css';

// Components
import CollectionTags from '../collectiontags/CollectionTags';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * CollectionEdit Component
 * 
 * This component allows the user to edit a collection.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionEdit = ({ isOpen, setOpen }) => {

    // Global
    const {
        currentApp,
        selectedCollection,
        setSelectedCollection
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);

    // Load the selectedCollection attributes
    useEffect(() => {
        if (!selectedCollection) return;
        setTitle(selectedCollection.title);
        setDescription(selectedCollection.description || '');
        setTags(selectedCollection.tags || []);
    }, [selectedCollection]);

    const handleSave = async () => {

        const data = {
            title: title,
            description: description,
            tags: tags
        };

        await dataManager.update(collections.models, currentApp.key, selectedCollection.key, data);

        setOpen(false);
    };

    if (!selectedCollection) return null;

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Collection Settings"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Collection Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <CollectionTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* SAVE */}
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default CollectionEdit;
