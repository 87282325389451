import React, { useState, useContext, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import MemberSummary from '../../members/summary/MemberSummary';
import Modal from '../modal/Modal';
import CheckOffIcon from '../../svg/CheckOffIcon';
import CheckOnIcon from '../../svg/CheckOnIcon';

const UserSelector = ({ selectedUsers, isOpen, includeCurrentUser = true, onClose, onSelect, onSelectComplete }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { currentUser, members } = useContext(Global);

    // State to track selected users directly as objects
    const [checkedUsers, setCheckedUsers] = useState([]);

    /** 
     * Initializes checkedUsers directly from selectedUsers array when modal opens.
     */
    useEffect(() => {
        setCheckedUsers(selectedUsers);
    }, [selectedUsers]);

    /** 
     * Toggles selection of a user.
     */
    const handleCheck = (member) => {
        const isChecked = checkedUsers.find(user => user.userKey === member.userKey);
        const updatedCheckedUsers = isChecked
            ? checkedUsers.filter(user => user.userKey !== member.userKey) // Deselect user
            : [...checkedUsers, member]; // Select user

        setCheckedUsers(updatedCheckedUsers);

        if (onSelect) {
            onSelect(updatedCheckedUsers);
        }
    };

    /** 
     * Handles click on the done button.
     */
    const handleComplete = () => {
        if (onSelectComplete) {
            onSelectComplete(checkedUsers); // Directly pass selected user objects
        }
        onClose();
    };

    return (
        <Modal
            title="Select Users"
            isOpen={isOpen}
            onClose={onClose}
            height="80%"
            width="400px">

            {/* CONTAINER */}
            <div className="user-selector-container" onClick={(e) => e.stopPropagation()}>

                {/* USERS */}
                <div className="user-selector-users">
                    {members
                        .filter((member) => includeCurrentUser || member.userKey !== currentUser.userKey) // Filter based on includeCurrentUser
                        .map((member, index) => {
                            const isChecked = checkedUsers.some(user => user.userKey === member.userKey);
                            return (
                                <>
                                    <div key={member.userKey || index} className="user-selector-user"
                                        style={{
                                            backgroundColor: theme.backgroundColorFaded,
                                            borderColor: isChecked ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                                        }}>

                                        {/* CHECKBOX */}
                                        <div className="user-selector-checkbox" onClick={() => handleCheck(member)}>
                                            {isChecked ? (
                                                <CheckOnIcon color={theme.highlightBackgroundColor} checkColor={theme.foregroundColor} width="22" height="22" />
                                            ) : (
                                                <CheckOffIcon color={theme.highlightBackgroundColor} width="22" height="22" />
                                            )}
                                        </div>

                                        {/* USER SUMMARY */}
                                        <div className="user-selector-summary-wrapper" onClick={() => handleCheck(member)}>
                                            <MemberSummary member={member} />
                                        </div>

                                    </div>
                                </>
                            );
                        })}
                </div>

                {/* FOOTER */}
                <div className="user-selector-footer">

                    {/* DONE BUTTON */}
                    <div
                        className="user-selector-done-button"
                        style={{
                            color: theme.highlightForegroundColor,
                            backgroundColor: theme.highlightBackgroundColor
                        }}
                        onClick={handleComplete}>
                        Done
                    </div>

                </div>

            </div>

        </Modal>
    );
};

export default UserSelector;
