import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Activity
import { activity } from '../../../common/managers/ActivityManager';

// Styles
import './FieldMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DeleteIcon from '../../../common/svg/DeleteIcon';
import DotsIcon from '../../../common/svg/DotsIcon';
import DownIcon from '../../../common/svg/DownIcon';
import RenameIcon from '../../../common/svg/RenameIcon';
import UpIcon from '../../../common/svg/UpIcon';

// Components
import ContextMenuButton from '../../../common/components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../common/components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../common/components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../common/components/contextmenu/ContextMenuPopup';
import FieldRemove from './FieldRemove';
import FieldRename from './FieldRename';

// Managers
import DataManager from '../../../common/managers/DataManager';

const dataManager = new DataManager();

const FieldMenu = ({ field, additionalOptions = [] }) => {
    const { theme } = useTheme();
    const {
        formFields,
        hideProgress,
        currentApp,
        selectedCollection,
        setSelectedCollection,
        showProgress
    } = useContext(Global);

    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [renameOpen, setRenameOpen] = useState(false);
    const [fieldTitle, setFieldTitle] = useState('');

    const toggleMenu = (event) => {
        event.stopPropagation();
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    useEffect(() => {
        if (!field) return;
        setFieldTitle(field.title);
    }, [field]);

    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setConfirmOpen(true);
    };

    const handleMoveUpClick = async (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        sort('up');
    };

    const handleMoveDownClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        sort('down');
    };

    const handleRenameClick = (event) => {
        event.stopPropagation();
        setMenuVisible(false);
        setRenameOpen(true);
    };

    const sort = async (direction) => {
        showProgress("Moving...");

        // Ensure selectedCollection exists
        if (!selectedCollection) {
            console.error("Selected model is not available");
            hideProgress();
            return;
        }

        // Check if fieldSort exists; if not, create it from formFields
        if (!selectedCollection.fieldSort || !Array.isArray(selectedCollection.fieldSort)) {
            const generatedFieldSort = formFields.map(field => field.key);

            // Save the generated fieldSort to the model
            const updatedModel = { ...selectedCollection, fieldSort: generatedFieldSort };

            try {
                await dataManager.update(
                    collections.models,
                    currentApp.key,
                    selectedCollection.key,
                    updatedModel
                );

                activity.log(currentApp.key, 'writes', 1);
                console.log("FieldSort array created and saved");

                // Update the selectedCollection in state
                selectedCollection.fieldSort = generatedFieldSort;
            } catch (error) {
                console.error("Error creating fieldSort array:", error);
                hideProgress();
                return; // Exit if the update fails
            }
        }

        // Re-check fieldSort after ensuring it's created
        if (!selectedCollection.fieldSort || !Array.isArray(selectedCollection.fieldSort)) {
            console.error("FieldSort is still undefined after initialization");
            hideProgress();
            return;
        }

        // Find the current index of the field in the fieldSort array
        const currentIndex = selectedCollection.fieldSort.findIndex(key => key === field.key);
        if (currentIndex === -1) {
            console.error("Field key not found in fieldSort array");
            hideProgress();
            return;
        }

        const newIndex = direction === 'up' ? currentIndex - 1 : currentIndex + 1;

        // Ensure the new index is within bounds
        if (newIndex >= 0 && newIndex < selectedCollection.fieldSort.length) {
            // Create a copy of the fieldSort array to work with
            const updatedFieldSort = [...selectedCollection.fieldSort];

            // Swap the positions of the current item and the target item
            [updatedFieldSort[currentIndex], updatedFieldSort[newIndex]] =
                [updatedFieldSort[newIndex], updatedFieldSort[currentIndex]];

            // Update the model with the new fieldSort array
            const updatedModel = { ...selectedCollection, fieldSort: updatedFieldSort };

            try {
                await dataManager.update(
                    collections.models,
                    currentApp.key,
                    selectedCollection.key,
                    updatedModel
                );

                setSelectedCollection(updatedModel);

                activity.log(currentApp.key, 'writes', 1);
            } catch (error) {
                console.error('Error updating field order:', error);
            }
        } else {
            console.error("New index is out of bounds");
        }

        hideProgress();
    };

    return (
        <>
            {/* BUTTON */}
            <div className="field-menu-button">
                <ContextMenuButton
                    onToggle={toggleMenu}
                    color={theme.foregroundColorFaded}
                    hoverColor={theme.foregroundColor}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={170}
                position={menuPosition}>

                {/* BASE OPTIONS */}
                <ContextMenuGroup title={field.title}>

                    {/* ADDITIONAL OPTIONS */}
                    {additionalOptions.length > 0 && additionalOptions.map((option, index) => (
                        <ContextMenuOption
                            key={index}
                            icon={option.icon || DotsIcon}
                            text={option.text}
                            onClick={(event) => {
                                event.stopPropagation();
                                option.onClick(event);
                                setMenuVisible(false);
                            }}
                        />
                    ))}

                    <ContextMenuOption
                        icon={RenameIcon}
                        text="Rename Field"
                        onClick={handleRenameClick}
                    />

                    <ContextMenuOption
                        icon={UpIcon}
                        text="Move Up"
                        onClick={handleMoveUpClick}
                    />

                    <ContextMenuOption
                        icon={DownIcon}
                        text="Move Down"
                        onClick={handleMoveDownClick}
                    />

                    {field && selectedCollection && field.key !== selectedCollection.titleFieldKey &&
                        <ContextMenuOption
                            icon={DeleteIcon}
                            text="Remove"
                            iconSize={11}
                            onClick={handleDeleteClick}
                        />
                    }
                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* RENAME MODAL */}
            <FieldRename
                field={field}
                fieldTitle={fieldTitle}
                setFieldTitle={setFieldTitle}
                renameOpen={renameOpen}
                setRenameOpen={setRenameOpen}
            />

            {/* REMOVE MODAL */}
            <FieldRemove
                field={field}
                confirmOpen={confirmOpen}
                setConfirmOpen={setConfirmOpen}
            />

        </>
    );
};

export default FieldMenu;
