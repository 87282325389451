import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { Timestamp } from 'firebase/firestore';
import { storage } from '../../../../firebaseConfig';

// Utilities
import { PostMode } from '../../../utilities/Enums';
import { generateKey } from '../../../utilities/Keys';

// Styles
import './ForumPostForm.css';

// Images
import UserThumb from '../../../components/userthumb/UserThumb';

// Components
import AddBlockButton from './AddBlockButton';
import Block from './Block';
import ForumPostFormButton from './ForumPostFormButton';
import ForumPostFormTitle from './ForumPostFormTitle';
import Modal from '../../../components/modal/Modal';

// Managers
import ForumPostManager from '../../../managers/ForumPostManager';

const forumPostManager = new ForumPostManager();

// Map of block types to Firebase storage folder names
const folderMap = {
    PHOTOS: "images",
    VIDEOS: "videos",
    DOCUMENTS: "files"
};

const ForumPostForm = ({ isVisible, setVisible, defaultPost }) => {
    
    // Global
    const {
        currentUser,
        hideProgress,
        currentApp,
        selectedBlock,
        selectedChannel,
        setSelectedBlock,
        setSelectedForumPost,
        showProgress
    } = useContext(Global);

    // Local State
    const [postMode, setPostMode] = useState(PostMode.ADD);
    const [title, setTitle] = useState("");
    const [titleFocused, setTitleFocused] = useState(false);
    const [blocks, setBlocks] = useState([]);

    // Event Handlers
    const handleTitleChange = (e) => setTitle(e.target.value);

    // References
    const blocksPanelRef = useRef(null); // Ref for the left panel

    /**
     * Trigger to populate the existing post and set the mode
     * to edit if a defaultPost is supplied.
     */
    useEffect(() => {

        // If there isn't a default post, then we don't need to load anything,
        // but we should add a text block if the form is visible.
        if (!defaultPost) {
            if (isVisible) {
                addBlock('TEXT', '');
            }
            return;
        }

        // Set the post mode to edit
        setPostMode(PostMode.EDIT);

        // Set the title
        setTitle(defaultPost.title);

        // Set the blocks
        setBlocks(defaultPost.blocks);

    }, [isVisible, defaultPost]);

    /**
     * Trigger to focus the title field when the modal is opened.
     */
    useEffect(() => {
        if (isVisible) {
            setTitleFocused(true);
        }
    }, [isVisible]);

    /**
     * Upload a file (photo, video, or document) to Firebase Storage and return the URL.
     *
     * @param {Blob} file - The file to upload (can be a photo, video, or document).
     * @param {string} directory - The directory path in Firebase Storage (e.g., 'images', 'videos', 'files').
     * @returns {Promise<string>} - The URL of the uploaded file.
     */
    const uploadFileToStorage = async (file, directory) => {
        try {
            const key = generateKey();
            const fileExt = file.name ? file.name.split('.').pop() : 'unknown'; // Use 'unknown' if the file extension is not available
            const storageRef = ref(storage, `${directory}/${key}.${fileExt}`);

            // Upload the file to Firebase Storage
            await uploadBytes(storageRef, file);

            // Get and return the file's download URL
            const url = await getDownloadURL(storageRef);
            return url;
        } catch (error) {
            console.error("Error uploading file:", error);
            throw new Error('Failed to upload file');
        }
    };


    /**
     * Method to handle posting to the selected channel.
     *
     * @param {string} e - Click event.
     */
    const handlePost = async () => {

        // Display a message to the user
        showProgress("Posting...");

        // Create a copy of the blocks to modify them
        const updatedBlocks = [...blocks];

        // Iterate over the blocks and handle blocks based on folder map
        for (let i = 0; i < updatedBlocks.length; i++) {
            const block = updatedBlocks[i];

            // Check if the block type is in the folder map (PHOTOS, VIDEOS, DOCUMENTS)
            if (folderMap[block.type] && Array.isArray(block.content)) {
                const folder = folderMap[block.type]; // Get the storage folder name

                // Process and upload each item in the content array
                const updatedContent = await Promise.all(block.content.map(async (item) => {
                    // Check if the item.url is a local blob (starts with 'blob:')
                    if (item.url.startsWith("blob:")) {
                        const blob = await (await fetch(item.url)).blob(); // Fetch the blob from the item URL
                        const url = await uploadFileToStorage(blob, folder); // Use the mapped folder name
                        return { ...item, url }; // Return the updated object with the new URL
                    }
                    return item; // Keep existing objects with URLs as-is
                }));

                // Update the block with the new content (uploaded URLs)
                updatedBlocks[i] = { ...block, content: updatedContent };
            }
        }

        // After all uploads are done, update the state with the new blocks
        setBlocks(updatedBlocks);

        const now = Timestamp.now();

        if (postMode === PostMode.ADD) {

            const key = generateKey();

            // Create the new post
            const newPost = {
                key: key,
                appKey: currentApp.key,
                channelKey: selectedChannel.key,
                type: "POST",
                userKey: currentUser.userKey,
                username: currentUser.username,
                title: title,
                blocks: updatedBlocks,
                dateCreated: now
            };

            // Add the updated blocks to the forum post
            await forumPostManager.add(
                currentApp.key,
                key,
                newPost
            );

            // Set the post to selected in state
            setSelectedForumPost(newPost);

        } else {

            const data = {
                title: title,
                blocks: updatedBlocks
            };

            // Update the forum post
            await forumPostManager.update(
                currentApp.key,
                defaultPost.key,
                data
            );

            // Update the selected post
            setSelectedForumPost((prev) => ({
                ...prev,
                title: title,
                blocks: blocks
            }));

        }

        hideProgress();

        // Hide the form
        setVisible(false);

        // Reset form fields
        setTitle("");
        setBlocks([]);
    };

    /**
     * Method to add a new block.
     * 
     * @param {string} type - Type of the block.
     */
    const addBlock = (type, data = "", index = null) => {
        const newBlock = {
            type,
            key: generateKey(),
            content: data
        };

        const newBlocks = [...blocks];
        const insertIndex = index !== null ? index : blocks.length;

        newBlocks.splice(insertIndex, 0, newBlock); // Insert the block at the correct position
        setBlocks(newBlocks);
    };

    // Add event listener for keydown (backspace or delete) when component mounts
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
                handleDelete();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [blocks, selectedBlock]); // Dependencies include blocks and selectedBlock to respond to changes

    // Function to handle deletion of the block when backspace/delete is pressed on an empty block
    const handleDelete = () => {
        if (selectedBlock && selectedBlock.content.trim() === "" && selectedBlock.type === "TEXT") {
            const blockIndex = blocks.findIndex(block => block.key === selectedBlock.key);
            if (blockIndex !== -1) {
                const updatedBlocks = [...blocks];
                updatedBlocks.splice(blockIndex, 1); // Remove the block at the specified index
                setBlocks(updatedBlocks); // Update the blocks state
                setSelectedBlock(null); // Clear the selected block
            }
        }
    };

    // Function to handle changes in the content of a block
    const handleChange = async (index, type, newContent) => {

        // Create a copy of the blocks array
        const updatedBlocks = [...blocks];

        // Locate the block at the given index and update its type and content
        if (index >= 0 && index < updatedBlocks.length) {
            const currentBlock = updatedBlocks[index];

            // Update the content and type for the block
            updatedBlocks[index] = {
                ...currentBlock,
                type: type,
                content: newContent
            };

            // Set the updated blocks to the state
            setBlocks(updatedBlocks);

            // Update selectedBlock only if content is being modified (not deleted)
            setSelectedBlock(updatedBlocks[index]);
        } else {
            console.warn(`Invalid index: ${index}`);
        }
    };

    // Function to handle key presses specifically for detecting empty block deletion
    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" || event.key === "Delete") {
            const currentBlock = blocks[index];

            // If the block is empty and backspace/delete is pressed, delete the block
            if (currentBlock && currentBlock.content.trim() === "") {
                handleDelete();
                event.preventDefault(); // Prevent default backspace/delete behavior
            }
        }
    };

    const handleSelect = async (index, type, newContent) => {
        addBlock(type, newContent, index);
    };

    return (
        <Modal
            title="Create Forum Post"
            isOpen={isVisible}
            onClose={() => setVisible(false)}
            width="90%"
            height="90%"
        >
            {/* CONTAINER */}
            <div className="forum-post-form-container">

                {/* INNER CONTAINER */}
                <div className="forum-post-form-inner-container">

                    {/* TITLE WRAPPER */}
                    <div className="forum-post-form-title-wrapper">

                        {/* USER DOT */}
                        <div className="forum-post-form-title-left">
                            <UserThumb
                                user={currentUser}
                                size="36"
                            />
                        </div>

                        {/* CENTER SECTION */}
                        <div className="forum-post-form-title-center">

                            {/* TITLE */}
                            <ForumPostFormTitle
                                title={title}
                                onChange={handleTitleChange}
                                focused={titleFocused}
                            />

                        </div>

                        {/* POST BUTTON */}
                        <div className="forum-post-form-title-right">
                            <ForumPostFormButton
                                onClick={handlePost}
                                text={postMode === PostMode.ADD ? "Post" : "Update"}
                            />
                        </div>

                    </div>

                    {/* CONTENT */}
                    <div className="forum-post-content">

                        {/* BLOCKS */}
                        <div className="forum-post-form-blocks" ref={blocksPanelRef}>
                            {blocks.map((block, index) => (
                                <React.Fragment key={block.key}>

                                    {/* BLOCK */}
                                    <Block
                                        index={index}
                                        block={block}
                                        onChange={handleChange}
                                        onDelete={handleDelete}
                                        onKeyDown={handleKeyDown}
                                        mode="EDIT"
                                    />

                                    {/* ADD BLOCK BUTTON AFTER EACH BLOCK */}
                                    <AddBlockButton
                                        index={index}
                                        onSelect={handleSelect}
                                    />

                                </React.Fragment>
                            ))}

                            {/* ADD BLOCK BUTTON AT THE END */}
                            {blocks.length === 0 &&
                                <AddBlockButton
                                    index={blocks.length} // Index after the last block
                                    onSelect={handleSelect}
                                />
                            }

                        </div>

                    </div>

                </div>

            </div>

        </Modal>
    );
};

export default ForumPostForm;
