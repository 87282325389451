/**
 * Function to convert hex to rgba.
 * 
 * @param {string} hex - The hex color code (e.g., "#AABBCC").
 * @param {number} alpha - The alpha value (0-1).
 * 
 * @returns {string} - The rgba color code (e.g., "rgba(170,187,204,0.5)").
 */
export function hexToRgba(hex, alpha) {
  let r = 0, g = 0, b = 0;
  if (hex.length === 4) {
    r = parseInt(hex[1] + hex[1], 16);
    g = parseInt(hex[2] + hex[2], 16);
    b = parseInt(hex[3] + hex[3], 16);
  } else if (hex.length === 7) {
    r = parseInt(hex[1] + hex[2], 16);
    g = parseInt(hex[3] + hex[4], 16);
    b = parseInt(hex[5] + hex[6], 16);
  }
  return `rgba(${r},${g},${b},${alpha})`;
}

/**
 * Function to generate color shades based on a base color.
 * 
 * @param {string} baseColor - The base color to generate shades from.
 * @param {number} arrayLength - The number of shades to generate.
 * @param {boolean} darkMode - Whether to generate shades for dark mode.
 * 
 * @returns {string[]} - An array of color shades.
 */
export function generateColorShades(baseColor, arrayLength, darkMode) {
  let [h, s, l] = hexToHsl(baseColor);

  // Set limits based on dark or light mode
  const minL = darkMode ? 40 : 10; // Adjust for min lightness
  const maxL = darkMode ? 60 : 45; // Adjust for max lightness

  // Adjust saturation to avoid extreme colors
  const minS = 60;
  const maxS = 80;

  // Generate the color shades
  const shades = Array.from({ length: arrayLength }, (_, i) => {
    let newL = l + (i - arrayLength / 2) * 5; // Incremental lightness
    let newS = s + (i - arrayLength / 2) * 3; // Incremental saturation

    // Clamp values to prevent extreme colors
    newL = Math.max(minL, Math.min(maxL, newL));
    newS = Math.max(minS, Math.min(maxS, newS));

    return hslToHex(h, newS, newL);
  });

  return shades.reverse();
}

/**
 * Function to convert hex to HSL.
 * 
 * @param {string} hex - The hex color code (e.g., "#AABBCC").
 * 
 * @returns {number[]} - An array containing the HSL values.
 */
function hexToHsl(hex) {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;

  let max = Math.max(r, g, b);
  let min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r: h = (g - b) / d + (g < b ? 6 : 0); break;
      case g: h = (b - r) / d + 2; break;
      case b: h = (r - g) / d + 4; break;
      default: break;
    }
    h /= 6;
  }
  return [h * 360, s * 100, l * 100];
}

/**
 * Function to convert HSL to hex.
 * 
 * @param {number} h - The hue value (0-360).
 * @param {number} s - The saturation value (0-100).
 * @param {number} l - The lightness value (0-100).
 * 
 * @returns {string} - The hex color code (e.g., "#AABBCC").
 */
function hslToHex(h, s, l) {
  s /= 100;
  l /= 100;

  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs((h / 60) % 2 - 1));
  let m = l - c / 2;
  let [r, g, b] = h < 60 ? [c, x, 0] : h < 120 ? [x, c, 0] : h < 180 ? [0, c, x] : h < 240 ? [0, x, c] : h < 300 ? [x, 0, c] : [c, 0, x];
  [r, g, b] = [r + m, g + m, b + m].map(v => Math.round(v * 255));
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

/**
 * Converts a hex color code to a grayscale hex color.
 * 
 * @param {string} hex - The hex color code (e.g., "#AABBCC").
 * 
 * @returns {string} - The grayscale hex color (e.g., "#808080").
 */
export function hexToGrayscaleHex(hex) {
  // Remove the hash if present
  hex = hex.replace("#", "");

  // Parse RGB components from hex code
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate luminance (perceived brightness)
  const luminance = Math.round(0.299 * r + 0.587 * g + 0.114 * b);

  // Convert luminance to a two-digit hex string
  const grayHex = luminance.toString(16).padStart(2, '0');

  // Return the grayscale color in hex format
  return `#${grayHex}${grayHex}${grayHex}`;
}

