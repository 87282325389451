import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Firebase
import { collections } from '../../../firebaseConfig';

// Utilities
import { getUserDisplayValue, userIsCreator } from '../../utilities/Users';

// Styles
import './MemberProfile.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AccountAboutHeaderPhoto from '../../../account/about/AccountAboutHeaderPhoto';
import AccountAboutPhoto from '../../../account/about/AccountAboutPhoto';
import MemberMenu from '../menu/MemberMenu';
import MemberRoleSelector from '../roleselector/MemberRoleSelector';

// Managers
import MemberManager from '../../managers/MemberManager';
import DataManager from '../../managers/DataManager';

const memberManager = new MemberManager();
const dataManager = new DataManager();

const MemberProfile = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        currentApp,
        selectedMember,
        setSelectedMember,
        setBackButtonAction,
        setBackVisible,
        appRoles
    } = useContext(Global);

    /** 
     * Sets the custom function to run when the back button is clicked.
     */
    useEffect(() => {

        setBackButtonAction(() => {
            return () => {
                setSelectedMember(null);
                setBackVisible(false);
            };
        });

        if (selectedMember) {
            setBackVisible(true);
        }

        return () => { // Reset on unmount
            setBackVisible(false);
            setBackButtonAction(null);
        };

    }, [selectedMember, setBackButtonAction, setBackVisible]);

    /*
    useEffect(() => {
        if (!selectedMember) return;

        async function fetchUser() {
            // Fetch the user record
            const fetchedUser = await userManager.fetchUser(selectedMember.userKey);
            setUser(fetchedUser);
        }
        fetchUser();

    }, [selectedMember]);
    */

    // Function to format date
    const formatDate = (date) => {
        // Check if the date is a Firebase Timestamp object
        if (date && date.toDate) {
            date = date.toDate(); // Convert Firebase Timestamp to JavaScript Date object
        }

        return date.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const handleRoleSelection = async (selectedRoles) => {

        // Update roles in the database
        const updatedUser = {
            ...selectedMember,
            roles: selectedRoles
        };

        setSelectedMember(updatedUser);

        try {
            await dataManager.update(
                collections.appusers,
                currentApp.key,
                selectedMember.key,
                updatedUser
            );

            console.log('Roles updated successfully.');
        } catch (error) {
            console.error('Error updating roles:', error);
        }
    };

    const handleUserRemove = async () => {
        // Call to remove user from backend or database
        await memberManager.removeMember(currentApp.key, selectedMember);
    };

    if (!selectedMember) return null;

    return (

        <div className="member-profile-container">

            <div className="member-profile-top">

                {/* HEADER */}
                <div className="member-profile-header-wrapper">
                    <AccountAboutHeaderPhoto
                        user={selectedMember}
                    />
                </div>

                {/* USER PHOTO */}
                <div className="member-profile-photo-wrapper"
                    style={{
                        borderColor: theme.backgroundColorFaded
                    }}>
                    <AccountAboutPhoto
                        user={selectedMember}
                        size="135"
                    />
                </div>

                {/* MENU */}
                {selectedMember.userKey !== currentUser.userKey &&
                    <div className="member-profile-menu">
                        <MemberMenu
                            onRemove={handleUserRemove}
                        />
                    </div>
                }

                {/* NAME */}
                <div className="member-profile-name"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {getUserDisplayValue(currentApp.displayKey, selectedMember)}
                </div>

                {/* JOINED */}
                <div className="member-profile-joined"
                    style={{
                        color: theme.foregroundColorFaded
                    }}>
                    Joined {formatDate(selectedMember.dateJoined)}
                </div>

            </div>

            <div className="member-profile-bottom">

                {userIsCreator(currentUser, appRoles) ? (

                    <div className="member-profile-roles"
                        style={{
                            borderColor: theme.backgroundColorFaded
                        }}>

                        <div className="member-profile-roles-label"
                            style={{
                                color: theme.foregroundColorFaded
                            }}>
                            Set Role(s):
                        </div>

                        <MemberRoleSelector
                            onRoleClick={handleRoleSelection}
                        />

                    </div>

                ) : (

                    <div className="member-profile-selected-roles">

                        <MemberRoleSelector
                            readonly={true}
                        />

                    </div>

                )}

                {/**
                    {currentApp.userKey !== user.key &&
                        <div className="member-profile-delete-button-container">
                            <button type="button" onClick={handleRemove}
                                className="member-profile-delete-button"
                                style={{
                                    backgroundColor: theme.highlightBackgroundColor,
                                    color: theme.highlightForegroundColor
                                }}>
                                {currentUser.userKey === user.key ? (
                                    <>
                                        Leave App
                                    </>
                                ) : (
                                    <>
                                        Remove from App
                                    </>
                                )}
                            </button>
                        </div>
                    }

                     */}

            </div>

        </div>

    );
};

export default MemberProfile;
