import React from 'react';

const ThemeIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    version="1.1"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24">
    <path d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
      stroke={color} strokeLinecap="round" strokeLinejoin="round" />
    <path d="M19 3L5 3C3.89543 3 3 3.89543 3 5L3 19C3 20.1046 3.89543 21 5 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3Z"
      stroke={color} strokeLinecap="round" strokeLinejoin="round" />
  </svg>

);

export default ThemeIcon;
