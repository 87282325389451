import React from 'react';

const DarkModeIcon = ({ color = "white", width = "24", height = "24" }) => (

    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10Zm0-1.5v-17a8.5 8.5 0 0 1 0 17Z"
            fill={color} />
    </svg>
    
);

export default DarkModeIcon;
