import React, { useContext, useEffect, useState, useRef } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../../common/utilities/Keys';

// Axios
import axios from 'axios';

// Styles
import './Link.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Managers
import DataManager from '../../../../common/managers/DataManager';
import LinkManager from '../../../../common/managers/LinkManager';

const dataManager = new DataManager();
const linkManager = new LinkManager();

const Link = ({
    url,
    onClick,
    mode = "VIEW"
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentApp,
    } = useContext(Global);

    const [metadata, setMetadata] = useState(null);
    const [loading, setLoading] = useState(true); // Track loading state
    const [error, setError] = useState(null);     // Track error state
    const inProgress = useRef(false); // Ref to track ongoing requests

    // Function to fetch metadata from external API
    const fetchMetadata = async (url) => {
        try {
            const response = await axios.post('https://us-central1-appdeckmain.cloudfunctions.net/fetchMetadata', { url });
            const metadata = {
                ...response.data, // Spread the actual data (metadata)
                url              // Append the URL to the metadata
            };
            return metadata;
        } catch (error) {
            console.error('Error fetching metadata:', error);
            throw new Error('Failed to retrieve metadata');
        }
    };

    // Check if the metadata exists in Firebase
    const checkAndFetchMetadata = async (url) => {
        if (inProgress.current) return; // Prevent duplicate fetches

        try {
            setLoading(true); // Start loading state
            inProgress.current = true; // Set the request as in progress

            // First, check if the metadata already exists in Firebase
            const cachedMetadata = await linkManager.fetchByUrl(url);

            if (cachedMetadata) {
                // Use cached metadata if available
                setMetadata(cachedMetadata);
            } else {
                // If not cached, fetch metadata from external source
                const fetchedMetadata = await fetchMetadata(url);

                // Cache the fetched metadata in Firebase using a unique generated key
                await dataManager.add(
                    collections.links, 
                    currentApp.key, 
                    generateKey(), 
                    fetchedMetadata
                );

                // Set the newly fetched metadata in the component state
                setMetadata(fetchedMetadata);
            }

            setLoading(false); // Stop loading state
        } catch (error) {
            console.error('Error in checkAndFetchMetadata:', error);
            setError('Failed to load link metadata');
            setLoading(false); // Stop loading state
        } finally {
            inProgress.current = false; // Reset request flag
        }
    };

    useEffect(() => {
        if (url && url.trim() !== '') {
            checkAndFetchMetadata(url);
        }
    }, [url]);

    const handleClick = () => {
        if (mode === "EDIT") {
            onClick();
        } else {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    if (loading) return <p>Loading preview...</p>;
    if (error) return <p>{error}</p>;

    return (
        metadata && (
            <div className="link-container"
                onClick={handleClick}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                }}>

                {/* IMAGE */}
                <div>
                    {metadata.image &&
                        <img
                            src={metadata.image}
                            alt={metadata.title}
                            className="link-image"
                        />
                    }
                </div>
                <div
                    className="link-title"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: currentApp.fontFamily
                    }}>
                    {metadata.title}
                </div>
                <div
                    className="link-description"
                    style={{
                        color: theme.foregroundColorFaded,
                        fontFamily: currentApp.fontFamily
                    }}>
                    {metadata.description}
                </div>
            </div>
        )
    );
};

export default Link;
