import React, { useState, useEffect } from 'react';

// Styles
import './StatusMessage.css'; // Ensure you have the CSS transitions here

// Theme
import { useTheme } from '../../../ThemeContext';

const StatusMessage = ({ message, visible, setVisible }) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        let fadeTimer;
        let hideTimer;

        if (visible) {
            // Reset fadeOut state if we're making it visible again
            setFadeOut(false);

            // After 3 seconds, start fade out
            fadeTimer = setTimeout(() => {
                setFadeOut(true);
            }, 2000);

            // After an additional 0.5 seconds (total 3.5s), hide message
            hideTimer = setTimeout(() => {
                setVisible(false);
            }, 3500);
        } else {
            // If not visible, ensure no timers and no fade
            setFadeOut(false);
        }

        return () => {
            if (fadeTimer) clearTimeout(fadeTimer);
            if (hideTimer) clearTimeout(hideTimer);
        };
    }, [visible, setVisible]);

    if (!visible) return null;

    return (
        <div
            className={`status-message-container ${fadeOut ? 'fade-out' : ''}`}
            style={{
                color: theme.foregroundColorFaded
            }}>
            {message}
        </div>
    );
};

export default StatusMessage;

