import { Timestamp } from 'firebase/firestore';

export const dayNamesLetter = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const dayNamesShort = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
export const dayNamesLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export function getElapsedTime(timestamp) {
  const now = Timestamp.now().toDate();
  const commentDate = timestamp.toDate();
  const diffInMs = now - commentDate;

  const minutes = Math.floor(diffInMs / 60000);
  const hours = Math.floor(diffInMs / 3600000);
  const days = Math.floor(diffInMs / 86400000);
  const months = Math.floor(diffInMs / 2592000000); // Approximate month
  const years = Math.floor(diffInMs / 31536000000); // Approximate year

  if (years > 0) return `${years} year${years > 1 ? 's' : ''} ago`;
  if (months > 0) return `${months} month${months > 1 ? 's' : ''} ago`;
  if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
  if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  return 'Just now';
};

export function getElapsedTimeShort(timestamp) {
  const now = Timestamp.now().toDate();
  const commentDate = timestamp.toDate();
  const diffInMs = now - commentDate;

  const seconds = Math.floor(diffInMs / 1000);
  const minutes = Math.floor(diffInMs / 60000);
  const hours = Math.floor(diffInMs / 3600000);
  const days = Math.floor(diffInMs / 86400000);
  const weeks = Math.floor(diffInMs / 604800000); // Approximate week
  const months = Math.floor(diffInMs / 2592000000); // Approximate month
  const years = Math.floor(diffInMs / 31536000000); // Approximate year

  if (years > 0) return `${years}y`;
  if (months > 0) return `${months}mo`;
  if (weeks > 0) return `${weeks}w`;
  if (days > 0) return `${days}d`;
  if (hours > 0) return `${hours}h`;
  if (minutes > 0) return `${minutes}m`;
  if (seconds > 0) return `${seconds}s`;
  return 'Just now';
};


export function getMonthAndYearString(year, month) {
  return new Date(year, month).toLocaleString('default', { month: 'long' }) + " " + year
};

export function getMonthDays(year, month) {
  return new Date(year, month + 1, 0).getDate();
};

export function getFirstDayOfMonth(year, month) {
  return new Date(year, month, 1).getDay();
};
