import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load app users for a specific app and merge current user information.
 */
const usePreloadMembers = () => {
  // Global state
  const { 
    currentUser, 
    currentApp, 
    setMembers, 
    setCurrentUser 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!currentApp || !currentApp.key) {
      setMembers([]);
      return;
    }

    // Function to load app users
    const loadMembers = (appKey) => {
      const handleUpdate = (users) => {
        // Set app users in the global state
        setMembers(users);

        // Log activity
        activity.log(appKey, 'reads', users.length);

        // If currentUser exists, merge the current app user data
        if (currentUser) {
          const member = users.find(user => user.userKey === currentUser.userKey);
          if (member) {
            // Only update currentUser if the merged data is different from the existing currentUser
            const mergedUser = { ...currentUser, ...member };

            // Prevent unnecessary update if currentUser already has the merged data
            if (JSON.stringify(currentUser) !== JSON.stringify(mergedUser)) {
              setCurrentUser(mergedUser);
            }
          }
        }
      };

      const params = [
        { field: "appKey", operator: "==", value: appKey },
      ];

      // Subscribe to app users
      const unsubscribe = dataManager.listAndSubscribe(
        collections.appusers,
        appKey,
        handleUpdate,
        params
      );

      return unsubscribe;
    };

    // Subscribe to app users
    const unsubscribeMembers = loadMembers(currentApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeMembers) {
        unsubscribeMembers();
      }
    };
  }, [currentApp?.key, currentUser, setMembers, setCurrentUser]);  // Ensure dependencies are correct

};

export default usePreloadMembers;
