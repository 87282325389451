// Firebase
import { collection, getDocs, query, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

class LinkManager {

    /**
     * Fetches an link with the specified url.
     * 
     * @param {string} url - Url to check for.
     * @returns {Promise<Object|null>} A promise that resolves to the link object if found, or null if not found.
    */
    async fetchByUrl(url) {
        try {
            const coll = collection(db, collections.links);
            const q = query(coll, where("url", "==", url));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching link by url:", error);
            throw error;
        }
    }

}

export default LinkManager;
