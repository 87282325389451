import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { Permission } from '../../../../common/utilities/Enums';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './AppPermissions.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import ChevronDownIcon from '../../../svg/ChevronDownIcon';
import ChevronUpIcon from '../../../svg/ChevronUpIcon';

// Components
import AppPermission from './permission/AppPermission';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

const AppPermissions = () => {
    const { theme } = useTheme();
    const {
        appPermissions,
        appRoles,
        currentApp,
        setAppPermissions,
    } = useContext(Global);

    const [expanded, setExpanded] = useState(true);

    const savePermissions = async (updatedPermissions) => {

        await dataManager.update(
            collections.apps,
            currentApp.key,
            currentApp.key,
            { permissions: updatedPermissions }
        );

        setAppPermissions(updatedPermissions);
    };

    const handlePermissionChange = (roleKey, type, isEnabled) => {
        const updatedPermissions = {
            ...appPermissions,
            roles: {
                ...appPermissions.roles,
                [roleKey]: {
                    ...appPermissions.roles?.[roleKey],
                    [type]: isEnabled,
                },
            },
        };

        setAppPermissions(updatedPermissions);
        savePermissions(updatedPermissions);
    };

    if (!currentApp) return null;

    return (
        <div className="app-permissions-container">
            {/* HEADER */}
            <div className="app-permissions-app-header">
                <div
                    className="app-permissions-app-chevron"
                    onClick={() => setExpanded(!expanded)}
                >
                    {expanded ? (
                        <ChevronUpIcon
                            color={theme.foregroundColorFaded}
                            width="20"
                            height="20"
                        />
                    ) : (
                        <ChevronDownIcon
                            color={theme.foregroundColorFaded}
                            width="20"
                            height="20"
                        />
                    )}
                </div>

                <div
                    className="app-permissions-app-title"
                    onClick={() => setExpanded(!expanded)}
                    style={{
                        color: theme.foregroundColor,
                    }}>
                    General
                </div>
            </div>

            {/* CONTENTS */}
            {expanded && (
                <div className="app-permissions-model-contents">
                    {appRoles &&
                        appRoles
                            .slice()
                            .sort((a, b) => a.priorityLevel - b.priorityLevel)
                            .map((role) => (
                                <React.Fragment key={role.key}>
                                    {!role.isCreator && (
                                        <div
                                            className="app-permissions-role-container"
                                            style={{
                                                borderColor: theme.backgroundColorFaded,
                                            }}>
                                            
                                            <div
                                                className="app-permissions-role-title"
                                                style={{
                                                    color: theme.foregroundColor,
                                                }}>
                                                {role.title}
                                            </div>

                                            {/* APP PERMISSIONS */}
                                            <AppPermission
                                                type={Permission.MANAGE_CHANNELS}
                                                role={role}
                                                title="Manage Channels"
                                                isChecked={
                                                    appPermissions?.roles?.[role.key]?.[Permission.MANAGE_CHANNELS] ?? true
                                                }
                                                onChange={handlePermissionChange}
                                            />
                                            <AppPermission
                                                type={Permission.MANAGE_CALENDARS}
                                                role={role}
                                                title="Manage Calendars"
                                                isChecked={
                                                    appPermissions?.roles?.[role.key]?.[Permission.MANAGE_CALENDARS] ?? true
                                                }
                                                onChange={handlePermissionChange}
                                            />
                                            <AppPermission
                                                type={Permission.MANAGE_COLLECTIONS}
                                                role={role}
                                                title="Manage Collections"
                                                isChecked={
                                                    appPermissions?.roles?.[role.key]?.[Permission.MANAGE_COLLECTIONS] ?? true
                                                }
                                                onChange={handlePermissionChange}
                                            />
                                            <AppPermission
                                                type={Permission.MANAGE_NEWSFEEDS}
                                                role={role}
                                                title="Manage Newsfeeds"
                                                isChecked={
                                                    appPermissions?.roles?.[role.key]?.[Permission.MANAGE_NEWSFEEDS] ?? true
                                                }
                                                onChange={handlePermissionChange}
                                            />
                                        </div>
                                    )}
                                </React.Fragment>
                            ))}
                </div>
            )}
        </div>
    );
};

export default AppPermissions;

