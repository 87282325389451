import React from 'react';

// Styles
import './CollectionPermission.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Images
import CheckOffIcon from '../../../../svg/CheckOffIcon';
import CheckOnIcon from '../../../../svg/CheckOnIcon';

// Components
import ConditionList from '../conditionlist/ConditionList';

/**
 * CollectionPermission Component
 * 
 * This component renders a permission.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionPermission = ({ model, type, role, title, isChecked, onChange }) => {

    const { theme } = useTheme();

    const handleToggle = () => {
        // Notify parent about the change
        onChange(role.key, type, !isChecked);
    };

    return (
        <div
            className="collection-permission-container"
            style={{
                borderTopColor: theme.backgroundColorFaded,
                color: theme.foregroundColorFaded,
            }}
        >
            {/* CHECKBOX */}
            <div className="collection-permission-checkbox" onClick={handleToggle}>
                {isChecked ? (
                    <CheckOnIcon
                        color={theme.highlightBackgroundColor}
                        checkColor={theme.highlightForegroundColor}
                        width="18"
                        height="18"
                    />
                ) : (
                    <CheckOffIcon
                        color={theme.highlightBackgroundColor}
                        width="18"
                        height="18"
                    />
                )}
            </div>

            {/* TITLE */}
            <div
                className="collection-permission-title"
                onClick={handleToggle}
                style={{
                    color: theme.foregroundColorFaded,
                }}
            >
                {title}
            </div>

            {/* CONDITIONS */}
            <div className="collection-permission-contents">
                <ConditionList model={model} type={type} role={role} isChecked={isChecked} />
            </div>
        </div>
    );
};

export default CollectionPermission;
