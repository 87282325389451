// Firebase
import { collection, getDocs, query, where, doc, updateDoc } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';
import { activity } from './ActivityManager';

class BookmarkManager {

    /**
     * Updates specified fields of the object for all bookmarks matching the appKey and objectKey.
     * 
     * @param {string} appKey - App key.
     * @param {string} objectKey - The key of the object.
     * @param {object} data - Partial object data to update.
     * @returns {Promise} - A promise that resolves when the update is complete.
     */
    async updateObject(appKey, objectKey, data) {
        try {
            // Query bookmarks where the appKey and objectKey match
            const memberBookmarksQuery = query(
                collection(db, collections.bookmarks),
                where('appKey', '==', appKey),
                where('objectKey', '==', objectKey)
            );

            // Fetch matching documents
            const querySnapshot = await getDocs(memberBookmarksQuery);

            // Loop through each document and update only the specified fields in the object attribute
            const updatePromises = querySnapshot.docs.map((docSnapshot) => {
                const bookmarkRef = doc(db, collections.bookmarks, docSnapshot.id);

                // Prepare the update object with the nested fields in the "object" attribute
                const updateData = {};
                for (const [key, value] of Object.entries(data)) {
                    updateData[`object.${key}`] = value; // This ensures that only the specified fields are updated
                }

                // Update the object field for each bookmark, but only the fields provided in 'data'
                return updateDoc(bookmarkRef, updateData);
            });

            // Wait for all updates to complete
            await Promise.all(updatePromises);

            // Log the number of writes
            activity.log(appKey, 'writes', querySnapshot.size);

            // Return success message
            return `Successfully updated ${querySnapshot.size} bookmark(s)`;
        } catch (error) {
            console.error('Error updating bookmarks:', error);
            throw new Error('Failed to update bookmarks');
        }
    }

}

export default BookmarkManager;
