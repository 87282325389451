import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AppAddOptions.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AppAddItem from '../item/AppAddItem.js';

// Templates
import { appTemplates } from '../Templates';

const AppAddOptions = ({ handleNewBasic, handleNewFromTemplate }) => {
    const { theme } = useTheme();
    const {
        currentApp,
        coreTheme
    } = useContext(Global);

    return (
        <div className="app-add-options-container">

            <AppAddItem
                icon="PlusIcon"
                name="Build a basic app"
                height="70px"
                onClick={handleNewBasic}
            />

            <div className="app-add-template-title"
                style={{
                    color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded
                }}>
                Build with a template:
            </div>

            {appTemplates.map((template) => (

                <AppAddItem
                    key={template.key}
                    icon={template.icon}
                    name={template.name}
                    description={template.description}
                    onClick={() => handleNewFromTemplate(template.key)}
                />
            ))}
            
        </div>
    );
};

export default AppAddOptions;