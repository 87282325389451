import React from 'react';

const ThemeIcon = ({ color = "white", width = "256", height = "256" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 256 256">
    <rect width="256" height="256" fill="none" />
    <path d="M128,192a24,24,0,0,1,24-24h46.21a24,24,0,0,0,23.4-18.65A96.48,96.48,0,0,0,224,127.17c-.45-52.82-44.16-95.7-97-95.17a96,96,0,0,0-95,96c0,41.81,26.73,73.44,64,86.61A24,24,0,0,0,128,192Z"
      fill="none" stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
    <circle fill={color} cx="128" cy="76" r="12" />
    <circle fill={color} cx="84" cy="100" r="12" />
    <circle fill={color} cx="84" cy="156" r="12" />
    <circle fill={color} cx="172" cy="100" r="12" />
  </svg>
);

export default ThemeIcon;
