import React, { useEffect, useState, useRef } from 'react';

// Styles
import './Barcode.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import BarcodeModal from './BarcodeModal';
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';

const Barcode = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true
}) => {
    const { theme } = useTheme();

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value
    const [barcodeModalOpen, setBarcodeModalOpen] = useState(false);

    // References
    const initialValueRef = useRef(object?.[field.key] || '');

    // Set initial value when object or field.key changes
    useEffect(() => {
        const newValue = object?.[field.key] || '';
        setValue(newValue);
        initialValueRef.current = newValue;
    }, [object, field.key]);

    // Call onUpdate when value changes but avoid triggering it when setting the initial value
    useEffect(() => {
        if (value !== initialValueRef.current) {
            onUpdate(field, value);
        }
    }, [value, field, onUpdate]);

    const handleScanClick = () => {
        setBarcodeModalOpen(true);
    };

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    value={value}
                    showFieldMenu={showFieldMenu}
                />

                <button
                    onClick={handleScanClick}
                    className="barcode-button"
                    style={{
                        backgroundColor: theme.highlightBackgroundColor,
                        borderColor: theme.highlightBackgroundColor,
                        color: theme.foregroundColor
                    }}>
                    Scan
                </button>
                
                <div className="barcode-text"
                    style={{ color: theme.foregroundColor }}>
                    {value}
                </div>
                {barcodeModalOpen &&
                    <BarcodeModal
                        barcodeModalOpen={barcodeModalOpen}
                        setBarcodeModalOpen={setBarcodeModalOpen}
                        onDetected={(detectedValue) => setValue(detectedValue)}
                    />
                }
            </FieldContainer>
        </>
    );
};

export default Barcode;
