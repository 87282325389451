import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../firebaseConfig';

// Styles
import './MenuSettings.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import Modal from '../../common/components/modal/Modal';

// Managers
import DataManager from '../../common/managers/DataManager';
import ModelManager from '../../common/managers/ModelManager';

const dataManager = new DataManager();
const modelManager = new ModelManager();

const MenuSettings = ({
    isMenuSettingsOpen,
    setMenuSettingsOpen
}) => {
    const { theme } = useTheme();
    const {
        appCollections,
        hideProgress,
        currentApp,
        selectedCollection,
        setAppCollections,
        setSelectedCollection,
        showProgress
    } = useContext(Global);

    const [title, setModelTitle] = useState('');

    useEffect(() => {

        if (!selectedCollection) {
            return;
        }

        if (isMenuSettingsOpen) {
            setModelTitle(selectedCollection.title || '');
        }

    }, [selectedCollection, isMenuSettingsOpen]);

    const handleSave = async () => {
        setMenuSettingsOpen(false);

        // Update the title
        const newTitle = selectedCollection.title;

        if (!newTitle.trim()) {
            alert('Please enter a model title.');
            return;
        }

        // Current timestamp
        const now = Timestamp.now();

        const data = {
            title: title,
            shared: false,
            dateModified: now
        };

        const result = await dataManager.update(
            collections.models,
            currentApp.key,
            selectedCollection.key,
            data
        );

        if (result.success) {
            // Reset the model and close the modal
            setModelTitle('');
        } 
    };

    const handleDelete = async () => {

        showProgress("Deleting collection...");

        // Close the modal
        setMenuSettingsOpen(false);

        if (!selectedCollection) {
            alert('No object selected!');
            return;
        }

        try {

            const modelKey = selectedCollection.key;

            await modelManager.delete(currentApp.key, modelKey);

            const updatedModels = appCollections.filter(model => model.key !== modelKey);
            setAppCollections(updatedModels);

            // Select the first object in the updated list, if any
            if (appCollections.length > 0) {
                setSelectedCollection(appCollections[0]);
            } else {
                // If no models are left, clear the selected model
                setSelectedCollection(null);
            }
        } catch (error) {
            console.error("Error deleting object and related data: ", error);
        }

        hideProgress();
    };

    return (

        <Modal title="Menu Item Settings"
            isOpen={isMenuSettingsOpen}
            onClose={() => setMenuSettingsOpen(false)}
            width="300px">

            <div className="menu-settings-container">
                <div className="menu-settings-wrapper">
                    <div className="menu-settings-left">
                        <div className="menu-settings-label"
                            style={{ color: theme.foregroundColorFaded }}
                        >
                            Title
                        </div>
                        <input
                            type="text"
                            name="title"
                            className="modal-input"
                            style={{
                                color: theme.foregroundColor,
                                backgroundColor: theme.backgroundColorFaded,
                                borderColor: theme.backgroundColorFaded
                            }}
                            value={title}
                            maxLength="25"
                            onChange={e => setModelTitle(e.target.value)}
                        />
                    </div>
                </div>

                <div className="modal-buttons">
                    <button
                        type="button"
                        onClick={handleSave}
                        className="modal-button"
                        style={{ color: theme.foregroundColor }}>
                        Save Changes
                    </button>
                </div>

                <div className="modal-buttons">
                    <button
                        type="button"
                        onClick={handleDelete}
                        className="modal-button-delete"
                        style={{ color: theme.foregroundColorFaded }}>
                        Delete
                    </button>
                </div>
            </div>
        </Modal>

    );
};

export default MenuSettings;
