import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './NewsfeedToolbar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import NewsfeedMenu from '../menu/NewsfeedMenu';

/**
 * NewsfeedToolbar Component
 * 
 * This component renders a newsfeed toolbar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NewsfeedToolbar = () => {
    const { theme } = useTheme();
    const {
        currentApp,
        selectedNewsfeed
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="newsfeed-toolbar-container">

                {/* LEFT */}
                <div className="newsfeed-toolbar-left"
                    style={{ 
                        color: theme.foregroundColorFaded,
                        fontFamily: currentApp.fontFamily,
                    }}>

                    {selectedNewsfeed &&
                        <>
                            {selectedNewsfeed.title}
                        </>
                    }
                    
                </div>

                {/* RIGHT */}
                <div className="newsfeed-toolbar-right">
                    <NewsfeedMenu />
                </div>
            </div>
        </>
    );
};

export default NewsfeedToolbar;
