import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { Section } from '../../../common/utilities/Enums';

// Styles
import './UserButton.css';

// Components
import UserThumb from '../userthumb/UserThumb';

/**
 * UserButton Component
 * 
 * This component renders a user button.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const UserButton = () => {

  // Global
  const {
    currentUser,
    setCurrentSection,
    setAccountOpen
  } = useContext(Global);

  /**
   * Handles the click event.
   */
  const handleClick = async () => {
    if (!isMobile) {
      // If we're on desktop, open the user account modal dialog
      setAccountOpen(true);
    } else {
      // If we're on mobile, toggle the account visibility and 
      // display the account menu (handled in the Desktop component)
      setCurrentSection(Section.ACCOUNT);
    }
  };

  return (
    <>
      <div
        onClick={handleClick}>
        {currentUser &&
          <UserThumb
            user={currentUser}
            size={24}
          />
        }
      </div>
    </>
  );
};

export default UserButton;

