import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Managers
import AppManager from '../../common/managers/AppManager';

const appManager = new AppManager();

/**
 * Custom hook to load userApps for a specific user.
 */
const usePreloadApps = () => {

  // Global
  const {
    currentUser,
    setUserApps,
    userApps
  } = useContext(Global);

  useEffect(() => {

    if (!currentUser) {
      setUserApps([]);
      return;
    }

    // If we've already loaded userApps, don't load them again
    if (userApps && userApps.length > 0) {
      return;
    }

    // Function to load userApps
    const loadApps = () => {
      
      console.log('Loading user apps for user:', currentUser);

      const handleUpdate = (items) => {
        console.log('User apps:', items);
        setUserApps(items);
      };

      const unsubscribe = appManager.listUserAppsAndSubscribe(
        currentUser.userKey,
        handleUpdate,
      );

      return unsubscribe;
    };

    // Subscribe to userApps
    const unsubscribeApps = loadApps();

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeApps) {
        unsubscribeApps();
      }
    };

  }, [currentUser, setUserApps]);

};

export default usePreloadApps;
