import React, { useState } from 'react';

// Styles
import './ContextMenuButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DotsIcon from '../../svg/DotsIcon';

/**
 * ContextMenuButton Component
 * 
 * This component renders a button for opening a context menu.
 * 
 * @param {string} title - The title of the button.
 * @param {object} icon - The icon component.
 * @param {integer} iconSize - The size of the icon.
 * @param {function} onToggle - The function to call when the button is clicked.
 * @param {string} color - The base color of the dots icon.
 * @param {string} hoverColor - The color of the dots icon on hover.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ContextMenuButton = ({ title, icon: IconComponent = DotsIcon, iconSize = 16, onToggle, color, hoverColor }) => {
  // Hook to track hover state
  const [isHovered, setIsHovered] = useState(false);

  // Access current theme
  const { theme } = useTheme();

  // Determine the icon color based on hover state:
  // - If hovered and hoverColor is defined, use hoverColor
  // - Otherwise, if not hovered:
  //    - If color is defined, use color
  //    - If color is undefined, pass no color prop, leaving the icon as-is
  let iconColor = undefined;
  if (isHovered && hoverColor) {
    // If hovered and hoverColor is given, use hoverColor
    iconColor = hoverColor;
  } else if (!isHovered && color !== undefined) {
    // If not hovered and color is defined, use color
    iconColor = color;
  }

  // If color is undefined and not hovered, iconColor remains undefined,
  // meaning we do not set the color prop, allowing the icon to remain as-is.

  return (
    <>
      {/* CONTAINER */}
      <div 
        className="context-menu-button-container"
        onClick={onToggle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >

        {/* ICON WRAPPER */}
        <div
          className="context-menu-button-icon"
          title={title}
        >
          {/* ICON 
              We only pass the color prop if iconColor is defined. This ensures
              that if color is not specified, the icon remains uncolored (or defaults 
              to its original styling). 
          */}
          <IconComponent
            {...(iconColor !== undefined && { color: iconColor })}
            width={iconSize}
            height={iconSize}
          />
        </div>

      </div>
    </>
  );
};

export default ContextMenuButton;
