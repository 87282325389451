// Firebase
import { collection, deleteDoc, doc, getDoc, getDocs, increment, query, onSnapshot, setDoc, Timestamp, updateDoc, where, writeBatch } from 'firebase/firestore';

import { collections, db, storage } from '../../firebaseConfig';
import { ref, deleteObject } from 'firebase/storage';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import BookmarkManager from './BookmarkManager';
import DocumentManager from './DocumentManager';
import FavoriteManager from './FavoriteManager';
import IndexManager from './IndexManager';

const bookmarkManager = new BookmarkManager();
const documentManager = new DocumentManager();
const favoriteManager = new FavoriteManager();
const indexManager = new IndexManager();

class ObjectManager {

    /**
     * Method to add an object to a menu item.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The key of the object model type.
     * @param {array} appCollections - All appCollections in the app.
     * @param {string} key - The new key of the object.
     * @param {object} data - Object to add.
     * 
     * @returns {object} - New object.
     */
    async add(appKey, modelKey, appCollections, key, data) {
        // Set the object autonumber
        const autonumber = await this.getAutonumber(modelKey);
        data.autonumber = autonumber;

        // Add the object to its model's collection
        await setDoc(doc(db, modelKey, key), data);

        // Create an index record for searching:
        // - Fetch the model and determine the title field key.
        // - Get the title value from the data by using the title field key
        // - Add the index record
        const appCollection = appCollections.find(appCollection => appCollection.key === modelKey);

        const titleFieldKey = appCollection.titleFieldKey;

        const objectTitle = data[titleFieldKey];
        const objectTags = data.tags || [];

        await indexManager.add(appKey, modelKey, key, objectTitle, objectTags, data);

        // Log 2 writes to the activity log.
        activity.log(appKey, 'writes', 1);
    }

    /**
     * Utility for the addObject method to find the next autonumber for an object.
     * 
     * @param {string} modelKey - The key of the object model type.
     * 
     * @returns {integer} - count
    */
    async getAutonumber(modelKey) {
        const fieldsRef = collection(db, collections.fields);
        const fieldsSnapshot = await getDocs(query(fieldsRef, where('modelKey', '==', modelKey)));
        let count;
        for (const fieldDoc of fieldsSnapshot.docs) {
            // If count is not defined, initialize it
            if (count === undefined) {
                const collectionRef = collection(db, modelKey);
                const objectsSnapshot = await getDocs(collectionRef);
                count = objectsSnapshot.size + 1;
            } else {
                count++;
            }
            // Return an object with both field.key and count
            return count;
        }
        // Default return value if no autonumber field is found
        return 1;
    }

    /**
     * Deletes an object, its relationships, and related summaries.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The model key of the object.
     * @param {string} object - The object being deleted.
     * @param {array} formField - An array of field objects.
    */
    async delete(appKey, modelKey, object, formFields) {

        // Filter gallery-type fields (image and video)
        const galleryFields = formFields.filter(field => field.type === 'gallery' || field.type === 'videogallery');

        // Extract image URLs and delete images from Firebase Storage
        for (const galleryField of galleryFields) {
            const fieldKey = galleryField.key;
            const urls = object[fieldKey] || [];

            for (const url of urls) {
                try {
                    const fileref = ref(storage, url);
                    await deleteObject(fileref);
                } catch (error) {
                    console.error(`Error deleting image: ${url}`, error);
                }
            }
        }

        // Filter document-type fields
        const documentFields = formFields.filter(field => field.type === 'documents');

        // Extract document URLs and delete documents from Firebase Storage
        for (const documentField of documentFields) {
            const fieldKey = documentField.key;

            const results = await documentManager.fetchFieldDocuments(object.key, fieldKey);
            for (const result of results) {
                const documentUrl = result.url;
                try {
                    const documentRef = ref(storage, documentUrl);
                    await deleteObject(documentRef);
                    console.log(`Document deleted successfully: ${documentUrl}`);
                } catch (error) {
                    console.error(`Error deleting document: ${documentUrl}`, error);
                }
            }
        }

        // Step 1: Delete the object from its collection
        try {
            await deleteDoc(doc(db, modelKey, object.key));

            activity.log(appKey, 'deletes', 1);

        } catch (error) {
            console.error(`Error deleting ${modelKey} object with key ${object.key}:`, error);
            throw error; // Propagate the error if deletion fails
        }

        // Step 2: Delete related items
        const deleteRelated = async (collectionName) => {
            const querySnapshot = await getDocs(
                query(collection(db, collectionName), where("objectKey", "==", object.key))
            );

            if (!querySnapshot.empty) {
                const docCount = querySnapshot.docs.length;
                activity.log(appKey, 'reads', docCount);
                activity.log(appKey, 'deletes', docCount);

                for (const doc of querySnapshot.docs) {
                    await deleteDoc(doc.ref);
                }
            } 
        };

        await deleteRelated(collections.index);
        await deleteRelated(collections.events);
        await deleteRelated(collections.bookmarks);
        await deleteRelated(collections.favorites);
    }

    /**
     * Method to elete multiple objects.
     * 
     * @param {Array<string>} objectKeys - The keys of the objects to delete.
     * @param {string} appKey - The key of the selected app.
     * @param {string} modelKey - The key of the selected model.
     */
    async deleteMultiple(appKey, modelKey, objectKeys) {
        const collectionRef = collection(db, modelKey);
        const batch = writeBatch(db);

        for (const objectKey of objectKeys) {
            const q = query(collectionRef, where("key", "==", objectKey));
            const querySnapshot = await getDocs(q);
            querySnapshot.forEach((doc) => {
                batch.delete(doc.ref);
            });
        }

        try {
            activity.log(appKey, 'deletes', batch.size);
            await batch.commit();
            console.log("Successfully deleted selected objects");
        } catch (error) {
            console.error("Error deleting selected objects: ", error);
        }
    }

    /**
     * Method to fetch a single object from the [modelKey] collection by its key (document ID).
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - The name of the collection from which to fetch the document.
     * @param {string} key - The ID of the document to fetch.
     * 
     * @returns {Promise<Object>} - A promise that resolves to the fetched object, including its ID.
    */
    async fetch(appKey, modelKey, key) {

        try {
            // Create a reference to the specific document in the collection
            const docRef = doc(db, modelKey, key);

            // Fetch the document from Firestore
            const docSnapshot = await getDoc(docRef);

            activity.log(appKey, 'reads', 1);

            // Check if the document exists
            if (docSnapshot.exists()) {
                // Return the document data with the document ID included
                return { id: docSnapshot.id, key: key, ...docSnapshot.data() };
            } else {
                // Handle the case where the document does not exist
                console.log("No such document!");
                return null;
            }
        } catch (error) {
            console.error("Error fetching object:", error);
            throw error;
        }
    }

    /**
     * Fetches objects and subscribes to real-time updates, optionally filtered by tags.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key.
     * @param {function} onUpdate - Callback function that handles the update.
     * @param {Array<string>} tags - Array of tags to filter by (optional).
     * @returns {function} Unsubscribe function to stop listening for updates.
     */
    async listAndSubscribe(appKey, modelKey, onUpdate, tags = []) {
        try {
            // Create a reference to the model's collection
            const objectsCollection = collection(db, modelKey);

            // Conditionally create a query based on whether tags are provided
            let q;

            if (tags.length > 0) {
                // If tags are present, filter by tags using 'array-contains-any'
                q = query(objectsCollection, where("tags", "array-contains-any", tags));
            } else {
                // If no tags, just query the entire collection
                q = query(objectsCollection);
            }

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const objects = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) {
                    onUpdate(objects);
                    activity.log(appKey, 'reads', objects.length);
                }
            }, error => {
                console.error("Error fetching objects:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Method to fetch all objects from the [modelKey] collection filtered by userKey
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key
     * @returns {Promise<Object>} - A promise that resolves to the fetched objects.
    */
    async list(appKey, modelKey) {

        try {
            // Reference to the collection
            const ref = collection(db, modelKey);

            const queryRef = query(ref,
                where("appKey", "==", appKey));

            // Execute the query
            const snapshot = await getDocs(queryRef);

            activity.log(appKey, 'reads', snapshot.docs.length);

            // Map through the documents in the snapshot to extract the document ID and data
            const objects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // Return the filtered objects
            return objects;
        } catch (error) {
            console.error("Error fetching objects:", error);
            throw error;
        }
    }

    /**
     * Updates an object.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key.
     * @param {string} key - Object key.
     * @param {object} fields - Json object.
    */
    async update(appKey, modelKey, appCollections, key, fields) {
        const ref = doc(db, modelKey, key);
    
        try {
            const now = Timestamp.now();
    
            // Add dateModified timestamp to the fields being updated
            const stamped = { ...fields, dateModified: now };
    
            // Update the object's document
            await updateDoc(ref, stamped);
    
            // Fetch the model to determine the title field key
            const appCollection = appCollections.find(appCollection => appCollection.key === modelKey);
            const titleFieldKey = appCollection.titleFieldKey;
    
            // Prepare the data for the index update
            const objectTitle = fields[titleFieldKey] || null; // Use the new title if provided
            const objectTags = fields.tags || []; // Default to an empty array if no tags are provided
    
            // Update the index record with title, tags, and object
            const indexData = {
                ...(objectTitle && { objectTitle }), // Include objectTitle if it's updated
                ...(objectTags.length > 0 && { objectTags }), // Include objectTags if provided
                object: stamped // Include the full object record
            };
    
            await indexManager.updateObject(appKey, key, indexData);
    
            console.log("Updating bookmarks: ", fields);
    
            // Update bookmarks and favorites with the new object fields
            await bookmarkManager.updateObject(appKey, key, fields);
            await favoriteManager.updateObject(appKey, key, fields);
    
            // Log activity for the total number of writes (1 for object, 1 for index, bookmarks/favorites)
            activity.log(appKey, 'writes', 3);
    
            console.log("Object updated successfully");
        } catch (error) {
            console.error("Error updating object:", error);
            throw error;
        }
    }    

    /**
     * Updates a specific object field with a new value.
     * 
     * @param {string} modelKey - The model key of the object.
     * @param {string} objectKey - Key of the object to update.
     * @param {string} fieldKey - Key of the field to update.
     * @param {string} newValue - New value to set.
    */
    async updateField(modelKey, objectKey, fieldKey, newValue) {
        try {
            const ref = doc(db, modelKey, objectKey);
            await updateDoc(ref, { [fieldKey]: newValue });
        } catch (error) {
            console.error("Error updating document: ", error);
            throw error;
        }
    }

    /**
     * Updates the view count for a list of objects.
     * 
     * @param {string} appKey - The key for the app.
     * @param {string} modelKey - The key for the model.
     * @param {string[]} objectKeys - An array of object keys that have been viewed.
     */
    async updateViewCount(appKey, modelKey, objectKeys) {
        const batch = writeBatch(db);  // Use Firestore's batch feature for bulk updates
        const now = Timestamp.now();

        try {
            for (let key of objectKeys) {
                const ref = doc(db, modelKey, key);

                // Check if the document exists
                const docSnapshot = await getDoc(ref);
                if (docSnapshot.exists()) {
                    // Increment view count atomically in Firestore
                    batch.update(ref, {
                        viewCount: increment(1),
                        dateModified: now
                    });
                } else {
                    console.warn(`Document with key ${key} does not exist, skipping update.`);
                }
            }

            // Commit the batched writes
            await batch.commit();

            // Log batch write activity (1 write per updated object)
            activity.log(appKey, 'writes', objectKeys.length);

            console.log(`View counts updated for ${objectKeys.length} objects.`);
        } catch (error) {
            console.error("Error updating view counts:", error);
            throw error;
        }
    }

    /**
     * Updates an object rating.
     * 
     * @param {string} appKey - App key.
     * @param {string} modelKey - Model key.
     * @param {string} key - Object key.
     * @param {number} userRating - New rating provided by the user (1 to 5).
     * @param {string} userKey - User key (to uniquely identify the user's rating).
     * @returns {Object} - Updated rating data including average and total votes.
     */
    async updateRating(appKey, modelKey, key, userRating, userKey) {
        const ref = doc(db, modelKey, key);

        try {
            // Retrieve current ratings data for the object
            const objectDoc = await getDoc(ref);
            const objectData = objectDoc.data();
            const ratings = objectData?.ratings || [];

            // Check if user has already rated and update their rating
            const existingRatingIndex = ratings.findIndex(r => r.userKey === userKey);
            if (existingRatingIndex >= 0) {
                // User has rated before, update their rating
                ratings[existingRatingIndex].rating = userRating;
            } else {
                // New rating from this user, add to ratings array
                ratings.push({ userKey, rating: userRating });
            }

            // Calculate the new average rating
            const totalVotes = ratings.length;
            const averageRating = ratings.reduce((acc, r) => acc + r.rating, 0) / totalVotes;

            // Prepare the data to update in the object
            const data = {
                ratings, // Update the ratings array
                averageRating, // Store average rating
                totalVotes // Store total number of votes
            };

            // Update the object's document with the new ratings data
            await updateDoc(ref, data);

            // Log a write to the activity log
            activity.log(appKey, 'writes', 1);

            return { average: averageRating, votes: totalVotes }; // Return updated values

        } catch (error) {
            console.error("Error updating ratings:", error);
            throw error;
        }
    }


}

export default ObjectManager;
