import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { Screen } from '../utilities/Enums';
import { vowels } from '../utilities/Strings';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Templates
import { appTemplates } from './Templates';

// Styles
import './AppAdd.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AppAddFeatures from './features/AppAddFeatures.js';
import AppAddForm from './form/AppAddForm.js';
import AppAddOptions from './options/AppAddOptions.js';
import AppAddProgress from './progress/AppAddProgress.js';
import Modal from '../components/modal/Modal';

// Managers
import CalendarManager from '../managers/CalendarManager';
import ChannelManager from '../managers/ChannelManager';
import DataManager from '../managers/DataManager';
import FontManager from '../managers/FontManager';
import ModelManager from '../managers/ModelManager';
import NewsfeedManager from '../managers/NewsfeedManager';
import RoleManager from '../managers/RoleManager';
import ThemeManager from '../managers/ThemeManager';

const calendarManager = new CalendarManager();
const channelManager = new ChannelManager();
const dataManager = new DataManager();
const fontManager = new FontManager();
const modelManager = new ModelManager();
const newsfeedManager = new NewsfeedManager();
const roleManager = new RoleManager();
const themeManager = new ThemeManager();

/**
 * AppAdd Component
 * 
 * This component allows the user to add an app.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppAdd = () => {
    const { theme } = useTheme();
    const {
        appAddVisible,
        userApps,
        currentUser,
        resetSelections,
        setUserApps,
        setAppAddVisible,
        setScreen,
        setMenuVisible,
        setCurrentApp,
    } = useContext(Global);

    // Local State
    const [appTitle, setAppTitle] = useState('');
    const [appDescription, setAppDescription] = useState('');
    const [focusedDiv, setFocusedDiv] = useState(1);
    const [modalTitle, setModalTitle] = useState("Build an App");
    const [selectedTemplate, setSelectedTemplate] = useState(null); // TODO!

    // Potential Features
    const [potentialModels, setPotentialModels] = useState([]);
    const [potentialCalendars, setPotentialCalendars] = useState([]);
    const [potentialChannels, setPotentialChannels] = useState([]);
    const [potentialNewsfeeds, setPotentialNewsfeeds] = useState([]);

    // Selected Features
    const [selectedCollections, setSelectedCollections] = useState([]);
    const [selectedCalendars, setSelectedCalendars] = useState([]);
    const [selectedChannels, setSelectedChannels] = useState([]);
    const [selectedNewsfeeds, setSelectedNewsfeeds] = useState([]);

    // Roles
    const [roles, setAppRoles] = useState({});

    // Carousel Steps
    const [currentStep, setCurrentStep] = useState(1);

    // Progress
    const [progressMessage, setProgressMessage] = useState('');
    const [progressFlash, setProgressFlash] = useState([]);
    const [progressVisible, setProgressVisible] = useState(false);

    /**
     * Navigates to the previous step.
     */
    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    /**
     * Navigates to the next step.
     */
    const handleNext = () => {
        if (currentStep === 2) {
            saveTitleAndDescription();
        } else {
            // Focus on the prompt input after the slide
            setTimeout(() => {
                setFocusedDiv(currentStep + 1);
            }, 300);

            setCurrentStep(currentStep + 1);
        }
    };

    /**
     * Finalizes the wizard and builds the app.
     */
    const handleFinish = () => {
        buildApp();
    };

    /**
     * Retrieves the modal title based on the template name.
     * 
     * @param {string} templateName - Name of the template.
     */
    const getModalTitle = (templateName) => {
        const firstLetter = templateName.charAt(0).toUpperCase(); // Get first letter and convert to uppercase
        if (vowels.includes(firstLetter)) {
            return `Build an ${templateName}`;
        } else {
            return `Build a ${templateName}`;
        }
    };

    /**
     * Generates potential features that the user might want to use.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     */
    const generatePotentialFeatures = async (appTitle, appDescription) => {

        // Helper function to inject a unique key into each item in the array
        const addKeysToItems = (items) => {
            return items.map((item) => ({
                ...item, // Spread the existing properties
                key: generateKey(), // Add a unique key
            }));
        };

        setProgressMessage("Generating feature set - please wait...");
        setProgressVisible(true);

        // Get a list of models from AI
        const models = await modelManager.generateModelList(appTitle, appDescription, 0.3);
        if (models.length > 0) {
            setProgressMessage(`Created ${models.length} models...`);
            setProgressFlash(models.map((model) => model.title)); // Flash model titles
        }
        const modelsWithKeys = addKeysToItems(models);
        setPotentialModels(modelsWithKeys);
        setSelectedCollections(modelsWithKeys);

        // Get a list of calendars from AI
        const calendars = await calendarManager.generateCalendarList(appTitle, appDescription, 0.3);
        if (calendars.length > 0) {
            setProgressMessage(`Created ${calendars.length} calendars...`);
            setProgressFlash(calendars.map((calendar) => calendar.title)); // Flash calendar titles
        }
        const calendarsWithKeys = addKeysToItems(calendars);
        setPotentialCalendars(calendarsWithKeys);
        setSelectedCalendars(calendarsWithKeys);

        // Get a list of channels from AI
        const appChannels = await channelManager.generateChannelList(appTitle, appDescription, 0.3);
        if (appChannels.length > 0) {
            setProgressMessage(`Created ${appChannels.length} communication channels...`);
            setProgressFlash(appChannels.map((channel) => channel.title)); // Flash channel titles
        }
        const appChannelsWithKeys = addKeysToItems(appChannels);
        setPotentialChannels(appChannelsWithKeys);
        setSelectedChannels(appChannelsWithKeys);

        // Get a list of newsfeeds from AI
        const appNewsfeeds = await newsfeedManager.generateNewsfeedList(appTitle, appDescription, 0.3);
        if (appNewsfeeds.length > 0) {
            setProgressMessage(`Created ${appNewsfeeds.length} newsfeeds...`);
            setProgressFlash(appNewsfeeds.map((newsfeed) => newsfeed.title)); // Flash newsfeed titles
        }
        const appNewsfeedsWithKeys = addKeysToItems(appNewsfeeds);
        setPotentialNewsfeeds(appNewsfeedsWithKeys);
        setSelectedNewsfeeds(appNewsfeedsWithKeys);

        // Get a list of roles from AI
        const roles = await roleManager.generateRoles(appTitle, appDescription, 0.3);
        setProgressMessage(`Identified security roles...`);
        setAppRoles(roles);

        // Hide progress
        setProgressVisible(false);

        // Clear the flash array
        setProgressFlash([]);
    };

    /**
     * Builds an app from the currently stored configuration.
     */
    const buildApp = async () => {

        // Get dark/light themes from AI
        setProgressMessage("Generating themes...");
        setProgressVisible(true);
        const themes = await themeManager.generateThemes(appTitle, appDescription, 0.7);

        // Get dark/light themes from AI
        setProgressMessage("Generating font...");
        const fontFamily = await fontManager.generateFont(appTitle, appDescription, 0.3);

        const userKey = currentUser.userKey;

        // Current timestamp
        const now = Timestamp.now();

        // Add the app
        const appKey = generateKey();

        const appData = {
            key: appKey,
            fontFamily: fontFamily,
            themes: themes,
            permissions: {},
            title: appTitle,
            description: appDescription,
            userKey: userKey,
            dateCreated: now,
            dateModified: now
        };

        // Add the app to the database
        await dataManager.add(
            collections.apps,
            appKey,
            appKey,
            appData
        );

        // Flatten the roles and add them to the database
        const flatRoles = await roleManager.flattenRoles(
            appKey,
            currentUser.userKey,
            roles
        );

        let adminRoleKey = null;

        for (const role of flatRoles) {
            await dataManager.add(collections.roles, appKey, role.key, role);

            // Identify the administrator role key
            if (role.isCreator && role.priorityLevel === 1) {
                adminRoleKey = role.key;
            }
        }

        // Add the app creator as the first app user
        const memberKey = generateKey();

        const memberData = {
            key: memberKey,
            appKey: appKey,
            userKey: currentUser.userKey,
            userEmail: currentUser.email,
            username: currentUser.username,
            photo: currentUser.photo,
            headerphoto: currentUser.headerphoto,
            roles: [adminRoleKey],
            dateJoined: now,
            dateCreated: now,
            dateModified: now
        };

        // Add the app user to the database
        await dataManager.add(
            collections.appusers,
            appKey,
            memberKey,
            memberData
        );

        // Update the userApps state array
        setUserApps([...userApps, appData]);

        // Initialize a map to store model titles and their keys
        const modelTitleToKey = {};

        // Initialize an array to store modelData objects
        const appCollectionsArray = [];

        let sortIndex = 0;

        // Add models to the app
        if (selectedCollections) {
            for (const model of selectedCollections) {
                const title = model.title;
                const description = model.description;
                const modelKey = generateKey();

                // Store the model key with its title for later lookup
                modelTitleToKey[title] = modelKey;

                // Current timestamp
                const now = Timestamp.now();

                const modelData = {
                    key: modelKey,
                    appKey: appKey,
                    userKey: userKey,
                    title: title,
                    description: description,
                    sort: sortIndex,
                    dateCreated: now,
                    dateModified: now,
                };

                // Add the model to the app
                await dataManager.add(collections.models, appKey, modelKey, modelData);

                // Push the modelData object to the appCollectionsArray
                appCollectionsArray.push(modelData);

                sortIndex++;
            }
        }

        // Create the app collections
        for (var i = 0; i < appCollectionsArray.length; i++) {

            const appCollection = appCollectionsArray[i];

            setProgressMessage(`Generating ${appCollection.title} feature...`);

            const response = await modelManager.generateModel(appTitle, appDescription, appCollection.title, appCollection.description, 0.3);

            console.log(response);

            await modelManager.processModelResponse(response, appData, appCollection)

        }

        sortIndex = 0;

        // Add calendars to the app
        if (selectedCalendars && selectedCalendars.length > 0) {

            for (const calendar of selectedCalendars) {
                const title = calendar.title;
                const description = calendar.description;
                const tags = calendar.tags;

                const calendarKey = generateKey();

                setProgressMessage(`Generating ${title} feature...`);

                // Current timestamp
                const now = Timestamp.now();

                const calendarData = {
                    key: calendarKey,
                    appKey: appKey,
                    userKey: userKey,
                    title: title,
                    description: description,
                    sort: sortIndex,
                    tags: tags || [],
                    dateCreated: now,
                    dateModified: now,
                };

                // Add the model to the app
                await dataManager.add(collections.calendars, appKey, calendarKey, calendarData);

                sortIndex++;
            }

        }

        sortIndex = 0;

        // Add channels to the app
        if (selectedChannels && selectedChannels.length > 0) {

            for (const channel of selectedChannels) {
                const title = channel.title;
                const description = channel.description;
                const type = channel.type;
                const channelKey = generateKey();

                setProgressMessage(`Generating ${title} feature...`);

                // Current timestamp
                const now = Timestamp.now();

                const channelData = {
                    key: channelKey,
                    appKey: appKey,
                    userKey: userKey,
                    title: title,
                    type: type,
                    description: description,
                    sort: sortIndex,
                    dateCreated: now,
                    dateModified: now,
                };

                // Add the model to the app
                await dataManager.add(collections.channels, appKey, channelKey, channelData);

                sortIndex++;
            }

        }

        sortIndex = 0;

        // Add newsfeeds to the app
        if (selectedNewsfeeds && selectedNewsfeeds.length > 0) {

            for (const newsfeed of selectedNewsfeeds) {
                const title = newsfeed.title;
                const description = newsfeed.description;
                const newsfeedKey = generateKey();

                setProgressMessage(`Generating ${title} feature...`);

                // Current timestamp
                const now = Timestamp.now();

                const newsfeedData = {
                    key: newsfeedKey,
                    appKey: appKey,
                    userKey: userKey,
                    title: title,
                    description: description,
                    sort: sortIndex,
                    dateCreated: now,
                    dateModified: now,
                };

                // Add the model to the app
                await dataManager.add(collections.newsfeeds, appKey, newsfeedKey, newsfeedData);

                sortIndex++;
            }

        }

        // Navigate to the app
        resetSelections();
        setScreen(Screen.RUN);
        setCurrentApp(appData);
        if (isMobile) {
            setMenuVisible(true);
        }

        setProgressVisible(false);

        setAppAddVisible(false);
    };

    /**
     * Handles the clicking of an add button in a form.
     * 
     * @param {string} e - Click event.
     */
    const saveTitleAndDescription = async () => {

        if (!appTitle) {
            alert('Please enter an app title.');
            return;
        }

        if (!appDescription) {
            alert('Please enter an app description.');
            return;
        }

        setProgressMessage("Creating feature list - please wait...");
        setProgressVisible(true);

        await generatePotentialFeatures(appTitle, appDescription);

        setProgressVisible(false);

        setCurrentStep(currentStep + 1);
    };

    /**
     * Begins the process of building a new basic app.
     */
    const handleNewBasic = () => {
        setModalTitle("Build an App");
        handleNext();
    };

    /**
     * Begins the process of building a new app from a template.
     * 
     * @param {string} key - Template key.
     */
    const handleNewFromTemplate = (key) => {

        // Find the selected template
        const template = appTemplates.find(t => t.key === key);
        setSelectedTemplate(template);

        // Update the modal title
        setModalTitle(getModalTitle(template.name));

        handleNext();
    };

    /**
     * Toggles the selection of a feature.
     * 
     * @param {string} key - Template key.
     */
    const toggleFeatureSelection = (type, item, isSelected) => {
        const updateFeatureSelection = (selectedList, setSelectedList) => {
            const updatedList = isSelected
                ? [...selectedList, item]
                : selectedList.filter((selectedItem) => selectedItem.key !== item.key);
            setSelectedList(updatedList);
        };

        if (type === 'model') updateFeatureSelection(selectedCollections, setSelectedCollections);
        else if (type === 'calendar') updateFeatureSelection(selectedCalendars, setSelectedCalendars);
        else if (type === 'channel') updateFeatureSelection(selectedChannels, setSelectedChannels);
    };

    return (
        <>
            {/* MODAL */}
            <Modal
                title={modalTitle}
                isOpen={appAddVisible}
                onClose={() => setAppAddVisible(false)}
                height={isMobile ? "85%" : "85%"}
                width={isMobile ? "95%" : "460px"}>

                {/* CAROUSEL */}
                <div className="app-add-carousel-container">

                    {/* STEPS */}
                    <div
                        className="app-add-carousel-steps"
                        style={{
                            transform: `translateX(-${(currentStep - 1) * 100}%)`,
                            height: currentStep === 1 ? "100%" : "82%"
                        }}>

                        {/* STEP 1 */}
                        <div className="app-add-carousel-item">
                            <AppAddOptions
                                handleNewBasic={handleNewBasic}
                                handleNewFromTemplate={handleNewFromTemplate}
                            />
                        </div>

                        {/* STEP 2 */}
                        <div className="app-add-carousel-item">

                            <AppAddForm
                                setTitle={setAppTitle}
                                setDescription={setAppDescription}
                                autoFocus={focusedDiv === 2}
                            />

                        </div>

                        {/* STEP 3 */}
                        <div className="app-add-carousel-item">
                            <AppAddFeatures
                                potentialModels={potentialModels}
                                selectedCollections={selectedCollections}
                                potentialCalendars={potentialCalendars}
                                selectedCalendars={selectedCalendars}
                                potentialChannels={potentialChannels}
                                selectedChannels={selectedChannels}
                                onToggleSelection={toggleFeatureSelection}
                            />
                        </div>
                    </div>

                    {/* CAROUSEL CONTROLS */}
                    {currentStep !== 1 &&

                        <div className="app-add-carousel-controls">

                            {/* BACK */}
                            {currentStep > 1 && (
                                <button
                                    className="app-add-carousel-button"
                                    style={{
                                        color: theme.foregroundColor
                                    }}
                                    onClick={handlePrevious}>
                                    Back
                                </button>
                            )}

                            {/* NEXT */}
                            {currentStep < 3 && (
                                <button
                                    className="app-add-carousel-button"
                                    style={{
                                        color: theme.foregroundColor
                                    }}
                                    onClick={handleNext}>
                                    Next
                                </button>
                            )}

                            {/* FINISH */}
                            {currentStep === 3 && (
                                <button
                                    className="app-add-carousel-button"
                                    style={{
                                        color: theme.foregroundColor
                                    }}
                                    onClick={handleFinish}>Let's go!</button>
                            )}

                        </div>

                    }

                </div>

            </Modal>

            {/* PROGRESS */}
            <AppAddProgress
                progressVisible={progressVisible}
                progressMessage={progressMessage}
                progressFlash={progressFlash}
                setProgressFlash={setProgressFlash}
            />

        </>
    );
};

export default AppAdd;
