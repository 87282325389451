import React, { useEffect, useRef, useState } from 'react';

// Firebase
import { Timestamp } from 'firebase/firestore';

// Styles
import './DateFieldCell.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// DayPicker
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const DayPickerStyles = useStyle`
    .rdp {
        --rdp-accent-color: ${(props) => props.highlightColor} !important;
        --rdp-background-color: transparent !important;
    }
    .rdp-caption {
        padding: 12px !important;
    }
    .rdp-caption-button {
        background-color: transparent !important;
        cursor: pointer;
    }
    .rdp-caption-select {
        background-color: transparent !important;
        cursor: pointer;
        font-size: 12pt;
        font-weight: bold;
    }
    .rdp-caption-title {
        font-size: 12pt;
        font-weight: bold;
    }
`;

// Function to format date
const formatDate = (date) => {
  if (date && date.toDate) {
    date = date.toDate();
  }
  return date.toLocaleDateString('en-US', {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  });
};

// Custom caption component with year selector
const CustomCaption = ({ currentMonth, onMonthChange, onYearChange }) => {
  const { theme } = useTheme();
  if (!currentMonth) {
    currentMonth = new Date(); // Default to the current date if undefined
  }

  const currentYear = currentMonth.getFullYear();
  const years = [];
  for (let i = currentYear - 120; i <= currentYear + 10; i++) {
    years.push(i);
  }

  const handleYearChange = (e) => {
    const newYear = parseInt(e.target.value, 10);
    onYearChange(newYear);
  };

  const handleMonthChange = (delta) => {
    onMonthChange(delta);
  };

  return (
    <div className="rdp-caption">
      <span className="rdp-caption-title">{currentMonth.toLocaleString('default', { month: 'long' })}</span>
      <div
        className="rdp-caption-button"
        style={{ color: theme.foregroundColor }}
        onClick={() => handleMonthChange(-1)}>{'<'}</div>
      <div
        className="rdp-caption-button"
        style={{ color: theme.foregroundColor }}
        onClick={() => handleMonthChange(1)}>{'>'}</div>
      <select
        className="rdp-caption-select"
        style={{ color: theme.foregroundColor }}
        value={currentYear}
        onChange={handleYearChange}>
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  );
};

/**
 * DateFieldCell Component
 * 
 * This component renders an input field that accepts date values.
 * 
 * @param {Object} object - The object containing field values.
 * @param {Object} field - The field metadata.
 * @param {Function} handleBlur - The function to call when the input loses focus.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const DateFieldCell = ({ object, field, handleBlur }) => {
  const { theme } = useTheme();

  // Local State
  const [value, setValue] = useState(object[field.key] || '');
  const [popupVisible, setPopupVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [currentMonth, setCurrentMonth] = useState(new Date());

  // References
  const popupRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (object && object[field.key]) {
      const date = object[field.key].toDate();
      setValue(formatDate(date));
      setSelectedDate(date);
      setCurrentMonth(date);
    } else {
      setValue('');
      setSelectedDate(null);
      setCurrentMonth(new Date());
    }
  }, [object, field.key]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false);
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const handleSelect = (date) => {
    if (date) {
      setSelectedDate(date);
      const formattedDate = formatDate(date);
      setValue(formattedDate);
      const timestamp = Timestamp.fromDate(date);
      handleBlur(object.key, field.key, timestamp);
      setCurrentMonth(date);
    } else {
      setSelectedDate(null);
      setValue('');
      handleBlur(object.key, field.key, '');
    }
    setPopupVisible(false);
  };

  const handleMonthChange = (delta) => {
    const newDate = new Date(currentMonth);
    newDate.setMonth(newDate.getMonth() + delta);
    setCurrentMonth(newDate);
  };

  const handleYearChange = (newYear) => {
    const newDate = new Date(currentMonth);
    newDate.setFullYear(newYear);
    setCurrentMonth(newDate);
  };

  const calculatePopupPosition = () => {
    if (inputRef.current) {
      const viewportHeight = window.innerHeight;
      const popupHeight = 340;
      const viewportWidth = window.innerWidth;
      const popupWidth = 300; // Adjust according to the actual width of your popup

      return {
        top: (viewportHeight - popupHeight) / 2,
        left: (viewportWidth - popupWidth) / 2 - 200
      };
    }
    return { top: 0, left: 0 };
  };

  const popupPosition = calculatePopupPosition();

  return (
    <div className="date-field-cell-outer-container">
      <div className="date-field-cell-container"
        style={{
          backgroundColor: "transparent",
          color: theme.foregroundColor,
          borderRightColor: theme.backgroundColorFaded,
          borderBottomColor: theme.backgroundColorFaded,
        }}
      >
        <input
          type="text"
          className="date-field-cell-input"
          style={{
            backgroundColor: "transparent",
            color: theme.foregroundColor
          }}
          value={value}
          onClick={togglePopup}
          readOnly
          ref={inputRef}
        />
      </div>
      {popupVisible && (
        <div
          className="date-field-popup"
          ref={popupRef}
          style={{
            top: popupPosition.top,
            left: popupPosition.left,
            backgroundColor: theme.backgroundColor,
            color: theme.foregroundColor
          }}>
          <DayPickerStyles highlightColor={theme.highlightBackgroundColor} />
          <DayPicker
            mode="single"
            selected={selectedDate}
            onSelect={handleSelect}
            month={currentMonth}
            captionLayout="dropdown"
            components={{
              Caption: (props) => (
                <CustomCaption
                  currentMonth={currentMonth}
                  onMonthChange={handleMonthChange}
                  onYearChange={handleYearChange}
                />
              )
            }}
          />
        </div>
      )}
    </div>
  );
};

export default DateFieldCell;
