import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { FormMode, ObjectsView, Section, ThemeMode } from '../common/utilities/Enums';
import { loadFont } from '../common/utilities/Fonts';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Mobile.css';

// Theme
import { useTheme } from '../ThemeContext';

// Images
import gradient from '../site/gradient.jpg';
import gradientlight from '../site/gradientlight.png';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AppSettings from '../common/appsettings/AppSettings';
import MemberProfile from '../common/members/profile/MemberProfile';
import Members from '../common/members/Members';
import Bookmarks from '../common/bookmarks/Bookmarks';
import Calendar from '../common/calendar/Calendar';
import Chat from '../common/channels/chat/Chat';
import Favorites from '../common/favorites/Favorites';
import FieldSelector from '../common/form/fieldselector/FieldSelector';
import Form from '../common/form/Form';
import Forum from '../common/channels/forum/Forum';
import Menu from '../common/menu/Menu';
import Messages from '../common/messages/Messages';
import NavBar from '../common/components/navbar/NavBar';
import Newsfeed from '../common/newsfeed/Newsfeed';
import Objects from '../common/objects/Objects';
import MobileHeader from './MobileHeader';
import TitleBar from '../common/titlebar/TitleBar';
import VideoChat from '../common/channels/videochat/VideoChat';

// Dynamic Styles
import { MobileStyles } from './MobileStyles';

const Mobile = () => {

  // Theme
  const { currentThemeMode, theme } = useTheme();

  // Global
  const {
    accountAboutVisible,
    accountBillingVisible,
    currentSection,
    currentUser,
    fieldSelectorVisible,
    formMode,
    menuVisible,
    objectsView,
    currentApp,
    selectedMember,
    selectedCollection,
    selectedObject,
    setMenuVisible,
  } = useContext(Global);

  const closeMenu = () => {
    setMenuVisible(false);
  };

  const renderContent = () => {
    if (fieldSelectorVisible) return <FieldSelector size="LARGE" />;

    // Show the add form if:
    // - there is a selected model, and
    // - there isn't a selected object, and 
    // - the objects view is not TABLE, and
    // - the form mode is ADD
    if (selectedCollection && !selectedObject && objectsView !== ObjectsView.TABLE && formMode === FormMode.ADD) return <Form />;

    // Show the edit form if:
    // - there is a selected model, and
    // - there is a selected object, and 
    // - the objects view is not TABLE, and
    // - the form mode is EDIT
    if (selectedCollection && selectedObject && objectsView !== ObjectsView.TABLE && formMode !== FormMode.ADD) return <Form />;

    if (currentSection === Section.BOOKMARKS) return <Bookmarks />;
    if (currentSection === Section.CALENDARS) return <Calendar />;
    if (currentSection === Section.CHAT) return <Chat />;
    if (currentSection === Section.FAVORITES) return <Favorites />;
    if (currentSection === Section.FORUM) return <Forum />;
    if (currentSection === Section.MESSAGES) return <Messages />;
    if (currentSection === Section.NEWSFEEDS) return <Newsfeed />;
    if (currentSection === Section.SETTINGS) return <AppSettings />;
    if (currentSection === Section.VIDEOCHAT) return <VideoChat />;

    if (currentSection === Section.ACCOUNT) {

      if (isMobile) {
        if (accountAboutVisible) {
          return <AccountAbout />;
        } else if (accountBillingVisible) {
          return <AccountBilling />;
        } else {
          return <AccountMenu />;
        }
      } else {
        return <AccountMenu />;
      }
    }
    //if (selectedForumPost) return <Forum />;
    if (currentSection === Section.MEMBERS) {
      if (selectedMember) {
        return <MemberProfile />
      } else {
        return <Members />;
      }
    }

    return <Objects />;
  };

  useEffect(() => {
    if (!currentApp) return;
    loadFont(currentApp.fontFamily || defaultFont);
  }, [currentApp]);

  return (
    <>
      <MobileStyles
        fontFamily={currentApp.fontFamily || defaultFont}
        selectionBackground={theme.highlightBackgroundColor}
        selectionForeground={theme.highlightForegroundColor}
      />
      <div
        className="mobile-container"
        style={{
          backgroundColor: theme.backgroundColor,
          backgroundImage: currentThemeMode === ThemeMode.DARK ? `url(${gradient})` : `url(${gradientlight})`,
          backgroundSize: 'cover', // Ensures the image covers the entire screen
          backgroundRepeat: 'no-repeat', // Prevents the image from repeating
          backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
          height: '100vh', // Ensures it takes up the full viewport height
          width: '100vw', // Ensures it takes up the full viewport width
        }}>
        {currentApp &&
          currentApp.userKey === currentUser.userKey &&
          !isMobile &&
          !isTablet && (
            <div className="mobile-title-bar">
              <TitleBar />
            </div>
          )}
        {menuVisible && (
          <div className="mobile-menu-mask" onClick={closeMenu}></div>
        )}
        <div className={isMobile ? "mobile-center" : "mobile-center-mobile"}
           style={{ 
            backgroundImage: currentThemeMode === ThemeMode.DARK ? `url(${gradient})` : `url(${gradientlight})`,
            backgroundSize: 'cover', // Ensures the image covers the entire screen
            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
            backgroundAttachment: 'fixed', // Fixes the image to the screen, not the scrolling content
            height: '100vh', // Ensures it takes up the full viewport height
            width: '100vw', // Ensures it takes up the full viewport width
           }}>
          <div className={`mobile-menu ${menuVisible ? 'visible' : ''}`}>
            <Menu />
          </div>
          <div className="mobile-content">
            <MobileHeader />
            <div className="mobile-summaries">
              {renderContent()}
            </div>
          </div>

          {/* BUTTONS MASK */}
          <div className="mobile-buttons-mask"
            style={{
              backgroundColor: theme.backgroundColor
            }}>
          </div>

          {/* BUTTONS */}
          {isMobile &&
            <div className="mobile-buttons">

              {/* NAV BAR */}
              {currentApp && currentUser &&
                <NavBar />
              }

            </div>
          }

        </div>
      </div>
    </>
  );
};

export default Mobile;
