import React, { useContext, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './InviteRoleSelector.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

/**
 * InviteRoleSelector Component
 * 
 * Displays a list of roles and ensures at least one role is always selected.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const InviteRoleSelector = ({ selectedRoles, onRoleClick }) => {
    const { theme } = useTheme();
    const { appRoles, currentApp } = useContext(Global);

    // Filter roles to exclude creator roles
    const filteredRoles = appRoles.filter(role => !role.isCreator);

    // Select the first role when the currentApp changes
    useEffect(() => {
        if (filteredRoles.length > 0 && selectedRoles.length === 0) {
            onRoleClick([filteredRoles[0].key]); // Select the first available role
        }
    }, [filteredRoles, currentApp, selectedRoles, onRoleClick]);

    const handleRoleClick = (role) => {
        const isSelected = selectedRoles.includes(role.key);

        // Prevent deselecting the last remaining role
        if (isSelected && selectedRoles.length === 1) return;

        // Update selected roles based on current selection
        const updatedRoles = isSelected
            ? selectedRoles.filter(key => key !== role.key) // Deselect role
            : [...selectedRoles, role.key]; // Select role

        onRoleClick(updatedRoles);
    };

    return (
        <div>
            <div className="invite-role-selector-container">
                {filteredRoles.map(role => (
                    <div
                        key={role.key}
                        className="invite-role-selector-role"
                        onClick={() => handleRoleClick(role)}
                        style={{
                            backgroundColor: selectedRoles.includes(role.key) ? theme.highlightBackgroundColor : theme.backgroundColorFaded,
                            color: selectedRoles.includes(role.key) ? theme.highlightForegroundColor : theme.foregroundColor,
                            cursor: selectedRoles.length > 1 || !selectedRoles.includes(role.key) ? 'pointer' : 'not-allowed', // Prevent click on last remaining role
                        }}
                    >
                        {role.title}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default InviteRoleSelector;
