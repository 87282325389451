import React, { useEffect, useState } from 'react';

// Styles
import './VideoGallery.css';

const VideoGallery = ({ 
    content,
    onClick,
    mode = "VIEW"
}) => {

    // Local State
    const [videoSources, setVideoSources] = useState([]);

    useEffect(() => {
        if (!content) return;

        // Map through the video URLs and handle them accordingly.
        const updatedVideoSources = content.map((item) => {
            if (item.url.startsWith('blob:')) {
                // It's already a blob URL, no need to call createObjectURL
                return { 
                    type: 'local', 
                    src: item.url 
                };
            }
            return { type: 'remote', src: item.url };
        });

        setVideoSources(updatedVideoSources);
    }, [content]);

    /**
     * Handles a videogallery click.
     */
    const handleClick = async () => {
        if (mode === "EDIT") {
            onClick();
        }

        // Do nothing in view mode
    };

    return (
        <div className="video-gallery-container"
            onClick={handleClick}>

            {videoSources && videoSources.length === 1 &&
                <video
                    controls
                    src={videoSources[0].src}
                    className="video-gallery-video"
                    style={{ width: '100%' }}
                />
            }

            {videoSources && videoSources.length === 2 &&
                <>
                    <div className="video-gallery-left">
                        <video
                            controls
                            src={videoSources[0].src}
                            className="video-gallery-video"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="video-gallery-right">
                        <video
                            controls
                            src={videoSources[1].src}
                            className="video-gallery-video"
                            style={{ width: '100%' }}
                        />
                    </div>
                </>
            }

            {videoSources && videoSources.length >= 3 &&
                <>
                    <div className="video-gallery-left">
                        <video
                            controls
                            src={videoSources[0].src}
                            className="video-gallery-video"
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className="video-gallery-right">
                        <div className="video-gallery-right-top">
                            <video
                                controls
                                src={videoSources[1].src}
                                className="video-gallery-video"
                                style={{ width: '100%' }}
                            />
                        </div>
                        <div className="video-gallery-right-bottom">
                            <video
                                controls
                                src={videoSources[2].src}
                                className="video-gallery-video"
                                style={{ width: '100%' }}
                            />
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default VideoGallery;
