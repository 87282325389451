import React, { useContext, useState, useEffect, useRef } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './Newsfeed.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import NewsfeedToolbar from './toolbar/NewsfeedToolbar';
import NewsfeedGridItem from './item/NewsfeedGridItem';

const Newsfeed = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        selectedNewsfeed,
    } = useContext(Global);

    // Local State
    const [items, setNewsItems] = useState([]);
    const [loading, setLoading] = useState(true);

    // New: Dynamic columns
    const [columns, setColumns] = useState([]); // columns will be an array of arrays
    const containerRef = useRef(null);

    // We will calculate the number of columns based on container width
    const calculateColumns = (width) => {
        // Minimum 2 columns, max width per column ~280px
        const minCols = 2;
        const colWidth = 280;
        const maxPossibleCols = Math.max(minCols, Math.floor(width / colWidth));
        return maxPossibleCols;
    };

    // Function to distribute items into the given number of columns
    const distributeItemsIntoColumns = (itemsArray, numCols) => {
        const newColumns = Array.from({ length: numCols }, () => []);
        itemsArray.forEach((item, index) => {
            const colIndex = index % numCols;
            newColumns[colIndex].push(item);
        });
        return newColumns;
    };

    const fetchNewsAPINews = async (searchQuery) => {
        try {
            setLoading(true);

            const response = await fetch(
                `https://us-central1-appdeckmain.cloudfunctions.net/fetchNewsAPINews?q=${encodeURIComponent(searchQuery)}`
            );

            if (!response.ok) {
                throw new Error(`Error fetching news from NewsAPI: ${response.status}`);
            }

            const fetchedItems = await response.json();
            setNewsItems(fetchedItems);
        } catch (err) {
            console.log(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!selectedNewsfeed) return;
        fetchNewsAPINews(selectedNewsfeed.title);
    }, [selectedNewsfeed]);

    const handleItemClick = (url) => {
        if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    // Use ResizeObserver to detect changes in container width
    useEffect(() => {
        if (!containerRef.current) return;
        const observer = new ResizeObserver((entries) => {
            for (let entry of entries) {
                const width = entry.contentRect.width;
                const numCols = calculateColumns(width);
                // Once we have the number of columns, distribute items
                if (items && items.length > 0) {
                    const distributed = distributeItemsIntoColumns(items, numCols);
                    setColumns(distributed);
                }
            }
        });
        observer.observe(containerRef.current);

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [items]);

    // Initially, when items first load, we want to set columns as well
    useEffect(() => {
        if (!containerRef.current || !items || items.length === 0) return;
        const width = containerRef.current.getBoundingClientRect().width;
        const numCols = calculateColumns(width);
        const distributed = distributeItemsIntoColumns(items, numCols);
        setColumns(distributed);
    }, [items]);

    return (
        <div className="newsfeed-container">
            {/* TOOLBAR */}
            <NewsfeedToolbar />

            {/* CONTENT */}
            {!loading &&
                <div 
                    className={isMobile ? "newsfeed-grid-container-mobile" : "newsfeed-grid-container"} 
                    ref={containerRef}>
                    {/* Dynamically create columns based on `columns` state */}
                    {columns.map((colItems, colIndex) => (
                        <div className="newsfeed-grid-column" key={`column-${colIndex}`}>
                            {colItems.map((item) => (
                                <NewsfeedGridItem
                                    key={item.guid}
                                    item={item}
                                    onClick={handleItemClick}
                                />
                            ))}
                        </div>
                    ))}
                </div>
            }

            {loading &&
                <div className="newsfeed-loading"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: theme.fontFamily,
                    }}>
                    Gathering recent news...
                </div>
            }

        </div>
    );
};

export default Newsfeed;
