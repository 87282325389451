import React from 'react';

// Styles
import './ModalSelect.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * ModalSelect Component
 * 
 * This component renders a select input field for a modal dialog.
 * 
 * @param {Object[]} options - The array of options with `key` and `title`.
 * @param {string} value - The currently selected value.
 * @param {function} onChange - The function to handle value change.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ModalSelect = ({ options, value, onChange }) => {
  // Theme
  const { theme } = useTheme();

  return (
    <select
      className="modal-select-container"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      style={{
        backgroundColor: theme.backgroundColorFaded,
        borderColor: theme.backgroundColorFaded,
        color: theme.foregroundColor,
      }}
    >
      {options.map(option => (
        <option key={option.key} value={option.key}>
          {option.title}
        </option>
      ))}
    </select>
  );
};

export default ModalSelect;
