import { httpsCallable } from 'firebase/functions';
import { arrayUnion, Timestamp } from "firebase/firestore";
import { functions, messaging } from '../../firebaseConfig'; // Import your Firebase functions configuration
import { getToken } from "firebase/messaging";

import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

class NotificationManager {

  /**
   * Sends a notification to a recipient.
   *
   * @param {string} userKey - The user key of the recipient.
   * @param {string} title - The title of the notification.
   * @param {string} body - The body of the notification.
   * @param {string} icon - The path to the icon for the notification.
   * 
   * @returns {Promise<Object>} - Returns a promise with the response from the server.
   */
  async sendNotification(userKey, title, body, icon = '/icons/chat.png', domain = '') {
    if (!userKey || !title || !body) {
      throw new Error('All parameters (senderKey, recipientKey, title, body) are required.');
    }

    try {
      console.log(`Sending notification from ${userKey}`);
      console.log(`Title: ${title}`);
      console.log(`Body: ${body}`);

      // Call the Firebase function to send the notification
      const sendNotificationFunc = httpsCallable(functions, 'sendNotification');
      const response = await sendNotificationFunc({
        userKey,
        title,
        body,
        icon,
        domain
      });

      console.log('Notification sent successfully:', response.data);
      return response.data; // Return the response for further handling if needed
    } catch (error) {
      console.error('Error sending notification:', error);
      throw error; // Re-throw the error to handle it upstream
    }
  }

  async requestNotificationPermission(combinedUser) {
    try {
      console.log('Requesting notification permission.');

      // Request permission first
      const permission = await Notification.requestPermission();
      if (permission === 'granted') {
        console.log('Notification permission granted.');

        // Wait for the service worker to be ready
        const registration = await navigator.serviceWorker.ready;
        console.log('Service Worker is active and ready.');

        // Check existing subscription
        const existingSubscription = await registration.pushManager.getSubscription();
        if (existingSubscription) {
          console.log('Using existing Push Subscription:', existingSubscription);

          // Use the existing subscription without re-subscribing
          return existingSubscription;
        }

        // No existing subscription, create a new one
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPID_KEY,
          serviceWorkerRegistration: registration,
        });

        if (token) {
          // Save the token in Firestore
          const data = {
            tokens: arrayUnion(token),
          };
          await userManager.update(combinedUser.userKey, data);
        } else {
          console.warn('No registration token available.');
        }
      } else {
        console.warn('Notification permission denied or not yet granted.');
      }
    } catch (error) {
      console.error('Error during notification setup:', error);
    }
  }

}

export default NotificationManager;
