import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './LogoLarge.css';

// Images
import LogoIcon from '../../common/svg/LogoIcon';

// Components
import AppLogo from '../applogo/AppLogo';

const LogoLarge = () => {

  // Global
  const {
    currentApp,
    coreTheme
  } = useContext(Global);

  return (
    <div className="logo-large-container">

      {currentApp ? (
        <div className="logo-large-app-logo">
          <AppLogo
            size={180}
          />
        </div>
      ) : (
        <>
          {/* DEFAULT APPDECK LOGO */}
          <div className="logo-large-icon-wrapper">
            <LogoIcon
              color={coreTheme && coreTheme.highlightBackgroundColor}
              width="60"
              height="60"
            />
          </div>
          <div className="logo-large-company"
            style={{ color: coreTheme && coreTheme.foregroundColor }}>
            appdeck
          </div>
        </>
      )}
    </div>
  );
};

export default LogoLarge;

