/**
 * Vowels
 */
export const vowels = ['A', 'E', 'I', 'O', 'U']; // Array of vowel characters

/**
 * Truncates the file name
 */
export const truncateFileName = (fileName, maxLength) => {
  if (!fileName) return '';
  if (fileName.length <= maxLength) return fileName;

  const extension = fileName.split('.').pop();
  const nameWithoutExtension = fileName.slice(0, fileName.length - extension.length - 1);

  const truncatedName = nameWithoutExtension.slice(0, maxLength - extension.length - 4); // 3 for '...' and 1 for the dot before extension

  return `${truncatedName}...${extension}`;
};

/**
 * Truncates the text and appends an ellipsis (...) if it exceeds the maxLength.
 * 
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum allowed length of the text, including ellipsis.
 * @returns {string} - The truncated text with ellipsis if needed.
 */
export const truncateText = (text, maxLength) => {
  // If the text is already shorter than or equal to maxLength, return it as is.
  if (text.length <= maxLength) return text;

  // Subtract 3 for the ellipsis and return the truncated text with '...'
  return text.slice(0, maxLength - 3) + '...';
};

/**
 * Cleans a string by converting it to lowercase, removing spaces, and keeping only letters and numbers.
 * @param {string} input The input string to clean.
 * @returns {string} The cleaned string.
 */
export function cleanString(input) {
  return input.toLowerCase().replace(/[^a-z0-9]/g, '');
}
