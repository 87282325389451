import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// ReactDOM
import ReactDOM from 'react-dom';

// Utilities
import { Permission, MenuSection } from '../../utilities/Enums';
import { hasCollectionPermission, hasAppPermission } from '../../utilities/Permissions';

// Styles
import './MenuBarButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CalendarIcon from '../../svg/CalendarIcon';
import ChevronDownIcon from '../../svg/ChevronDownIcon';
import CubeIcon from '../../svg/CubeIcon';
import HashtagIcon from '../../svg/HashtagIcon';
import ForumIcon from '../../svg/ForumIcon';
import NewsIcon from '../../svg/NewsIcon';
import VideoIcon from '../../svg/VideoIcon';

// Components
import MenuBarAddButton from './MenuBarAddButton';
import MenuBarButtonOption from './MenuBarButtonOption';

/**
 * MenuBarButton Component
 * 
 * This component represents a button with a dropdown menu, allowing the user to select
 * from a list of options. 
 * 
 * @returns {JSX.Element} The rendered component
 */
const MenuBarButton = ({ section, title, options, onSelect, showAddButton = true }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        currentApp,
        setSelectedChannel,
        setSelectedCollection,
        setCalendarAddOpen,
        setChannelAddOpen,
        setCollectionAddOpen
    } = useContext(Global);

    // Local State
    const [optionsVisible, setOptionsVisible] = useState(false);

    // References
    const optionsRef = useRef(null);
    const buttonRef = useRef(null);

    /**
     * Toggles the visibility of the options dropdown
     */
    const handleClick = () => {
        setOptionsVisible(!optionsVisible);
    };

    /**
     * Determines the appropriate icon based on the section and option type
     * 
     * @param {object} option - The option item to get the icon for
     * @returns {JSX.Element|null} The icon component or null if no match
     */
    const getIcon = (option) => {
        if (section === MenuSection.CALENDARS) {
            return CalendarIcon;
        } else if (section === MenuSection.CHANNELS) {
            if (option.type === 'TEXT') return HashtagIcon;
            if (option.type === 'FORUM') return ForumIcon;
            if (option.type === 'VIDEO') return VideoIcon;
        } else if (section === MenuSection.NEWSFEEDS) {
            return NewsIcon;
        } else if (section === MenuSection.COLLECTIONS) {
            return CubeIcon;
        } else if (section === MenuSection.SETTINGS) {
            if (option.icon) {
                return option.icon;
            } else {
                return null;
            }
        }
        return null;
    };

    /**
     * Closes the dropdown if clicking outside the dropdown or button area
     */
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                optionsRef.current && !optionsRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)
            ) {
                setOptionsVisible(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    /**
     * Handles click on an add button.
     */
    const handleAddClick = () => {
        switch (section) {
            case MenuSection.CALENDARS:
                setSelectedChannel(null);
                setSelectedCollection(null);
                setCalendarAddOpen(true);
                break;
            case MenuSection.CHANNELS:
                setChannelAddOpen(true);
                break;
            case MenuSection.COLLECTIONS:
                setCollectionAddOpen(true);
                break;
            default:
                break;
        }

        // Hide the options
        setOptionsVisible(false);
    };

    /**
     * Checks if the user has manage permission for the given section using hasAppPermission.
     * 
     * @returns {boolean} True if the user has manage permission, otherwise false
     */
    const hasManagePermission = () => {
        switch (section) {
            case MenuSection.CALENDARS:
                return hasAppPermission(currentApp, currentUser, Permission.MANAGE_CALENDARS);
            case MenuSection.CHANNELS:
                return hasAppPermission(currentApp, currentUser, Permission.MANAGE_CHANNELS);
            case MenuSection.COLLECTIONS:
                return hasAppPermission(currentApp, currentUser, Permission.MANAGE_COLLECTIONS);
            case MenuSection.NEWSFEEDS:
                return hasAppPermission(currentApp, currentUser, Permission.MANAGE_NEWSFEEDS);
            default:
                return true; // Allow by default for unsupported sections
        }
    };

    /**
     * Filters options based on permissions using hasCollectionPermission.
     * 
     * @param {object} option - The current option being checked
     * @returns {boolean} True if the option should be displayed, otherwise false
     */
    const filterOptions = (option) => {
        if (section === MenuSection.COLLECTIONS) {
            return hasCollectionPermission(currentApp, currentUser, option, Permission.READ);
        }
        return true; // Always true for CHANNELS and CALENDARS
    };

    /**
     * Renders dropdown options, with hover and click functionality
     */
    const dropDownOptions = (
        <div
            ref={optionsRef}
            className="menu-bar-button-options"
            style={{
                backgroundColor: theme.backgroundColorFaded,
                position: 'absolute',
                left: buttonRef.current ? buttonRef.current.getBoundingClientRect().left : 0,
                top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom : 0
            }}>
            {options.filter(filterOptions).map((option, index) => {
                const IconComponent = getIcon(option);
                return (
                    <MenuBarButtonOption
                        key={index}
                        option={option}
                        icon={IconComponent}
                        onSelect={() => {
                            onSelect(option);
                            setOptionsVisible(false);
                        }}
                    />
                );
            })}

            {showAddButton && hasManagePermission() && (
                <MenuBarAddButton
                    onClick={handleAddClick}
                />
            )}
        </div>
    );

    return (
        <div ref={buttonRef}
            className="menu-bar-button-container"
            onClick={handleClick}>
            <div
                className="menu-bar-button-text"
                style={{
                    color: theme.foregroundColor
                }}>
                {title}
            </div>
            <div className="menu-bar-button-icon">
                <ChevronDownIcon
                    color={theme.foregroundColorFaded}
                    width="14"
                    height="14"
                />
            </div>
            {optionsVisible &&
                ReactDOM.createPortal(
                    dropDownOptions,
                    document.getElementById('portal-root')
                )
            }
        </div>
    );
};

export default MenuBarButton;
