import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountPassword.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * AccountPassword Component
 * 
 * This component renders an input for a new user's password.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountPassword = ({ onChange }) => {
  const { theme } = useTheme();
  const { currentApp, coreTheme } = useContext(Global);

  // Local State
  const [passwordError, setPasswordError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  const passwordRequirements = [
    "at least 8 characters long",
    "contain an uppercase letter",
    "contain a lowercase letter",
    "contain a number",
    "contain a special character (!@#$%^&*)"
  ];

  /**
   * Method to validate password requirements.
   * 
   * @param {string} password - Password to validate.
   * @returns {boolean} - True if the password meets all requirements, false otherwise.
   */
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()]).{8,}$/;
    return regex.test(password);
  };

  /**
   * Handles changes to the password field without validation.
   * 
   * @param {Event} e - Change event
   */
  const handlePasswordChange = (e) => {
    const value = e.target.value.trim();
    setPassword(value);
    onChange(value, false); // Notify parent of changes, assuming invalid initially
  };

  /**
   * Validates the password on blur.
   * 
   * @param {Event} e - Blur event
   */
  const handlePasswordBlur = () => {
    if (password.length === 0) return;
    
    if (!validatePassword(password)) {
      setPasswordError('Password does not meet the requirements.');
      onChange(password, false);
      return;
    }

    if (password !== passwordConfirm) {
      setPasswordError('Passwords do not match.');
      onChange(password, false);
      return;
    }

    setPasswordError('');
    onChange(password, true); // Notify parent of valid password
  };

  /**
   * Handles changes to the confirm password field without validation.
   * 
   * @param {Event} e - Change event
   */
  const handlePasswordConfirmChange = (e) => {
    const confirm = e.target.value.trim();
    setPasswordConfirm(confirm);
    onChange(confirm, false); // Notify parent of changes, assuming invalid initially
  };

  /**
   * Validates the confirm password on blur.
   * 
   * @param {Event} e - Blur event
   */
  const handlePasswordConfirmBlur = () => {
    if (passwordConfirm.length === 0) return;
    
    if (password !== passwordConfirm) {
      setPasswordError('Passwords do not match.');
      onChange(passwordConfirm, false);
      return;
    }

    if (!validatePassword(password)) {
      setPasswordError('Password does not meet the requirements.');
      onChange(passwordConfirm, false);
      return;
    }

    setPasswordError('');
    onChange(passwordConfirm, true); // Notify parent of valid password
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="account-password-container">

        {/* PASSWORD LABEL */}
        <div
          className="account-username-label"
          style={{
            color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded
          }}>
          Password
        </div>

        {/* PASSWORD INPUT */}
        <input
          type="password"
          className="account-password-input"
          style={{
            color: coreTheme.foregroundColor,
            backgroundColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded
          }}
          value={password}
          onChange={handlePasswordChange}
          onBlur={handlePasswordBlur}
          required
        />

        {/* CONFIRM PASSWORD LABEL */}
        <div
          className="account-username-label"
          style={{
            color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded
          }}>
          Confirm Password
        </div>

        {/* CONFIRM PASSWORD INPUT */}
        <input
          type="password"
          className="account-password-input"
          style={{
            color: coreTheme.foregroundColor,
            backgroundColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded
          }}
          value={passwordConfirm}
          onChange={handlePasswordConfirmChange}
          onBlur={handlePasswordConfirmBlur}
          required
        />

        {/* ERROR MESSAGE */}
        {passwordError && (
          <div
            className="account-password-error"
            style={{
              color: currentApp ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor,
            }}
          >
            {passwordError}
          </div>
        )}

        {/* PASSWORD REQUIREMENTS */}
        <div className="account-password-requirements"
          style={{
            color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded,
          }}>
          <ul>
            {passwordRequirements.map((requirement, index) => (
              <li key={index}>{requirement}</li>
            ))}
          </ul>
        </div>

      </div>
    </>
  );
};

export default AccountPassword;
