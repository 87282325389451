import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Enums
import { ThemeMode } from '../../common/utilities/Enums';

// Styles
import './AppLogo.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppLogo = ({ size, showTitle = true }) => {
    const { currentThemeMode, theme } = useTheme();
    const {
        currentApp
    } = useContext(Global);

    const [logoSrc, setLogoSrc] = useState(null);

    useEffect(() => {
        if (!currentApp) return;

        if (currentThemeMode === ThemeMode.DARK) {
            if (currentApp.logoDark) {
                setLogoSrc(currentApp.logoDark);
            }
        } else {
            if (currentApp.logoLight) {
                setLogoSrc(currentApp.logoLight);
            }
        }

    }, [currentApp, currentThemeMode]);

    return (

        <div className="app-logo-container"
            style={{
                height: size,
                width: size
            }}>

            {/* LOGO */}
            {logoSrc &&
                <img src={logoSrc}
                    alt={currentApp && currentApp.title}
                    style={{
                        width: size
                    }}
                />
            }

            {/* TITLE */}
            {showTitle &&
                <div
                    className="app-logo-title"
                    style={{ color: theme.backgroundColorFaded }}>
                    {currentApp && currentApp.title}
                </div>
            }

        </div>

    );
};

export default AppLogo;