import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './Apps.css';

// Components
import AppItem from '../AppItem';

const Apps = () => {
    const { 
        userApps,
    } = useContext(Global);

    return (
        <div className="build-apps-background"
            style={{
                gridTemplateColumns: userApps.length > 0 ? 'repeat(3, 1fr)' : 'none',
            }}>
            {userApps.map(app => (
                <AppItem
                    key={app.key}
                    app={app}
                />
            ))}
        </div>
    );
};

export default Apps;
