import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { Screen } from '../common/utilities/Enums';

// Enums
import { ThemeMode } from '../common/utilities/Enums';

// Styles
import './MobileLogo.css';

// Theme
import { useTheme } from '../ThemeContext';

const MobileLogo = () => {
  const { currentThemeMode, theme } = useTheme();
  const {
    currentApp,
    currentUser,
    resetSelections,
    setScreen
  } = useContext(Global);

  const handleClick = () => {

    resetSelections();

    if (currentUser) {
      setScreen(Screen.HOME);
    } else {
      setScreen(Screen.WELCOME);
    }
  };

  // Determine which logo to display based on the current theme
  const logoSrc = currentThemeMode === ThemeMode.DARK ? currentApp.logoDark : currentApp.logoLight;

  return (
    <>
      {!logoSrc && (
        <div
          onClick={handleClick}>
          <div className="mobile-logo-container">
            <div
              className="mobile-logo"
              style={{
                color: theme.foregroundColor,
                fontFamily: currentApp.fontFamily
              }}>
              {currentApp.title}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MobileLogo;