import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../Global.js';

// Utilities
import { defaultFont } from '../../utilities/Defaults.js';

// Styles
import './AppAddForm.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

/**
 * AppAddForm Component
 * 
 * This component renders a basic app add form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppAddForm = ({ setTitle, setDescription, autoFocus }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentApp,
        coreTheme
    } = useContext(Global);

    // References
    const titleRef = useRef(null);

    useEffect(() => {
        if (autoFocus && titleRef.current) {
            titleRef.current.focus();
        }
    }, [autoFocus]);

    return (

        <div className="app-add-form-container">

            {/* TITLE */}
            <div className="app-add-form-label" style={{
                color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor
            }}>
                Title
            </div>
            <input ref={titleRef}
                type="text"
                name="title"
                className="app-add-form-input"
                style={{
                    color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor,
                    borderBottomColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded,
                    fontFamily: currentApp ? currentApp.fontFamily : defaultFont
                }}
                maxLength="100"
                onChange={e => setTitle(e.target.value)}
                autoFocus={autoFocus}
            />

            {/* DESCRIPTION */}
            <div className="app-add-form-label" style={{
                color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor
            }}>
                Description
            </div>
            <textarea
                name="description"
                className="app-add-form-description-textarea"
                style={{
                    color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor,
                    borderBottomColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded,
                    fontFamily: currentApp ? currentApp.fontFamily : 'Open Sans'
                }}
                onChange={e => setDescription(e.target.value)}
                maxLength="250"></textarea>

            <div className="app-add-form-hint"
                style={{
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColorFaded
                }}>
                The app title and description you specify will be used to generate
                a list of features for you to choose from.
            </div>

        </div>

    );
};

export default AppAddForm;
