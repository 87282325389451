import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../firebaseConfig';

// Utilities
import { generateKey } from '../../utilities/Keys';

// Styles
import './Invite.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import Modal from '../../components/modal/Modal';
import InviteRoleSelector from './inviteroleselector/InviteRoleSelector';

// Managers
import DataManager from '../../managers/DataManager';

const dataManager = new DataManager();

const Invite = ({ open, setOpen }) => {
  const { theme } = useTheme();
  const { currentApp } = useContext(Global);

  const [inviteKey, setInviteKey] = useState('');
  const [invite, setInvite] = useState({ roles: [] });
  const [selectedRoles, setSelectedRoles] = useState([]); // Separate state for roles
  const [url, setUrl] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    // Don't run if the modal is closed
    if (!open) return;

    const key = generateKey(16);
    const link = `https://appdeck.ai/${currentApp.domain}/${key}`;

    // Create an invite when the modal opens
    async function createInvite() {
      const invite = {
        key,
        appKey: currentApp.key,
        roles: [],
        link,
        dateCreated: Timestamp.now(),
      };

      // Add the invite to the database
      await dataManager.add(collections.invites, currentApp.key, key, invite);

      setInvite(invite);
      setSelectedRoles([]); // Reset roles on new invite
    }

    createInvite();

    setInviteKey(key);
    setUrl(link);
    setCopied(false);
  }, [setOpen, open, currentApp.domain]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url)
      .then(() => setCopied(true))
      .catch((error) => console.error('Failed to copy link:', error));
  };

  const handleRoleClick = (roles) => {
    setSelectedRoles(roles);
  };

  useEffect(() => {
    const updateInviteRoles = async () => {
        // Only update if roles in the invite differ from selectedRoles
        if (JSON.stringify(invite.roles) !== JSON.stringify(selectedRoles)) {
            const updatedInvite = { ...invite, roles: selectedRoles };

            // Update state and database
            setInvite(updatedInvite);
            await dataManager.update(collections.invites, currentApp.key, invite.key, updatedInvite);
        }
    };

    if (invite.key) updateInviteRoles();
}, [selectedRoles, invite.key, currentApp.key]);

  return (
    <Modal
      title="Invite Users"
      isOpen={open}
      onClose={() => setOpen(false)}
      height="auto"
      width={isMobile ? "90%" : "500px"}>
      <div className="invite-container">

        {/* LINK */}
        <div className="invite-link-container">
          <input
            className="invite-key"
            style={{
              color: theme.foregroundColor,
              backgroundColor: theme.backgroundColorFaded,
            }}
            value={url}
            readOnly
          />
          <div
            className="invite-copy-button"
            style={{
              color: theme.highlightForegroundColor,
              backgroundColor: theme.highlightBackgroundColor,
              fontFamily: theme.fontFamily,
            }}
            onClick={copyToClipboard}>
            Copy Link
          </div>
        </div>

        <div className="invite-role-label"
          style={{ color: theme.foregroundColorFaded }}>
          Select initial role(s) for this link:
        </div>

        <div className="invite-roles">
          <InviteRoleSelector
            selectedRoles={selectedRoles}
            onRoleClick={handleRoleClick}
          />
        </div>

        {/* COPIED */}
        <div className="invite-link-copied"
          style={{ color: theme.foregroundColorFaded }}>
          {copied && <>Link copied to clipboard!</>}
        </div>

      </div>
    </Modal>
  );
};

export default Invite;
