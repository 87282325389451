import React, { useContext, useRef, useState } from 'react';

// Global
import { Global } from '../../../../../Global';

// Styles
import './LinkForm.css';

// Theme
import { useTheme } from '../../../../../ThemeContext';

// Components
import Modal from '../../../../../common/components/modal/Modal';

const LinkForm = ({ onSave, isVisible, setVisible }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
    } = useContext(Global);

    // Local State
    const [newLink, setNewLink] = useState('');

    // References
    const inputRef = useRef(null);

    const handleSave = async () => {
        onSave(newLink);
        setVisible(false);
    };

    return (

        <Modal title="Add Website" 
            isOpen={isVisible} 
            onClose={() => setVisible(false)}
            width="400px">
            <input type="text"
                ref={inputRef}
                className="modal-input"
                placeholder='Enter a website URL'
                onChange={(e) => setNewLink(e.target.value)}
                style={{
                    backgroundColor: theme.backgroundColorFaded,
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor
                }}
            />
            <div className="modal-buttons">
                <button 
                    onClick={handleSave} 
                    className="modal-button"
                    style={{ color: theme.foregroundColor }}>
                    Save
                </button>
            </div>
        </Modal>

    );
};

export default LinkForm;
