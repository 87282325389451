import React from 'react';

// Styles
import './ConversationsToolBar.css';

// Theme
import { useTheme } from '../../ThemeContext';

const ConversationsToolBar = () => {
    const { theme } = useTheme();

    return (

        <div className="conversations-toolbar-container">
            <div className="conversations-toolbar-buttons">
                <div className="conversations-toolbar-left"
                    style={{ color: theme.foregroundColorFaded }}>
                    Direct Messages
                </div>
                <div className="conversations-toolbar-right" >
                </div>
            </div>
        </div>
    );
};

export default ConversationsToolBar;
