import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission, Section, UserPreference } from '../../utilities/Enums';
import { hasAppPermission } from '../../utilities/Permissions';

// Styles
import './Calendars.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CalendarAddButton from './calendaraddbutton/CalendarAddButton';
import CalendarAdd from './calendaradd/CalendarAdd';
import CalendarItem from './calendaritem/CalendarItem';

// Managers
import UserManager from '../../managers/UserManager';

const userManager = new UserManager();

/**
 * Calendars Component
 * 
 * This component renders a calendar-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Calendars = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        appCalendars,
        currentUser,
        currentApp,
        resetSelections,
        selectedCalendar,
        setCalendarAddOpen,
        setCurrentSection,
        setCurrentUser,
        setMenuVisible,
        setSelectedCalendar,
        setSelectedCalendarType,
        setSelectedChannel,
        setSelectedCollection,
    } = useContext(Global);

    /**
     * Handles a calendar click event.
     * 
     * @param {object} calendar - Calendar that was clicked on.
     */
    const handleCalendarClick = async (calendar) => {
        resetSelections();

        setCurrentSection(Section.CALENDARS);
        setSelectedCalendar(calendar);

        // Set the last screen preference
        const screen = {
            section: 'CALENDARS',
            calendarKey: calendar.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );

        // Retrieve the saved LAST_CALENDAR_TYPE preference before displaying it.
        const lastCalendarType = userManager.getPreference(
            currentUser,
            currentApp.key,
            UserPreference.LAST_CALENDAR_TYPE
        );

        if (lastCalendarType) {
            setSelectedCalendarType(lastCalendarType);
        }

    };

    /**
     * Handles an add calendar button click event.
     */
    const handleAddCalendarClick = () => {
        setSelectedChannel(null);
        setSelectedCollection(null);
        setCalendarAddOpen(true);
        setMenuVisible(false);
    };

    return (
        <>
            {/* TITLE */}
            <div className="calendars-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: currentApp.fontFamily
                }}>
                Calendars

                {/* ADD CALENDAR */}
                {currentApp && hasAppPermission(currentApp, currentUser, Permission.MANAGE_CALENDARS) &&
                    <CalendarAddButton
                        onClick={handleAddCalendarClick}
                    />
                }

            </div>

            {/* COLLECTION LIST */}
            <div className="calendars-group">

                {/* COLLECTIONS */}
                {appCalendars.map(calendar => (
                    <CalendarItem
                        key={calendar.key}
                        calendar={calendar}
                        title={calendar.title}
                        isActive={selectedCalendar && selectedCalendar.key === calendar.key}
                        onClick={() => handleCalendarClick(calendar)} />
                ))}

                {/* FORM */}
                <CalendarAdd />

            </div>
        </>
    );
};

export default Calendars;
