import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './Block.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import Document from '../../components/document/Document';
import Event from '../../components/event/Event';
import Gallery from '../../components/gallery/Gallery';
import Link from '../../components/link/Link';
import Object from '../../../objects/Object';
import Text from '../../components/text/Text';
import VideoGallery from '../../components/videogallery/VideoGallery';

const Block = ({
    index,
    block,
    onChange,
    onDelete,
    onKeyDown,
    mode = "VIEW"
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        appCollections,
        resetSelections,
        selectedBlock,
        setSelectedBlock,
        setSelectedCollection,
        setSelectedObject,
    } = useContext(Global);

    // Local State
    const [content, setContent] = useState(block.content || null);
    const [type, setType] = useState(block.type || null);

    /**
     * Handles additions and changes to the block content.
     */
    const handleChange = (type, content) => {
        setType(type);
        setContent(content);
        onChange(index, type, content);
    };

    /**
     * Handles an object click and navigates to the object in collections.
     */
    const handleObjectClick = async (object) => {
        if (mode === "VIEW") {
            resetSelections();
            const appCollection = appCollections.find(appCollection => appCollection.key === object.modelKey);
            setSelectedCollection(appCollection);
            setSelectedObject(object);
        } else {
            setSelectedBlock(block);
        }
    };

    /** 
     * Sets the selected block to this one when the textarea gains focus.
     */
    const handleFocus = () => {
        setSelectedBlock(block);
    }

    /** 
     * Handles deletion of the block.
     */
    const handleDelete = () => {
        if (index > 0) {
            onDelete(index);
        }
    }

    /**
     * Handles a block click.
     */
    const handleClick = async () => {
        if (mode === "EDIT") {
            setSelectedBlock(block);
        }
    };

    return (
        <>
            {/* CONTAINER */}
            <div className="block-container"
                style={{
                    borderColor: "transparent",
                    color: theme.foregroundColor
                }}>

                {type !== null && (
                    <>
                        {/* TEXT */}
                        {type === "TEXT" && (
                            <Text
                                block={block}
                                onFocus={handleFocus}
                                onChange={handleChange}
                                onDelete={handleDelete}
                                onKeyDown={onKeyDown}
                            />
                        )}

                        {/* PHOTOS */}
                        {type === "PHOTOS" && (
                            <div className="block-gallery-wrapper"
                                style={{
                                    height: content.length === 1 ? "auto" : "200px",
                                    borderColor: (selectedBlock && selectedBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>

                                <Gallery
                                    content={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />

                            </div>
                        )}

                        {/* VIDEOS */}
                        {type === "VIDEOS" && (
                            <div className="block-gallery-wrapper"
                                style={{
                                    borderColor: (selectedBlock && selectedBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>

                                <VideoGallery
                                    content={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />

                            </div>
                        )}

                        {/* DOCUMENTS */}
                        {type === "DOCUMENTS" && (
                            <div className="block-documents-wrapper">
                                <div
                                    className="block-document-wrapper"
                                    style={{
                                        borderColor: (selectedBlock && selectedBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                    }}>
                                    <Document
                                        content={content[0]}
                                        onClick={handleClick}
                                        mode={mode}
                                    />
                                </div>
                            </div>
                        )}

                        {/* OBJECTS */}
                        {type === "OBJECT" && (
                            <div className="block-object-wrapper"
                                style={{
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: (selectedBlock && selectedBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}
                                onClick={() => handleObjectClick(content)}>
                                <Object object={content} />
                            </div>
                        )}

                        {/* EVENTS */}
                        {type === "EVENT" && (
                            <div className="block-event-wrapper"
                                style={{
                                    backgroundColor: theme.backgroundColorFaded,
                                    borderColor: (selectedBlock && selectedBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>
                                <Event
                                    event={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />
                            </div>
                        )}

                        {/* LINK */}
                        {type === "LINK" && (
                            <div className="block-link-wrapper"
                                style={{
                                    borderColor: (selectedBlock && selectedBlock.key === block.key) ? theme.highlightBackgroundColor : "transparent"
                                }}>

                                <Link
                                    url={content}
                                    onClick={handleClick}
                                    mode={mode}
                                />

                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default Block;
