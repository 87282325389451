/**
 * Returns the display value for a user based on the given display key.
 * Falls back to the username if the selected value is undefined, empty, or invalid.
 *
 * @param {string} displayKey - The key specifying which user property to display ("fullname", "displayname", "handle").
 * @param {Object} user - The fallback user object containing username.
 * @returns {string} - The appropriate display value or fallback username.
 */
export function getUserDisplayValue(displayKey, user) {
  if (!user) return "";
  
  // Map displayKey to the corresponding user fields
  const displayMap = {
    fullname: user.fullName || user.username,
    displayname: user.displayName || user.username,
    handle: user.handle || user.username,
  };

  // Return the value for the displayKey or fall back to user.username
  return displayMap[displayKey]?.trim() || user.username;
}

/**
 * Utility to retrieve an app user from the members cache.
 */
export const getMember = (members, memberKey) => {
  const user = members.find((member) => member.userKey === memberKey);
  return user || null;
};

/**
 * Utility to detect whether a user is a creator based on their roles.
 * 
 * @param {Object} user - The user object containing an array of role keys.
 * @param {Array} roles - An array of role objects.
 * @returns {boolean} - True if the user belongs to the creator group, false otherwise.
 */
export const userIsCreator = (user, roles) => {
  // Ensure user and roles are valid before proceeding
  if (!user || !Array.isArray(user.roles) || !Array.isArray(roles)) {
    return false;
  }

  // Check if any group in the user's group list is an admin group
  return roles.some((role) =>
    user.roles.includes(role.key) && role.isCreator
  );
};
