import React, { useState } from 'react';

// Styles
import './MenuBarButtonOption.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * MenuBarButtonOption Component
 * 
 * This component represents an option in a menu bar button.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MenuBarButtonOption = ({ option, icon: IconComponent, onSelect }) => {

    // Theme
    const { theme } = useTheme();

    const [hovered, setHovered] = useState(null);

    return (
        <div
            className="menu-bar-button-option-container"
            onClick={onSelect}
            onMouseEnter={() => setHovered(true)}  // Set hover
            onMouseLeave={() => setHovered(false)}   // Reset hover
            style={{
                borderColor: theme.backgroundColorFaded,
                color: theme.foregroundColor,
                backgroundColor: hovered ? theme.highlightBackgroundColor : 'transparent'
            }}>
            <div className="menu-bar-button-option-icon">
                {IconComponent && (
                    <IconComponent
                        color={hovered ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                        width="13"
                        height="13"
                    />
                )}
            </div>
            <div className="menu-bar-button-option-item">
                <div
                    className="menu-bar-button-option-title"
                    style={{
                        color: hovered ? theme.highlightForegroundColor : theme.foregroundColor
                    }}>
                    {option.title}
                </div>
                <div className="menu-bar-button-option-description"
                    style={{
                        color: hovered ? theme.highlightForegroundColor : theme.foregroundColorFaded
                    }}>
                    {option.description}
                </div>
            </div>
        </div>
    );
};

export default MenuBarButtonOption;
