import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { auth } from '../../firebaseConfig';
import { signInWithEmailAndPassword } from 'firebase/auth';

// Utilities
import { LoginMode, Screen } from '../../common/utilities/Enums';

// Styles
import './Login.css';

// Components
import LoginButton from './LoginButton';
import LoginLink from './LoginLink';
import LoginUsername from '../components/loginusername/LoginUsername';
import LoginPassword from '../components/loginpassword/LoginPassword';

// Managers
import InviteManager from '../../common/managers/InviteManager';
import NotificationManager from '../../common/managers/NotificationManager';
import UserManager from '../../common/managers/UserManager';

const inviteManager = new InviteManager();
const notificationManager = new NotificationManager();
const userManager = new UserManager();

/**
 * Login Component
 * 
 * This component renders a login form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function Login() {
    const {
        hideProgress,
        invite,
        setCurrentUser,
        setLoginMode,
        setScreen,
        setCurrentApp,
        showProgress,
        coreTheme
    } = useContext(Global);

    // Local State
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(false);

    /**
     * Method to handle submit of the login form.
     * 
     * @param {string} e - Click event.
     */
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Regex for detecting email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        // Regex for detecting phone number format (international format without special characters)
        const phoneRegex = /^\d{10,15}$/;  // Adjusting the regex to accept only numeric values

        let userRecord = null;

        try {
            showProgress("Logging in...");

            // Step 1: Check if the input matches an email format
            if (emailRegex.test(username)) {
                // Fetch user by email
                userRecord = await userManager.fetchUserWithEmail(username);
            }
            // Step 2: Check if it's a phone number by stripping non-numeric characters
            else {
                // Remove all non-numeric characters
                const cleanedPhone = username.replace(/\D/g, '');

                // If it matches a valid phone number length (adjust this based on your needs)
                if (phoneRegex.test(cleanedPhone)) {
                    // Fetch user by cleaned phone number
                    userRecord = await userManager.fetchUserWithPhone(cleanedPhone);
                }
                // Step 3: If neither email nor valid phone, treat as username
                else {
                    // Fetch user by username
                    userRecord = await userManager.fetchUserWithUsername(username);
                }
            }

            // Step 4: If user record found, attempt to sign in with the user's email
            if (userRecord && userRecord.email) {

                showProgress("Logging in...");

                // Sign in to firebase authentication
                await signInWithEmailAndPassword(auth, userRecord.email, password);

                // Set current user in global state
                const combinedUser = await userManager.fetchCurrentUser(auth.currentUser);

                // Request notification permission after successful login
                await notificationManager.requestNotificationPermission(combinedUser);

                // INVITE PROCESSING
                //
                // If an invite is present, we need to detect if the user is an
                // existing member or not.
                //
                // If the user is new, we'll:
                // 1. add them as a member to the app. 
                // 2. send a notification to the app creator that a new member has joined.
                // 3. navigate to the app.
                if (invite) {

                    console.log("Invite exists after logging in:", invite);

                    await inviteManager.processInvite(combinedUser, invite, setCurrentApp, setScreen);

                } else {

                    console.log("No invite exists after logging in.");

                    // Since there wasn't an invite present and no app specified, navigate to the 
                    // home screen.
                    setScreen(Screen.HOME);
                }

                hideProgress();

                setCurrentUser(combinedUser);

            } else {
                setErrorMessage("User not found.");
            }
        } catch (error) {
            console.trace('Login error:', error);
            // Handle error (e.g., invalid credentials, network errors)
            setErrorMessage("Failed to log in. Please check your credentials.");
            console.error('Login error:', error);
        } finally {
            hideProgress();
        }
    };

    /**
     * Handles click on the sign up link.
     */
    const handleSignupClick = async () => {
        setLoginMode(LoginMode.SIGNUP);
    };

    /**
     * Method to handle username change.
     * @param {string} value - The current username value.
     */
    const handleUsernameChange = (value) => {
        setUsername(value);
        setSubmitEnabled(value.length > 0 && password.length > 0);
    };

    /**
     * Method to handle password change.
     * @param {string} value - The current password value.
     */
    const handlePasswordChange = (value) => {
        setPassword(value);
        setSubmitEnabled(value.length > 0 && username.length > 0);
    };

    return (
        <div className="login-container">

            {/* USERNAME */}
            <LoginUsername onChange={handleUsernameChange} />

            {/* PASSWORD */}
            <LoginPassword onChange={handlePasswordChange} />

            {errorMessage &&
                <div className="login-error"
                    style={{
                        color: coreTheme.foregroundColor,
                        borderColor: coreTheme.highlightBackgroundColor
                    }}>
                    {errorMessage}
                </div>
            }

            {/* SUBMIT */}
            <LoginButton onClick={handleSubmit} enabled={submitEnabled} />

            {/* SIGN-UP LINK */}
            <LoginLink onClick={handleSignupClick} />

        </div>
    );
}

export default Login;
