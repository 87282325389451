import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { ObjectsView, Permission, UserPreference } from '../utilities/Enums';
import { hasAppPermission, hasCollectionPermission } from '../utilities/Permissions';

// Styles
import './ViewMenu.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import CardsIcon from '../svg/CardsIcon';
import DownIcon from '../svg/DownIcon';
import DateIcon from '../svg/DateIcon';
import DeleteIcon from '../svg/DeleteIcon';
import PencilIcon from '../svg/PencilIcon';
import FieldIcon from '../svg/FieldIcon';
import GridIcon from '../svg/GridIcon';
import FeatureIcon from '../svg/FeatureIcon';
import TableIcon from '../svg/TableIcon';
import UpIcon from '../svg/UpIcon';

// Components
import CollectionDelete from '../../common/menu/collections/collectiondelete/CollectionDelete';
import CollectionEdit from '../../common/menu/collections/collectionedit/CollectionEdit';
import Designer from '../../desktop/layoutdesigner/Designer';
import ContextMenuButton from '../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../components/contextmenu/ContextMenuPopup';

// Managers
import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

const ViewMenu = () => {
  const { theme } = useTheme();
  const {
    appFields,
    currentUser,
    objects,
    currentApp,
    selectedCollection,
    setCurrentUser,
    setSortDirection,
    setSortField,
    setObjectsView,
    sortDirection
  } = useContext(Global);

  // Local State
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [canvasVisible, setCanvasVisible] = useState(false);
  const [dynamicSortOptions, setDynamicSortOptions] = useState([]);

  /**
   * Effect to generate dynamic sort options based on the selected model's fields
   */
  useEffect(() => {

    const sortTypes = [
      'countries',
      'currency',
      'day',
      'lookup',
      'month',
      'number',
      'phone',
      'states',
      'text',
      'time',
      'year'
    ];

    if (!objects || objects.length === 0) return;

    const modelFields = appFields
      .filter(field => field.modelKey === selectedCollection.key && sortTypes.includes(field.type));

    let newDynamicSortOptions = [];

    for (var i = 0; i < modelFields.length; i++) {
      const { key, title, type } = modelFields[i];

      if (sortTypes.includes(type)) {
        newDynamicSortOptions.push({ title, key: key, direction: 'ASC' });
      }

      setDynamicSortOptions(newDynamicSortOptions);
    }
  }, [objects, appFields, selectedCollection.key]);

  // Toggle visibility function
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  }

  const handleAscending = () => {
    setSortDirection("ASC");
    setMenuVisible(false);
  }

  const handleDescending = () => {
    setSortDirection("DESC");
    setMenuVisible(false);
  }

  const handleGenerateUI = () => {

    // Show the canvas
    setCanvasVisible(true);

    // Hide the menu
    setMenuVisible(false);
  }

  const handleFieldSelection = (key) => {
    setMenuVisible(false);
    setSortField(key);
    setSortDirection(sortDirection);
  };

  const setViewPreference = async (view) => {

    // Call the setPreference method to update LAST_CALENDAR_TYPE settings
    await userManager.setPreference(
      currentUser,
      setCurrentUser,
      currentApp.key,
      UserPreference.LAST_OBJECTS_VIEW,
      view
    );

  };

  const handleListClick = () => {
    setObjectsView(ObjectsView.LIST);
    setMenuVisible(false);
    setViewPreference(ObjectsView.LIST);
  };

  const handleGridClick = () => {
    setObjectsView(ObjectsView.GRID);
    setMenuVisible(false);
    setViewPreference(ObjectsView.GRID);
  };

  const handleTableClick = () => {
    setObjectsView(ObjectsView.TABLE);
    setMenuVisible(false);
    setViewPreference(ObjectsView.TABLE);
  };

  const handleEditCollection = () => {
    setEditOpen(true);
    setMenuVisible(false);
  };

  const handleDeleteCollection = () => {
    setDeleteOpen(true);
    setMenuVisible(false);
  };

  return (
    <div>

      {/* BUTTON */}
      <ContextMenuButton 
        title={`${selectedCollection.title} Options`}
        onToggle={toggleMenu} 
      />

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={200}
        position={menuPosition}>

        {/* MANAGE SECTION */}
        {currentApp && hasAppPermission(currentApp, currentUser, Permission.MANAGE_COLLECTIONS) &&
          <ContextMenuGroup title={selectedCollection.title}>

            {/* EDIT MODEL */}
            {hasCollectionPermission(currentApp, currentUser, selectedCollection, Permission.UPDATE) &&
              <ContextMenuOption
                icon={PencilIcon}
                text="Settings"
                onClick={handleEditCollection}
                iconSize="11"
              />
            }

            {/* DELETE MODEL */}
            {hasCollectionPermission(currentApp, currentUser, selectedCollection, Permission.DELETE) &&
              <ContextMenuOption
                icon={DeleteIcon}
                text="Delete Collection"
                onClick={handleDeleteCollection}
                iconSize="11"
              />
            }

            {/* DESIGNER */}
            {!isMobile &&
              <ContextMenuOption
                icon={FeatureIcon}
                text="Layout"
                onClick={handleGenerateUI}
                iconSize="11"
              />
            }

          </ContextMenuGroup>
        }

        {/* VIEW SECTION */}
        <ContextMenuGroup title="View">

          {/* GRID */}
          <ContextMenuOption
            icon={GridIcon}
            text="Grid"
            onClick={handleGridClick}
          />

          {/* CARDS */}
          <ContextMenuOption
            icon={CardsIcon}
            text="Cards"
            onClick={handleListClick}
          />

          {/* TABLE 
          <ContextMenuOption
            icon={TableIcon}
            text="Table" onClick={handleTableClick}
            iconSize="12"
          />
          */}

        </ContextMenuGroup>

        {/* SORT BY SECTION */}
        <ContextMenuGroup title="Sort By">

          {/* DYNAMIC FIELDS */}
          {dynamicSortOptions.map((option) => (

            <ContextMenuOption
              key={option.key}
              icon={FieldIcon}
              text={option.title}
              onClick={() => handleFieldSelection(option.key)}
              iconSize="15"
            />

          ))}

          {/* DATE CREATED */}
          <ContextMenuOption
            icon={DateIcon}
            text="Date Added"
            onClick={() => handleFieldSelection('CREATED')}
          />

          {/* DATE MODIFIED */}
          <ContextMenuOption
            icon={DateIcon}
            text="Last Modified"
            onClick={() => handleFieldSelection('MODIFIED')}
          />

        </ContextMenuGroup>

        {/* SORT DIRECTION SECTION */}
        <ContextMenuGroup title="Sort Direction">

          {/* ASCENDING */}
          <ContextMenuOption
            icon={UpIcon}
            text="Ascending"
            onClick={handleAscending}
            iconSize="11"
          />

          {/* DESCENDING */}
          <ContextMenuOption
            icon={DownIcon}
            text="Descending"
            onClick={handleDescending}
            iconSize="11"
          />

        </ContextMenuGroup>

      </ContextMenuPopup>

      {/* DESIGNER 
      <Designer visible={canvasVisible} setVisible={setCanvasVisible} />
      */}
      <Designer visible={canvasVisible} setVisible={setCanvasVisible} />

      {/* COLLECTION EDIT */}
      <CollectionDelete
        isOpen={deleteOpen}
        setOpen={setDeleteOpen}
      />

      {/* COLLECTION EDIT */}
      <CollectionEdit
        isOpen={editOpen}
        setOpen={setEditOpen}
      />

    </div>
  );
};

export default ViewMenu;
