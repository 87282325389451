import React from 'react';

const RolesIcon = ({ color = "white", width = "21", height = "21" }) => (
  <svg 
  version="1.1"
  width={width}
  height={height}
  viewBox="0 0 21 21">
    <g fill="none" fillRule="evenodd" stroke={color} strokeLinecap="round" strokeLinejoin="round" transform="translate(2 2)">
      <path d="m5.5.5h6v5h-6z" />
      <path d="m10.5 11.5h6v5h-6z" />
      <path d="m.5 11.5h6v5h-6z" />
      <path d="m3.498 11.5v-3h10v3" />
      <path d="m8.5 8.5v-3" />
    </g>
  </svg>
);

export default RolesIcon;
