import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getElapsedTime } from '../../../common/utilities/DateTime';
import { MemberThumbDirection } from '../../../common/utilities/Enums';
import { getUserDisplayValue } from '../../../common/utilities/Users';

// Styles
import './ConversationSummary.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Components
import MemberThumbs from '../../components/memberthumbs/MemberThumbs';

// Styled Components
const InlineStyles = useStyle`
    .conversation-summary-user:hover {
        background-color: ${(props) => props.hoverColor} !important;
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const ConversationSummary = ({ conversation }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const { currentApp } = useContext(Global);

    const [participants, setParticipants] = useState([]);
    const [lastMessage, setLastMessage] = useState("");

    useEffect(() => {
        if (conversation.participants) {
            setParticipants(conversation.participants);
        }

        // Trim the last message text to 200 characters
        const trimmedLastMessage = conversation.lastMessage
            ? conversation.lastMessage.text.slice(0, 200)
            : '';

        setLastMessage(trimmedLastMessage);
    }, [conversation]);

    function getUsernamesString() {
        return participants
            .map(user => getUserDisplayValue(currentApp.displayKey, user)) // Extract each username
            .filter(Boolean) // Remove any undefined or null usernames
            .join(', '); // Join with a comma and space
    }

    return (
        <>
            <InlineStyles
                hoverColor={theme.backgroundColorFaded}
            />
            <div className="conversation-summary-container">

                <div className="conversation-summary-top">
                    <MemberThumbs
                        members={participants}
                        direction={MemberThumbDirection.LTR}
                        borderWidth={1}
                        size={24}
                        offset={20}
                    />
                </div>

                <div className="conversation-summary-center"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {getUsernamesString()}
                </div>

                <div className="conversation-summary-last-message"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    {lastMessage && (
                        <div className={conversation.lastMessageRead ? "conversation-summary-last-read" : "conversation-summary-last-unread"}>
                            {lastMessage}
                        </div>
                    )}
                </div>

                {conversation && conversation.lastMessage && conversation.lastMessage.dateCreated &&
                    <div className="conversation-summary-elapsed"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        {getElapsedTime(conversation.lastMessage.dateCreated)}
                    </div>
                }
            </div>
        </>
    );
};

export default ConversationSummary;
