import React from 'react';

const BookmarksIcon = ({ color = "white", width = "16", height = "16" }) => (

    <svg
        height={height}
        viewBox="0 -960 960 960"
        width={width}>
        <path fill={color} d="M180-100v-530.77q0-29.92 21.19-51.11 21.2-21.2 51.12-21.2h301.15q29.92 0 51.12 21.2 21.19 21.19 21.19 51.11V-100L403.08-213.08 180-100Zm60-92.54 163.08-86.38 162.69 86.38v-438.23q0-5.38-3.46-8.85-3.46-3.46-8.85-3.46H252.31q-5.39 0-8.85 3.46-3.46 3.47-3.46 8.85v438.23Zm480-58.61v-536.54q0-5.39-3.46-8.85t-8.85-3.46H295.77v-60h411.92q29.92 0 51.12 21.19Q780-817.61 780-787.69v536.54h-60ZM240-643.08h325.77H240Z" />
    </svg>

);

export default BookmarksIcon;
