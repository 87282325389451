import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { ChannelType, MenuSection, Section, UserPreference } from '../../common/utilities/Enums';

// Managers
import UserManager from '../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * Custom hook to load a user's last screen for the selected app.
 */
const useLoadPagePreference = () => {

  // Global
  const { 
    appCalendars,
    appChannels,
    appCollections,
    currentApp, 
    currentUser,
    resetSelections,
    setCurrentSection,
    setSelectedCalendar,
    setSelectedCalendarType,
    setSelectedChannel,
    setSelectedCollection,
  } = useContext(Global);

  useEffect(() => {

    if (!currentApp || !appCalendars || !appChannels || !appCollections) return;

    const lastPage = userManager.getPreference(
      currentUser,
      currentApp.key,
      UserPreference.LAST_PAGE
    );

    if (lastPage) {

      resetSelections();

      switch (lastPage.section) {

        case MenuSection.CALENDARS:

          // Retrieve the saved LAST_CALENDAR_TYPE preference before displaying it.
          const lastCalendarType = userManager.getPreference(
            currentUser,
            currentApp.key,
            UserPreference.LAST_CALENDAR_TYPE
          );

          if (lastCalendarType) {
            setSelectedCalendarType(lastCalendarType);
          }

          setCurrentSection(Section.CALENDARS);
          setSelectedCalendar(appCalendars.find(calendar => calendar.key === lastPage.calendarKey));

          break;

        case MenuSection.CHANNELS:

          if (!appChannels || appChannels.length === 0) return;

          const channel = appChannels.find(channel => channel.key === lastPage.channelKey);

          if (!channel || channel === undefined) return;

          if (channel.type === ChannelType.TEXT) { setCurrentSection(Section.CHAT) }
          if (channel.type === ChannelType.FORUM) { setCurrentSection(Section.FORUM) }
          if (channel.type === ChannelType.VIDEO) { setCurrentSection(Section.VIDEOCHAT) }

          setSelectedChannel(channel);

          break;

        case MenuSection.COLLECTIONS:

          setSelectedCollection(appCollections.find(model => model.key === lastPage.modelKey));

          break;

        default:
          return;
      }
    }

  }, [currentApp?.key, appCalendars, appChannels]);
  
};

export default useLoadPagePreference;
