import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections, db, storage } from '../../firebaseConfig';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Styles
import './AccountAboutHeaderPhoto.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Managers
import MemberManager from '../../common/managers/MemberManager';
import DataManager from '../../common/managers/DataManager';

const memberManager = new MemberManager();
const dataManager = new DataManager();

const AccountAboutHeaderPhoto = ({ user }) => {
    const { theme } = useTheme();
    const { 
        currentUser, 
        hideProgress, 
        currentApp, 
        selectedMember,
        setSelectedMember,
        showProgress 
    } = useContext(Global);

    // Local state for editability
    const [isEditable, setIsEditable] = useState(false);

    // Effect to determine if the photo is editable
    useEffect(() => {
        if (currentUser && user) {
            setIsEditable(user.userKey === currentUser.userKey);
        } else {
            setIsEditable(false);
        }
    }, [currentUser, user]);

    const handleFileUpload = async (event) => {
        showProgress("Uploading...");

        const file = event.target.files[0];
        const fileExtension = file.name.split('.').pop(); // Extract the file extension

        const image = document.createElement('img');
        image.onload = async () => {
            // Create canvas
            const maxDimension = 1000;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            const ratio = Math.min(maxDimension / image.width, maxDimension / image.height);
            const width = image.width * ratio;
            const height = image.height * ratio;

            canvas.width = width;
            canvas.height = height;

            // Draw the image scaled down
            ctx.drawImage(image, 0, 0, width, height);

            // Convert canvas to blob
            canvas.toBlob(async (blob) => {
                // Log the size of the blob in bytes
                if (currentApp) {
                    activity.log(currentApp.key, 'uploads', blob.size);
                }

                try {
                    // Upload the new file
                    const storageRef = ref(storage, `users/profile/${selectedMember.userKey}.header.${fileExtension}`); // Use the original extension
                    await uploadBytes(storageRef, blob); // Upload the blob
                    const fileUrl = await getDownloadURL(storageRef); // Get the URL

                    if (isEditable) {
                        setSelectedMember({ ...user, headerphoto: fileUrl });
                    }

                    const data = { headerphoto: fileUrl };
                    
                    //await updateDoc(doc(db, collections.users, selectedMember.userKey), data);

                    // Update user
                    await dataManager.update(collections.users, currentApp.key, user.userKey, data);

                    // Update app user
                    await memberManager.updateUserInfo(currentApp.key, user.userKey, data);

                    hideProgress();
                } catch (error) {
                    console.error("Error handling user header photo upload:", error);
                    hideProgress();
                }
            }, `image/${fileExtension}`); // Specify the correct MIME type
        };

        image.src = URL.createObjectURL(file);
    };

    return (
        <div
            className="account-about-header-photo-container"
            style={{
                backgroundColor: theme.highlightBackgroundColor,
            }}
        >
            <label htmlFor="account-about-header-photo-upload" className="account-about-header-photo-wrapper">
                {selectedMember.headerphoto &&
                    <img
                        src={selectedMember.headerphoto}
                        className="account-about-header-photo"
                        alt="User"
                    />
                }
            </label>
            {isEditable && (
                <input
                    id="account-about-header-photo-upload"
                    type="file"
                    onChange={handleFileUpload}
                    style={{ display: 'none' }}
                />
            )}
        </div>
    );
};

export default AccountAboutHeaderPhoto;
