import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountRequiredField.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers
import UserManager from '../../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * AccountRequiredField Component
 * 
 * This component renders an input for a user's value.
 * 
 * @param {function} onChange - The function to call with updated values.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountRequiredField = ({ fieldKey, fieldName, onChange, defaultValue = '', allowSpaces = false }) => {
  const { theme } = useTheme();
  const {
    currentUser,
    currentApp,
    coreTheme
  } = useContext(Global);

  // Local State
  const [error, setError] = useState('');
  const [value, setValue] = useState('');

  /**
   * Initializes the field value.
   */
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * Method to handle value input change.
   * 
   * @param {Event} e - Change event
   */
  const handleRequiredFieldValueChange = async (e) => {
    const value = e.target.value;
  
    onChange(value, false); // Assume invalid initially
  
    // Validate presence
    if (!value) {
      setError('A valid value is required.');
      return;
    }
  
    // Validate length (3 to 20 characters)
    if (value.length < 3 || value.length > 20) {
      setError('Value must be between 3 and 20 characters.');
      return;
    }
  
    // Determine the character set regex based on allowSpaces
    const valueRegex = allowSpaces
      ? /^[a-zA-Z0-9_.@\- ]{3,20}$/ // Allow spaces
      : /^[a-zA-Z0-9_.@-]{3,20}$/; // Disallow spaces
  
    if (fieldKey === 'handle') {
      // Validate character set for handle
      if (!valueRegex.test(value)) {
        const errorMessage = allowSpaces
          ? 'Value can only contain letters, numbers, underscores, hyphens, spaces, and periods.'
          : 'Value can only contain letters, numbers, underscores, hyphens, and periods.';
        setError(errorMessage);
        return;
      }
  
      // Make sure the handle is prefixed with an '@' symbol
      if (!value.startsWith('@')) {
        setError('Handle must start with an "@" symbol.');
        return;
      }
  
      // Check if handle already exists, excluding the current user from the check if applicable
      const exists = await userManager.handleExists(value);
      if (exists && (!currentUser || currentUser.handle !== value)) {
        setError('An account with this handle already exists.');
        return;
      }
    } else {
      // Validate character set for other fields
      if (!valueRegex.test(value)) {
        const errorMessage = allowSpaces
          ? 'Value can only contain letters, numbers, underscores, hyphens, spaces, and periods.'
          : 'Value can only contain letters, numbers, underscores, hyphens, and periods.';
        setError(errorMessage);
        return;
      }
    }
  
    // If we have a valid value and it doesn't exist, clear the error
    setError('');
  
    // Let the parent know the value is valid
    onChange(value, true);
  };  

  return (
    <>

      {/* CONTAINER */}
      <div className="account-required-field-container">

        {/* LABEL */}
        <div
          className="account-required-field-label"
          style={{
            color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded
          }}>
          {fieldName}
        </div>

        {/* INPUT */}
        <input
          type="text"
          className="account-required-field-input"
          style={{
            color: coreTheme.foregroundColor,
            backgroundColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded
          }}
          value={value}
          onChange={handleRequiredFieldValueChange}
          placeholder={fieldName}
          required
        />

        {/* ERROR MESSAGE */}
        {error && (
          <div
            className="account-required-field-error"
            style={{
              color: currentApp ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor,
            }}>
            {error}
          </div>
        )}
      </div>

    </>
  );
};

export default AccountRequiredField;
