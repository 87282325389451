import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountUsername.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Managers
import UserManager from '../../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * AccountUsername Component
 * 
 * This component renders an input for a user's username.
 * 
 * @param {function} onChange - The function to call with updated values.
 * @param {string} defaultValue - The default username value.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountUsername = ({ onChange, defaultValue = '' }) => {
  const { theme } = useTheme();
  const { currentUser, currentApp, coreTheme } = useContext(Global);

  // Local State
  const [usernameError, setUsernameError] = useState('');
  const [value, setValue] = useState('');

  /**
   * Initializes the field value.
   */
  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /**
   * Validates the username.
   * 
   * @param {string} username - The username to validate.
   * @returns {Promise<void>}
   */
  const validateUsername = async (username) => {
    if (username.length === 0) return;
    
    // Validate username presence
    if (!username) {
      setUsernameError('A valid username is required.');
      onChange(username, false);
      return;
    }

    // Validate username length (3 to 20 characters)
    if (username.length < 3 || username.length > 20) {
      setUsernameError('Username must be between 3 and 20 characters.');
      onChange(username, false);
      return;
    }

    // Validate username character set (alphanumeric, underscore, hyphen, period)
    const usernameRegex = /^[a-zA-Z0-9_.-]{3,20}$/;
    if (!usernameRegex.test(username)) {
      setUsernameError('Username can only contain letters, numbers, underscores, hyphens, and periods.');
      onChange(username, false);
      return;
    }

    // Check if username already exists, excluding the current user
    const exists = await userManager.usernameExists(username);
    if (exists && (!currentUser || currentUser.username !== username)) {
      setUsernameError('An account with this username already exists.');
      onChange(username, false);
      return;
    }

    // Clear the error if validation passes
    setUsernameError('');
    onChange(username, true);
  };

  /**
   * Handles username input change without validation.
   * 
   * @param {Event} e - Change event
   */
  const handleUsernameChange = (e) => {
    const username = e.target.value.trim();
    setValue(username);
    onChange(username, false); // Notify parent of the change
  };

  /**
   * Handles username validation onBlur.
   * 
   * @param {Event} e - Blur event
   */
  const handleUsernameBlur = async (e) => {
    const username = e.target.value.trim();
    await validateUsername(username);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="account-username-container">

        {/* USERNAME LABEL */}
        <div 
          className="account-username-label"
          style={{ 
            color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded
          }}>
          Username
        </div>

        {/* USERNAME INPUT */}
        <input
          type="text"
          className="account-username-input"
          style={{
            color: currentApp ? theme.foregroundColor : coreTheme.foregroundColor,
            backgroundColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded
          }}
          value={value}
          onChange={handleUsernameChange} // Update value only
          onBlur={handleUsernameBlur} // Perform validation onBlur
          required
        />

        {/* ERROR MESSAGE */}
        {usernameError && (
          <div
            className="account-username-error"
            style={{
              color: currentApp ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor,
            }}>
            {usernameError}
          </div>
        )}
      </div>
    </>
  );
};

export default AccountUsername;
