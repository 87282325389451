import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Section } from '../../../common/utilities/Enums';

// Styles
import './Settings.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import SettingItem from './settingitem/SettingItem';

/**
 * Settings Component
 * 
 * This component renders a channel-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Settings = () => {
    const { theme } = useTheme();
    const {
        resetSelections,
        currentApp,
        selectedSetting,
        setCurrentSection,
        setSelectedSetting,
        settings,
    } = useContext(Global);

    const handleOptionClick = (key) => {
        resetSelections();
        setSelectedSetting(settings.find(option => option.key === key));
        setCurrentSection(Section.SETTINGS);
    }

    return (
        <>
            {/* TITLE */}
            <div className="settings-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: currentApp.fontFamily
                }}>
                Settings
            </div>

            {/* CHANNEL LIST */}
            <div className="settings-group">

                {/* MENU */}
                {settings.map((option, index) => (
                    <SettingItem
                        key={index}
                        setting={option}
                        isActive={selectedSetting && selectedSetting.key === option.key}
                        onClick={() => handleOptionClick(option.key)}
                    />
                ))}

            </div>

        </>
    );
};

export default Settings;
