import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumMenu.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import DeleteIcon from '../../../../common/svg/DeleteIcon';
import SettingsIcon from '../../../../common/svg/SettingsIcon';

// Components
import ContextMenuButton from '../../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../../components/contextmenu/ContextMenuPopup';

import ChannelDelete from '../../../../common/menu/channels/channeldelete/ChannelDelete';
import ChannelEdit from '../../../../common/menu/channels/channeledit/ChannelEdit';

const ForumMenu = () => {
    const { theme } = useTheme();
    const {
        selectedChannel,
    } = useContext(Global);

    // Local State
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [editOpen, setEditOpen] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    /**
     * Method to toggle the display of the menu.
     * 
     * @param {string} e - Click event.
     */

    const toggleMenu = (e) => {
        if (!menuVisible) {
            e.preventDefault();
            setMenuPosition({ x: e.clientX, y: e.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    /**
     * Method to handle a delete click event.
     * 
     * @param {string} e - Click event.
     */
    const handleDeleteClick = (event) => {
        event.stopPropagation();
        setDeleteOpen(true);
        setMenuVisible(false);
    };

    /**
     * Method to handle a edit click event.
     * 
     * @param {string} e - Click event.
     */
    const handleEditClick = (event) => {
        event.stopPropagation();
        setEditOpen(true);
        setMenuVisible(false);
    };

    return (
        <div className="forum-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="forum-menu-container">

                <ContextMenuButton
                    title="Feed Options"
                    onToggle={toggleMenu}
                />

            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={190}
                position={menuPosition}>

                {/* FORM OPTIONS */}
                <ContextMenuGroup title="Updates">

                    {/* SETTINGS */}
                    <ContextMenuOption
                        icon={SettingsIcon}
                        text="Settings"
                        onClick={handleEditClick}
                    />

                    {/* DELETE */}
                    <ContextMenuOption
                        icon={DeleteIcon}
                        text="Delete"
                        onClick={handleDeleteClick}
                    />

                </ContextMenuGroup>

            </ContextMenuPopup>

            {/* CHANNEL EDIT */}
            <ChannelEdit
                channel={selectedChannel}
                isOpen={editOpen}
                setOpen={setEditOpen}
            />

            {/* CHANNEL DELETE */}
            <ChannelDelete
                channel={selectedChannel}
                isOpen={deleteOpen}
                setOpen={setDeleteOpen}
            />

        </div>
    );
};

export default ForumMenu;
