import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission, Section, UserPreference } from '../../utilities/Enums';
import { hasAppPermission } from '../../utilities/Permissions';

// Styles
import './Channels.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import ChannelAddButton from './channeladdbutton/ChannelAddButton';
import ChannelItem from './channelitem/ChannelItem';
import ChannelAdd from './channeladd/ChannelAdd';

// Utilities
import { ChannelType } from '../../utilities/Enums';

// Managers
import UserManager from '../../../common/managers/UserManager';

const userManager = new UserManager();

/**
 * Channels Component
 * 
 * This component renders a channel-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Channels = () => {
    const { theme } = useTheme();
    const {
        appChannels,
        currentUser,
        resetSelections,
        currentApp,
        selectedChannel,
        setChannelAddOpen,
        setCurrentSection,
        setCurrentUser,
        setMenuVisible,
        setSelectedCalendar,
        setSelectedChannel,
        setSelectedCollection,
    } = useContext(Global);

    /**
     * Handles a channel click event.
     * 
     * @param {object} channel - Channel that was clicked on.
     */
    const handleChannelClick = async (channel) => {
        resetSelections();

        setSelectedCalendar(null);
        setSelectedCollection(null);
        setSelectedChannel(channel);

        if (channel.type === ChannelType.TEXT) { setCurrentSection(Section.CHAT) }
        if (channel.type === ChannelType.FORUM) { setCurrentSection(Section.FORUM) }
        if (channel.type === ChannelType.VIDEO) { setCurrentSection(Section.VIDEOCHAT) }

        // Set the last screen preference
        const screen = {
            section: 'CHANNELS',
            channelKey: channel.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );
    };

    /**
     * Handles an add channel button click event.
     */
    const handleAddChannelClick = () => {
        setChannelAddOpen(true);
        setMenuVisible(false);
    };

    return (
        <>
            {/* TITLE */}
            <div className="channels-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: currentApp.fontFamily
                }}>

                Channels

                {/* ADD CHANNEL BUTTON */}
                {currentApp && hasAppPermission(currentApp, currentUser, Permission.MANAGE_CHANNELS) &&
                    <ChannelAddButton
                        onClick={handleAddChannelClick}
                    />
                }

            </div>

            {/* CHANNEL LIST */}
            <div className="channels-group">
                {appChannels.map(channel => (
                    <ChannelItem
                        key={channel.key}
                        channel={channel}
                        isActive={selectedChannel && selectedChannel.key === channel.key}
                        onClick={() => handleChannelClick(channel)}
                    />
                ))}

            </div>

            {/* FORM */}
            <ChannelAdd />
        </>
    );
};

export default Channels;
