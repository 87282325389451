import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../Global';

// Enums
import { ThemeMode } from '../../../common/utilities/Enums';

// Utilities
import { hexToRgba } from '../../../common/utilities/Colors';

// Circle Loader
import { CircleLoader } from 'react-spinners';

// Styles
import './AppAddProgress.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import gradient from '../../../site/gradient.jpg';

/**
 * AppAddProgress Component
 * 
 * This component renders a masked progress indicator.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppAddProgress = ({ progressVisible, progressMessage, progressFlash }) => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    currentApp,
  } = useContext(Global);

  const [currentFlash, setCurrentFlash] = useState('');
  const [flashOpacity, setFlashOpacity] = useState(1); // Explicitly track opacity

  useEffect(() => {
    let isCancelled = false;

    const displayFlashMessage = (index) => {
      if (isCancelled || index >= progressFlash.length) return;

      setCurrentFlash(progressFlash[index]);
      setFlashOpacity(1);

      // Fade out after a short delay
      setTimeout(() => {
        setFlashOpacity(0);
      }, 200);

      // Schedule the next message
      setTimeout(() => {
        displayFlashMessage(index + 1);
      }, 500);
    };

    if (progressFlash && progressFlash.length > 0) {
      displayFlashMessage(0);
    }

    return () => {
      isCancelled = true;
    };
  }, [progressFlash]);

  if (!progressVisible) return null;

  // Conditional overlay styles
  const overlayStyleApp = hexToRgba(
    theme.currentThemeMode === ThemeMode.DARK ? 'black' : 'white',
    0.8 // 80% opacity
  );

  const overlayStyleCore = {
    backgroundImage: `url(${gradient})`, // Semi-transparent gradient image
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    opacity: 0.8, // Semi-transparency for the image
  };

  return (
    <>
      {/* MASK */}
      <div
        className="app-add-progress-mask"
        style={{
          ...(currentApp
            ? { backgroundColor: overlayStyleApp } // Dark/light background with opacity
            : overlayStyleCore), // Gradient image background
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 100, // Behind the content
        }}
      ></div>

      {/* CONTENT */}
      <div
        className="app-add-progress-content"
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          zIndex: 200, // Above the mask
        }}>

        {/* CIRCLE LOADER */}
        <div className="app-add-progress-circle">
          <CircleLoader
            size={40}
            color={theme.highlightBackgroundColor}
            loading={true}
          />
        </div>

        {/* MESSAGE */}
        <div
          className="app-add-progress-status-message"
          style={{
            color: theme.foregroundColor,
            textAlign: 'center',
            marginBottom: '1rem',
          }}
        >
          {progressMessage}
        </div>

        {/* FLASH */}
        <div
          className="app-add-progress-status-flash"
          style={{
            color: theme.foregroundColor,
            textAlign: 'center',
            marginBottom: '1rem',
            opacity: flashOpacity, // Bind opacity state
            transition: 'opacity 0.3s ease-out', // Smooth fade-out effect
          }}
        >
          {currentFlash}
        </div>
        
      </div>
    </>
  );
};

export default AppAddProgress;
