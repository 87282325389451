import React, { useEffect, useState } from 'react';

// Styles
import './Month.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import FieldContainer from '../../FieldContainer';
import FieldHeader from '../../FieldHeader';
import Select from '../../../../common/components/select/Select';

const Month = ({
    object,
    field,
    onUpdate,
    showFieldMenu = true,
    readOnly = false
}) => {
    // Theme
    const { theme } = useTheme();

    // Local State
    const [value, setValue] = useState(''); // Default ADD mode value

    /**
     * Initialize the field value.
     */
    useEffect(() => {
        setValue(object?.[field.key] || '');
    }, [object, field.key]);

    /**
     * Method to handle the change in value.
     * 
     * @param {string} val - New value.
     */
    const handleChange = (val) => {
        setValue(val);
        onUpdate(field, val);
    };

    const options = [
        { value: "JAN", label: "January" },
        { value: "FEB", label: "February" },
        { value: "MAR", label: "March" },
        { value: "APR", label: "April" },
        { value: "MAY", label: "May" },
        { value: "JUN", label: "June" },
        { value: "JUL", label: "July" },
        { value: "AUG", label: "August" },
        { value: "SEP", label: "September" },
        { value: "OCT", label: "October" },
        { value: "NOV", label: "November" },
        { value: "DEC", label: "December" }
    ];

    return (
        <>
            {/* CONTAINER */}
            <FieldContainer
                readOnly={readOnly}
                field={field}>

                {/* HEADER */}
                <FieldHeader
                    field={field}
                    readOnly={readOnly}
                    showFieldMenu={showFieldMenu}
                />

                {/* READ-ONLY TEXT */}
                {readOnly ? (
                    <div className='month-value'
                        style={{ color: theme.foregroundColor }}>
                        {options.find(option => option.value === value)?.label}
                    </div>
                ) : (
                    <>
                        {/* INPUT */}
                        <Select
                            options={options}
                            value={value}
                            onChange={(value) => handleChange(value)}
                        />
                    </>
                )}

            </FieldContainer>
        </>
    );
};

export default Month;
