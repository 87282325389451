import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { truncateFileName } from '../../../../common/utilities/Strings';

// Styles
import './Document.css';

// Images
import DocumentIcon from '../../../svg/DocumentIcon';

// Theme
import { useTheme } from '../../../../ThemeContext';

const Document = ({
    content,
    onClick,
    mode = "VIEW"
}) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentApp,
    } = useContext(Global);

    if (!content) return null;

    const handleDocumentClick = (url) => {
        if (mode === "EDIT") {
            onClick();
        } else {
            window.open(url, '_blank', 'noopener,noreferrer');
        }
    };

    return (
        <>
            <div key={content.url} className="document-container"
                onClick={() => handleDocumentClick(content.url)}
                style={{
                    backgroundColor: theme.backgroundColorFaded
                }}>
                <DocumentIcon
                    color={theme.highlightBackgroundColor}
                    width="14"
                    height="14"
                />
                <div
                    className="document-filename"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: currentApp.fontFamily,
                    }}>
                    {truncateFileName(content.name, 100)}
                </div>
            </div>
        </>
    );
};

export default Document;
