// Firebase
import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query, updateDoc, where, writeBatch } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

class AppManager {

    /**
     * Determines if a domain is already in use by another app.
     * 
     * @param {string} domain - Domain to check.
     * 
     * @returns {Promise<boolean>} True if the domain is already in use, otherwise false.
     * @throws Will throw an error if the Firestore query fails.
     */
    async domainExists(domain) {
        try {
            // Get a reference to the 'apps' collection
            const coll = collection(db, collections.apps);

            // Create a query to check if the domain field matches the given domain
            const q = query(coll, where("domain", "==", domain));

            // Execute the query and get the results
            const snapshot = await getDocs(q);

            // Return true if at least one document matches, otherwise false
            return !snapshot.empty;
        } catch (error) {
            // Log the error and rethrow it for further handling
            console.error("Error fetching data:", error);
            throw error;
        }
    }

    /**
 * Gets an app by domain if it exists.
 * 
 * @param {string} domain - Domain to retrieve.
 * @returns {Promise<Object|null>} The app data if found, otherwise null.
 * @throws Will throw an error if the Firestore query fails.
 */
    async getByDomain(domain) {

        try {
            // Reference the 'apps' collection in Firestore
            const coll = collection(db, collections.apps);

            // Query the collection for a document where 'domain' matches the provided domain
            const q = query(coll, where("domain", "==", domain));

            // Execute the query and fetch the documents
            const snapshot = await getDocs(q);

            // Check if any documents were found
            if (!snapshot.empty) {
                // Return the data of the first matching document
                return snapshot.docs[0].data();
            } else {
                // Return null if no matching document is found
                return null;
            }
        } catch (error) {
            // Log the error and rethrow it for further handling
            console.error("Error fetching app by domain:", error);
            throw error;
        }
    }

    /**
      * Fetches userApps created by a specific user and subscribes to real-time updates.
      * 
      * @param {function} onUpdate - Callback function that handles the update.
      */
    fetchApps(onUpdate) {
        try {
            // Create a reference to the userApps collection
            const userAppsCollection = collection(db, collections.apps);

            // Create a query to find userApps by userKey and sort them by title
            const q = query(userAppsCollection, orderBy("title"));

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(q, snapshot => {
                const appList = snapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));

                // Call the onUpdate callback with the updated list
                if (onUpdate) onUpdate(appList);
            }, error => {
                console.error("Error fetching userApps:", error);
            });

            // Return the unsubscribe function to allow the caller to unsubscribe later
            return unsubscribe;
        } catch (error) {
            console.error("Error setting up real-time updates:", error);
            throw error;
        }
    }

    /**
     * Subscribes to real-time updates for userApps that a user has access to.
     * 
     * @param {string} userKey - User key to get userApps for.
     * @param {Function} onUpdate - Callback function to handle updates with an array of user userApps.
     * @returns {Function} Unsubscribe function to stop the real-time updates.
     */
    listUserAppsAndSubscribe(userKey, onUpdate) {
        try {
            // Step 1: Query the members collection to get appKey values for the given userKey
            const membersCollection = collection(db, collections.appusers);
            const membersQuery = query(membersCollection, where("userKey", "==", userKey));

            const unsubscribe = onSnapshot(membersQuery, async (membersSnapshot) => {
                // Extract appKey values from the query result
                const appKeys = membersSnapshot.docs.map(doc => doc.data().appKey);

                if (appKeys.length === 0) {
                    onUpdate([]); // Notify with an empty array if no userApps are found
                    return;
                }

                // Step 2: Query the userApps collection with the appKey values
                const userAppsCollection = collection(db, collections.apps);
                const userAppsQuery = query(userAppsCollection, where("__name__", "in", appKeys));

                onSnapshot(userAppsQuery, (userAppsSnapshot) => {
                    // Map the app details from the query result
                    const appDetails = userAppsSnapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data()
                    }));

                    // Notify the subscriber with the updated user apps
                    onUpdate(appDetails);
                });
            });

            return unsubscribe; // Return the unsubscribe function to stop the subscription
        } catch (error) {
            console.error("Error subscribing to user userApps:", error);
            throw error;
        }
    }

    /**
     * Fetches userApps that a user has access to.
     * 
     * @param {string} userKey - User key to get userApps for.
     * @returns {Promise<Array>} A promise that resolves to an array of user userApps.
     */
    async fetchUserApps(userKey) {
        try {
            // Step 1: Query the members collection to get appKey values for the given userKey
            const membersCollection = collection(db, collections.appusers);
            const membersQuery = query(membersCollection, where("userKey", "==", userKey));
            const membersSnapshot = await getDocs(membersQuery);

            // Extract appKey values from the query result
            const appKeys = membersSnapshot.docs.map(doc => doc.data().appKey);

            if (appKeys.length === 0) {
                return []; // Return an empty array if no userApps are found for the user
            }

            // Step 2: Query the userApps collection with the appKey values
            const userAppsCollection = collection(db, collections.apps);
            const userAppsQuery = query(userAppsCollection, where("__name__", "in", appKeys));
            const userAppsSnapshot = await getDocs(userAppsQuery);

            // Map the app details from the query result
            const appDetails = userAppsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            return appDetails;
        } catch (error) {
            console.error("Error fetching user userApps:", error);
            throw error;
        }
    }

    /**
     * Updates an app's version number in the Firestore database.
     * 
     * @param {string} appKey - The key (document ID) of the app to update.
     * @param {string} version - New version number of the app.
     * @returns {Promise<Object>} A promise that resolves to an object indicating the operation's success or failure.
     */
    async updateVersion(appKey, version) {
        await updateDoc(doc(db, collections.apps, appKey), { version });

        activity.log(appKey, 'writes', 1);
    }

    /**
     * Updates the app's rating data in the Firestore database.
     *
     * @param {string} appKey - The key (document ID) of the app to update.
     * @param {string} userKey - The key (document ID) of the user providing the rating.
     * @param {number} rating - The rating value provided by the user.
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async updateAppRatings(appKey, userKey, rating) {
        const appDocRef = doc(db, collections.apps, appKey);
        const appDoc = await getDoc(appDocRef);

        if (appDoc.exists()) {
            const appData = appDoc.data();
            const ratings = appData.ratings || {};

            // Add or update the user rating
            ratings[userKey] = rating;

            // Calculate the average rating
            const ratingValues = Object.values(ratings);
            const totalRatings = ratingValues.length;
            const averageRating = totalRatings > 0
                ? Math.round(ratingValues.reduce((sum, r) => sum + r, 0) / totalRatings)
                : 0;

            await updateDoc(appDocRef, { ratings, rating: averageRating });
        } else {
            // Handle the case where the app document does not exist
            console.error(`No app document found for appKey: ${appKey}`);
        }
    }

    /**
     * Deletes documents from a specified collection based on a query.
     * 
     * @param {object} batch - The Firestore batch operation.
     * @param {string} collectionPath - The path of the collection to delete documents from.
     * @param {object} queryConstraints - The constraints for querying the documents.
     * @param {string} appKey - The key of the app for logging activities.
     * @param {string} modelKey - (Optional) The key of the model for logging activities.
     */
    async deleteDocuments(batch, collectionPath, queryConstraints, appKey, modelKey = null) {
        const querySnapshot = await getDocs(query(collection(db, collectionPath), queryConstraints));

        activity.log(appKey, 'reads', querySnapshot.docs.length);

        querySnapshot.forEach(doc => {
            batch.delete(doc.ref);
            activity.log(appKey, 'deletes', 1);
        });
    };

    /**
     * Deletes an app from the Firestore database.
     * 
     * @param {string} appKey - The key (document ID) of the app to delete.
     */
    async deleteApp(appKey) {
        try {
            // Start a batch operation to execute multiple write operations as a single atomic unit
            const batch = writeBatch(db);

            // First, delete the app document from the "userApps" collection
            const appRef = doc(db, collections.apps, appKey);
            batch.delete(appRef);

            // Query and delete all models associated with the selected app
            const appCollectionsSnapshot = await getDocs(query(collection(db, collections.models), where("appKey", "==", appKey)));

            for (const modelDoc of appCollectionsSnapshot.docs) {
                const originalModelData = modelDoc.data();
                const modelKey = originalModelData.key;
                const modelRef = modelDoc.ref;

                // Delete the model document itself
                batch.delete(modelRef);
                activity.log(appKey, 'deletes', 1);

                // Delete fields, objects, and summaries associated with the model
                await this.deleteDocuments(batch, collections.fields, where("modelKey", "==", modelKey), appKey, modelKey);
                await this.deleteDocuments(batch, modelKey, {}, appKey, modelKey);
            }

            // Delete records related to the app's key
            await this.deleteDocuments(batch, collections.appusers, where("appKey", "==", appKey), appKey);
            await this.deleteDocuments(batch, collections.events, where("appKey", "==", appKey), appKey);
            await this.deleteDocuments(batch, collections.transactions, where("appKey", "==", appKey), appKey);

            // Commit the batch
            await batch.commit();

        } catch (error) {
            console.error('Error deleting app:', error);
            return { success: false, message: "Error deleting app." };
        }
    }
}

export default AppManager;
