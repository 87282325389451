import React, { useState } from 'react';

// Styles
import './MemberMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import RemoveIcon from '../../svg/RemoveIcon';

// Components
import ContextMenuButton from '../../components/contextmenu/ContextMenuButton';
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';

const MemberMenu = ({ onRemove }) => {

    // Theme
    const { theme } = useTheme();

    // Local State
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });

    const toggleMenu = (event) => {
        if (!menuVisible) {
            event.preventDefault();
            setMenuPosition({ x: event.clientX, y: event.clientY });
            setMenuVisible(true);
        } else {
            setMenuVisible(false);
        }
    };

    const handleRemove = (event) => {
        event.stopPropagation();
        onRemove();
        setMenuVisible(false);
    };

    return (
        <div className="member-menu-outer-container">

            {/* MENU BUTTON */}
            <div className="member-menu-container">
                <ContextMenuButton
                    title="Member Options"
                    onToggle={toggleMenu}
                />
            </div>

            {/* MENU */}
            <ContextMenuPopup
                visible={menuVisible}
                setVisible={setMenuVisible}
                backgroundColor={theme.backgroundColorFaded}
                width={190}
                position={menuPosition}>

                {/* REMOVE */}
                <ContextMenuOption
                    icon={RemoveIcon}
                    text="Remove from App"
                    onClick={handleRemove}
                />

            </ContextMenuPopup>

        </div>
    );
};

export default MemberMenu;
