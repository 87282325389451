import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Enums
import { ThemeMode } from '../../common/utilities/Enums';

// Styles
import './AppTitle.css';

// Theme
import { useTheme } from '../../ThemeContext';

const AppTitle = () => {
    const { theme } = useTheme();
    const {
        currentThemeMode,
        currentApp
    } = useContext(Global);

    // State Va
    const [logoSrc, setLogoSrc] = useState(null);

    useEffect(() => {
        if (!currentApp) return;

        if (currentThemeMode === ThemeMode.DARK) {
            if (currentApp.logoDark) {
                setLogoSrc(currentApp.logoDark);
            } else {
                setLogoSrc(null);
            }
        } else {
            if (currentApp.logoLight) {
                setLogoSrc(currentApp.logoLight);
            } else {
                setLogoSrc(null);
            }
        }
    }, [currentApp?.key, currentThemeMode]);

    return (
        <>
            {/* CONTAINER */}
            <div className="app-title-container">

                {/* IMAGE */}
                {logoSrc &&
                    <img
                        className="app-title-image"
                        src={logoSrc}
                        alt={currentApp.title}
                    />
                }

                {/* TITLE */}
                <div
                    className="app-title-name"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: currentApp && currentApp.fontFamily
                    }}>
                    {currentApp.title}
                </div>
            </div>
        </>
    );
};

export default AppTitle;
