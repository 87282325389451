import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { getUserDisplayValue } from '../../../common/utilities/Users';

// Styles
import './ChatUser.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import UserThumb from '../../../common/components/userthumb/UserThumb';

const ChatUser = ({ member }) => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentApp,
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="chat-user-container">

                {/* DOT */}
                <div className="chat-user-dot"
                    style={{
                        color: theme.foregroundColor
                    }}>
                    <UserThumb
                        user={member}
                        size="16"
                    />
                </div>

                {/* USERNAME */}
                <div className="chat-user-name"
                    style={{
                        color: theme.foregroundColor,
                        fontFamily: currentApp.fontFamily
                    }}>
                        {getUserDisplayValue(currentApp.displayKey, member)}
                </div>

            </div>
        </>
    );
};

export default ChatUser;
