import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { defaultFont } from '../common/utilities/Defaults.js';
import { Screen } from '../common/utilities/Enums.js';

// Custom Hooks

import usePreloadApps from '../home/hooks/PreloadApps.js';

import useLoadAppPermissions from './hooks/LoadAppPermissions.js';
import useLoadObjectsViewPreference from './hooks/LoadObjectsViewPreference.js';
import useLoadPagePreference from './hooks/LoadPagePreference.js';
import useLoadThemePreference from './hooks/LoadThemePreference.js';
import useLoadMenuTypePreference from './hooks/LoadMenuTypePreference.js';

import usePreloadAppCalendars from './hooks/PreloadAppCalendars.js';
import usePreloadAppChannels from './hooks/PreloadAppChannels.js';
import usePreloadAppCollections from './hooks/PreloadAppCollections.js';
import usePreloadAppEvents from './hooks/PreloadAppEvents.js';
import usePreloadAppFields from './hooks/PreloadAppFields.js';
import usePreloadAppNewsfeeds from './hooks/PreloadAppNewsfeeds.js';
import usePreloadAppRoles from './hooks/PreloadAppRoles.js';
import usePreloadMembers from './hooks/PreloadMembers.js';
import usePreloadMemberBookmarks from './hooks/PreloadMemberBookmarks.js';
import usePreloadMemberFavorites from './hooks/PreloadMemberFavorites.js';
import usePreloadConversations from './hooks/PreloadConversations.js';

import useProcessLoginStatus from './hooks/ProcessLoginStatus.js';

// Styles
import './Root.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Account from '../account/Account';
import AppAdd from '../common/appadd/AppAdd';
import Home from '../home/Home';
import LogoLarge from '../common/logolarge/LogoLarge';
import Desktop from '../desktop/Desktop';
import Mobile from '../mobile/Mobile';
import Welcome from '../welcome/Welcome';
import Progress from '../common/components/progress/Progress';

// Dynamic Styles
import { RootStyles } from './RootStyles';

const Root = () => {

  // Theme
  const {
    theme
  } = useTheme();

  // Global
  const {
    appAddVisible,
    screen,
    currentApp,
    coreTheme
  } = useContext(Global);

  // HOOKS

  // Login Status
  useProcessLoginStatus();

  // Preload apps
  usePreloadApps();

  // App Data Preload
  usePreloadAppCalendars();
  usePreloadAppChannels();
  usePreloadAppCollections();
  usePreloadAppEvents();
  usePreloadAppFields();
  usePreloadAppNewsfeeds();
  usePreloadAppRoles();
  usePreloadMemberBookmarks();
  usePreloadMemberFavorites();
  usePreloadMembers();
  usePreloadConversations();

  // App Permissions
  useLoadAppPermissions();

  // Theme Preference
  useLoadThemePreference();

  // Object View Preference
  useLoadObjectsViewPreference();

  // Page Preference
  useLoadPagePreference();

  // Menu Type Preference
  useLoadMenuTypePreference();

  return (
    <>
      <RootStyles
        fontFamily={(currentApp && currentApp.fontFamily) || defaultFont}
        placeholderColor={theme.foregroundColorFaded}
        scrollbarColor={theme.foregroundColorFaded}
      />

      {/* SPLASH SCREEN */}
      {screen === Screen.SPLASH && (
        <div className="root-container"
          style={{
            backgroundColor: (coreTheme && coreTheme.backgroundColor) ? coreTheme.backgroundColor : 'black'
          }}>
          <LogoLarge />
        </div>
      )}

      {/* LOGGED IN - HOME */}
      {screen === Screen.HOME && (
        <Home />
      )}

      {/* LOGGED OUT - WELCOME */}
      {screen === Screen.WELCOME && (
        <Welcome />
      )}

      {/* RUN */}
      {screen === Screen.RUN && (
        <>
          {isMobile ? (
            <>
              <Mobile />
            </>
          ) : (
            <>
              <Desktop />
            </>
          )}
        </>
      )}

      {/* PROGRESS PANEL */}
      <Progress />

      {/* ACCOUNT */}
      <Account />

      {/* APP ADD */}
      {appAddVisible &&
        <AppAdd />
      }

    </>
  );
};

export default Root;

