import React from 'react';

// Styles
import './TagSelector.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * TagSelector Component
 * 
 * Displays a list of tags and allows selection unless in readonly mode.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TagSelector = ({ tags, selectedTags, onTagClick, nowrap = false, readonly = false, align = "left" }) => {
    const { theme } = useTheme();

    // In readonly mode, filter selectedTags to include only tags that still exist in the full tags list
    const filteredSelectedTags = readonly
        ? selectedTags.filter(tag => tags.includes(tag)) // Only keep tags that are in 'tags'
        : selectedTags;

    const handleTagClick = (tag) => {
        if (readonly) return;

        if (filteredSelectedTags.includes(tag)) {
            // Remove the tag if it's already selected
            onTagClick(filteredSelectedTags.filter(t => t !== tag));
        } else {
            // Add the tag if it's not selected
            onTagClick([...filteredSelectedTags, tag]);
        }
    };

    return (
        <div>
            {/* TAGS CONTAINER */}
            <div className="tag-selector-container"
                style={{
                    whiteSpace: nowrap ? 'nowrap' : 'normal',
                    flexWrap: nowrap ? 'nowrap' : 'wrap',
                    justifyContent: align
                }}>

                {/* Display selected tags only if readonly; otherwise, show all tags */}
                {(readonly ? filteredSelectedTags : tags).map(tag => (
                    <div
                        key={tag}
                        className="tag-selector-tag"
                        onClick={() => handleTagClick(tag)}
                        style={{
                            backgroundColor: readonly
                                ? theme.backgroundColorFaded // No highlight for readonly mode
                                : (filteredSelectedTags.includes(tag) ? theme.highlightBackgroundColor : theme.backgroundColorFaded),
                            color: readonly
                                ? theme.foregroundColor // No highlight color for readonly mode
                                : (filteredSelectedTags.includes(tag) ? theme.highlightForegroundColor : theme.foregroundColor)
                        }}>
                        {tag}
                    </div>
                ))}
                
            </div>
        </div>
    );
};

export default TagSelector;
