import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppBar.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Components
import AppBarAdd from '../../../desktop/components/appbaradd/AppBarAdd';
import AppBarItem from '../../../desktop/components/appbaritem/AppBarItem';
import AppBarSearch from '../../../desktop/components/appbarsearch/AppBarSearch';

const AppBar = () => {
    const { theme } = useTheme();
    const {
        userApps,
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="app-bar-container"
                style={{
                    backgroundColor: isMobile ? theme.backgroundColor : "transparent",
                    borderRightColor: theme.backgroundColorFaded
                }}>

                {/* TOP */}
                <div className="app-bar-top">

                    {/* APPS */}
                    {userApps.map(app => (

                        <AppBarItem
                            key={app.key}
                            app={app}
                            count={parseInt(Math.random(0, 20) * 10)} // TEMP
                        />
                        
                    ))}

                </div>

                {/* BOTTOM */}
                <div className="app-bar-bottom">

                    {/* ADD BUTTON */}
                    <AppBarAdd />

                    {/* SEARCH BUTTON */}
                    <AppBarSearch />

                </div>

            </div>
        </>
    );
};

export default AppBar;
