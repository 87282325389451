import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load bookmarks for a specific app.
 */
const usePreloadMemberBookmarks = () => {

  // Global
  const { 
    currentUser,
    currentApp, 
    setMemberBookmarks 
  } = useContext(Global);

  useEffect(() => {

    // Exit early if no selected app or app key
    if (!currentApp || !currentApp.key || !currentUser || !currentUser.userKey) {
      setMemberBookmarks([]);
      return;
    }

    // Function to load bookmarks
    const loadBookmarks = (appKey) => {
      const handleUpdate = (items) => {
        setMemberBookmarks(items);
        activity.log(appKey, 'reads', items.length);
      };

      const params = [
        { field: "userKey", operator: "==", value: currentUser.userKey },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.bookmarks,
        appKey,
        handleUpdate,
        params
      );

      return unsubscribe;
    };

    // Subscribe to bookmarks
    const unsubscribeBookmarks = loadBookmarks(currentApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeBookmarks) {
        unsubscribeBookmarks();
      }
    };
  }, [currentApp?.key, setMemberBookmarks, currentUser]);

};

export default usePreloadMemberBookmarks;
