import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { FormMode, Permission, ObjectsView, UserPreference } from '../utilities/Enums';
import { hasCollectionPermission } from '../utilities/Permissions';

// Device Detection
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Objects.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Components
import AddButton from '../../common/components/addbutton/AddButton';
import Divider from '../../common/components/divider/Divider';
import Object from './Object';
import ObjectsToolBar from './ObjectsToolBar';
import ObjectsGrid from './ObjectsGrid';
import Table from '../../desktop/table/Table';
import TagSelector from '../../common/components/tagselector/TagSelector';

// Managers
import ObjectManager from '../../common/managers/ObjectManager';
import UserManager from '../../common/managers/UserManager';

const objectManager = new ObjectManager();
const userManager = new UserManager();

const Objects = () => {
    const { theme } = useTheme();
    const {
        currentUser,
        formMode,
        appCollections,
        objects,
        objectsView,
        currentApp,
        selectedCollection,
        selectedObject,
        selectedObjectTags,
        setCurrentUser,
        setFormMode,
        setObjects,
        setSelectedCollection,
        setSelectedObject,
        setSelectedObjectTags,
        setSortField,
        sortField,
        sortDirection
    } = useContext(Global);

    // Local State
    const [width, setWidth] = useState("50%");

    // Set the default view mode to the title
    useEffect(() => {
        if (!selectedCollection) return;
        setSortField(selectedCollection.titleFieldKey);
    }, [selectedCollection]);

    // Adjust the width and list height based on view type and device
    useEffect(() => {
        setWidth(objectsView === ObjectsView.TABLE
            ? "100%"
            : (isMobile || isTablet ? "100%" : "50%"));
    }, [objectsView]);

    useEffect(() => {
        if (selectedObject) {
            setFormMode(FormMode.EDIT);
        }
    }, [selectedObject]);

    // Handle fetching objects and real-time updates
    useEffect(() => {
        if (!appCollections || appCollections.length === 0 || !selectedCollection) {
            setObjects([]);
            return;
        }

        const handleUpdate = (items) => {
            items.sort((a, b) => {
                let valueA, valueB;
                switch (sortField) {
                    case 'CREATED':
                        valueA = a.dateCreated;
                        valueB = b.dateCreated;
                        break;
                    case 'MODIFIED':
                        valueA = a.dateModified;
                        valueB = b.dateModified;
                        break;
                    default:
                        valueA = a[sortField]?.toLowerCase() || '';
                        valueB = b[sortField]?.toLowerCase() || '';
                }

                return sortDirection === 'ASC'
                    ? valueA.localeCompare(valueB)
                    : valueB.localeCompare(valueA);
            });

            setObjects(prevObjects =>
                JSON.stringify(prevObjects) !== JSON.stringify(items) ? items : prevObjects
            );
        };

        // Subscribe to real-time updates, optionally filtered by tags
        const unsubscribe = objectManager.listAndSubscribe(
            currentApp.key,
            selectedCollection.key,
            handleUpdate,
            selectedObjectTags
        );

        // Cleanup: Ensure that unsubscribe is a function before calling it
        return () => {
            if (typeof unsubscribe === 'function') {
                unsubscribe();  // Ensure unsubscribe is called only if it's a function
            }
        };

    }, [selectedCollection, sortField, sortDirection, appCollections, currentApp.key, setObjects, selectedObjectTags]);

    // Handle the click event for the add button
    const handleAddClick = () => {
        //setObjectsView(ObjectsView.LIST);
        setSelectedObject(null);
        setFormMode(FormMode.ADD);
    };

    // Set the tags according to saved preferences for the selected model
    useEffect(() => {
        if (!selectedCollection || !currentUser || !currentApp.key) return;

        const fetchTagsPreference = async () => {
            // Retrieve the saved preference object containing tags for all models
            const lastTagsObject = await userManager.getPreference(
                currentUser,
                currentApp.key,
                UserPreference.LAST_OBJECT_LIST_TAGS
            );

            // Check if there are saved tags for the current selectedCollection
            if (lastTagsObject && lastTagsObject[selectedCollection.key]) {
                setSelectedObjectTags(lastTagsObject[selectedCollection.key]); // Set tags for the current model
            } else {
                setSelectedObjectTags([]); // Default to an empty array if no tags are saved for this model
            }
        };

        fetchTagsPreference();
    }, [selectedCollection, currentUser, currentApp.key, setSelectedObjectTags]);

    // Handle tag selection updates and save preferences for the selected model
    const handleTagSelection = async (tags) => {
        setSelectedObjectTags(tags); // Update local state

        // Retrieve the current preference object, if it exists
        const lastTagsObject = await userManager.getPreference(
            currentUser,
            currentApp.key,
            UserPreference.LAST_OBJECT_LIST_TAGS
        ) || {}; // Default to an empty object if no preference is saved

        // Update the preference object with tags for the selected model key
        lastTagsObject[selectedCollection.key] = tags;

        // Save the updated preference object
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_OBJECT_LIST_TAGS,
            lastTagsObject
        );
    };

    return (
        <div
            className={isMobile || isTablet ? "objects-container-mobile" : "objects-container"}
            style={{
                backgroundColor: "transparent",
                borderRightColor: theme.backgroundColorFaded,
                height: "100%",
                width: (formMode && !isMobile) ? "50%" : "100%"
            }}>
            {/* TOOLBAR */}
            <ObjectsToolBar />

            {/* TAG SELECTOR */}
            {selectedCollection?.tags && selectedCollection.tags.length > 0 &&
                <div className="objects-tag-selector">
                    <TagSelector
                        tags={selectedCollection?.tags || []}
                        selectedTags={selectedObjectTags}
                        onTagClick={handleTagSelection}
                    />
                </div>
            }

            {/* RENDER LIST OF OBJECTS 
            {objectsView === ObjectsView.TABLE &&
                <Table />
            }*/}

            {objects && objectsView === ObjectsView.LIST &&
                <div
                    className={isMobile ? "objects-list-mobile" : "objects-list"}>
                    {objects.map((object, index) => (
                        <div key={object.key}>
                            <Object object={object} />
                            <Divider />
                        </div>
                    ))}
                </div>
            }

            {objects && objectsView === ObjectsView.GRID &&
                <ObjectsGrid />
            }

            {/* ADD BUTTON */}
            {selectedCollection &&
                formMode !== FormMode.ADD &&
                hasCollectionPermission(currentApp, currentUser, selectedCollection, Permission.CREATE) && (
                    <div className={isMobile ? "objects-add-button-mobile" : "objects-add-button"}>
                        <AddButton onClick={handleAddClick} />
                    </div>
                )}
        </div>
    );
};

export default Objects;

