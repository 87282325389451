export const Screen = {
    SPLASH: 'SPLASH',
    HOME: 'HOME',
    WELCOME: 'WELCOME',
    RUN: 'RUN',
};

export const Section = {
    ACCOUNT: 'ACCOUNT',
    BOOKMARKS: 'BOOKMARKS',
    CALENDARS: 'CALENDARS',
    CHAT: 'CHAT',
    COLLECTIONS: 'COLLECTIONS',
    FAVORITES: 'FAVORITES',
    FORUM: 'FORUM',
    MEMBERS: 'MEMBERS',
    MESSAGES: 'MESSAGES',
    NEWSFEEDS: 'NEWSFEEDS',
    SETTINGS: 'SETTINGS',
    VIDEOCHAT: 'VIDEOCHAT',
};

export const ThemeMode = {
    DARK: 'DARK',
    LIGHT: 'LIGHT',
};

export const CalendarType = {
    ALL: 'ALL',
    MONTH: 'MONTH',
    WEEK: 'WEEK',
    DAY: 'DAY',
    SCHEDULE: 'SCHEDULE',
    STORYLINE: 'STORYLINE',
};

export const ChannelType = {
    TEXT: 'TEXT',
    FORUM: 'FORUM',
    VOICE: 'VOICE',
    VIDEO: 'VIDEO',
};

export const MemberThumbDirection = {
    LTR: 'LTR',
    RTL: 'RTL',
};

export const FormMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
    VIEW: 'VIEW',
};

export const LoginMode = {
    LOGIN: 'LOGIN',
    SIGNUP: 'SIGNUP'
};

export const MenuType = {
    LEFT: 'LEFT',
    TOP: 'TOP',
};

export const PostMode = {
    ADD: 'ADD',
    EDIT: 'EDIT',
};

export const MenuSection = {
    CALENDARS: 'CALENDARS',
    CHANNELS: 'CHANNELS',
    COLLECTIONS: 'COLLECTIONS',
    NEWSFEEDS: 'NEWSFEEDS',
    SETTINGS: 'SETTINGS',
};

export const UserPreference = {
    LAST_CALENDAR_TYPE: 'LAST_CALENDAR_TYPE',
    LAST_FORM_MODE: 'LAST_FORM_MODE',
    LAST_OBJECT_LIST_TAGS: 'LAST_OBJECT_LIST_TAGS',
    LAST_THEME: 'LAST_THEME',
    LAST_PAGE: 'LAST_PAGE',
    LAST_MENU_TYPE: 'LAST_MENU_TYPE',
    LAST_OBJECTS_VIEW: 'LAST_OBJECTS_VIEW',
};

export const ObjectsView = {
    LIST: 'LIST',
    GRID: 'GRID',
    TABLE: 'TABLE',
};

export const Permission = {
    CREATE: 'CREATE',
    READ: 'READ',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    MANAGE_CHANNELS: 'MANAGE_CHANNELS',
    MANAGE_CALENDARS: 'MANAGE_CALENDARS',
    MANAGE_COLLECTIONS: 'MANAGE_COLLECTIONS',
    MANAGE_NEWSFEEDS: 'MANAGE_NEWSFEEDS',
};