import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AppSettingsGeneral.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AppSettingsDescription from './description/AppSettingsDescription';
import AppSettingsDomain from './domain/AppSettingsDomain';
import AppSettingsTitle from './title/AppSettingsTitle';

const AppSettingsGeneral = ({ title, setTitle, description, setDescription, domain, setDomain }) => {
  const { theme } = useTheme();
  const {
  } = useContext(Global);

  return (
    <>
      <div className="app-settings-general-container">

        <AppSettingsTitle
          title={title}
          setTitle={setTitle}
        />

        <AppSettingsDescription
          description={description}
          setDescription={setDescription}
        />
        
        <AppSettingsDomain
          domain={domain}
          setDomain={setDomain}
        />

      </div>
    </>
  );
};

export default AppSettingsGeneral;
