import React, { useEffect, useRef, useState } from 'react';

// Device Detection
import { isMobile } from 'react-device-detect';

// SketchPicker
import { SketchPicker } from 'react-color';

// Styles
import './ColorSelector.css';

const ColorSelector = ({ color, onUpdate }) => {

  // Local State
  const [selectedColor, setSelectedColor] = useState("#000000");
  const [popupVisible, setPopupVisible] = useState(false);

  // References
  const buttonRef = useRef(null);
  const popupRef = useRef(null);

  useEffect(() => {
    setSelectedColor(color);
  }, [color]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopupVisible(false);
      }
    }

    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const handleColorChange = (color) => {
    setSelectedColor(color.hex);
    onUpdate(color.hex);
  };

  const handleClick = async (event) => {
      event.stopPropagation();
  };

  const pickerStyles = {
    default: {
      picker: {
        backgroundColor: '#0f0f0f',
        boxShadow: 'none',
        border: '1px solid #0f0f0f',
      },
    },
  };

  // Calculate position for the popup
  const popupStyle = isMobile
    ? {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: 'fixed',
        zIndex: 1000
      }
    : {
        top: buttonRef.current ? buttonRef.current.offsetTop + buttonRef.current.offsetHeight / 2 - 450 / 2 : 0,
        left: buttonRef.current ? buttonRef.current.offsetLeft + buttonRef.current.offsetWidth + 10 : 0
      };

  return (
    <div onClick={handleClick}>
      <div
        className="color-selector-button"
        style={{ backgroundColor: selectedColor }}
        onClick={togglePopup}
        ref={buttonRef}
      >
      </div>
      {popupVisible && (
        <div
          className="color-selector-popup"
          ref={popupRef}
          style={popupStyle}
        >
          <SketchPicker
            color={selectedColor}
            onChangeComplete={handleColorChange}
            styles={pickerStyles}
          />
        </div>
      )}
    </div>
  );
};

export default ColorSelector;
