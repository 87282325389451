import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './CalendarMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Utilities
import { UserPreference } from '../../utilities/Enums';

// Images
import AgendaIcon from '../../svg/AgendaIcon';
import DayIcon from '../../svg/DayIcon';
import GanttIcon from '../../svg/GanttIcon';
import MonthIcon from '../../svg/MonthIcon';
import StorylineIcon from '../../svg/StorylineIcon';
import WeekIcon from '../../svg/WeekIcon';
import DeleteIcon from '../../svg/DeleteIcon';
import SettingsIcon from '../../svg/SettingsIcon';

// Components
import CalendarDelete from '../../menu/calendars/calendardelete/CalendarDelete';
import CalendarEdit from '../../menu/calendars/calendaredit/CalendarEdit';
import ContextMenuButton from '../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';

// Managers
import UserManager from '../../managers/UserManager';

const userManager = new UserManager();

const CalendarMenu = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    currentUser,
    currentApp,
    selectedCalendar,
    setCurrentUser,
    setSelectedCalendarType
  } = useContext(Global);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setConfirmOpen] = useState(false);

  const calendarOptions = [
    { key: 'SCHEDULE', icon: AgendaIcon, text: 'All Events' },
    { key: 'DAY', icon: DayIcon, text: 'Day' },
    { key: 'WEEK', icon: WeekIcon, text: 'Week' },
    { key: 'MONTH', icon: MonthIcon, text: 'Month' },
    { key: 'STORYLINE', icon: StorylineIcon, text: 'Storyline', iconSize: 15 },
    //{ key: 'GANTT', icon: GanttIcon, text: 'Gantt', iconSize: 17 }
  ];

  /**
   * Toggles visibility of the context menu.
   */
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  const handleCalendarSelection = async (key) => {
    setSelectedCalendarType(key);
    setMenuVisible(false);

    // Call the setPreference method to update LAST_CALENDAR_TYPE settings
    await userManager.setPreference(
      currentUser,
      setCurrentUser,
      currentApp.key,
      UserPreference.LAST_CALENDAR_TYPE,
      key
    );
  };

  const handleDeleteClick = (event) => {
      event.stopPropagation();
      setMenuVisible(false);

      setConfirmOpen(true);
  };

  const handleEditClick = (event) => {
      event.stopPropagation();
      setMenuVisible(false);
      setEditOpen(true);
  };

  if (!selectedCalendar) return null;

  return (
    <div className="calendar-menu-outer-container">

      {/* MENU BUTTON */}
      <div className="calendar-menu-container">
        <ContextMenuButton
          title="Calendar Views"
          onToggle={toggleMenu}
        />
      </div>

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={140}
        position={menuPosition}>

        <ContextMenuGroup title="Calendar">

            <ContextMenuOption
              key="SETTINGS"
              icon={SettingsIcon}
              text="Settings"
              onClick={handleEditClick}
            />

            <ContextMenuOption
              key="DELETE"
              icon={DeleteIcon}
              text="Delete"
              onClick={handleDeleteClick}
            />

        </ContextMenuGroup>

        <ContextMenuGroup title="View">

          {calendarOptions.map(({ key, icon, text, iconSize = 16 }) => (
            <ContextMenuOption
              key={key}
              icon={icon}
              text={text}
              onClick={() => handleCalendarSelection(key)}
              iconSize={iconSize}
            />
          ))}

        </ContextMenuGroup>

      </ContextMenuPopup>

      <CalendarDelete
          calendar={selectedCalendar}
          isOpen={deleteOpen}
          setOpen={setConfirmOpen}
      />

      {/* CALENDAR EDIT */}
      <CalendarEdit 
          calendar={selectedCalendar}
          isOpen={editOpen}
          setOpen={setEditOpen}
      />

    </div>
  );
};

export default CalendarMenu;
