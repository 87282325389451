import React, { useContext, useEffect, useRef } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './FieldSelectorHeader.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import CloseIcon from '../../../common/svg/CloseIcon';

const FieldSelectorHeader = () => {
    const { theme } = useTheme();
    const {
        fieldSelectorVisible,
        setFieldFilter,
        setFieldSelectorVisible
    } = useContext(Global);

    const inputRef = useRef(null);

    useEffect(() => {
        if (fieldSelectorVisible && inputRef.current) {
            inputRef.current.focus();
            setFieldFilter("");
        }
    }, [fieldSelectorVisible, setFieldFilter]);

    const handleFilter = (event) => {
        setFieldFilter(event.target.value);
    };

    const handleClose = () => {
        setFieldSelectorVisible(false);
    };

    return (
        <div className="field-selector-header-background">
            <input
                type="text"
                name="title"
                className="field-selector-header-input"
                ref={inputRef} // Reference to the input element
                style={{
                    color: theme.foregroundColor,
                    backgroundColor: "transparent",
                    borderBottomColor: theme.backgroundColorFaded,
                }}
                onChange={handleFilter} // Handle input changes
                placeholder="Search..."
            />
            <div className="field-selector-header-close" onClick={handleClose}>
                <CloseIcon
                    color={theme.foregroundColor}
                    width="16"
                    height="16"
                />
            </div>
        </div>
    );
};

export default FieldSelectorHeader;
