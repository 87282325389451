import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Firebase
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { storage } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';

// Activity
import { activity } from '../../../managers/ActivityManager';

// Styles
import './ChatPostForm.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import UserThumb from '../../../components/userthumb/UserThumb';

// Components

// Buttons
import DocumentButton from '../../components/buttons/documents/DocumentButton';
import EventButton from '../../components/buttons/events/EventButton';
import PhotoButton from '../../components/buttons/photos/PhotoButton';
import ObjectButton from '../../components/buttons/objects/ObjectButton';
import VideoButton from '../../components/buttons/videos/VideoButton';

import ChatPostFormButton from './ChatPostFormButton';
import ChatPostFormDocuments from './attachments/documents/ChatPostFormDocuments';
import ChatPostFormEvents from './attachments/events/ChatPostFormEvents';
import ChatPostFormInput from './ChatPostFormInput';
import ChatPostFormPhotos from './attachments/photos/ChatPostFormPhotos';
import ChatPostFormObjects from './attachments/objects/ChatPostFormObjects';
import ChatPostFormVideos from './attachments/videos/ChatPostFormVideos';

// Managers
import ObjectManager from '../../../managers/ObjectManager';
import ChatPostManager from '../../../managers/ChatPostManager';

const objectManager = new ObjectManager();
const chatPostManager = new ChatPostManager();

/**
 * ChatPostForm Component
 * 
 * This component renders form for creating channel posts.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChatPostForm = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        hideProgress,
        currentApp,
        selectedChannel,
        showProgress
    } = useContext(Global);

    // Local State
    const [documentFiles, setDocumentFiles] = useState([]);
    const [isFocused, setIsFocused] = useState(false);
    const [localPhotos, setLocalPhotos] = useState([]);
    const [localVideos, setLocalVideos] = useState([]);
    const [photoUrls, setPhotoUrls] = useState([]);
    const [postContent, setPostContent] = useState("");
    const [videoUrls, setVideoUrls] = useState([]);
    const [objects, setObjects] = useState([]);
    const [events, setAppEvents] = useState([]);

    // Event Handlers
    const handleChange = (e) => setPostContent(e.target.value);
    const handleFocus = () => setIsFocused(true);

    /**
     * Method to handle posting to the selected channel.
     * 
     * @param {string} e - Click event.
     */
    const handlePost = async () => {

        // Display a message to the user
        showProgress('Posting...');

        const uploadedPhotoUrls = [];
        const uploadedVideoUrls = [];
        const uploadedDocumentUrls = [];
        let totalBytes = 0; // Initialize totalBytes to accumulate file sizes

        // Upload photos
        for (const file of localPhotos) {
            const key = generateKey();
            const storageRef = ref(storage, `images/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedPhotoUrls.push(url);

            // Accumulate file size
            totalBytes += file.size;
        }

        // Upload videos
        for (const file of localVideos) {
            const key = generateKey();
            const storageRef = ref(storage, `videos/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedVideoUrls.push(url);

            // Accumulate file size
            totalBytes += file.size;
        }

        // Upload documents
        for (const { file } of documentFiles) {
            const key = generateKey();
            const storageRef = ref(storage, `files/${key}.${file.name.split('.').pop()}`);
            await uploadBytes(storageRef, file);
            const url = await getDownloadURL(storageRef);
            uploadedDocumentUrls.push({ url, name: file.name });

            // Accumulate file size
            totalBytes += file.size;
        }

        // Log the total bytes of uploaded files
        activity.log(currentApp.key, 'uploads', totalBytes);

        hideProgress();

        chatPostManager.add(
            currentApp.key,
            selectedChannel.key,
            currentUser,
            postContent,
            uploadedPhotoUrls,
            uploadedVideoUrls,
            uploadedDocumentUrls,
            objects,
            events
        );

        setPostContent("");
        setPhotoUrls([]);
        setLocalPhotos([]);
        setVideoUrls([]);
        setLocalVideos([]);
        setDocumentFiles([]);
        setObjects([]);
        setAppEvents([]);
        setIsFocused(false);
    };

    const handleSearch = async (entry) => {
        const object = await objectManager.fetch(currentApp.key, entry.modelKey, entry.objectKey);
        setObjects(prev => [...prev, object]);
    };

    const handleAddEvent = async (event) => {
        setAppEvents(prev => [...prev, event]);
    };

    return (
        <>
            {/* CONTAINER */}
            <div className={(isMobile || isTablet) ? "chat-post-form-container-mobile" : "chat-post-form-container"}
                style={{
                    borderTopColor: isFocused ? theme.backgroundColorFaded : "transparent"
                }}>

                {/* PHOTOS */}
                <ChatPostFormPhotos
                    urls={photoUrls}
                />

                {/* VIDEOS */}
                <ChatPostFormVideos
                    urls={videoUrls}
                />

                {/* DOCUMENTS */}
                <ChatPostFormDocuments
                    files={documentFiles}
                />

                {/* OBJECTS */}
                <ChatPostFormObjects
                    objects={objects}
                />

                {/* EVENTS */}
                <ChatPostFormEvents
                    events={events}
                />

                {/* ATTACHMENT BUTTONS */}
                <div className={`chat-post-form-attachments ${isFocused ? 'visible' : 'hidden'}`}>

                    {/* ATTACH PHOTOS */}
                    <PhotoButton
                        localPhotos={localPhotos}
                        setLocalPhotos={setLocalPhotos}
                        photoUrls={photoUrls}
                        setPhotoUrls={setPhotoUrls}
                    />

                    {/* ATTACH VIDEOS */}
                    <VideoButton
                        localVideos={localVideos}
                        setLocalVideos={setLocalVideos}
                        videoUrls={videoUrls}
                        setVideoUrls={setVideoUrls}
                    />

                    {/* ATTACH DOCUMENTS */}
                    <DocumentButton
                        documentFiles={documentFiles}
                        setDocumentFiles={setDocumentFiles}
                    />

                    {/* ATTACH OBJECTS */}
                    <ObjectButton
                        onSearch={handleSearch}
                    />

                    {/* ATTACH EVENTS */}
                    <EventButton
                        onAddEvent={handleAddEvent}
                    />

                </div>

                {/* INPUT AREA */}
                <div className="chat-post-form-wrapper">
                    <div className="chat-post-form-left">
                        <UserThumb
                            user={currentUser}
                            size="36"
                        />
                    </div>
                    <div className="chat-post-form-center">
                        <ChatPostFormInput
                            postContent={postContent}
                            onFocus={handleFocus}
                            onChange={handleChange}
                        />
                    </div>
                    {isFocused && (
                        <div className="chat-post-form-right">
                            <ChatPostFormButton onClick={handlePost} />
                        </div>
                    )}
                </div>

            </div>
        </>
    );
};

export default ChatPostForm;
