import React from 'react';

// Styles
import './AppSettingsSaveButton.css';

// Images
import SaveIcon from '../svg/SaveIcon';

// Theme
import { useTheme } from '../../ThemeContext';

const AppSettingsSaveButton = ({ onClick }) => {
    const { theme } = useTheme();

    return (

        <div className="app-settings-save-button-container"
            style={{
                backgroundColor: theme.highlightBackgroundColor,
                color: theme.highlightForegroundColor
            }}
            onClick={onClick}>
            <SaveIcon
                color={theme.highlightForegroundColor}
                width="25"
                height="25"
            />
        </div>
        
    );
};

export default AppSettingsSaveButton;
