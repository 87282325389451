import React, { useContext } from 'react';

// Global
import { Global } from '../../../../Global';

// Styles
import './ForumToolBar.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import ForumMenu from '../forummenu/ForumMenu';

/**
 * ForumToolbar Component
 * 
 * This component renders a forum toolbar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ForumToolBar = () => {
    const { theme } = useTheme();
    const {
        currentApp,
        selectedChannel
    } = useContext(Global);

    return (
        <>
            {/* CONTAINER */}
            <div className="forum-toolbar-container">

                {/* LEFT */}
                <div className="forum-toolbar-left"
                    style={{ 
                        color: theme.foregroundColorFaded,
                        fontFamily: currentApp.fontFamily,
                    }}>

                    {selectedChannel &&
                        <>
                            {selectedChannel.title}
                        </>
                    }
                    
                </div>

                {/* RIGHT */}
                <div className="forum-toolbar-right">

                    <ForumMenu />

                </div>

            </div>
        </>
    );
};

export default ForumToolBar;
