import React, { useState } from 'react';

// Styles
import './MemberInviteButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import PlusIcon from '../../svg/PlusIcon';

// Components
import Invite from '../invite/Invite';

const MemberInviteButton = () => {
  const { theme } = useTheme();

  const [inviteOpen, setInviteOpen] = useState(false);

  const handleAdd = async () => {
    setInviteOpen(true);
  };

  return (
    <>
      <div className="member-invite-button-container"
        style={{ 
          backgroundColor: theme.highlightBackgroundColor 
        }}
        onClick={handleAdd}>
        <PlusIcon
          color={theme.highlightForegroundColor}
          width="25"
          height="25"
        />
      </div>
      <Invite 
        open={inviteOpen}
        setOpen={setInviteOpen}
      />
    </>
  );
};

export default MemberInviteButton;
