// Managers
import PromptManager from './PromptManager';

const promptManager = new PromptManager();

class CalendarManager {

    /**
     * Generates a list of calendars for a new app with the provided app title and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable list of calendars.
     */
    async generateCalendarList(appTitle, appDescription, temperature) {

        const calendarListPrompt = await this.prepareCalendarListPrompt(appTitle, appDescription);

        const response = await promptManager.sendJsonPrompt(calendarListPrompt, temperature);

        return response.calendars;
    };

    /**
     * Prepares a prompt for generating a list of calendars for an app based on an app's title 
     * and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * 
     * @returns Prompt that can be used to generate a list of calendars.
     */
    async prepareCalendarListPrompt(appTitle, appDescription) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        The goal of your response is to define a list of calendars that might be useful based upon the title
        and description of the app. If nothing is applicable, you can simply return an empty list.

        Using the provided TITLE and DESCRIPTION, define a list of models that will be used to structure the app's data.
        Fields will be added later.

        If applicable, provide an array of tags for each calendar to categorize events that might occur on the calendar.

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Do not add comments or characters that might lead to the inability to parse the JSON.

        [APP TITLE AND DESCRIPTION]
        TITLE: ${appTitle}
        DESCRIPTION: ${appDescription}

        Do not add any nesting, and do not structure the response differently from the example.

        [EXAMPLE RESPONSE]
        {
            "calendars": [
                {
                    "title": "Appointments",
                    "description": "Client appointments with our salon.",
                    "tags": [
                        "Hair", 
                        "Tanning",
                        "Nails",
                    ]
                },
                {
                    "title": "Work Schedule",
                    "description": "Schedule for keeping track of expected employee work hours.",
                    "tags": [
                        "Weekends", 
                        "Weekdays",
                        "Graveyard",
                    ]
                }
            ]
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }
    
}

export default CalendarManager;
