import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { MenuType, UserPreference } from '../../common/utilities/Enums';

// Styles
import './MenuHeader.css';

// Theme
import { useTheme } from '../../ThemeContext';

// Images
import DockIcon from '../svg/DockIcon';

// Managers
import UserManager from '../../common/managers/UserManager';

const userManager = new UserManager();

const MenuHeader = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        currentUser,
        currentApp,
        setCurrentUser,
        setMenuType,
    } = useContext(Global);

    const handleMenuHideClick = async () => {
        setMenuType(MenuType.TOP);

        // Call the setPreference method to update LAST_THEME settings
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_MENU_TYPE,
            MenuType.TOP
        );
    };

    return (

        <div className="menu-header-container">

            <div className="menu-header-dock-up-button"
                title="Hide Menu"
                onClick={handleMenuHideClick}>
                <DockIcon
                    color={theme.foregroundColorFaded}
                    width="24"
                    height="24"
                />
            </div>

        </div>
    );
};

export default MenuHeader;
