import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';
import { ChannelType, Section } from '../../../utilities/Enums';

// Styles
import './ChannelAdd.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import ChannelTypes from '../channeltypes/ChannelTypes';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalSwitch from '../../../components/modal/ModalSwitch';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * ChannelAdd Component
 * 
 * This component allows the user to add a channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ChannelAdd = () => {
    const { theme } = useTheme();
    const {
        channelAddOpen,
        appChannels,
        currentUser,
        hideProgress,
        resetSelections,
        currentApp,
        setChannelAddOpen,
        setCurrentSection,
        setSelectedChannel,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [selectedType, setSelectedType] = useState(ChannelType.TEXT);
    const [isPrivate, setPrivate] = useState(false);

    /**
     * Handles the add of a channel.
     * 
     * @param {string} e - Click event.
     */
    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a channel title.');
            return;
        }

        showProgress("Adding channel...");

        setChannelAddOpen(false);

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const appKey = currentApp.key;

        const newKey = generateKey();  // Generate key for the new channel

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each channel to find the highest sort number
        appChannels.forEach(channel => {
            const sortNumber = channel.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const channelData = {
            key: newKey,
            appKey: appKey,
            private: isPrivate,
            sort: highestSortNumber + 1,
            title: title,
            description: description,
            type: selectedType,
            userKey: userKey,
            username: username,
            dateCreated: now,
            dateModified: now,
        };

        await dataManager.add(
            collections.channels, 
            appKey, 
            newKey, 
            channelData
        );

        resetSelections();
        setSelectedChannel(channelData);

        if (selectedType === ChannelType.TEXT) { setCurrentSection(Section.CHAT) }
        if (selectedType === ChannelType.FORUM) { setCurrentSection(Section.FORUM) }
        if (selectedType === ChannelType.VIDEO) { setCurrentSection(Section.VIDEOCHAT) }

        setTitle('');

        setDescription('');

        hideProgress();
    };

    /**
     * Handles toggle of the private setting.
     * 
     * @param {boolean} newValue - The new privacy setting (true for private, false for public).
     */
    const handleTogglePrivate = (newValue) => {
        setPrivate(newValue);
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Channel"
                isOpen={channelAddOpen}
                onClose={() => setChannelAddOpen(false)}
                width="330px">

                {/* TITLE */}
                <ModalSection>

                    <ModalLabel text="Title" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* CHANNEL TYPE */}
                <ModalSection>

                    <ModalLabel text="Channel Type" />

                    <ChannelTypes
                        selectedType={selectedType}
                        setSelectedType={setSelectedType}
                    />

                </ModalSection>

                {/* PRIVACY */}
                <ModalSection>

                    <ModalSwitch
                        label="Private (Invite Only)"
                        value={isPrivate}
                        onChange={handleTogglePrivate}
                    />

                    {/* PRIVACY DESCRIPTION */}
                    <div className="channel-add-privacy-description"
                        style={{ color: theme.foregroundColorFaded }}>
                        {isPrivate ?
                            "Only selected app members can participate." :
                            "All app members can participate."
                        }
                    </div>

                </ModalSection>

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Add Channel"
                    />

                </ModalButtons>

            </Modal>
        </>
    );
};

export default ChannelAdd;
