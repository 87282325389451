import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Screen } from '../../../common/utilities/Enums';

// Device Detection
import { isMobile } from 'react-device-detect';

// Styles
import './AppBarItem.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Components
import AppDot from '../appdot/AppDot';

const AppBarItem = ({ app, count = 0 }) => {

    // Theme
    const { 
        theme 
    } = useTheme();

    // Global
    const {
        resetSelections,
        currentApp,
        setScreen,
        setCurrentApp
    } = useContext(Global);

    /**
     * Handles click on the app dot.
     */
    const handleAppClick = async (app) => {
        resetSelections();
        setCurrentApp(app);
        setScreen(Screen.RUN);
    };

    return (
        <>
            {/* CONTAINER */}
            <div
                key={app.key}
                className="app-bar-item-container"
                onClick={() => handleAppClick(app)}
                style={{
                    borderLeftColor: app.key === currentApp.key ? theme.highlightBackgroundColor : "transparent"
                }}>

                <div className="app-bar-item-app-container"
                    title={app.title}>

                    {/* APP DOT */}
                    <div className="app-bar-item-dot">
                        <AppDot
                            app={app}
                        />
                    </div>

                    {/* COUNT */}
                    {count > 0 &&
                        <div className="app-bar-item-count"
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                borderColor: theme.backgroundColor,
                                color: theme.highlightForegroundColor
                            }}>
                            {count}
                        </div>
                    }

                </div>

            </div>
        </>
    );
};

export default AppBarItem;
