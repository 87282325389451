import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load favorites for a specific app.
 */
const usePreloadMemberFavorites = () => {

  // Global
  const { 
    currentUser,
    currentApp, 
    setFavorites 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!currentApp || !currentApp.key || !currentUser) {
      setFavorites([]);
      return;
    }

    // Function to load favorites
    const loadFavorites = (appKey) => {
      const handleUpdate = (items) => {
        setFavorites(items);
        activity.log(appKey, 'reads', items.length);
      };

      const params = [
        { field: "userKey", operator: "==", value: currentUser.userKey },
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.favorites,
        appKey,
        handleUpdate,
        params
      );

      return unsubscribe;
    };

    // Subscribe to favorites
    const unsubscribeFavorites = loadFavorites(currentApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeFavorites) {
        unsubscribeFavorites();
      }
    };
  }, [currentApp?.key, setFavorites, currentUser]);
  
};

export default usePreloadMemberFavorites;
