import React from 'react';

// Styles
import './AppBarSearch.css';

// Themes
import { useTheme } from '../../../ThemeContext';

// Images
import SearchIcon from '../../../common/svg/SearchIcon';

/**
 * AppBarSearch Component
 * 
 * This component renders an add icon for adding an app from the app bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AppBarSearch = ({ onClick }) => {
    const { theme } = useTheme();

    return (
        <>
            {/* CONTAINER */}
            <div className="app-bar-search-container"
                title="Search Notebooks">

                {/* CIRCLE */}
                <div className="app-bar-search-circle"
                    style={{
                        backgroundColor: theme.backgroundColorFaded,
                    }}
                    onClick={onClick}>

                    {/* SEARCH ICON */}
                    <SearchIcon
                        color={theme.foregroundColorFaded}
                        width="20"
                        height="20"
                    />

                </div>
            </div>
        </>
    );
};

export default AppBarSearch;
