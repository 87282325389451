/**
 * Default font.
 */
export const defaultFont = 'Urbanist';

/**
 * Default summary title field styles.
 */
export const defaultSummaryTitleStyles = {
  "color": "[foregroundColor]",
  "fontSize": "12pt",
  "fontWeight": "bold",
  "height": "24px",
  "left": "4%",
  "lineHeight": "1em",
  "textAlign": "left",
  "top": "10px",
  "width": "92%"
}