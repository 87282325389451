import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { Timestamp } from 'firebase/firestore';
import { collections } from '../../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../utilities/Keys';
import { defaultSummaryTitleStyles } from '../../../utilities/Defaults';

// Styles
import './CollectionAdd.css';

// Components
import CollectionTags from '../collectiontags/CollectionTags';
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
//import ModalSwitch from '../../../components/modal/ModalSwitch';
import ModalTextarea from '../../../components/modal/ModalTextarea';

// Managers
import DataManager from '../../../../common/managers/DataManager';
import PromptManager from '../../../../common/managers/PromptManager';

const dataManager = new DataManager();
const promptManager = new PromptManager();

/**
 * CollectionAdd Component
 * 
 * This component allows the user to add a collection.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const CollectionAdd = () => {

    // Global
    const {
        collectionAddOpen,
        currentUser,
        hideProgress,
        appCollections,
        resetSelections,
        currentApp,
        setCollectionAddOpen,
        setFormMode,
        setSelectedCollection,
        showProgress
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState([]);
    const [autogen, setAutogen] = useState(false);
    const [autopop, setAutopop] = useState(false);

    // Clear fields when the modal is opened
    useEffect(() => {
        if (collectionAddOpen) {
            setTitle('');
            setDescription('');
            setTags([]);
            setAutogen(false);
            setAutopop(false);
        }
    }, [collectionAddOpen]);

    /**
     * Adds a new collection to the database.
     */
    const handleAdd = async () => {

        if (!title) {
            alert('Please enter a collection title.');
            return;
        }

        showProgress("Adding collection...");

        setCollectionAddOpen(false);

        const userKey = currentUser.userKey;
        const username = currentUser.username;
        const appKey = currentApp.key;

        const newModelKey = generateKey();  // Generate key for the new model

        // Current timestamp
        const now = Timestamp.now();

        // Initialize the highest sort number to 0
        let highestSortNumber = 0;

        // Iterate through each model to find the highest sort number
        appCollections.forEach(model => {
            const sortNumber = model.sort;
            if (sortNumber > highestSortNumber) {
                highestSortNumber = sortNumber;
            }
        });

        const titleFieldKey = generateKey();

        // We need to create a default title field if autogen is disabled
        const titleFieldData = {
            appKey: appKey,
            key: titleFieldKey,
            title: "Title",
            description: "Title of the item.",
            type: "text",
            modelKey: newModelKey,
            modelTitle: title,
            modelDescription: "",
            options: [],
        };

        const titleFieldContentKey = generateKey();

        const titleFieldBlock = {
            id: generateKey(),
            content: [{
                id: titleFieldContentKey,
                key: titleFieldContentKey,
                title: "Title",
                field: titleFieldData,
                type: "field",
            }],
            align: 'left',
        }

        // Create the model data
        const modelData = {
            key: newModelKey,
            appKey: appKey,
            userKey: userKey,
            username: username,
            title: title,
            description: description,
            sort: highestSortNumber + 1,
            fieldSort: [titleFieldKey], // Include the title field key in the field sort order
            tags: tags,
            titleFieldKey: titleFieldKey,
            rows: [{ id: generateKey(), blocks: [titleFieldBlock] }],
            styles: defaultSummaryTitleStyles,
            dateCreated: now,
            dateModified: now,
        };

        // Add the model to the database first
        await dataManager.add(
            collections.models,
            appKey,
            newModelKey,
            modelData
        );

        // Generate default fields if autogen is enabled
        if (autogen) {
            console.log('Submitting prompt...');
            await submitModelPrompt(currentApp, modelData, []);
        } else {

            console.log('Autogen is disabled. Adding default title field...');
            console.log(modelData);
            console.log(titleFieldData);

            // Add the title field to the database
            await dataManager.add(
                collections.fields,
                appKey,
                titleFieldKey,
                titleFieldData
            );

            // Set the selected model
            setSelectedCollection(modelData);
        }

        // Reset the title field
        setTitle('');

        // Reset the description field
        setDescription('');

        // Reset the autogen field if necessary
        //setAutogen(true);

        // Hide the progress panel
        hideProgress();
    };

    const submitModelPrompt = async (app, model, appCollections) => {

        try {

            const prompt = await promptManager.prepareModelPrompt(app.title, app.description, model.title, model.description, appCollections, autopop);

            console.log('Model prompt:', prompt);

            const response = await promptManager.send(prompt);

            console.log('Model prompt response:', response);

            await promptManager.processModelResponse(
                response,
                app,
                model,
                showProgress,
                setSelectedCollection,
                setFormMode,
                resetSelections,
                currentUser
            );

        } catch (error) {
            console.error('Failed to fetch API response:', error);
        }
    };

    return (
        <>
            {/* MODAL */}
            <Modal title="New Collection"
                isOpen={collectionAddOpen}
                onClose={() => setCollectionAddOpen(false)}
                width="330px"
                height="auto">

                {/* NAME */}
                <ModalSection>

                    <ModalLabel text="Collection Name" />

                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                        autoFocus={true}
                    />

                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>

                    <ModalLabel text="Description" />

                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />

                </ModalSection>

                {/* TAGS */}
                <ModalSection>

                    <ModalLabel text="Tags" />

                    <CollectionTags
                        initialTags={tags}
                        onTagsChange={(updatedTags) => setTags(updatedTags)}
                    />

                </ModalSection>

                {/* AI FIELD GENERATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Generate Fields"
                        value={autogen}
                        onChange={() => setAutogen(!autogen)}
                    />

                </ModalSection>
                */}

                {/* AI DATA POPULATION 
                <ModalSection>

                    <ModalSwitch
                        label="Auto-Populate Data"
                        value={autopop}
                        onChange={() => setAutopop(!autopop)}
                    />

                </ModalSection>
                */}

                {/* BUTTONS */}
                <ModalButtons>

                    {/* ADD BUTTON */}
                    <ModalButton
                        onClick={handleAdd}
                        label="Continue..."
                    />

                </ModalButtons>

            </Modal >
        </>
    );
};

export default CollectionAdd;
