/**
 * Checks whether the current user has the specified permission for a model.
 *
 * @param {Object} currentApp - The selected app.
 * @param {Object} currentUser - The current user object.
 * @param {Object} model - The model object (e.g., Tasks).
 * @param {string} action - The action to check (e.g., 'create', 'read', 'update', 'delete').
 * @returns {boolean} True if the user has the permission, otherwise false.
 */
export const hasCollectionPermission = (currentApp, currentUser, model, action, record = null) => {
  if (!currentUser || !model || !action) return false;

  const userRoles = currentUser.roles || [];
  const permissions = model.permissions || {};

  // Grant full access to the app creator (using currentApp.userKey)
  if (currentApp?.userKey && currentUser.userKey === currentApp.userKey) {
      return true;
  }

  // Handle creator-only special case
  if (permissions.creatorOnly && (action === 'update' || action === 'delete')) {
      if (record?.creatorKey && record.creatorKey === currentUser.userKey) {
          return true; // Grant permission to the record creator
      }
      return false; // Deny to others if creatorOnly is enforced
  }

  // Fallback to role-based permissions
  return userRoles.some(roleKey => {
      const rolePermissions = permissions.roles?.[roleKey] || {};
      return rolePermissions[action] !== false;
  });
};

/**
 * Checks whether the current user has the specified app-level permission.
 *
 * @param {Object} currentApp - The selected app.
 * @param {Object} currentUser - The current user object.
 * @param {string} action - The app-level action to check (e.g., 'MANAGE_CHANNELS', 'MANAGE_CALENDARS', 'MANAGE_COLLECTIONS', 'MANAGE_NEWSFEEDS').
 * @returns {boolean} True if the user has the permission, otherwise false.
 */
export const hasAppPermission = (currentApp, currentUser, action) => {
    if (!currentUser || !currentApp || !action) {
        return false;
    }

    const userRoles = currentUser.roles || [];
    const permissions = currentApp.permissions || {};

    // Grant full access to the app creator
    if (currentApp?.userKey && currentUser.userKey === currentApp.userKey) {
        return true;
    }

    // Fallback to role-based permissions
    const hasPermission = userRoles.some((roleKey) => {
        const rolePermissions = permissions.roles?.[roleKey] || {};
        return rolePermissions[action] !== false; // Default to true if not explicitly false
    });

    return hasPermission;
};




