import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load conversations for a specific app.
 */
const usePreloadConversations = () => {

  // Global
  const { 
    currentApp, 
    setConversations 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!currentApp || !currentApp.key) {
      setConversations([]);
      return;
    }

    // Function to load conversations
    const loadConversations = (appKey) => {
      const handleUpdate = (items) => {
        setConversations(items);
        activity.log(appKey, 'reads', items.length);
      };

      const unsubscribe = dataManager.listAndSubscribe(
        collections.conversations,
        appKey,
        handleUpdate
      );

      return unsubscribe;
    };

    // Subscribe to conversations
    const unsubscribeConversations = loadConversations(currentApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeConversations) {
        unsubscribeConversations();
      }
    };
  }, [currentApp?.key, setConversations]);

};

export default usePreloadConversations;
