import React from 'react';

// Styles
import './Divider.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * Divider Component
 * 
 * This component renders a divider.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Divider = ({ direction = "horizontal" }) => {

  // Theme
  const { theme } = useTheme();

  return (
    <>
      {/* CONTAINER */}
      {direction === "vertical" ? (
        <div className="divider-container-vertical"
          style={{
            borderRightColor: theme.backgroundColorFaded
          }}></div>
      ) : (
        <div className="divider-container-horizontal"
          style={{
            borderBottomColor: theme.backgroundColorFaded
          }}></div>
      )}
    </>
  );
};

export default Divider;

