import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { UserPreference } from '../../common/utilities/Enums';

// Theme
import { useTheme } from '../../ThemeContext';

// Managers
import UserManager from '../../common/managers/UserManager';
const userManager = new UserManager();

/**
 * Custom hook to load a user's theme for the selected app.
 */
const useLoadThemePreference = () => {

  // Theme
  const {
    setCurrentThemeMode,
  } = useTheme();

  // Global
  const { 
    currentUser,
    currentApp, 
  } = useContext(Global);

  useEffect(() => {
    if (!currentApp) return;

    function loadThemePreference() {

      const lastTheme = userManager.getPreference(
        currentUser,
        currentApp.key,
        UserPreference.LAST_THEME
      );

      if (lastTheme) {
        setCurrentThemeMode(lastTheme);
      }
    }

    loadThemePreference();

  }, [currentApp]);
  
};

export default useLoadThemePreference;
