import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './AccountFormLabel.css';

// Theme
import { useTheme } from '../../../ThemeContext';

/**
 * AccountFormLabel Component
 * 
 * This component renders a button to save changes to the user account.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const AccountFormLabel = ({ label }) => {
  const { theme } = useTheme();
  const {
    currentApp,
    coreTheme
  } = useContext(Global);

  return (

    <div className="account-form-label-container"
        style={{ color: currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded}}>
        {label}
      </div>

  );
};

export default AccountFormLabel;
