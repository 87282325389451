import React, { useState } from 'react';

// Styles
import './MenuBarAddButton.css';

// Theme
import { useTheme } from '../../../ThemeContext';
// Images
import PlusIcon from '../../svg/PlusIcon';

/**
 * MenuBarAddButton Component
 * 
 * This component represents a button to add an item.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const MenuBarAddButton = ({ onClick }) => {

    // Theme
    const { theme } = useTheme();

    const [hovered, setHovered] = useState(null);

    return (
        <div
            className="menu-bar-add-button"
            onClick={onClick}
            onMouseEnter={() => setHovered(true)}  // Set hover
            onMouseLeave={() => setHovered(false)}   // Reset hover
            style={{
                borderColor: theme.backgroundColorFaded,
                color: theme.foregroundColor,
                backgroundColor: hovered ? theme.highlightBackgroundColor : 'transparent'
            }}>
            <div className="menu-bar-add-button-icon">
                <PlusIcon
                    color={hovered ? theme.highlightForegroundColor : theme.foregroundColorFaded}
                    width="13"
                    height="13"
                />
            </div>
            <div className="menu-bar-add-button-item">
                <div
                    className="menu-bar-add-button-title"
                    style={{
                        color: hovered ? theme.highlightForegroundColor : theme.foregroundColor
                    }}>
                    Add
                </div>
            </div>
        </div>
    );
};

export default MenuBarAddButton;
