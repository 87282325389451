import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Firebase
import { collections } from '../../../../firebaseConfig';

// Styles
import './RoleEdit.css';

// Components
import Modal from '../../../components/modal/Modal';
import ModalButton from '../../../components/modal/ModalButton';
import ModalButtons from '../../../components/modal/ModalButtons';
import ModalInput from '../../../components/modal/ModalInput';
import ModalLabel from '../../../components/modal/ModalLabel';
import ModalSection from '../../../components/modal/ModalSection';
import ModalTextarea from '../../../components/modal/ModalTextarea';
import ModalSelect from '../../../components/modal/ModalSelect';

// Managers
import DataManager from '../../../managers/DataManager';

const dataManager = new DataManager();

/**
 * RoleEdit Component
 * 
 * This component allows the user to edit a role and assign a parent role.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const RoleEdit = ({ role, isOpen, setOpen }) => {

    // Global
    const {
        appRoles,
        currentApp,
    } = useContext(Global);

    // Local State
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [parentKey, setParentKey] = useState('');
    const [otherRoles, setOtherRoles] = useState([]);

    // Load the role attributes
    useEffect(() => {
        if (!role) return;

        setTitle(role.title);
        setDescription(role.description || '');
        setParentKey(role.parentKey || '');

        // Filter roles to exclude the current role
        const filteredRoles = appRoles.filter(r => r.key !== role.key);
        setOtherRoles(filteredRoles);
    }, [role, appRoles]);

    const handleSave = async () => {
        const data = {
            title: title,
            description: description,
            parentKey: parentKey || null, // Save null if no parent role is selected
        };

        await dataManager.update(
            collections.roles, 
            currentApp.key, 
            role.key, 
            data
        );

        // Clear the form
        setTitle('');
        setDescription('');
        setParentKey('');

        setOpen(false);
    };

    if (!role) return null;

    // Map roles to options for ModalSelect
    const roleOptions = otherRoles.map(otherRole => ({
        key: otherRole.key,
        title: otherRole.title,
    }));

    return (
        <>
            {/* MODAL */}
            <Modal
                title="Edit Role"
                isOpen={isOpen}
                onClose={() => setOpen(false)}
                width="330px"
                height="auto"
            >
                {/* NAME */}
                <ModalSection>
                    <ModalLabel text="Name" />
                    <ModalInput
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        maxLength="100"
                    />
                </ModalSection>

                {/* DESCRIPTION */}
                <ModalSection>
                    <ModalLabel text="Description" />
                    <ModalTextarea
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        maxLength="255"
                        lines="2"
                    />
                </ModalSection>

                {/* PARENT */}
                {!role.isCreator && (
                    <ModalSection>
                        <ModalLabel text="Parent" />
                        <ModalSelect
                            options={roleOptions}
                            value={parentKey}
                            onChange={(key) => setParentKey(key)}
                        />
                    </ModalSection>
                )}

                {/* BUTTONS */}
                <ModalButtons>
                    <ModalButton
                        onClick={handleSave}
                        label="Save"
                    />
                </ModalButtons>
            </Modal>
        </>
    );
};

export default RoleEdit;
