import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../Global';

// Utilities
import { LoginMode } from '../../common/utilities/Enums';

// Firebase
import { doc, setDoc } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, collections, db } from '../../firebaseConfig';

// Styles
import './Signup.css';

// Components
import AccountForm from '../components/accountform/AccountForm';

import SignupButton from './SignupButton';
import SignupLink from './SignupLink';

// Managers
import InviteManager from '../../common/managers/InviteManager';
import NotificationManager from '../../common/managers/NotificationManager';
import UserManager from '../../common/managers/UserManager';

const inviteManager = new InviteManager();
const notificationManager = new NotificationManager();
const userManager = new UserManager();

/**
 * Signup Component
 * 
 * This component renders a signup form.
 * 
 * @returns {JSX.Element} The rendered component.
 */
function Signup() {

  // Global
  const {
    hideProgress,
    invite,
    setCurrentUser,
    setLoginMode,
    setScreen,
    setCurrentApp,
    showProgress
  } = useContext(Global);

  // Local State
  const [email, setEmail] = useState(''); // The editable email field
  const [username, setUsername] = useState(''); // Username (required)
  const [fullName, setFullName] = useState(''); // Full Name (optionally required)
  const [displayName, setDisplayName] = useState(''); // Display Name (optionally required)
  const [handle, setHandle] = useState(''); // Handle (optionally required)
  const [password, setPassword] = useState(''); // Password (required)

  // Validation States
  const [formValid, setFormValid] = useState(false);
  const [submitEnabled, setSubmitEnabled] = useState(false);

  /**
   * Enable the submit button only if all required fields are valid.
   */
  useEffect(() => {
    setSubmitEnabled(formValid);
  }, [formValid]);

  /**
   * Method to handle submit of the signup form.
   * 
   * @param {string} e - Click event.
   */
  const handleSubmit = async (e) => {
    try {

      showProgress("Signing up...");

      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      const data = {
        key: user.uid,
        username,
        email,
        fullname: fullName,
        displayname: displayName,
        handle,
        dateCreated: new Date(),
      };

      await setDoc(doc(db, collections.users, user.uid), data);

      const combinedUser = await userManager.fetchCurrentUser(auth.currentUser);
      setCurrentUser(combinedUser);

      // Request notification permission after successful login
      await notificationManager.requestNotificationPermission(combinedUser);

      // If an invite exists, create an app user
      if (invite) {

        await inviteManager.processInvite(combinedUser, invite, setCurrentApp, setScreen);

      } else {

        console.log("No invite exists after signing up.");

        // Since there wasn't an invite present and no app specified, navigate to the 
        // home screen.
        setScreen(Screen.HOME);
      }

      hideProgress();

    } catch (error) {
      console.error('Failed to create an account:', error.message);
    }
  };

  /**
   * Handles click on the login link.
   */
  const handleLoginClick = async () => {
    setLoginMode(LoginMode.LOGIN);
  };

  /**
   * Method to handle submit of the signup form.
   * 
   * @param {string} e - Click event.
   */
  const handleFormChange = async (
    username,
    email,
    fullName,
    displayName,
    handle,
    password,
    isValid
  ) => {
    setUsername(username);
    setEmail(email);
    setFullName(fullName);
    setDisplayName(displayName);
    setHandle(handle);
    setPassword(password);
    setFormValid(isValid);
  };

  return (
    <div className="signup-container">

      {/* FORM */}
      <AccountForm
        onChange={handleFormChange}
      />

      {/* SUBMIT */}
      <SignupButton onClick={handleSubmit} enabled={submitEnabled} />

      {/* SIGN-IN LINK */}
      <SignupLink onClick={handleLoginClick} />

    </div>
  );
}

export default Signup;
