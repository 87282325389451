import React, { useContext, useEffect, useState, useRef } from 'react';

// Global context and styles
import { Global } from '../../../../Global';

// Styles
import './CalendarSchedule.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Components
import AddButton from '../../../../common/components/addbutton/AddButton';
import CalendarScheduleEvent from './CalendarScheduleEvent';

const CalendarSchedule = () => {
    const { theme } = useTheme();
    const {
        activeDate,
        appEvents,
        selectedCalendar,
        selectedCalendarTags,
        selectedCalendarType,
        setEventVisible,
        setSelectedEvent,
        setSelectedTime
    } = useContext(Global);

    // State to hold sorted and grouped events
    const [displayEvents, setDisplayEvents] = useState([]); // Filtered events for display
    const [groupedEvents, setGroupedEvents] = useState({});
    
    // References
    const containerRef = useRef(null);

    // Filters today's events by tag
    useEffect(() => {
        if (appEvents && selectedCalendar) {
            // Filter the events based on selectedCalendarTags
            const filteredEvents = appEvents.filter(event => {
                // Check if the event's calendarKey matches the selected calendar key
                if (event.calendarKey !== selectedCalendar.key) {
                    return false;
                }
                // If no tags are selected, display all events
                if (selectedCalendarTags.length === 0) {
                    return true;
                }

                // Check if any of the event's tags are in selectedCalendarTags
                return event.tags.some(tag => selectedCalendarTags.includes(tag));
            });

            // Set the filtered events to display
            setDisplayEvents(filteredEvents);
        }
    }, [appEvents, selectedCalendar, selectedCalendarTags]);

    useEffect(() => {
        if (displayEvents) {
            // Sort events by startDate
            const sorted = [...displayEvents].sort((a, b) => a.startDate.toMillis() - b.startDate.toMillis());

            // Group events by day
            const grouped = sorted.reduce((groups, event) => {
                const eventDate = event.startDate.toDate();
                const dateKey = eventDate.toLocaleDateString(undefined, { year: 'numeric', month: 'long', day: 'numeric' });

                if (!groups[dateKey]) {
                    groups[dateKey] = [];
                }
                groups[dateKey].push(event);
                return groups;
            }, {});

            setGroupedEvents(grouped);
        }
    }, [displayEvents]);

    useEffect(() => {
        if (containerRef.current && Object.keys(groupedEvents).length > 0) {
            // Find the closest date to today
            const today = new Date();
            const sortedDates = Object.keys(groupedEvents).sort((a, b) => {
                const dateA = new Date(a);
                const dateB = new Date(b);
                return dateA - dateB;
            });

            let targetDateIndex = sortedDates.findIndex(date => new Date(date) >= today);

            if (targetDateIndex === -1) {
                // If no future date found, use the last date
                targetDateIndex = sortedDates.length - 1;
            }

            // Scroll to the target date
            const targetElement = containerRef.current.children[targetDateIndex];
            if (targetElement) {
                const targetPosition = targetElement.offsetTop - containerRef.current.clientHeight / 2 + targetElement.clientHeight / 2;
                containerRef.current.scrollTo({
                    top: targetPosition,
                    behavior: 'smooth'
                });
            }
        }
    }, [groupedEvents]);

    const handleEventClick = (eventKey) => {
        const event = Object.values(groupedEvents).flat().find(e => e.key === eventKey);
        setSelectedEvent(event);
        setEventVisible(true);
    }

    const handleAddClick = () => {
        // Clear the currently selected event
        setSelectedEvent(null);

        // Create a new date object from activeDate
        const selected = new Date(activeDate.getTime());
        selected.setHours(0);
        selected.setMinutes(0);
        selected.setSeconds(0);
        setSelectedTime(selected);

        // Display the event form
        setEventVisible(true);
    }

    return (
        <div className="calendar-schedule-container" ref={containerRef}>
            {Object.keys(groupedEvents).map((date, index) => (
                <div key={index} className="calendar-schedule-day">
                    <div className="calendar-schedule-date"
                        style={{
                            color: theme.foregroundColorFaded
                        }}>
                        {date}
                    </div>
                    {groupedEvents[date].map((event, idx) => (
                        <div
                            key={event.key} // Ensure the key is unique for each event
                            className="calendar-schedule-event"
                            onClick={() => handleEventClick(event.key)}
                            style={{
                                backgroundColor: theme.highlightBackgroundColor,
                                borderColor: theme.backgroundColor
                            }}>
                            <CalendarScheduleEvent event={event} index={idx} />
                        </div>
                    ))}
                </div>
            ))}

            {/* ADD BUTTON */}
            {(selectedCalendarType === 'SCHEDULE' || selectedCalendarType === 'STORYLINE') &&
                <div className="calendar-storyline-add-button">
                    <AddButton onClick={handleAddClick} />
                </div>
            }

        </div>
    );
};

export default CalendarSchedule;
