import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../../Global';

// Utilities
import { getElapsedTime } from '../../../utilities/DateTime';

// Styles
import './ChatPosts.css';

// Theme
import { useTheme } from '../../../../ThemeContext';

// Images
import RemoveIcon from '../../../svg/RemoveIcon';

// Components
import ChatPost from '../chatpost/ChatPost';

// Managers
import ChatPostManager from '../../../managers/ChatPostManager';

const chatPostManager = new ChatPostManager();

const ChatPosts = () => {
  const { theme } = useTheme();
  const {
    editingPosts,
    currentApp,
    selectedChannel,
  } = useContext(Global);

  // Local State
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    if (!currentApp || !selectedChannel) return;

    const handleUpdate = (items) => {
      setPosts(items);
    };

    const unsubscribe = chatPostManager.fetchAndSubscribe(currentApp.key, selectedChannel.key, handleUpdate);

    return () => unsubscribe();

  }, [currentApp?.key, selectedChannel, setPosts]);

  const handleUserClick = async (userKey) => {
    /*
    const member = await memberManager.fetchMember(userKey, currentApp.key);
    resetSelections();
    setSelectedMember(member);
    */
  };

  const handleRemove = (post) => {
    chatPostManager.delete(post.key);
  };

  const getTimestamp = (post) => {
    return <span
      className="chat-posts-post-timestamp"
      style={{
        color: theme.foregroundColorFaded
      }}
    >
      {getElapsedTime(post.dateCreated)}
    </span>
  };

  return (
    <>
      {/* CONTAINER */}
      <div className="chat-posts-container">
  
        {/* LIST */}
        {posts.map((post) => (

          <React.Fragment key={post.key}>

            {/* LEFT SIDE */}
            <div className="chat-posts-post-wrapper">
  
              <div
                className="chat-posts-post"
                style={{
                  color: theme.foregroundColorFaded
                }}>
  
                {/* POST */}
                <ChatPost
                  post={post}
                  handleUserClick={handleUserClick}
                  timestamp={getTimestamp(post)}
                />
  
              </div>
  
              {/* REMOVE BUTTON */}
              {editingPosts &&
                <div className="chat-posts-post-remove-container" onClick={() => handleRemove(post)}>
                  <RemoveIcon
                    color={theme.foregroundColorFaded}
                    width="16"
                    height="16"
                  />
                </div>
              }
            </div>
  
            {/* DIVIDER */}
            <div
              className="chat-posts-divider"
              style={{
                borderBottomColor: theme.backgroundColorFaded
              }}></div>

          </React.Fragment>
          
        ))}
      </div>
    </>
  );
  
};

export default ChatPosts;
