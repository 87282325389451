import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './NewsfeedMenu.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DeleteIcon from '../../svg/DeleteIcon';
import SettingsIcon from '../../svg/SettingsIcon';

// Components
import NewsfeedDelete from '../../menu/newsfeeds/newsfeeddelete/NewsfeedDelete';
import NewsfeedEdit from '../../menu/newsfeeds/newsfeededit/NewsfeedEdit';
import ContextMenuButton from '../../components/contextmenu/ContextMenuButton';
import ContextMenuGroup from '../../components/contextmenu/ContextMenuGroup';
import ContextMenuOption from '../../components/contextmenu/ContextMenuOption';
import ContextMenuPopup from '../../components/contextmenu/ContextMenuPopup';

const NewsfeedMenu = () => {

  // Theme
  const { theme } = useTheme();

  // Global
  const {
    selectedNewsfeed,
  } = useContext(Global);

  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setConfirmOpen] = useState(false);

  /**
   * Toggles visibility of the context menu.
   */
  const toggleMenu = (event) => {
    if (!menuVisible) {
      event.preventDefault();
      setMenuPosition({ x: event.clientX, y: event.clientY });
      setMenuVisible(true);
    } else {
      setMenuVisible(false);
    }
  };

  const handleDeleteClick = (event) => {
      event.stopPropagation();
      setMenuVisible(false);
      setConfirmOpen(true);
  };

  const handleEditClick = (event) => {
      event.stopPropagation();
      setMenuVisible(false);
      setEditOpen(true);
  };

  if (!selectedNewsfeed) return null;

  return (
    <div className="newsfeed-menu-outer-container">

      {/* MENU BUTTON */}
      <div className="newsfeed-menu-container">
        <ContextMenuButton
          title="Newsfeed Options"
          onToggle={toggleMenu}
        />
      </div>

      {/* MENU */}
      <ContextMenuPopup
        visible={menuVisible}
        setVisible={setMenuVisible}
        backgroundColor={theme.backgroundColorFaded}
        width={140}
        position={menuPosition}>

        <ContextMenuGroup title="Newsfeed">

            <ContextMenuOption
              key="SETTINGS"
              icon={SettingsIcon}
              text="Settings"
              onClick={handleEditClick}
            />

            <ContextMenuOption
              key="DELETE"
              icon={DeleteIcon}
              text="Delete"
              onClick={handleDeleteClick}
            />

        </ContextMenuGroup>

      </ContextMenuPopup>

      <NewsfeedDelete
          newsfeed={selectedNewsfeed}
          isOpen={deleteOpen}
          setOpen={setConfirmOpen}
      />

      {/* CALENDAR EDIT */}
      <NewsfeedEdit 
          newsfeed={selectedNewsfeed}
          isOpen={editOpen}
          setOpen={setEditOpen}
      />

    </div>
  );
};

export default NewsfeedMenu;
