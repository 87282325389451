import React from 'react';

// Styles
import './AppAddFeatures.css';

// Theme
import { useTheme } from '../../../ThemeContext.js';

// Components
import AppAddFeaturesItem from './AppAddFeaturesItem.js';

const AppAddFeatures = ({
    potentialModels,
    selectedCollections,
    potentialCalendars,
    selectedCalendars,
    potentialChannels,
    selectedChannels,
    onToggleSelection
}) => {

    // Theme
    const { theme } = useTheme();

    const isSelected = (list, item) =>
        list.some((selectedItem) => selectedItem.key === item.key);

    return (
        <div className="app-add-features-container">
            {/* Hint */}
            <div className="app-add-features-hint"
                style={{
                    borderColor: theme.backgroundColorFaded,
                    color: theme.foregroundColor
                }}>
                Select the features you would like, and we will automatically include 
                them. You may customize features at any time.
            </div>

            {/* Models */}
            {potentialModels.map((model) => (
                <AppAddFeaturesItem
                    key={model.key}
                    title={model.title}
                    description={model.description}
                    isChecked={isSelected(selectedCollections, model)}
                    onCheck={(isChecked) =>
                        onToggleSelection('model', model, isChecked)
                    }
                />
            ))}

            {/* Calendars */}
            {potentialCalendars.map((calendar) => (
                <AppAddFeaturesItem
                    key={calendar.key}
                    title={calendar.title}
                    description={calendar.description}
                    isChecked={isSelected(selectedCalendars, calendar)}
                    onCheck={(isChecked) =>
                        onToggleSelection('calendar', calendar, isChecked)
                    }
                />
            ))}

            {/* Channels */}
            {potentialChannels.map((channel) => (
                <AppAddFeaturesItem
                    key={channel.key}
                    title={channel.title}
                    description={channel.description}
                    isChecked={isSelected(selectedChannels, channel)}
                    onCheck={(isChecked) =>
                        onToggleSelection('channel', channel, isChecked)
                    }
                />
            ))}
        </div>
    );
};

export default AppAddFeatures;

