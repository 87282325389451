import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Utilities
import { LoginMode } from '../common/utilities/Enums';

// Styles
import './Welcome.css';

// Theme
import { useTheme } from '../ThemeContext';

// Components
import Login from '../account/login/Login';
import LogoLarge from '../common/logolarge/LogoLarge';
import Signup from '../account/signup/Signup';

const Welcome = () => {
  const { theme } = useTheme();
  const {
    loginMode,
    currentApp,
    coreTheme
  } = useContext(Global);

  return (
    <>
      {/* CONTAINER */}
      <div className="welcome-container"
        style={{ backgroundColor: currentApp ? theme.backgroundColor : coreTheme.backgroundColor }}>

        {/* CENTER */}
        <div className="welcome-center">
            
            {/* WELCOME LOGIN CONTAINER */}
          <div className="welcome-login-container">

            {/* LEFT */}
            <div className="welcome-left">

              {/* LOGO */}
              <div className="welcome-logo-container">
                <LogoLarge />
              </div>

            </div>

            {/* DIVIDER */}
            <div className="welcome-divider"
              style={{
                backgroundColor: currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded
              }}></div>

            {/* RIGHT */}
            <div className="welcome-right">

              {/* FORM WRAPPER */}
              <div className="welcome-form-wrapper">
                {loginMode === LoginMode.LOGIN ? (
                  <Login />
                ) : (
                  <Signup />
                )}
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
