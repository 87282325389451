// Managers
import PromptManager from './PromptManager';

const promptManager = new PromptManager();

class ChannelManager {

    /**
     * Generates a list of channels for a new app with the provided app title and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable list of models that can individually be populated with fields
     *          in subsequent prompts.
     */
    async generateChannelList(appTitle, appDescription, temperature) {

        const channelListPrompt = await this.prepareChannelListPrompt(appTitle, appDescription);

        const response = await promptManager.sendJsonPrompt(channelListPrompt, temperature);

        return response.channels;
    };

    /**
     * Prepares a prompt for generating a list of channels for an app based on an app's title 
     * and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * 
     * @returns Prompt that can be used to generate a list of channels.
     */
    async prepareChannelListPrompt(appTitle, appDescription) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        The goal of your response is to define a list of channels that might be useful based upon the title
        and description of the app. 

        There are three types of channels in the system: TEXT, FORUM, and VIDEO. These channels are similar to Discord channels. 
        Please provide a list of titles for pre-built channels that might be useful for users the app.

        There is no need to create models related to appointment scheduling as those will be handled separately.

        If nothing is applicable, you can simply return an empty list.

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Do not add comments or characters that might lead to the inability to parse the JSON.

        [APP TITLE AND DESCRIPTION]
        TITLE: ${appTitle}
        DESCRIPTION: ${appDescription}

        Do not add any nesting, and do not structure the response differently from the example.

        [EXAMPLE RESPONSE]
        {
            "channels": [
                {
                    "title": "Customer Support",
                    "description": "A place to post your questions and get answers.",
                    "type": "FORUM",
                },
                {
                    "title": "General Chat",
                    "description": "Connect with others and chat about anything.",
                    "type": "TEXT",
                }
            ]
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }

}

export default ChannelManager;
