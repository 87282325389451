import React, { useContext, useEffect, useRef, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Firebase
import { collections } from '../../../firebaseConfig';

// Utilities
import { generateKey } from '../../../common/utilities/Keys';

// Styles
import './FieldSelector.css';

// Theme
import { useStyle, useTheme } from '../../../ThemeContext';

// Components
import FieldSelectorHeader from './FieldSelectorHeader';
import FieldPreview from './FieldPreview';

// Managers
import DataManager from '../../../common/managers/DataManager';

const dataManager = new DataManager();

// Styled Components
const InlineStyles = useStyle`
    .field-selector-field:hover {
        border-color: ${(props) => props.hoverColor} !important;
    }
`;

const FieldSelector = ({ size = "SMALL", customOptions = [] }) => {
    const { theme } = useTheme();
    const {
        fieldFilter,
        fieldSelectorVisible,
        appCollections,
        currentApp,
        selectedCollection,
        setFieldSelectorVisible,
        setSelectedField,
    } = useContext(Global);

    // Local State
    const [isAnimating, setIsAnimating] = useState(false);
    const [options, setOptions] = useState([]);

    // References
    const prevCustomOptionsRef = useRef();

    useEffect(() => {
        if (JSON.stringify(prevCustomOptionsRef.current) !== JSON.stringify(customOptions)) {
            if (customOptions.length > 0) {
                setOptions(customOptions);
            } else {
                const dynamicChecklists = appCollections.map(model => ({
                    value: 'objectchecklist',
                    name: `${model.title} Checklist`,
                    checklistModelKey: model.key, // Additional data to pass to the field
                    description: `Enables creation of checklists related to ${model.title}.`
                }));

                setOptions([
                    { value: 'videogallery', name: 'Video', description: `Allows you to upload and display a collection of videos related to ${selectedCollection.title}.` },
                    { value: 'comments', name: 'Comments', description: `Lets users have conversations about ${selectedCollection.title}.` },
                    { value: 'text', name: 'Text', description: `Stores a single line of text information about ${selectedCollection.title}.` },
                    { value: 'gallery', name: 'Photos', description: `Stores a photo gallery related to specific ${selectedCollection.title}.` },
                    { value: 'documents', name: 'Documents', description: `Stores documents related to ${selectedCollection.title}.` },
                    { value: 'checkbox', name: 'Checkbox', description: `Keeps track of true/false information.` },
                    { value: 'barcode', name: 'Barcode', description: `Enables scanning of SKU barcodes with a phone camera.` },
                    { value: 'checklist', name: 'Checklist', description: `Enables creation of checklists related to a specific record.` },
                    { value: 'countries', name: 'Country', description: 'Allow selection from a pre-populated list of countries.' },
                    { value: 'currency', name: 'Currency', description: 'Enforce currency formatting when typing.' },
                    { value: 'date', name: 'Date', description: 'Displays a date popup.' },
                    { value: 'day', name: 'Day', description: 'Allow selection from a list of weekdays.' },
                    { value: 'number', name: 'Number', description: 'Enforces number formatting when users enter data into the model.' },
                    { value: 'location', name: 'Location', description: 'Allows users to enter location information.' },
                    { value: 'lookup', name: 'List', description: 'Lets users choose an item from a list of options that you configure.' },
                    { value: 'month', name: 'Month', description: 'Allows users to select an option from a list of months.' },
                    { value: 'monthyear', name: 'Month/Year', description: 'Lets users select an applicable month and year.' },
                    { value: 'password', name: 'Password', description: 'Applies password masking and allows users to show/hide the password.' },
                    { value: 'phone', name: 'Phone Number', description: 'Applies consistent phone number formatting as users enter information.' },
                    
                    // Dynamically create relationships options
                    ...appCollections.map(model => ({
                        value: 'relationships', 
                        name: model.title, 
                        description: `Enables the linking of ${model.key === selectedCollection.key ? 'other' : ''} ${model.title}.`,
                        modelKey: model.key
                    })),
                
                    { value: 'states', name: 'State', description: 'Lets users choose from a list of pre-defined states in the United States.' },
                    { value: 'time', name: 'Time', description: `Allows users to define a time to associate with the record.` },
                    { value: 'userlist', name: 'User List', description: 'Allows users to select other app users to associate with an item.' },
                    { value: 'year', name: 'Year', description: 'Allows users to select an option from a list of years.' },
                    ...dynamicChecklists,
                ]);                
            }
            prevCustomOptionsRef.current = customOptions;
        }
    }, [customOptions, appCollections, selectedCollection.title]);

    const filterOptions = (options, filter) => {
        if (!filter) {
            return options;
        }

        const lowerCaseFilter = filter.toLowerCase();
        return options.filter(option =>
            option.name.toLowerCase().includes(lowerCaseFilter)
        );
    };

    const filteredOptions = filterOptions(options, fieldFilter);

    const handleAdd = async (val, checklistModelKey) => {

        let selectedType = null;
        let fieldType = val;
        let relatedModelKey = null;

        // Special case for ObjectChecklist
        if (val === 'objectchecklist' && checklistModelKey !== undefined) {
            
            // Find the selected type using the checklistModelKey (there will be multiple objectchecklist types)
            selectedType = options.find(type => (type.value === val) && (type.checklistModelKey === checklistModelKey));

        } else 

        if (val === 'relationships') {
            // Special case for Relationships
            
            // Find the selected type
            selectedType = options.find(type => type.value === val);
            relatedModelKey = selectedType.modelKey;

        } else { 
            // Standard field creation
            
            // Find the selected type
            selectedType = options.find(type => type.value === val);

        }

        // Set the field title
        const fieldTitle = selectedType.name;
        
        try {

            const fieldKey = generateKey();

            if (checklistModelKey === undefined) checklistModelKey = "";

            const newField = {
                appKey: currentApp.key,
                key: fieldKey,
                modelKey: selectedCollection.key,
                title: fieldTitle,
                relatedModelKey: relatedModelKey,
                type: fieldType,
                checklistModelKey: checklistModelKey,
            };

            // Add the field to the database
            await dataManager.add(
                collections.fields,
                currentApp.key, 
                fieldKey, 
                newField
            );

            // Add the field key to the model.fieldSort array
            const updatedModel = { ...selectedCollection, fieldSort: [...selectedCollection.fieldSort, fieldKey] };
            await dataManager.update(
                collections.models, 
                currentApp.key, 
                selectedCollection.key, 
                updatedModel
            );

            setSelectedField(newField);
            setFieldSelectorVisible(false);
        } catch (error) {
            console.error('Error adding document:', error);
        }
    };

    useEffect(() => {
        if (fieldSelectorVisible) {
            // Trigger the animation
            setIsAnimating(true);
        } else {
            setIsAnimating(false);
        }
    }, [fieldSelectorVisible]);

    if (!fieldSelectorVisible && !isAnimating) return null;

    return (
        <>
            <InlineStyles
                hoverColor={theme.highlightBackgroundColor}
            />
            <div className="field-selector-container"
                style={{
                    borderRightColor: theme.backgroundColorFaded,
                    borderTopColor: theme.backgroundColorFaded,
                    opacity: isAnimating ? 1 : 0, transition: 'opacity 300ms'
                }}>
                <FieldSelectorHeader />
                <div className={isMobile ? "field-selector-fields-mobile" : "field-selector-fields"}>
                    {filteredOptions.map((option, index) => (
                        <div key={index}
                            className="field-selector-field"
                            style={{
                                borderColor: theme.backgroundColorFaded
                            }}
                            onClick={() => handleAdd(option.value, option.checklistModelKey)}>
                            {option &&
                                <FieldPreview
                                    type={option.value}
                                    title={option.name}
                                    description={option.description}
                                    size={size}
                                />
                            }
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default FieldSelector;
