import React, { useContext, useRef } from 'react';

// Global
import { Global } from '../../../Global';

// Styles
import './UserPhotoSummary.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import UserThumb from '../../../common/components/userthumb/UserThumb';

const UserPhotoSummary = ({ element, object }) => {

    // Theme
    const { getProcessedStyles } = useTheme();

    // Global
    const {
      members
    } = useContext(Global);
    const containerRef = useRef(null);

    const { styles = {} } = element;

    // Filter out position and size styles handled by parent
    const { top, left, width, height, zIndex, ...filteredStyles } = styles;

    const processed = getProcessedStyles(filteredStyles);

    const member = members.find(member => member.userKey === object.userKey);

    return (
        <div
            className="user-photo-summary-container"
            ref={containerRef}
            style={{ ...processed, height }}>
            <UserThumb 
                user={member} 
                size="24"
            />
        </div>
    );
};

export default UserPhotoSummary;
