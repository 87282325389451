import React, { useContext, useEffect, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { FormMode, Permission, Section, UserPreference } from '../../utilities/Enums';
import { hasAppPermission, hasCollectionPermission } from '../../utilities/Permissions';

// Styles
import './Collections.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import CollectionAddButton from './collectionaddbutton/CollectionAddButton';
import CollectionAdd from './collectionadd/CollectionAdd';
import CollectionItem from './collectionitem/CollectionItem';

// Managers
import UserManager from '../../../common/managers/UserManager';

const userManager = new UserManager();

/**
 * Collections Component
 * 
 * This component renders a collection-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Collections = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        appCollections,
        currentApp,
        currentUser,
        resetSelections,
        selectedCollection,
        setCollectionAddOpen,
        setCurrentSection,
        setCurrentUser,
        setFormMode,
        setMenuVisible,
        setSelectedCollection,
    } = useContext(Global);

    /**
     * Handles a collection click event.
     * 
     * @param {object} collection - Collection that was clicked on.
     */
    const handleCollectionClick = async (model) => {
        resetSelections();
        
        setFormMode(null);
        
        setCurrentSection(Section.COLLECTIONS)
        setSelectedCollection(model);

        // Set the last screen preference
        const screen = {
            section: 'COLLECTIONS',
            modelKey: model.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );
    };

    /**
     * Handles an add collection button click event.
     */
    const handleAddCollectionClick = () => {
        setCollectionAddOpen(true);
        setMenuVisible(false);
    };

    return (
        <>
            {/* TITLE */}
            <div className="collections-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: currentApp.fontFamily
                }}>
                Collections

                {/* ADD COLLECTION BUTTON */}
                {currentApp && hasAppPermission(currentApp, currentUser, Permission.MANAGE_COLLECTIONS) &&
                    <CollectionAddButton
                        onClick={handleAddCollectionClick}
                    />
                }
            </div>

            {/* COLLECTION LIST */}
            <div className="collections-group">

                {/* COLLECTIONS */}
                {appCollections.map(model => (
                    <React.Fragment key={model.key}>
                        {hasCollectionPermission(currentApp, currentUser, model, Permission.READ) &&
                            <CollectionItem
                                key={model.key}
                                model={model}
                                title={model.title}
                                isActive={selectedCollection && selectedCollection.key === model.key}
                                onClick={() => handleCollectionClick(model)} />
                        }
                    </React.Fragment>
                ))}

                {/* FORM */}
                <CollectionAdd />

            </div>

        </>
    );
};

export default Collections;
