import React, { useContext, useState, useEffect } from 'react';

// Global
import { Global } from '../../../../Global';

// Firestore
import { Timestamp } from 'firebase/firestore';

// Utilities
import { getEventTimespan } from '../../../utilities/Events';

// Styles
import './CalendarDayEvent.css';

// Theme
import { useStyle, useTheme } from '../../../../ThemeContext';

// Managers
import EventManager from '../../../../common/managers/EventManager';

const eventManager = new EventManager();

const InlineStyles = useStyle`
  .calendar-day-event-fade {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 30%;
    background: linear-gradient(to bottom, transparent, ${(props) => props.gradientColor});
    z-index: 1;
  }
`;

const CalendarDayEvent = ({ event, index, leftOffset, useDrag }) => {
  const { theme } = useTheme();
  const {
    appEvents,
    currentApp,
    setEventVisible,
    setSelectedEvent
  } = useContext(Global);

  // Local State
  const [resizeHeight, setResizeHeight] = useState(null);
  const [isResizing, setIsResizing] = useState(false);

  let overlapIndent = 30;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'event',
    item: { event },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const handleEventClick = (event) => {
    setSelectedEvent(event);
    setEventVisible(true);
  };

  const handleResizeStart = (e) => {
    e.preventDefault();
    setIsResizing(true);
  };

  useEffect(() => {
    const handleResize = (e) => {
      if (isResizing) {
        const eventContainer = document.querySelector('.calendar-day-event-container');
        if (!eventContainer) return;

        const eventRect = eventContainer.getBoundingClientRect();
        const newHeight = e.clientY - eventRect.top;
        console.log("e.clientY", e.clientY);
        console.log("eventRect.top", eventRect.top);
        console.log("newHeight", newHeight);
        setResizeHeight(newHeight);
      }
    };

    const handleResizeEnd = async () => {
      if (isResizing) {
        setIsResizing(false);

        // Calculate the nearest 15-minute interval for the end time
        const startTime = event.startDate.toDate();
        const hoursAdded = resizeHeight / 70;
        const endTime = new Date(startTime.getTime() + hoursAdded * 60 * 60 * 1000);

        // Snap to the nearest 15-minute interval
        const roundedMinutes = Math.round(endTime.getMinutes() / 15) * 15;
        endTime.setMinutes(roundedMinutes);
        endTime.setSeconds(0);

        let endTimestamp = Timestamp.fromDate(endTime);

        const data = {
          endDate: endTimestamp,
        };

        await eventManager.update(currentApp.key, event.key, data);

      }
    };

    if (isResizing) {
      document.addEventListener('mousemove', handleResize);
      document.addEventListener('mouseup', handleResizeEnd);
    } else {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    }

    // Clean up the listeners on unmount
    return () => {
      document.removeEventListener('mousemove', handleResize);
      document.removeEventListener('mouseup', handleResizeEnd);
    };
  }, [isResizing, resizeHeight, event]);

  const getEventStyle = (event, index) => {
    const startTime = event.startDate.toDate();
    const endTime = event.endDate.toDate();

    const top = (startTime.getHours() + startTime.getMinutes() / 60) * 70;
    const midnight = new Date(startTime);
    midnight.setHours(23, 59, 59, 999);

    const displayEndTime = endTime > midnight ? midnight : endTime;
    const durationHours = (displayEndTime - startTime) / (1000 * 60 * 60);
    const height = resizeHeight !== null ? resizeHeight : durationHours * 70 - 2;

    // Determine if this is a multi-day event
    const isMultiDay = endTime > midnight;

    let left = 0;
    let overlapCount = 0;
    for (let i = 0; i < index; i++) {
      const otherEvent = appEvents[i];
      const otherStartTime = otherEvent.startDate.toDate();
      const otherEndTime = otherEvent.endDate.toDate();

      if ((startTime >= otherStartTime && startTime < otherEndTime) || (endTime > otherStartTime && endTime <= otherEndTime)) {
        overlapCount++;
      }
    }

    left = leftOffset + (overlapCount * overlapIndent);

    return {
      top: `${top}px`,
      height: `${height}px`,
      left: `${left}px`,
      backgroundColor: theme.highlightBackgroundColor,
      borderColor: theme.backgroundColor,
      overflow: 'hidden',
      isMultiDay,  // New flag to indicate multi-day status
    };
  };

  const eventStyle = getEventStyle(event, index);

  return (
    <>
      <InlineStyles gradientColor={theme.highlightBackgroundColor} />

      <div
        ref={drag}
        className="calendar-day-event-container"
        onClick={() => handleEventClick(event)}
        style={{ ...eventStyle, opacity: isDragging ? 0.5 : 1 }}
      >
        <div
          className="calendar-day-event-title"
          style={{
            color: theme.highlightForegroundColor
          }}>
          {event.title}
        </div>

        <div
          className="calendar-day-event-time"
          style={{
            color: theme.highlightForegroundColor
          }}>
          {getEventTimespan(event)}
        </div>

        {event.attachments &&
          <div className="calendar-day-event-details" style={{ color: theme.highlightForegroundColor }}>
            {event.attachments.map((item, index) => (
              <div key={index} className="calendar-day-event-detail">
                {item.objectTitle}
              </div>
            ))}
            {Object.entries(event.formData)
              .filter(([key, value]) => {
                const field = event.fields.find(f => f.key === key);
                return field && field.type !== 'checklist';
              })
              .map(([key, value], index) => (
                <div key={index} className="calendar-day-event-detail">
                  {Array.isArray(value)
                    ? value.map(item => <div key={item.key}>{item.note || ''}</div>)
                    : value}
                </div>
              ))}
          </div>
        }

        {/* FADE */}
        <div className="calendar-day-event-fade"></div>

        {/* RESIZE HANDLE */}
        {!eventStyle.isMultiDay && (
          <div
            className="calendar-day-event-resize-handle"
            onMouseDown={handleResizeStart}
            style={{
              backgroundColor: theme.backgroundColor,
              opacity: isDragging ? 0.4 : 0.2,
            }}
          ></div>
        )}

      </div>
    </>
  );
};

export default CalendarDayEvent;
