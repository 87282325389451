import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Utilities
import { Permission, Section, UserPreference } from '../../utilities/Enums';
import { hasAppPermission } from '../../utilities/Permissions';

// Styles
import './Newsfeeds.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import NewsfeedAddButton from './newsfeedaddbutton/NewsfeedAddButton';
import NewsfeedAdd from './newsfeedadd/NewsfeedAdd';
import NewsfeedItem from './newsfeeditem/NewsfeedItem';

// Managers
import UserManager from '../../managers/UserManager';

const userManager = new UserManager();

/**
 * Newsfeeds Component
 * 
 * This component renders a newsfeed-related menu items in the menu.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Newsfeeds = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        appNewsfeeds,
        currentApp,
        currentUser,
        resetSelections,
        selectedNewsfeed,
        setNewsfeedAddOpen,
        setCurrentSection,
        setCurrentUser,
        setFormMode,
        setMenuVisible,
        setSelectedNewsfeed,
    } = useContext(Global);

    /**
     * Handles a newsfeed click event.
     * 
     * @param {object} newsfeed - Newsfeed that was clicked on.
     */
    const handleNewsfeedClick = async (model) => {
        resetSelections();

        setFormMode(null);

        setCurrentSection(Section.NEWSFEEDS)
        setSelectedNewsfeed(model);

        // Set the last screen preference
        const screen = {
            section: 'NEWSFEEDS',
            modelKey: model.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );
    };

    /**
     * Handles an add newsfeed button click event.
     */
    const handleAddNewsfeedClick = () => {
        setNewsfeedAddOpen(true);
        setMenuVisible(false);
    };

    return (
        <>
            {/* TITLE */}
            <div className="newsfeeds-group-title"
                style={{
                    color: theme.foregroundColorFaded,
                    fontFamily: currentApp.fontFamily
                }}>
                News

                {/* ADD NEWSFEED BUTTON */}
                {currentApp && hasAppPermission(currentApp, currentUser, Permission.MANAGE_NEWSFEEDS) &&
                    <NewsfeedAddButton
                        onClick={handleAddNewsfeedClick}
                    />
                }
            </div>

            {/* NEWSFEED LIST */}
            <div className="newsfeeds-group">

                {/* NEWSFEEDS */}
                {appNewsfeeds.map(model => (
                    <React.Fragment key={model.key}>
                        <NewsfeedItem
                            key={model.key}
                            model={model}
                            title={model.title}
                            isActive={selectedNewsfeed && selectedNewsfeed.key === model.key}
                            onClick={() => handleNewsfeedClick(model)} />
                    </React.Fragment>
                ))}

                {/* FORM */}
                <NewsfeedAdd />

            </div>

        </>
    );
};

export default Newsfeeds;
