// Firebase
import { collection, deleteDoc, doc, getDocs, query, setDoc, Timestamp, where } from 'firebase/firestore';
import { collections, db } from '../../firebaseConfig';

// Utilities
import { generateKey } from '../utilities/Keys';

// Managers
import { activity } from './ActivityManager';
import MemberManager from './MemberManager';
import DataManager from './DataManager';
import NotificationManager from './NotificationManager';
import UserIndexManager from './UserIndexManager';

const memberManager = new MemberManager();
const dataManager = new DataManager();
const notificationManager = new NotificationManager();
const userIndexManager = new UserIndexManager();

class InviteManager {

    /**
     * Method to add and process a new app member from an invite.
     * 
     * This will:
     * - Add the user as an app user
     * - Add the user to the user index for the app to enable searching
     * - Notify the app creator of the new member
     * - Navigate to the app
     * 
     * @param {string} key - The new key of the invite.
     * @param {string} data - App data
     * @returns {invite} - New invite record.
    */
    async addInviteMember(app, currentUser, invite) {

        // Current timestamp
        const now = Timestamp.now();

        // Add the app user.
        const memberKey = generateKey();
        const memberData = {
            key: memberKey,
            appKey: invite.appKey,
            userKey: currentUser.key,
            username: currentUser.username,
            email: currentUser.email,
            roles: invite.roles,
            dateJoined: now
        };

        console.log("Adding app user:", memberData);

        // Add the app user
        await memberManager.add(invite.appKey, memberKey, memberData);

        // Add the user to the user index for the app
        await userIndexManager.add(invite.appKey, currentUser);

        // Notify the creator of the new member by sending a notification.

        console.log("Notifying creator of new member...");

        const title = "New Member";
        const body = `${currentUser.username} has accepted an invite to join ${app.title}.`;

        try {
          const response = await notificationManager.sendNotification(
            app.userKey,
            title,
            body,
            '/icons/chat.png',
            app.domain
          );
          console.log('Notification response:', response);
        } catch (error) {
          console.error('Failed to send notification:', error);
        }
    }

    /**
     * Method to add a new invite.
     * 
     * @param {string} key - The new key of the invite.
     * @param {string} data - App data
     * @returns {invite} - New invite record.
    */
    async addInvite(key, data) {

        await setDoc(doc(db, collections.invites, key), data);

        activity.log(key, 'writes', 1);

        return data;
    }

    /**
     * Fetches an invite.
     * 
     * @param {string} key - Invite key.
     * @returns {Promise<Object|null>} A promise that resolves to the invite object if found, or null if not found.
    */
    async fetchInvite(key) {
        try {
            const inviteCollection = collection(db, collections.invites);
            const q = query(inviteCollection, where("key", "==", key));
            const querySnapshot = await getDocs(q);

            if (!querySnapshot.empty) {
                return querySnapshot.docs[0].data();
            } else {
                return null;
            }
        } catch (error) {
            console.error("Error fetching invite:", error);
            throw error;
        }
    }

    /**
     * Deletes an invite from the Firestore database.
     * 
     * @param {string} key - Invite key.
     */
    async deleteInvite(key) {
        try {

            await deleteDoc(doc(db, collections.invites, key));

        } catch (error) {
            console.error('Error deleting invite:', error);
            // Return an error message
            return { success: false, message: "Error deleting invite." };
        }
    }

    async processInvite(user, invite, setCurrentApp, setScreen) {

        // Get the app indicated in the invite
        const app = await dataManager.get(
            collections.apps,
            invite.appKey,
            invite.appKey
        );

        if (app) {

            // Since an invite exists, we need to check if the user is already a member
            // of the app referenced in the invite.
            const member = await memberManager.fetchMember(user.userKey, invite.appKey);

            if (!member) {

                console.log("User is not a member yet.");

                // Add the user as a member. This will:
                // - Add the user as an app user
                // - Add the user to the user index for the app to enable searching
                // - Notify the app creator of the new member
                // - Navigate to the app
                await this.addInviteMember(app, user, invite);


            } else {

                console.log("User is already a member.");

            }

            // Navigate to the app
            setCurrentApp(app);
            setScreen(Screen.RUN);

        } else {

            console.log("App specified in invite does no longer exists.");

            // Navigate to the home screen
            setScreen(Screen.HOME);

        }
    }
}

export default InviteManager;
