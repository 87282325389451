import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile, isTablet } from 'react-device-detect';

// Styles
import './Forum.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Components
import AddButton from '../../components/addbutton/AddButton';
import ForumPost from './forumpost/ForumPost';
import ForumPosts from './forumposts/ForumPosts';
import ForumPostForm from './forumpostform/ForumPostForm';
import ForumToolBar from './forumtoolbar/ForumToolBar';
import TagSelector from '../../../common/components/tagselector/TagSelector';

/**
 * Forum Component
 * 
 * This component renders a forum channel.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const Forum = () => {
  const { theme } = useTheme();
  const {
    selectedChannel,
    selectedForumPost,
    selectedForumTags,
    setSelectedForumTags,
  } = useContext(Global);

  // Local State
  const [formVisible, setFormVisible] = useState(false);

  // Handle the click event for the add button
  const handleAddClick = async () => {
    setFormVisible(true);
  };

  return (
    <>
      {/* CONTAINER */}
      <div className={(isMobile || isTablet) ? "forum-container-mobile" : "forum-container"}>

        {(!isMobile || (isMobile && !selectedForumPost)) && (
          <>
            {/* LEFT */}
            <div className="forum-left"
              style={{
                borderRightColor: theme.backgroundColorFaded
              }}>

              {/* TOOLBAR */}
              <div className="forum-toolbar">
                <ForumToolBar />
              </div>

              {/* TAGS */}
              <div className="forum-tag-selector">
                <TagSelector
                  tags={(selectedChannel && selectedChannel.tags) || []}
                  selectedTags={selectedForumTags}
                  onTagClick={setSelectedForumTags}
                />
              </div>

              {/* POSTS */}
              <div className="forum-posts">
                <ForumPosts />
              </div>

              {/* ADD BUTTON */}
              <div className="forum-add-button">
                <AddButton onClick={handleAddClick} />
              </div>

            </div>

          </>
        )}

        {selectedForumPost &&
          <>
            {/* RIGHT */}
            <div className={isMobile ? "forum-right-mobile" : "forum-right"}>
              <div className="forum-post">
                <ForumPost />
              </div>
            </div>
          </>
        }

      </div>

      {/* POST FORM */}
      <ForumPostForm
        isVisible={formVisible}
        setVisible={setFormVisible}
      />

    </>
  );
};

export default Forum;
