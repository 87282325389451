import React, { useContext } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { ChannelType, FormMode, MenuType, MenuSection, Section, UserPreference } from '../../utilities/Enums';

// Styles
import './MenuBar.css';

// Theme
import { useTheme } from '../../../ThemeContext';

// Images
import DockIcon from '../../svg/DockIcon';

// Components
import MenuBarButton from './MenuBarButton';

// Managers
import UserManager from '../../managers/UserManager';

const userManager = new UserManager();

const MenuBar = () => {

    // Theme
    const { theme } = useTheme();

    // Global
    const {
        appCalendars,
        appChannels,
        appCollections,
        appNewsfeeds,
        currentApp,
        currentUser,
        resetSelections,
        setCurrentSection,
        setCurrentUser,
        setFormMode,
        setMenuType,
        setSelectedCalendar,
        setSelectedCalendarType,
        setSelectedChannel,
        setSelectedCollection,
        setSelectedNewsfeed,
        setSelectedSetting,
        settings
    } = useContext(Global);

    const handleMenuShowClick = async () => {
        setMenuType(MenuType.LEFT);

        // Call the setPreference method to update LAST_THEME settings
        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_MENU_TYPE,
            MenuType.LEFT
        );
    };

    const handleCalendarSelect = async (option) => {
        const calendar = appCalendars.find((c) => c.key === option.key);
        setCurrentSection(Section.CALENDARS);
        setSelectedCalendar(calendar);

        // Set the last screen preference
        const screen = {
            section: 'CALENDARS',
            calendarKey: calendar.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );

        // Retrieve the saved LAST_CALENDAR_TYPE preference before displaying it.
        const lastCalendarType = userManager.getPreference(
            currentUser,
            currentApp.key,
            UserPreference.LAST_CALENDAR_TYPE
        );

        if (lastCalendarType) {
            setSelectedCalendarType(lastCalendarType);
        }

    }

    const handleChannelSelect = async (option) => {
        const channel = appChannels.find((c) => c.key === option.key);

        setSelectedCalendar(null);
        setSelectedCollection(null);
        setSelectedChannel(channel);

        if (channel.type === ChannelType.TEXT) { setCurrentSection(Section.CHAT) }
        if (channel.type === ChannelType.FORUM) { setCurrentSection(Section.FORUM) }
        if (channel.type === ChannelType.VIDEO) { setCurrentSection(Section.VIDEOCHAT) }

        // Set the last screen preference
        const screen = {
            section: 'CHANNELS',
            channelKey: channel.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );
    }

    const handleCollectionSelect = async (option) => {
        const appCollection = appCollections.find((appCollection) => appCollection.key === option.key);
        resetSelections();

        // Show the add form on the desktop
        if (!isMobile) {
            setFormMode(FormMode.ADD);
        }

        setCurrentSection(Section.COLLECTIONS);
        setSelectedCollection(appCollection);

        // Set the last screen preference
        const screen = {
            section: MenuSection.COLLECTIONS,
            modelKey: appCollection.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );
    }

    const handleNewsfeedSelect = async (option) => {
        const appNewsfeed = appNewsfeeds.find((appNewsfeed) => appNewsfeed.key === option.key);
        resetSelections();

        setCurrentSection(Section.NEWSFEEDS);
        setSelectedNewsfeed(appNewsfeed);

        // Set the last screen preference
        const screen = {
            section: MenuSection.NEWSFEEDS,
            newsfeedKey: appNewsfeed.key,
        }

        await userManager.setPreference(
            currentUser,
            setCurrentUser,
            currentApp.key,
            UserPreference.LAST_PAGE,
            screen
        );
    }

    const handleSettingSelect = async (opt) => {

        resetSelections();
        setSelectedSetting(settings.find(option => option.key === opt.key));
        setCurrentSection(Section.SETTINGS);

    }

    return (

        <div className="menu-bar-container">

            {/* DOCK DOWN BUTTON */}
            <div className="menu-bar-dock-down-button"
                title="Show Menu"
                onClick={handleMenuShowClick}>
                <DockIcon
                    color={theme.foregroundColorFaded}
                    width="24"
                    height="24"
                />
            </div>

            {/* SECTION BUTTONS */}
            <div className="menu-bar-buttons">

                {/* NEWSFEED */}
                <MenuBarButton
                    section={MenuSection.NEWSFEEDS}
                    title="News"
                    options={appNewsfeeds}
                    onSelect={handleNewsfeedSelect}
                />

                {/* COLLECTIONS */}
                <MenuBarButton
                    section={MenuSection.COLLECTIONS}
                    title="Collections"
                    options={appCollections}
                    onSelect={handleCollectionSelect}
                />

                {/* CALENDAR */}
                <MenuBarButton
                    section={MenuSection.CALENDARS}
                    title="Calendars"
                    options={appCalendars}
                    onSelect={handleCalendarSelect}
                />

                {/* CHANNELS */}
                <MenuBarButton
                    section={MenuSection.CHANNELS}
                    title="Channels"
                    options={appChannels}
                    onSelect={handleChannelSelect}
                />

                {/* SETTINGS */}
                <MenuBarButton
                    section={MenuSection.SETTINGS}
                    title="Settings"
                    options={settings}
                    onSelect={handleSettingSelect}
                    showAddButton={false}
                />

            </div>

        </div>
    );
};

export default MenuBar;
