import { useEffect, useContext } from 'react';

// Global
import { Global } from '../../Global';

// Firebase
import { collections } from '../../firebaseConfig';

// Activity
import { activity } from '../../common/managers/ActivityManager';

// Managers
import DataManager from '../../common/managers/DataManager';
const dataManager = new DataManager();

/**
 * Custom hook to load newsfeeds for a specific app.
 */
const usePreloadAppNewsfeeds = () => {

  // Global
  const { 
    currentApp, 
    setAppNewsfeeds 
  } = useContext(Global);

  useEffect(() => {
    // Exit early if no selected app or app key
    if (!currentApp || !currentApp.key) {
      setAppNewsfeeds([]);
      return;
    }

    // Function to load models
    const loadModels = (appKey) => {
      const handleUpdate = (items) => {
        setAppNewsfeeds(items);
        activity.log(appKey, 'reads', items.length);
      };

      const sortFields = [
        "sort", // Ascending by default
      ];

      const unsubscribe = dataManager.listAndSubscribe(
        collections.newsfeeds,
        appKey,
        handleUpdate,
        [],
        sortFields
      );

      return unsubscribe;
    };

    // Subscribe to models
    const unsubscribeModels = loadModels(currentApp.key);

    // Cleanup when component unmounts or when dependencies change
    return () => {
      if (unsubscribeModels) {
        unsubscribeModels();
      }
    };
  }, [currentApp?.key, setAppNewsfeeds]);

};

export default usePreloadAppNewsfeeds;
