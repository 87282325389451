// Managers
import PromptManager from './PromptManager';

const promptManager = new PromptManager();

class NewsfeedManager {

    /**
     * Generates a list of newsfeeds for a new app with the provided app title and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * @param {double} temperature - The preferred temperature for the AI to use.
     * 
     * @returns A parsable list of newsfeeds.
     */
    async generateNewsfeedList(appTitle, appDescription, temperature) {

        const newsfeedListPrompt = await this.prepareNewsfeedListPrompt(appTitle, appDescription);

        const response = await promptManager.sendJsonPrompt(newsfeedListPrompt, temperature);

        return response.newsfeeds;
    };

    /**
     * Prepares a prompt for generating a list of newsfeeds for an app based on an app's title 
     * and description.
     * 
     * @param {string} appTitle - The title of the app.
     * @param {string} appDescription - The description of the app.
     * 
     * @returns Prompt that can be used to generate a list of newsfeeds.
     */
    async prepareNewsfeedListPrompt(appTitle, appDescription) {

        let instructions = `
        [START INSTRUCTIONS]

        [GOAL]
        The goal of your response is to define a list of newsfeeds that might be useful based upon the title
        and description of the app. If nothing is applicable, you can simply return an empty list.

        Using the provided app TITLE and DESCRIPTION, define a list of newsfeeds that will be used to structure the app's data.
        Each newsfeed should contain a title, description, and an array of tags. The title should be a short, descriptive name 
        for the newsfeed, as it will be used in queries to RSS feed systems to pull in relevant data.

        If applicable, provide an array of tags for each newsfeed to categorize topics within the constraints of the feed.

        Your response MUST contain ONLY JSON, and no surrounding explanation or any other text. 
        Do not add comments or characters that might lead to the inability to parse the JSON.

        [APP TITLE AND DESCRIPTION]
        TITLE: ${appTitle}
        DESCRIPTION: ${appDescription}

        Do not add any unnecessary nesting, and do not structure the response differently from the example.

        [EXAMPLE RESPONSE]
        {
            "newsfeeds": [
                {
                    "title": "AI News",
                    "description": "News from the AI world.",
                    "tags": [
                        "OpenAI", 
                        "Gemini",
                        "LLMs",
                    ]
                },
                {
                    "title": "Financial Markets",
                    "description": "News from the financial markets.",
                    "tags": [
                        "Stocks",
                        "Cryptocurrency",
                        "Investing",
                    ]
                }
            ]
        }

        [END INSTRUCTIONS]`;

        return instructions;
    }
    
}

export default NewsfeedManager;
