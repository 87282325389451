import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../../Global';

// Device Detections
import { isMobile } from 'react-device-detect';

// Utilities
import { Screen, Section } from '../../utilities/Enums';

// Styles
import './NavBar.css';

// Images
import BookmarksIcon from '../../../common/svg/BookmarksIcon';
import FavoriteIcon from '../../../common/svg/FavoriteIcon';
import HomeIcon from '../../../common/svg/HomeIcon';
import MessagesIcon from '../../../common/svg/MessagesIcon';
import SearchIcon from '../../../common/svg/SearchIcon';
import UsersIcon from '../../../common/svg/UsersIcon';
import WandIcon from '../../../common/svg/WandIcon';

// Components
import NavButton from './NavButton';
import PromptModal from '../../../common/prompt/PromptModal';
import Search from '../../../common/search/Search';

// Managers
import ObjectManager from '../../../common/managers/ObjectManager';

const objectManager = new ObjectManager();

/**
 * NavBar Component
 * 
 * This component renders a nav button bar.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const NavBar = () => {

  // Global
  const {
    memberBookmarks,
    appCollections,
    memberFavorites,
    currentApp,
    currentSection,
    resetSelections,
    currentUser,
    setScreen,
    setCurrentApp,
    setCurrentSection,
    setSelectedCollection,
    setSelectedObject,
  } = useContext(Global);

  // Local State
  const [promptOpen, setPromptOpen] = useState(false);
  const [searchVisible, setSearchVisible] = useState(false);

  const handleSearch = async (entry) => {

    // Reset the visibility
    resetSelections();

    // Find the model in the appCollections array and set it to active
    const appCollection = appCollections.find(appCollection => appCollection.key === entry.modelKey);
    setSelectedCollection(appCollection);

    // Fetch the selected object and set it to active
    const object = await objectManager.fetch(currentApp.key, entry.modelKey, entry.objectKey);
    setSelectedObject(object);

    // Close the search dialog
    setSearchVisible(false);
  };

  const handleHomeClick = () => {

    // Reset Selections
    resetSelections();

    // Clear the current app
    setCurrentApp(null);

    if (currentUser) {
        setScreen(Screen.HOME);
    } else {
        setScreen(Screen.WELCOME);
    }
    
  };

  return (
    <>
      <div className="nav-bar-container">

        {/* HOME */}
        <NavButton
          icon={HomeIcon}
          title={`Appdeck Home`}
          size={isMobile ? 24 : 24}
          selected={false}
          onClick={handleHomeClick}
        />

        {/* SEARCH */}
        <NavButton
          icon={SearchIcon}
          title="Search"
          size={isMobile ? 25 : 25}
          selected={searchVisible}
          onClick={() => setSearchVisible(true)}
        />

        {/* BOOKMARKS */}
        {memberBookmarks.length > 0 &&
          <NavButton
            icon={BookmarksIcon}
            title="Bookmarks"
            size={isMobile ? 24 : 24}
            selected={currentSection === Section.BOOKMARKS}
            onClick={() => setCurrentSection(Section.BOOKMARKS)}
          />
        }

        {/* FAVORITES */}
        {memberFavorites.length > 0 &&
          <NavButton
            icon={FavoriteIcon}
            title="Favorites"
            size={isMobile ? 24 : 24}
            selected={currentSection === Section.FAVORITES}
            onClick={() => setCurrentSection(Section.FAVORITES)}
          />
        }

        {/* MESSAGES */}
        <NavButton
          icon={MessagesIcon}
          title="Direct Messages"
          size={isMobile ? 21 : 21}
          selected={currentSection === Section.MESSAGES}
          onClick={() => setCurrentSection(Section.MESSAGES)}
        />

        {/* MEMBERS */}
        <NavButton
          icon={UsersIcon}
          title="Members"
          size={isMobile ? 24 : 24}
          selected={currentSection === Section.MEMBERS}
          onClick={() => setCurrentSection(Section.MEMBERS)}
        />

        {/* QUICK NOTE 
        <NavButton
          icon={WandIcon}
          title="Quick Note"
          size={isMobile ? 18 : 18}
          selected={false}
          onClick={() => setPromptOpen(true)}
        />
        */}

      </div>

      {/* SEARCH FORM */}
      <Search
        onSearch={handleSearch}
        isVisible={searchVisible}
        setVisible={setSearchVisible}
      />

            {/* PROMPT MODAL */}
            <PromptModal
                isOpen={promptOpen}
                setOpen={setPromptOpen}
            />

    </>
  );
};

export default NavBar;

