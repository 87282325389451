import React, { useContext, useEffect, useState } from 'react';

// Routing
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Custom Hooks
import usePreloadApps from './home/hooks/PreloadApps.js';

// Navigation
import { useNavigate } from 'react-router-dom';

// Contexts
import { UserProvider } from './UserContext';
import { ThemeProvider } from './ThemeContext';
import { GlobalProvider, Global } from './Global';

// Components
import Root from './root/Root';

// Styles
import './App.css';

// Managers
import AppManager from './common/managers/AppManager';
import MemberManager from './common/managers/MemberManager';
import InviteManager from './common/managers/InviteManager';

const appManager = new AppManager();
const memberManager = new MemberManager();
const inviteManager = new InviteManager();

function App() {
  return (
    <GlobalProvider>
      <UserProvider>
        <Router>
          <div className="App-root">
            <AppDomainHandler>
              <ThemeConsumer>
                <Routes>
                  <Route path="/" element={<Root />} />
                  <Route path="/:domain" element={<Root />} />
                  <Route path="/:domain/:inviteKey" element={<Root />} />
                  <Route path="*" element={<Root />} />
                </Routes>
              </ThemeConsumer>
            </AppDomainHandler>
          </div>
        </Router>
      </UserProvider>
    </GlobalProvider>
  );
}

// Handles app domain and invite detection
const AppDomainHandler = ({ children }) => {
  const {
    currentUser,
    processedInviteKeys,
    resetSelections,
    setCurrentApp,
    setInvite,
    setProcessedInviteKeys,
    setScreen
  } = useContext(Global);

  const [app, setApp] = useState(null); // State for storing the app
  const [domain, setDomain] = useState(null); // State for storing the domain
  const [inviteKey, setInviteKey] = useState(null); // State for storing the invite key

  const navigate = useNavigate();

  usePreloadApps();

  useEffect(() => {
    const pathname = window.location.pathname;
    const segments = pathname.startsWith("/") ? pathname.slice(1).split("/") : [];
    setDomain(segments[0] || null); // Set domain
    setInviteKey(segments[1] || null); // Set invite key
  }, []);

  useEffect(() => {
    if (!domain) return; // Exit if no domain

    const fetchAppByDomain = async () => {
      console.log("Fetching app by domain:", domain);
      try {
        const fetchedApp = await appManager.getByDomain(domain.toLowerCase());
        if (fetchedApp) {
          setApp(fetchedApp); // Set the app
          if (!inviteKey) {
            resetSelections();
            setCurrentApp(fetchedApp);
            setScreen(Screen.RUN);
          }
        } else {
          console.warn(`App with domain "${domain}" not found.`);
          setApp(null); // Clear the app state
        }
      } catch (error) {
        console.error("Error fetching app by domain:", error);
      }
    };

    fetchAppByDomain();
  }, [domain]); // Only re-run if domain changes

  useEffect(() => {

    if (!app || !inviteKey) return; // Exit if app or inviteKey is not set

    // If the invite key has already been processed, exit
    if (processedInviteKeys.includes(inviteKey)) return;

    const processInvite = async () => {

      console.log("Checking invite with passed-in key:", inviteKey);

      try {

        // Check for an existing invite in the database matching the
        // invite key passed in the URL.
        const existingInvite = await inviteManager.fetchInvite(inviteKey);

        if (existingInvite) {

          console.log("Invite exists... setting invite in state:", existingInvite);

          setInvite(existingInvite);

          // Detect if the user is signed in
          if (currentUser) {

            console.log("User is logged in.");

            // We need to check if the user is already a member
            // of the app referenced in the invite.
            const member = await memberManager.fetchMember(currentUser.userKey, existingInvite.appKey);

            if (!member) {

              console.log("User is not a member yet.");

              // Add the user as a member. This will:
              // - Add the user as an app user
              // - Add the user to the user index for the app to enable searching
              // - Notify the app creator of the new member
              // - Navigate to the app
              await inviteManager.addInviteMember(app, currentUser, existingInvite);

            } else {

              console.log("User is already a member.");

              // Since the user is already a member, we'll just navigate to the app.
              setCurrentApp(app);
              setScreen(Screen.RUN);

            }


          } else {

            console.log("User is logged out.");

            console.log("Navigating to the site root in order to prompt for a login.");

            // Make sure we don't process the same keys multiple times in the same session
            setProcessedInviteKeys([...processedInviteKeys, inviteKey]);

            // Redirect to login by navigating to the site root. We need to do this
            // because we don't want parameters hanging around in the URL after the
            // user logs in. The invite will be waiting in state after login/signup.
            navigate('/');

          }

        } else {
          console.warn(`Invite with key "${inviteKey}" not found or expired.`);
          alert("Sorry, this invite does not exist or has expired.");
        }
      } catch (error) {
        console.error("Error fetching invite:", error);
      }
    };

    processInvite();

  }, [app, inviteKey, currentUser]); // Only re-run if app, inviteKey, or currentUser changes

  return children;
};

// Provides the theme context based on the current app
const ThemeConsumer = ({ children }) => {
  const { currentApp } = useContext(Global);

  return (
    <ThemeProvider currentApp={currentApp}>
      {children}
    </ThemeProvider>
  );
};

export default App;
