import React from 'react';

const GridIcon = ({ color = "white", width = "24", height = "24" }) => (

  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke={color}
    strokeLinecap="round"
    strokeLinejoin="round"
    fill="none">
    <path d="M3 6.75c0-1.768 0-2.652.55-3.2C4.097 3 4.981 3 6.75 3c1.768 0 2.652 0 3.2.55.55.548.55 1.432.55 3.2 0 1.768 0 2.652-.55 3.2-.548.55-1.432.55-3.2.55-1.768 0-2.652 0-3.2-.55C3 9.403 3 8.519 3 6.75m0 10.507c0-1.768 0-2.652.55-3.2.548-.55 1.432-.55 3.2-.55 1.768 0 2.652 0 3.2.55.55.548.55 1.432.55 3.2 0 1.768 0 2.652-.55 3.2-.548.55-1.432.55-3.2.55-1.768 0-2.652 0-3.2-.55C3 19.91 3 19.026 3 17.258M13.5 6.75c0-1.768 0-2.652.55-3.2.548-.55 1.432-.55 3.2-.55 1.768 0 2.652 0 3.2.55.55.548.55 1.432.55 3.2 0 1.768 0 2.652-.55 3.2-.548.55-1.432.55-3.2.55-1.768 0-2.652 0-3.2-.55-.55-.548-.55-1.432-.55-3.2m0 10.507c0-1.768 0-2.652.55-3.2.548-.55 1.432-.55 3.2-.55 1.768 0 2.652 0 3.2.55.55.548.55 1.432.55 3.2 0 1.768 0 2.652-.55 3.2-.548.55-1.432.55-3.2.55-1.768 0-2.652 0-3.2-.55-.55-.548-.55-1.432-.55-3.2" />
  </svg>

);

export default GridIcon;
