import React from 'react';

const FontIcon = ({ color = "white", width = "24", height = "24" }) => (
  <svg
    version="1.1"
    width={width}
    height={height}
    viewBox="0 0 24 24">
    <path fill={color} d="M11.307,4l-6,16h2.137l1.875-5h6.363l1.875,5h2.137l-6-16H11.307z M10.068,13L12.5,6.515L14.932,13H10.068z" />
  </svg>
);

export default FontIcon;
