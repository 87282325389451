import React, { useContext } from 'react';

// Global
import { Global } from '../../Global';

// Styles
import './LoginButton.css';

// Theme
import { useTheme } from '../../ThemeContext';

/**
 * LoginButton Component
 * 
 * This component renders a button to attempt a user login.
 * 
 * @param {function} onClick - The function to call on click.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const LoginButton = ({ onClick, enabled }) => {
  const { theme } = useTheme();
  const {
    currentApp,
    coreTheme
  } = useContext(Global);

  return (

    <div className="login-button-container"
      onClick={onClick}
      style={{
        color: enabled
          ? (currentApp ? theme.highlightForegroundColor : coreTheme.highlightForegroundColor)
          : (currentApp ? theme.foregroundColorFaded : coreTheme.foregroundColorFaded),
        backgroundColor: enabled
          ? (currentApp ? theme.highlightBackgroundColor : coreTheme.highlightBackgroundColor)
          : (currentApp ? theme.backgroundColorFaded : coreTheme.backgroundColorFaded)
      }}>
      Log In
    </div>

  );
};

export default LoginButton;
