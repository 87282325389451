import React, { useContext, useState } from 'react';

// Global
import { Global } from '../../Global';

// Device Detection
import { isMobile } from 'react-device-detect';

// Utilities
import { MemberThumbDirection, Screen } from '../../common/utilities/Enums';

// Styles
import './TitleBar.css';

// Components
import AppTitle from '../apptitle/AppTitle';
import MemberThumbs from '../../common/components/memberthumbs/MemberThumbs';
import NavBar from '../../common/components/navbar/NavBar';
import ThemeToggle from '../../desktop/components/themetoggle/ThemeToggle';
import UserButton from '../components/userbutton/UserButton';

/**
 * TitleBar Component
 * 
 * This component renders the title bar at the top of the screen.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const TitleBar = () => {

    // Global
    const {
        currentUser,
        currentApp,
        members,
        resetSelections,
        setCurrentApp,
        setScreen,
    } = useContext(Global);

    /**
     * Method to handle the APPDECK logo click event.
     */
    const handleLogoClick = () => {

        // Reset Selections
        resetSelections();

        // Clear the current app
        setCurrentApp(null);

        if (currentUser) {
            setScreen(Screen.HOME);
        } else {
            setScreen(Screen.WELCOME);
        }
    }

    return (
        <>
            {/* CONTAINER */}
            <div className="title-bar-container">

                {/* LEFT */}
                <div className="title-bar-left">

                    {!isMobile && currentApp && currentUser &&
                        <>
                            {/* APP INFO */}
                            <div className="title-bar-app"
                                onClick={handleLogoClick}>

                                {/* APP TITLE */}
                                <AppTitle />

                            </div>

                            {/* BUTTONS */}
                            <div className="title-bar-buttons">

                                {/* NAV BAR */}
                                {!isMobile && currentApp && currentUser &&
                                    <NavBar />
                                }

                            </div>
                        </>
                    }
                </div>

                {/* RIGHT */}
                <div className="title-bar-right">

                    {/* MEMBER THUMBS */}
                    {members &&
                        <div
                            className="title-bar-members"
                            title="Members">
                            <MemberThumbs
                                members={members}
                                direction={MemberThumbDirection.RTL}
                                size={22}
                                borderWidth={1}
                                offset={13}
                            />
                        </div>
                    }

                    {/* THEME */}
                    {currentApp &&
                        <ThemeToggle />
                    }

                    {/* USER BUTTON */}
                    {currentUser &&
                        <div
                            className="title-bar-current-user"
                            title="My Account">
                            <UserButton />
                        </div>
                    }

                </div>

            </div>

        </>
    );
};

export default TitleBar;
