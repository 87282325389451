import React, { useContext } from 'react';

// Global
import { Global } from '../Global';

// Device detection
import { isMobile } from 'react-device-detect';

// Styles
import './Home.css';

// Images
import gradient from '../site/gradient.jpg';

// Components
import AccountAbout from '../account/about/AccountAbout';
import AccountBilling from '../account/billing/AccountBilling';
import AccountMenu from '../account/menu/AccountMenu';
import AddAppButton from './AddAppButton.js';
import Apps from './apps/Apps';
import LogoLarge from '../common/logolarge/LogoLarge';
import TitleBar from '../common/titlebar/TitleBar';

// Managers
import NotificationManager from '../common/managers/NotificationManager';

const notificationManager = new NotificationManager();

const Home = () => {

  // Global
  const {
    accountAboutVisible,
    accountBillingVisible,
    accountVisible,
    coreTheme,
    currentUser,
  } = useContext(Global);

  // HOOKS

  // Send notification
  const sendNotification = async (userKey, title, body) => {

    console.log("Sending notification to:", userKey);
    console.log("Title:", title);
    console.log("Body:", body);

    try {
      const response = await notificationManager.sendNotification(userKey, title, body, '/icons/chat.png', 'bobcattracker');
      console.log('Notification response:', response);
    } catch (error) {
      console.error('Failed to send notification:', error);
    }
  };

  return (
    <div className="home-container"
      style={{
        backgroundColor: coreTheme.backgroundColor,
        backgroundImage: `url(${gradient})`, 
        backgroundSize: 'cover', 
        backgroundRepeat: 'no-repeat',
        backgroundAttachment: 'fixed', 
        height: '100vh',
        width: '100vw',
      }}>

      {/* TITLE BAR */}
      <div className="home-title-bar">
        <div className="home-title-bar-wrapper">
          <TitleBar />
        </div>
      </div>

      {/* MOBILE */}
      {isMobile ? (
        <>
          {accountBillingVisible ? (
            <AccountBilling />
          ) : (
            <>
              {accountAboutVisible ? (
                <AccountAbout />
              ) : (
                <>
                  {accountVisible ? (
                    <AccountMenu />
                  ) : (
                    <div className="home-scroller">
                      {/*<VideoNetwork />*/}
                      <div className="home-logo">
                        <LogoLarge />
                      </div>

                      <div className="home-tagline"
                        style={{
                          color: coreTheme.foregroundColorFaded,
                        }}>
                        A new era of app-building is here.
                      </div>

                      <div className="home-add">
                        <AddAppButton />
                      </div>

                      <div className="home-apps">
                        <Apps />
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <>
          {/* DESKTOP */}
          <div className="home-scroller">
            {/* <VideoNetwork /> */}
            <div className="home-logo">
              <LogoLarge />
            </div>

            <div className="home-tagline"
              style={{
                color: coreTheme.foregroundColorFaded,
              }}>
              A new era of app-building is here.
            </div>

            <div className="home-add">
              <AddAppButton />
            </div>

            <div className="home-apps">
              <Apps />
            </div>
          </div>
        </>
      )}


      <div className="add-app-button-container"
        onClick={() => sendNotification(currentUser.userKey, "New Message", 'You have a new message!')}
        style={{
          backgroundColor: coreTheme.highlightBackgroundColor,
          color: coreTheme.highlightForegroundColor,
        }}>
        Send Test Message
      </div>
    </div>
  );

};

export default Home;

